import React, { useState } from 'react';
import Button, {ButtonWrap} from '@hubins/components/Button';
import { __ } from 'localisation';
import ChooseSecuritiesInput from '@hubins/components/ChooseSecuritiesInput';
import Input from '@hubins/components/Input';
import InputCurrency from '@hubins/components/Input/Currency';
import middleman from 'helpers/middleman';
import ErrorMessage from 'components/ErrorMessage';
import { Column, Columns } from 'components/Columns'
import UnitPriceInput from '@hubins/components/UnitPriceInput';
import ExchangeRateInput from '@hubins/components/ExchangeRateInput';
import TradeAmountInput from '@hubins/components/TradeAmountInput';
import DecimalInput from '@hubins/components/DecimalInput';
import ChoosePortfolioInput from '@hubins/components/ChoosePortfolioInput';
import Comments from '@hubins/components/Comments';

type Props = {
  selectedTransaction: any;
  setSelectedTransaction: (transaction: any) => void;
  setCreateNew: (createNew: boolean) => void;
  setChooseSources: (chooseSources: boolean) => void;
  portfolioUuid: string | undefined;
  setProfileUuid: (profileUuid: string | undefined) => void;
}

const Step2 = ({
  selectedTransaction,
  setSelectedTransaction,
  setCreateNew,
  setChooseSources,
  portfolioUuid,
  setProfileUuid
}: Props) => {
  const [security, setSecurity] = useState<any>(selectedTransaction?.security || null);
  const [amount, setAmount] = useState<number | string>(selectedTransaction?.amount ? selectedTransaction.amount / 100 : 0);
  const [units, setUnits] = useState<number | string>(selectedTransaction?.units || 0);
  const [origin, setOrigin] = useState<string | number>(selectedTransaction?.origin || '');
  const [originAccount, setOriginAccount] = useState<string | number>(selectedTransaction?.origin_account || '');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [unitPrice, setUnitPrice] = useState<number | string>(selectedTransaction?.unit_price || '');
  const [exchangeRate, setExchangeRate] = useState<number | string>(selectedTransaction?.exchange_rate || '');
  const [selectedPortfolio, setSelectedPortfolio] = useState<any>(selectedTransaction?.portfolio || null);
  const [contactName, setContactName] = useState<string | number>(selectedTransaction?.contact_name || '');
  const [contactEmail, setContactEmail] = useState<string | number>(selectedTransaction?.contact_email || '');
  const [contactPhone, setContactPhone] = useState<string | number>(selectedTransaction?.contact_phone || '');

  const save = async (chooseSources: boolean) => {
    setLoading(true);

    try {

      if (!amount) {
        throw new Error(__('amount_is_required'));
      }

      if (!security) {
        throw new Error(__('security_is_required'));
      }

      if (!units) {
        throw new Error(__('units_is_required'));
      }

      if (!unitPrice) {
        throw new Error(__('unit_price_is_required'));
      }

      if (!exchangeRate) {
        throw new Error(__('exchange_rate_is_required'));
      }

      const url = selectedTransaction ? `/ql/crm/transaction/update/${selectedTransaction.uuid}` : '/ql/crm/transaction/store';
      const response = await middleman.promisePost(url, {
        portfolioUuid: portfolioUuid || selectedPortfolio?.uuid || selectedTransaction?.portfolio_uuid,
        amount,
        units,
        origin,
        originAccount,
        type: 'TOF',
        status: 'draft',
        security_id: security ? security.id : undefined,
        unitPrice: unitPrice,
        exchangeRate: exchangeRate,
        currency: security?.currency || undefined,
        contactName,
        contactEmail,
        contactPhone,
      });

      response.data.security = security;

      setSelectedTransaction(response.data);

      if (chooseSources) {
        if (!portfolioUuid) {
          const profileUuid = selectedPortfolio?.policy_holder[0]?.uuid;
          if (!profileUuid) {
            throw new Error(__('no_policy_holder_found'));
          }
          setProfileUuid(profileUuid);
        }
        setChooseSources(true);
        return;
      }

    } catch (e) {
      setError(e?.response?.data?.message || e.message);
    }
    setLoading(false);
  }

  return (
    <>
      <Button
        className='cta s-bottom-xl s-top-xl'
        icon='arrow left 1'
        iconColor='white'
        iconLeft
        onClick={() => {
          setCreateNew(false);
          setSelectedTransaction(null);
      }}>
        {__('back')}
      </Button>
      <Columns>
      {!portfolioUuid && (
        <Column s="12">
          <ChoosePortfolioInput
            callback={setSelectedPortfolio}
            value={selectedPortfolio}
            controlled
          />
        </Column>
      )}
      <Column s="12">
        <ChooseSecuritiesInput
          callback={setSecurity}
          value={security}
          controlled
        />
      </Column>
      <Column l="6">
        <InputCurrency
          label={__('choose_amount')}
          value={amount}
          callback={setAmount}
          suffix='SEK'
        />
      </Column>
      <Column l="6">
        <UnitPriceInput
          value={unitPrice}
          callback={setUnitPrice}
          securityId={security?.id}
        />
      </Column>
      <Column l="6">
        <Input
          label={__('currency')}
          value={security?.currency || ''}
          disabled={true}
          controlled={true}
        />
      </Column>
      <Column l="6">
        <ExchangeRateInput
          currency={security?.currency}
          callback={setExchangeRate}
          value={exchangeRate}
        />
      </Column>
      <Column l="6">
        <DecimalInput
          label={__('choose_units')}
          value={units}
          callback={setUnits}
        />
      </Column>
      <Column l="6">
        <Input
          label={__('choose_origin')}
          value={origin}
          callback={setOrigin}
        />
      </Column>
      <Column l="6">
        <Input
          label={__('choose_origin_account')}
          value={originAccount}
          callback={setOriginAccount}
        />
      </Column>
      <Column l="6">
        <TradeAmountInput
          units={units}
          unitPrice={unitPrice}
          exchangeRate={exchangeRate}
          currency={'SEK'}
          multiplier={security?.multiplier}
        />
      </Column>
      <Column l="6">
        <Input
          label={__('contact_name')}
          value={contactName}
          callback={setContactName}
        />
      </Column>
      <Column l="6">
        <Input
          inputType='email'
          label={__('contact_email')}
          value={contactEmail}
          callback={setContactEmail}
        />
      </Column>
      <Column l="6">
        <Input
          inputType='tel'
          label={__('contact_phone')}
          value={contactPhone}
          callback={setContactPhone}
        />
      </Column>
      {selectedTransaction && (
        <Column l="12">
          <Comments
            model='Transaction'
            modelUuid={selectedTransaction?.uuid}
            presetChannels={['hubins_backoffice', 'hubins_compliance', 'quantum_backoffice', 'quantum_compliance']}
          />
        </Column>
      )}
      <Column l="12">
          <ButtonWrap>
            <Button onClick={() => save(true)} disabled={loading} loading={loading}>
              {__('choose_sources')}
            </Button>
            <Button onClick={() => save(false)} disabled={loading} loading={loading} className='cta'>
              {__('save')}
            </Button>
          </ButtonWrap>
        </Column>
      <ErrorMessage text={error} showError={!!error} />
      </Columns>
    </>
  )
};

export default Step2;