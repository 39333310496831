import React, { useState, useEffect } from 'react';
import Button from '@hubins/components/Button';
import { Column, Columns } from 'components/Columns';
import Dropdown from '@hubins/components/Dropdown';
import { useArchivedApprovals } from 'queries/approvals';
import { tableHeaders, actionOptions, loadingState } from 'helpers/approvals';
import ApiPaginationTable from 'modules/Tables/ApiPaginationTable';

const ArchiveApprovals = () => {

  const [args, setArgs] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasFetched, setHasFetched] = useState(false);

  const { data, isFetched, isLoading, refetch } = useArchivedApprovals(args, page + 1);

  useEffect(() => {
    if (isFetched && !hasFetched) {
      setTotalPages(data.pages);
      setHasFetched(true);
    }
  }, [isFetched]);

  const search = (value) => {
    setArgs(value);
    setPage(0);
  };

  const refresh = () => {
    refetch();
  };


  return (
    <Columns>
      <Column s="12" className="space-between flex--row flex align-center align-left">
        <Dropdown
          label={"Filter by action"}
          options={actionOptions}
          value={args}
          callback={search}
          placeholder="Action"
          className="input-max-width"
        />

        <Button className="medium" onClick={refresh} icon="Button Refresh Arrows by Streamlinehq">
          Refresh
        </Button>
      </Column>
      <Column s="12">
        <ApiPaginationTable
          headers={tableHeaders}
          data={(!data || !data.result || isLoading || !isFetched) ? loadingState : data.result}
          fetchData={setPage}
          pageCount={totalPages}
          index={page}
        />
      </Column>
    </Columns>
  );

};

export default ArchiveApprovals;
