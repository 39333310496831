import React from 'react';
import FormHistory from 'modules/FormHistory';

const Terms = ({ contact, profileUuid, portfolioUuid }) => {
  const isCompany = contact.type === 'COMPANY';
  const key = isCompany
    ? 'c_terms'
    : 'p_terms';

  return <FormHistory
    formKey={key}
    contactName={contact.name}
    portfolioUuid={portfolioUuid}
    profileUuid={profileUuid}
  />;


};

export default Terms;
