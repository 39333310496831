import React from 'react';
import './index.scss';
import { LoadingBar } from '@hubins/components/LoadingIndicators';

interface BadgeProps {
  className?: string,
  color?: string,
  static?: boolean,
  children?:any
}

const Badge = ({
  static: propsStatic = false,
  ...props
}:BadgeProps) => (
  props.children ?
    (
      <span className={`badge ${props.className ? props.className : ''} ${props.color ? props.color : ''}`}>
        {props.children}
      </span>
    ) :
    propsStatic ? (
      <span className={`badge badge__static ${props.className ? props.className : ''} ${props.color ? props.color : ''}`} />
    )
      : (
        <LoadingBar small />
      )
);

export default Badge;
