import React, { useState } from 'react';
import Heading from '@hubins/components/Heading'
import Dropdown from '@hubins/components/Dropdown';

interface Props {
  callback: (year: Number) => void;
}

const YearlyReportDateSelector = ({ callback }:Props) => {

  const currentYear = new Date().getFullYear() - 1;
  const [selected, setSelected] = useState(currentYear);

  const yearDiff = currentYear - 2020;
  const options:any = [];

  for (let i = 0; i <= yearDiff; i++) {

    options.push({
      title: 2020 + i,
      id: 2020 + i
    }); // TODO: Temp fix to test future years
    // options.push(<option key={i} value={2020 + i}>{2020 + i}</option>); // TODO: Temp fix to test future years
    // options.push(<option key={i} value={2021 + i}>{2021 + i}</option>);
  }

  const handleChange = (val) => {
    setSelected(val);
    callback(val);
  }

  return (
    <>
        <Dropdown
          className="s-top-l"
          id="years"
          label="Välj år"
          options={options}
          value={selected}
          callback={handleChange}
        />
    </>
  )

};

export default YearlyReportDateSelector;