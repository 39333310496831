import {
  useQuery,
} from 'react-query';

import middleman from 'helpers/middleman';

export const useTransactions = (portfolioUuid: string, status: string | undefined = undefined, type: string | undefined = undefined ) => useQuery(['transactions', portfolioUuid, status, type], () => {
  return middleman.promisePost(`/transactions/get/${portfolioUuid}`, { status, type, })
    .then(res => res.data);
}, { enabled: !!portfolioUuid, refetchOnWindowFocus: false});

export const useBackofficeTransactions = (portfolioUuid: string, status: string | undefined = undefined, type: string | undefined = undefined) => useQuery(['bo_transactions', portfolioUuid, status, type], () => {
  return middleman.promisePost(`/ql/crm/transactions/get/${portfolioUuid}`, { status, type, })
    .then(res => res.data);
}, { enabled: !!portfolioUuid, refetchOnWindowFocus: false });

export const usePremiumTransactions = (portolfioUuid: string) => useQuery(['premiumTransactions', portolfioUuid], () => {
  return middleman.promisePost(`/transactions/show`, {
        portfolioUuids: [portolfioUuid],
        type: 'PRE'
      }).then(res => res.data);
}, { enabled: !!portolfioUuid, refetchOnWindowFocus: false });

export const useClientTofTransactions = (portfolioUuid: string) => useQuery(['clientTofTransactions', portfolioUuid], () => {
  return middleman.promisePost(`/transactions/show?withSecurity=true`, {
    portfolioUuids: [portfolioUuid],
    type: 'TOF'
  }).then(res => res.data);
}, { enabled: !!portfolioUuid, refetchOnWindowFocus: false });

export const useTofTransactions = (status?: string, approval_status?: string, portfolioUuids?: string[]) => useQuery(['tofTransactions', status, approval_status, portfolioUuids], () => {
  return middleman.promisePost(`/ql/crm/transactions/show?withSecurity=true`, {
        status: status || undefined,
        approval_status: approval_status || undefined,
        type: 'TOF',
        portfolioUuids: portfolioUuids || undefined
      }).then(res => res.data);
}, { enabled: !!status || !!approval_status, refetchOnWindowFocus: false });

export const useFilteredTransactions = (enabled: boolean, statuses?: string, type?: string, portfolioUuid?: string, portfolioUuids?: string[], securityId?: number, currency?: string, startDate?: string, endDate?: string, page?: number) => useQuery(['filteredTransactions', statuses, type, portfolioUuid, portfolioUuids, securityId, currency, startDate, endDate, page], () => {
  return middleman.promisePost(`/ql/crm/transactions/show?withSecurity=true&page=${page}`, {
    statuses,
    type,
    portfolioUuids: portfolioUuid ? [portfolioUuid] : portfolioUuids ? portfolioUuids : undefined,
    securityId,
    currency,
    startDate,
    endDate,
  }).then(res => res.data);
}, { enabled: enabled, refetchOnWindowFocus: false });

export const useBrokerTransactions = (enabled: boolean, statuses?: string, type?:string, securityId?: number, currency?: string, startDate?: string, endDate?: string, page?: number) => useQuery(['filteredTransactions', statuses, type, securityId, currency, startDate, endDate, page], () => {
  return middleman.promisePost(`/crm/transactions/show?withSecurity=true&page=${page}`, {
    statuses,
    type,
    securityId,
    currency,
    startDate,
    endDate,
  }).then(res => res.data);
}, { enabled: enabled, refetchOnWindowFocus: false });

export const useFATransactions = (
  startDate: string | undefined = undefined,
  endDate: string | undefined = undefined,
  typeCodes: string[] | undefined = undefined,
  portfolioId: string | undefined = undefined,
  portfolioUuids: string[] | undefined = undefined
) => useQuery(['faTransactions', startDate, endDate, typeCodes, portfolioId, portfolioUuids], () => {
  return middleman.promisePost('/ql/crm/fa/transactions', { startDate, endDate, typeCodes, portfolioShortName: portfolioId, portfolioUuids }).then(res => res.data);
}, { enabled: true, refetchOnWindowFocus: false });

export const useUnbookedTransactions = (initiated: boolean, page: number) => useQuery(['unbookedTransactions', page], () => {
  return middleman.get(`/ql/crm/transactions/pending-bookings?page=${page}`).then(res => res.data);
}, { enabled: initiated, refetchOnWindowFocus: false });
