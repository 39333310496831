import React from 'react';
import DynamicLoadPage from '@hubins/components/DynamicLoadPage';
import LoadingAnimation from "@hubins/components/HubinsLoadingAnimation";

const Chart = DynamicLoadPage({
  loader: () => import('./Chart'),
  loadingComponent: LoadingAnimation,
});

const InsuranceVsDirectBars = (props) => {
  return <Chart {...props} />;
};

export default InsuranceVsDirectBars;
