import React, { useEffect, useState, useRef, useCallback } from 'react';
import middleman from 'helpers/middleman';
import SimpleTable from 'modules/Tables/SimpleTable';
import Button from '@hubins/components/Button';
import RisksHistoryModal from '@hubins/components/RisksHistoryModal';
import DateInput from '@hubins/components/DateInput';
import { getSelectedDatePlusOneDay } from 'helpers/date';
import LoadingBar from '@hubins/components/LoadingIndicators';
import FMAHistory from '@hubins/components/FMAHistory/FMAHistory';
import __ from 'localisation';
import { getRiskGroup } from 'helpers/fma';

type Props = {
  profileUuid: string;
  portfolioUuid: string;
  withoutSearch?: boolean;
  endDate?: string;
};

const loadingObj = {
  key: <LoadingBar />,
  text: <LoadingBar />,
  score: <LoadingBar />,
  history: <LoadingBar />,
  items: []
}

const loadingState = [
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
]

const FMATable = ({profileUuid, portfolioUuid, withoutSearch, endDate: propsEndDate}: Props) => {

  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(propsEndDate ? getSelectedDatePlusOneDay(propsEndDate) : getSelectedDatePlusOneDay());
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [score, setScore] = useState(0);
  const [riskGroup, setRiskGroup] = useState(2);
  const [history, setHistory] = useState([]);
  const [showFmaHistory, setShowFmaHistory] = useState(false);
  const [importedFmaScore, setImportedFmaScore] = useState(0);

  const search = useCallback(() => {
    setEndDate(getSelectedDatePlusOneDay(date));
  }, [date, setEndDate]);

  const headers = useRef([
    {
      Header: '',
      accessor: 'key',
      maxWidth: 10,
    },
    {
      Header: '',
      accessor: 'text',
    },
    {
      Header: '',
      accessor: 'score',
    },
    {
      Header: '',
      accessor: 'history',
      Cell: ({row}) => (
        <div className='flex-end'>
          <Button className='transparent' icon="arrow right 1" disabled={!row.original.items || (Array.isArray(row.original.items) && row.original.items.length === 0)} onClick={() => setHistory(row.original.items)}>
            {__('view_history')}
          </Button>
        </div>
      )
    }
  ])

  const fetchFmaData = async () => {
    try {
      setLoading(true);
      const [
        response,
        fmaImportedResponse
      ] = await Promise.all([
        middleman.get(`/ql/crm/risk-assessment/fma/${profileUuid}/${portfolioUuid}/${endDate}`),
        middleman.get(`/ql/crm/risk-assessment/fma/manual-risk/${portfolioUuid}`)
      ]);
      const score = response.data.reduce((acc, curr) => acc + curr.score, 0);
      setRows(response.data);
      setScore(score);
      setRiskGroup(getRiskGroup(score));
      setImportedFmaScore(fmaImportedResponse?.data || 0);
      setLoading(false);
    } catch (e) {
      console.error(`Error fetching FMA data: ${e}`);
      setLoading(false);
    }

  }


  useEffect(() => {
    fetchFmaData();
  }, [endDate])

  const handleModalClose = () => {
    setHistory([]);
  }

  return (
    <>
      {!withoutSearch && (
        <>
          <DateInput
            label={__('date')}
            value={date}
            callback={setDate}
            className='input-max-width'
          />
          <Button
            onClick={search}
            icon="Button Refresh Arrows by Streamlinehq"
            className='s-top-md'
          >
            {__('search')}
          </Button>
        </>
      )}
      <RisksHistoryModal callback={handleModalClose} data={history} />
      <FMAHistory
        profileUuid={profileUuid}
        portfolioUuid={portfolioUuid}
        open={showFmaHistory}
        setOpen={setShowFmaHistory}
      />
      <br />
      <Button
        onClick={() => setShowFmaHistory(true)}
        className='s-top-md s-bottom-md'
        icon="Common File Stack by Streamlinehq"
      >
        Full History
      </Button>
      <div className='s-bottom-md'>
        <p>Total Score: <strong>{score}</strong> <br /> Risk Categorization: <strong>{riskGroup}</strong><br />Imported Risk Categorization from FA: <strong>{importedFmaScore}</strong></p>
      </div>
      <SimpleTable headers={headers.current} data={loading ? loadingState : rows} />
    </>
  )
};

export default FMATable;