import React, { useEffect, useState } from 'react';
import middleman from 'helpers/middleman';
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import Heading from '@hubins/components/Heading';
import ApprovalPortfolioSummaryWithPurpose from '@hubins/components/ApprovalPortfolioSummaryWithPurpose/ApprovalPortfolioSummaryWithPurpose';
// import { ApprovalPortfolioTransactionsList } from '@hubins/components/ApprovalPortfolioTransactions/ApprovalPortfolioTransactions';
import SofItem from './SofItem';
import __ from 'localisation';


const Sof = ({ profile_uuid, /*source_of_funds_uuid,*/ canEdit, endDate }) => {

  const [loading, setLoading] = useState(true);
  const [sources, setSources] = useState([]);
  const [portfolios, setPortfolios] = useState([]);

  const fetchInitData = async () => {
    setLoading(true);
    try {
      const sofPromise = await middleman.get(`/ql/crm/source-of-funds/filter/${profile_uuid}${endDate ? `/${endDate}` : ''}`);
      const portfoliosPromise = middleman.get(`/ql/crm/profile/portfolios/${profile_uuid}`);
      const [sofResponse, portfoliosResponse] = await Promise.all([sofPromise, portfoliosPromise]);
      setSources(sofResponse.data);
      setPortfolios(portfoliosResponse.data);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchInitData();
  }, []);

  if (loading) return (<LoadingBox />);

  return (
    <>

      <Heading size="4" className="s-top-m">
        {__('portfolios')}
      </Heading>
      {portfolios.map((portfolio) => (
        <ApprovalPortfolioSummaryWithPurpose key={portfolio.uuid} portfolioUuid={portfolio.uuid} portfolio={portfolio} endDate={endDate} />
      ))}
      {/* <ApprovalPortfolioTransactionsList portfolios={portfolios} status="draft" title={__("pending_transactions")} /> */}
      <Heading size="4" className="s-top-xl">
        {__('sof')}
      </Heading>

      {sources.map((source) => {
        return (
          <SofItem
            key={source.uuid}
            refetch={fetchInitData}
            canEdit={canEdit}
            {...source}
          />
        );
      })}
    </>
  );
};




export default Sof;
