import React from 'react';
import SettingsExpandableFormSummary from '@hubins/components/SettingsExpandableFormSummary';

const privateKeys = [
  'p_presale',
  'p_da_terms',
  'p_da_purpose',
  'p_da_transactions',
  'p_da_products',
  'p_da_target_return',
  'p_da_tax',
  'p_da_beneficiaries',
  'p_da_so',
  'p_ins',
  'p_terms',
  'p_aftersale',
];

const companyKeys = [
  'c_presale',
  'c_da_terms',
  'c_da_purpose',
  'c_da_transactions',
  'c_da_products',
  'c_da_target_return',
  'c_da_tax',
  'c_da_beneficiaries',
  'c_da_so',
  'c_ins',
  'c_terms',
  'c_aftersale',
];

const PortfolioRegistration = ({ portfolioUuid, profileUuid, profile, endDate }) => {

  const isCompany = profile.type === 'COMPANY';
  const keys = isCompany ? companyKeys : privateKeys;

  return (
    <>
      {keys.map((key) => (
        <SettingsExpandableFormSummary
          key={key}
          formKey={key}
          portfolioUuid={portfolioUuid}
          titleSize="5"
          profileUuid={profileUuid}
          endDate={endDate}
          />
      ))}
    </>
  )

};

export default PortfolioRegistration;