import React, { useState, useEffect, useContext } from 'react';
import { FormErrorContext } from '@hubins/components/FormErrorHandler';
import { getForm } from 'helpers/form';
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import CreateForm from 'modules/CreateForm';
import { formDataToCollection } from 'helpers/filters';
import middleman from 'helpers/middleman';
import Wrapper from '@hubins/components/Wrapper';
import { appendAllFieldValues } from 'helpers/formscms';
import { Column, Columns } from 'components/Columns';
import { mergeAllFiles, filterFilesToUpload } from 'helpers/createForm';
import { useHistory } from 'react-router-dom';
import SourceConfirmation from '@hubins/components/SourceConfirmation';
import SofChangeNotes from '@hubins/components/SofChangeNotes';
import Button from '@hubins/components/Button';
import __ from 'localisation';

const SourceOfFunds = ({ match }) => {
  const { setError: setFormError } = useContext(FormErrorContext);
  const { type, profileUuid, sourceId } = match.params;

  const [form, setForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [submitBody, setSubmitBody] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesError, setFilesError] = useState(false);
  const [showChangeNotes, setShowChangeNotes] = useState(false);

  const history = useHistory();

  const init = async () => {
    let formRes = await getForm(type, 'sof', profileUuid, undefined);
    if (sourceId) {
      const sourceRes = await middleman.promiseGet(`/ql/crm/source-of-funds/${profileUuid}/${sourceId}`);
      const source = sourceRes.data;
      const formResponse = source.form;
      formRes.content = appendAllFieldValues(formRes.content, formResponse.response);
    }
    setForm(formRes);
  };

  useEffect(() => {
    init();
  }, []);

  const saveResponse = async (changeNote) => {
    try {
      setError(false);
      setFilesError(false);
      setLoading(true);
      let sourceIdOfSource = sourceId;
      if (sourceId) {
        await middleman.promisePost(`/ql/crm/source-of-funds/update/${profileUuid}/${sourceId}`, { ...submitBody, changeNote: { text: changeNote }});
      } else {
        const result = await middleman.promisePost(`/ql/crm/source-of-funds/create/${profileUuid}`, { ...submitBody, changeNote: { text: changeNote }});
        sourceIdOfSource = result.data.uuid;
      }

      if (files && files.length > 0) {
        const filesPromises = files.map((item) => {
          const formData = new FormData();
          formData.append('file', item);
          formData.append('filename', item.name);
          return middleman.postFormData(`/ql/crm/proof-of-funds/create/${profileUuid}/${sourceIdOfSource}`, formData).catch(err => {
            console.error(err.message);
            const message = `Något gick fel när filen ${item.name} skulle laddas upp. Kontrollera att filen inte är för stor och försök igen.`;
            setFilesError(message);
            throw new Error(message);
          });
        });
        await Promise.all(filesPromises);
      }
      history.goBack();
    } catch (e) {
      console.error(e);
      setFormError((e.response.data && e.response.data.message)
        ? e.response.data.message
        : __('common_error_validation'));
      setError(__('common_error_validation'));
      setLoading(false);
      setSubmitBody(false);
      setShowChangeNotes(false);
    }
  };

  const edit = () => {
    setLoading(false);
    setSubmitBody(false);
  };

  const submit = (values) => {
    const body = {
      key: form.key,
      type: form.type,
      version: form.version,
      data: formDataToCollection(values),
    };
    const newFiles = filterFilesToUpload(mergeAllFiles(values));
    setFiles(newFiles);
    setShowConfirmation(true);
    setSubmitBody(body);
  };

  const confirm = () => {
    setShowChangeNotes(true);
    setShowConfirmation(false);
  };

  if (!form) return <LoadingBox />;

  return (
    <>
      <Button icon="arrow left 1" iconLeft onClick={() => history.goBack()}>
        {__("cancel")}
      </Button>
      <SofChangeNotes
        open={showChangeNotes}
        close={() => setShowChangeNotes(false)}
        callback={saveResponse}
        loading={loading}
        error={error}
      />
      {(submitBody && showConfirmation) && (
        <SourceConfirmation
          body={submitBody}
          saveResponse={confirm}
          edit={edit}
          loading={loading}
          setError={setError}
          type={type}
        />
      )}
      <Wrapper>
        <Columns>
          <Column l="6">
            <CreateForm
              formData={form.content}
              submitType={submit}
              submitText={__("save")}
              buttonProps={{ icon: "arrow right 1" }}
              loading={loading}
              disabled={loading}
              error={filesError || error}
            />
          </Column>
        </Columns>
      </Wrapper>
    </>
  );

};

export default SourceOfFunds;
