import React from 'react';
import FormHistory from 'modules/FormHistory';

const InsuranceKYC = ({ contact, profileUuid, portfolioUuid }) => {
  const isCompany = contact.type === 'COMPANY';
  const key = isCompany
    ? 'c_ins'
    : 'p_ins';

  return <FormHistory
    formKey={key}
    contactName={contact.name}
    portfolioUuid={portfolioUuid}
    profileUuid={profileUuid}
  />;


};

export default InsuranceKYC;
