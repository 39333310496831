import React, { useState, useEffect } from 'react';
import { getFieldValue } from 'helpers/summary';
import ExpandableSummaryTable from 'components/ExpandableSummaryTable';
import SummaryItem from 'components/SummaryItem';
import LoadingBar from '@hubins/components/LoadingIndicators';
import { getLanguage } from 'helpers/form';


import {
  displayStatement,
} from 'helpers/formscms';

import __ from 'localisation/hubins/registration';

import middleman from 'helpers/middleman';


const ExpandableFormSummary = ({
  title,
  formKey,
  afterLoading,
  caseId,
  withoutTitle = false,
  sourceId,
  type,
  portfolioUuid,
  profileUuid,
  editLink,
  summary: propsSummary,
  titleSize,
  endDate = undefined
}) => {
  const [loading, setLoading] = useState(propsSummary ? false : true);
  const [summary, setSummary] = useState(propsSummary || false);


  const fetchForms = async () => {
    if (!propsSummary) {
      setLoading(true);

      // TODO: remove /ql/crm endpoint
      // const endpoint = uid ? '/ql/crm/forms/summary' : '/forms/summary';
      const endpoint = '/forms/summary';
      const result = await middleman.promisePost(endpoint, { key: formKey, caseId, sourceId, type, portfolioUuid, profileUuid, language: getLanguage(), endDate })
        .then(res => (res.data))
        .catch(() => {
          console.error('failed fetching: ', formKey);
          return false;
        });
      setSummary(result);
      setLoading(false);
    }
  };

  const renderRepeaterFields = (repeater, value, depth) => {
    const indexedFields = repeater.fields.reduce((fields, field) => {
      fields[field.id] = field;
      return fields;
    }, {});

    return value.reduce((rFields, set, index) => {

      const keys = Object.keys(set);

      const setFields = keys.map((rKey, rIndex) => {
        const field = indexedFields[rKey];

        if (!field) {
          return null;
        }

        return (
          <SummaryItem
            key={`field-${index}-rep-${rIndex}`}
            title={field?.title || field?.label || field?.heading || field?.description || ''}
            value={getFieldValue(field, set)}
            depth={depth}
          />);
      });

      return [...rFields, ...setFields];
    }, []);

  };

  useEffect(() => {
    fetchForms();
  }, [profileUuid, portfolioUuid, endDate]);

  useEffect(() => {
    if (!loading && afterLoading) {
      afterLoading();
    }

  }, [loading]);

  if (loading) {
    return <LoadingBar large h1 />;
  }

  return (
    <ExpandableSummaryTable
      heading={withoutTitle ? '' : title ? title : __(summary.key)}
      editLink={editLink}
      titleSize={titleSize}
    >
      {(loading || summary === false || !summary?.response || summary?.response?.length === 0) ? (
        <>
          {loading ? (
            <LoadingBar large h1 />
          ) : "Hittade ingen data"}
        </>
      ) : (
        <>
          {summary.content.reduce((arr, section) => {
            if (section.fields) {
              section.fields.forEach(field => {

                const hasVisibleConditions = field.visibleConditions && field.visibleConditions.length > 0;
                const isVisible = typeof summary.response[field.id] !== 'undefined' && summary.response[field.id] !== null;

                if (
                  displayStatement(field)
              || !isVisible
                ) {
                  return;
                }

                if (field.type === 'repeater') {
                  const summaryItems = renderRepeaterFields(field, summary.response[field.id], hasVisibleConditions ? 1 : 0);
                  arr.push([...summaryItems]);
                  return;
                }

                arr.push([
                  <SummaryItem
                    key={field.id}
                    title={field?.title || field?.label || field?.heading || field?.description || ''}
                    value={getFieldValue(field, summary.response)}
                    depth={hasVisibleConditions ? 1 : 0}
                  />,
                ]);

              });
            }


            return arr;
          }, [])}
        </>
      )}
    </ExpandableSummaryTable>
  );

};

export default ExpandableFormSummary;
