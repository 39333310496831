import {
  useQuery,
} from 'react-query';

import {
  endowmentHandler,
  summariesHandler,
  transactionsHandler,
  HISTORY_endowmentHandler,
  summariesHandler_ALL,
  chartHandler_ALL,
} from './responseFormatter';

import middleman from 'helpers/middleman';

export const useReport = (startDate: string, endDate: string, portfolioUuid: string) => useQuery(['report', startDate, endDate, portfolioUuid], () => {
  console.log('Fetching report for:', { startDate, endDate, portfolioUuid });
  return middleman.get(
    `/v1/fa/reports/${portfolioUuid}/${startDate}/${endDate}`
  ).then((response) => {
    console.log('Report API Response:', response);
    return {
      summaries: summariesHandler(response),
      endowment: endowmentHandler(response),
      currency: response.data.currency,
      distribution: response.data.distributions,
      proceeds: response.data.proceeds,
      accounts: response.data.accounts,
      marketValue: response.data.marketValue,
    }
  }).catch(error => {
    console.error('Report API Error:', error);
    throw error;
  });
}, {enabled: !!portfolioUuid && !!startDate && !!endDate, refetchOnWindowFocus: false});

export const useChart = (startDate: string, endDate: string, portfolioUuid: string) => useQuery(['chart', startDate, endDate, portfolioUuid], () => {
  return middleman.get(
    `/v1/fa/reports/graphql/chart/${portfolioUuid}/${startDate}/${endDate}`
  ).then((response) => {
    return response.data;
  });
}, { enabled: !!portfolioUuid && !!startDate && !!endDate, refetchOnWindowFocus: false });

export const useTransactions = (startDate: string, endDate: string, portfolioUuid: string) => useQuery(['transactions', startDate, endDate, portfolioUuid], () => {
  console.log('Fetching transactions for:', { startDate, endDate, portfolioUuid });
  return middleman.get(
    `/fa/transactions/${portfolioUuid}/${startDate}/${endDate}`
  ).then((response) => {
    console.log('Transactions API Response:', response);
    return {
      transactions: transactionsHandler(response),
      endowment: HISTORY_endowmentHandler(response),
    };
  }).catch(error => {
    console.error('Transactions API Error:', error);
    throw error;
  });
}, { enabled: !!portfolioUuid && !!startDate && !!endDate, refetchOnWindowFocus: false });

export const useHoldings = (startDate: string, endDate: string, portfolioUuid: string) => useQuery(['holdings', startDate, endDate, portfolioUuid], () => {
  return middleman.get(
    `/fa/holdings/${portfolioUuid}/${startDate}/${endDate}`
  ).then((response) => {
    return response.data;
  });
}
,{ enabled: !!portfolioUuid && !!startDate && !!endDate, refetchOnWindowFocus: false });

export const useAllReports = (startDate: string, endDate: string, portfolioUuid: string) => useQuery(['allReports', startDate, endDate, portfolioUuid], () => {
  return middleman.get(
    `/v1/fa/reports/all/${portfolioUuid}/${startDate}/${endDate}`
  ).then((response) => {
    return {
      summaries: summariesHandler_ALL(response),
      chart: chartHandler_ALL(response),
    }
  });
}, {enabled: !!portfolioUuid && !!startDate && !!endDate, refetchOnWindowFocus: false});