import { Column } from 'components/Columns';
import React from 'react';
import { __ } from 'localisation';
import { formatMoney } from 'helpers';
import { LoadingBar } from '@hubins/components/LoadingIndicators';

export const PortfolioBalanceList = ({ balance }) => {

  const { cashBalance, securitiesBalance, totalBalance, accruedInterest } = balance;

  return (
    <Column s="12" className="selected_account_info s-top-m nospace">
      <ul className="selected_account_info__list numbers">
        <li>
          <strong className="t-uc c-neutral-6">{__('value_securities')}</strong><br />
          <strong className="t-uc">{securitiesBalance ? formatMoney(securitiesBalance) : <LoadingBar medium />}</strong>
        </li>
        <li>
          <strong className="t-uc c-neutral-6">{__('liquid_funds')}</strong><br />
          <strong className="t-uc">{cashBalance ? formatMoney(cashBalance) : <LoadingBar medium />}</strong>
        </li>
        <li>
          <strong className="t-uc c-neutral-6">{__('accrued_interest')}</strong><br />
          <strong className="t-uc">{accruedInterest ? formatMoney(accruedInterest) : <LoadingBar medium />}</strong>
        </li>
        <li>
          <strong className="t-uc c-neutral-6">{__('just_total')}</strong><br />
          <strong>{totalBalance ? formatMoney(totalBalance) : <LoadingBar medium />}</strong>
        </li>
      </ul>
    </Column>
  );
};

export default PortfolioBalanceList;
