import React, { useState, useEffect } from 'react';
import middleman from 'helpers/middleman';
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import Box from 'components/Box';

type Props = {
  portfolioUuid: string;
};

const FaNotes = ({ portfolioUuid }: Props) => {

  const [notes, setNotes] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  const fetchNotes = async () => {
    try {
      const response = await middleman.get(`/ql/crm/portfolio/fa-notes/${portfolioUuid}`);
      setNotes(response.data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching notes: ', err);
    }
  };


  useEffect(() => {
    fetchNotes();
  }, [])

  if (loading) {
    return <LoadingBox />;
  }

  return (
    <>
      <Box bg="neutral-2" className="s-top-xl">
        <p style={{whiteSpace: "break-spaces"}}>{notes}</p>
      </Box>
    </>
  )

};

export default FaNotes;