import React from 'react';
import PrivateDocuments from '@hubins/components/Documents/PrivateDocuments';

type Props = {
  portfolioUuid: string;
  profileUuid: string;
}

const InternalDocuments = ({ portfolioUuid, profileUuid }: Props) => {

  return (
    <div className='s-top-xl'>
      <PrivateDocuments
        compact
        portfolio
        portfolioUuid={portfolioUuid}
        tags="internalDocument"
        excludeTag={false}
        className="s-top-xl"
      />
      <PrivateDocuments
        compact
        portfolioUuid={portfolioUuid}
        profileUuid={profileUuid}
        tags="internalDocument"
        excludeTag={false}
        className="s-top-xl"
      />
    </div>
  )

};

export default InternalDocuments;