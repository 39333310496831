import React from 'react';
import { useAllSecurities } from 'queries/securities';
import Button from '@hubins/components/Button';
import __ from 'localisation';
import LoadingBar from '@hubins/components/LoadingIndicators';
import SimpleTable from 'modules/Tables/SimpleTable';

type Props = {
  callback: (security: object) => void;
}

const loadingObj = {
  choose: <LoadingBar small />,
  name: <LoadingBar small />,
  isinCode: <LoadingBar small />,
  securityCode: <LoadingBar small />
}

const ChooseSecurities = ({ callback }: Props) => {
  const { data, isLoading } = useAllSecurities();

  const headers = [
    {
      Header: '',
      accessor: 'choose',
      Cell: ({ row }) => (
        <Button
          loading={isLoading}
          disabled={isLoading}
          className='small'
          icon="arrow right 1"
          onClick={() => callback(row.original)}
        >
          {__('choose')}
        </Button>
      )
    },
    {
      Header: __('name'),
      accessor: 'name',
    },
    {
      Header: __('isin'),
      accessor: 'isinCode',
    },
    {
      Header: __('security_code'),
      accessor: 'securityCode',
    },
  ]

  const tableData = isLoading ? [
    loadingObj,
    loadingObj,
    loadingObj,
    loadingObj,
  ] : data || [];


  return (
    <SimpleTable
      data={tableData}
      headers={headers}
    />
  )
};

export default ChooseSecurities;