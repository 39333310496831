import React from 'react';
import { Columns, Column } from 'components/Columns';
import Portfolios from './Portfolios';
import Comments from '@hubins/components/Comments';
import SimpleTable from 'modules/Tables/SimpleTable';
import LoadingBar from '@hubins/components/LoadingIndicators';
import Heading from '@hubins/components/Heading';
import __ from 'localisation';

const typeValueHeaders = [
  {
    Header: '',
    accessor: 'type',
  },
  {
    Header: '',
    accessor: 'value',
  }
];



const Overview = ({profile}) => {

  const clientData = [
    {
      type: __('name'),
      value: profile?.name || <LoadingBar />,
    },
    {
      type: __('national_identity_number'),
      value: profile?.national_identity_number || <LoadingBar />,
    },
    {
      type: __('updated_at'),
      value: profile?.updated_at || <LoadingBar />,
    }
  ];

  return (
    <Columns>
      <Column l="6">
        <Heading size={"4"} className='s-bottom-m'>{__('menu_users')}</Heading>
        <SimpleTable data={clientData} headers={typeValueHeaders} />
      </Column>
      <Column l="6">
        <Heading size={"4"} className='s-bottom-m'>{__('portfolios')}</Heading>
        <Portfolios profileUuid={profile.uuid} />
      </Column>
      <Column xs="12">
        <Comments
          model='Profile'
          modelUuid={profile.uuid}
          presetChannels={['hubins_backoffice', 'hubins_compliance', 'quantum_backoffice', 'quantum_compliance']}
        />
      </Column>
    </Columns>
  )
};

export default Overview;