import React from 'react';
import Button from '@hubins/components/Button';
import Icon from '@hubins/components/IconNew';
import __ from 'localisation';

import './index.scss';


const SummaryItem = ({
  title,
  value = '',
  depth,
  error = false,
  children,
  handleEdit,
  dataId,
  className,
}) => {

  return (
    <>
      <li
        className={`summary-item ${error ? 'summary-item--error' : ''} ${className ? className : ''}`}
        onClick={handleEdit ? handleEdit : undefined}
        data-id={dataId}
      >
        <strong className="summary-item__cell summary-item__cell--title clarification" style={{ paddingLeft: depth ? ((depth - 1) * 20 + 7 + 'px') : undefined }}>
          {depth > 0 && (
            <Icon icon="Arrow direction" size={16} spacing="right" />
          )} {title && <span className={error ? 'c-danger' : ''}>{title}</span>}
        </strong>
        <span className="summary-item__cell summary-item__cell--value">
          {value || '-'}
          {error && <span className="summary-item__error c-danger">{__('error_empty')}</span>}
        </span>
        {(depth === 0 && handleEdit) && <Button onlyIcon onClick={handleEdit} className="summary-item__cell summary-item__cell--button"><Icon label={__('edit')} icon="pencil write 2" /></Button>}

      </li>
      {children}

    </>
  );
};

export default SummaryItem;
