import React, { useState } from 'react';
import __ from 'localisation';
import __reg from 'localisation/hubins/registration';
import KYC from 'views/Client/Registration/KYC';
import BasicInfo from 'views/Client/Registration/BasicInfo';
import CompanyBasicInfo from 'views/Client/Registration/CompanyBasicInfo';
import Knowledge from 'views/Client/Registration/Knowledge';
import Dropdown from '@hubins/components/Dropdown';

type Props = {
  profileUuid: string,
  nationalIdentityNumber: string,
  isCompany: boolean
  profile: any
}

const Registration = ({ profileUuid, isCompany, profile  }: Props) => {

  const [selectedTab, setSelectedTab] = useState('');

  const components = {
    'roaring': <BasicInfo contact={profile} />,
    'kyc': <KYC contact={profile} profileUuid={profileUuid} />,
    'kt': <Knowledge contact={profile} profileUuid={profileUuid} />,
    'bi': <CompanyBasicInfo contact={profile} profileUuid={profileUuid} />,
  }

  const options = isCompany ? [
    {
      id: 'kt',
      title: __reg('p_kt'),
    },
    {
      id: 'bi',
      title: __reg('c_bi'),
    },
    {
      id: 'kyc',
      title: __reg('p_kyc'),
    },
  ] : [
    {
      id: 'roaring',
      title: __reg('p_roaring'),
    },
    {
      id: 'kt',
      title: __reg('p_kt'),
    },
    {
      id: 'kyc',
      title: __reg('p_kyc'),
    },
  ]

  return (
    <>
      <div className='s-left-s s-bottom-l'>
        <Dropdown
          className={"input-max-width"}
          label={__('form')}
          options={options}
          value={selectedTab}
          callback={setSelectedTab}
          placeholder={__('choose_form')}
        />
      </div>
      {components[selectedTab]}
    </>
  )

};

export default Registration;