import React, { useState } from 'react';
import Dropdown from '@hubins/components/Dropdown';
import Button from '@hubins/components/Button';
import middleman from 'helpers/middleman';
import { Column, Columns } from 'components/Columns';
import ErrorMessage from 'components/ErrorMessage';
import __ from 'localisation';

type Props = {
  portfolio: any;
}

const statusOptions = [
  { title: __('registration_completed'), id: 'issued' },
  { title: __('registration_pending'), id: 'pending' },
  { title: __('inactive'), id: 'inactive' },
  { title: __('portfolio_cancelled'), id: 'cancelled' },
];

const Portfolio = ({ portfolio }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [status, setStatus] = useState<string>(portfolio.status || '');


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setError(null);
      setLoading(true);
      await middleman.post(`/ql/crm/portfolio/update/${portfolio.uuid}`, { status });
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Columns>
        <Column l="6">
          <Dropdown label={__('status')} options={statusOptions} value={status} callback={setStatus} className="input-max-width" />
        </Column>
        <Column s="12" className="s-top-l">
          <Button type="submit" loading={loading} className="cta">
            {__('save')}
          </Button>
          <ErrorMessage text={error} showError={!!error} />
        </Column>
      </Columns>
    </form>
  );
};

export default Portfolio;