import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'components/Tabs';
import { __ } from 'localisation';
import Approvals from './Approvals';
import { isBackofficeUser } from 'helpers/userRoles';
import RiskTool from './RiskTool';
import Orders from './Orders';
import ClientView from './ClientView';
import RegistrationTabs from './RegistrationTabs';
import Portfolio from './Portfolio';
import Dashboard from './Dashboard';

const tabIndexes = {
  'dashboard': 0,
  'client-view': 1,
  'registration': 2,
  'fma': 3,
  'approvals': 4,
  'orders': 5,
  'portfolio': 6,
};

const tabs = [
  'dashboard',
  'client-view',
  'registration',
  'fma',
  'approvals',
  'orders',
  'portfolio',
];

const Client = ({ match, history, client, portfolio, userRoles }) => {
  const { profileUuid, portfolioUuid, tab } = match.params;
  const [index, setIndex] = useState( (tab && typeof tabIndexes[tab] !== 'undefined') ? tabIndexes[tab] : 0);
  const backOfficeUser = isBackofficeUser(userRoles);

  const changeTab = (newIndex) => {
    const newTab = tabs[newIndex];
    history.replace({ pathname: `/dashboard/clients/${profileUuid}/${portfolioUuid}/tabs/${newTab}` });
    setIndex(newIndex);
  };

  return (
    <>
      <Tabs
        className="line-tabs"
        selectedTabClassName="line-tabs__tab--selected"
        selectedTabPanelClassName="line-tabs__tab-panel--selected"
        onSelect={changeTab}
        selectedIndex={index}
      >
        <TabList className="line-tabs__tab-list">
          <Tab className="heading heading--5 line-tabs__tab">{__('overview')}</Tab>
          <Tab className="heading heading--5 line-tabs__tab">{__('client_view')}</Tab>
          {backOfficeUser && (
            <>
              <Tab className="heading heading--5 line-tabs__tab">{__('registration')}</Tab>
              <Tab className="heading heading--5 line-tabs__tab">{__('risk_tool')}</Tab>
              <Tab className="heading heading--5 line-tabs__tab">{__('menu_approvals')}</Tab>
              <Tab className="heading heading--5 line-tabs__tab">{__('orders')}</Tab>
              <Tab className="heading heading--5 line-tabs__tab">{__('portfolio')}</Tab>
            </>
          )}
        </TabList>
        <TabPanel className={`line-tabs__tab-panel`}>
          <Dashboard client={client} portfolio={portfolio} />
        </TabPanel>
        <TabPanel className={`line-tabs__tab-panel`}>
          <ClientView
            portfolio={portfolio}
            client={client}
            profileUuid={profileUuid}
            portfolioUuid={portfolioUuid}
          />
        </TabPanel>

        {backOfficeUser && (
          <>
            <TabPanel className={`line-tabs__tab-panel`}>
              <RegistrationTabs
                portfolioUuid={portfolioUuid}
                profileUuid={profileUuid}
                client={client}
                portfolio={portfolio}
              />
            </TabPanel>
            <TabPanel className={`line-tabs__tab-panel`}>
              <RiskTool
                client={client}
                portfolioUuid={portfolioUuid}
                profileUuid={profileUuid}
                portfolio={portfolio}
              />
            </TabPanel>
            <TabPanel>
              <Approvals
                portfolioUuid={portfolioUuid}
                profileUuid={profileUuid}
              />
            </TabPanel>
            <TabPanel className={`line-tabs__tab-panel`}>
              <Orders
                portfolioUuid={portfolioUuid}
                profileUuid={profileUuid}
              />
            </TabPanel>
            <TabPanel className={`line-tabs__tab-panel`}>
              <Portfolio portfolio={portfolio} />
            </TabPanel>
          </>
        )}
      </Tabs>
    </>
  );
};

export default Client;
