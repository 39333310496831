import React from 'react';
import FMATable from '@hubins/components/FMATable/FMATable';
import { Tab, Tabs, TabList, TabPanel } from 'components/Tabs';
import useTabs from 'hooks/useTabs';
import { useState } from 'react';
import DueDiligenceResponses from './DueDiligenceResponses';
import HubinsRisks from './HubinsRisks';
import FaNotes from './FaNotes';
import InternalDocuments from './InternalDocuments';
import BusinessProfile from 'views/Client/NEW_BusinessProfile';
import __ from 'localisation';

const tabs = [
  'business-profile',
  'fma',
  'hubins',
  'dd',
  'fa-notes',
  'internal-documents'
];

const RiskTool = ({ profileUuid, portfolioUuid, client, portfolio }) => {
  const [index, setIndex] = useState(0);
  useTabs(setIndex, index, tabs);
  return (
    <>
      <Tabs
        className="line-tabs"
        selectedTabClassName="line-tabs__tab--selected"
        selectedTabPanelClassName="line-tabs__tab-panel--selected"
        onSelect={setIndex}
        selectedIndex={index}
      >
        <TabList className="line-tabs__tab-list line-tabs__submenu">
          <Tab className="heading heading--5 line-tabs__tab">{__('business_profile')}</Tab>
          <Tab className="heading heading--5 line-tabs__tab">{__('risk_tool')}</Tab>
          <Tab className="heading heading--5 line-tabs__tab">{__('risk_profile')}</Tab>
          <Tab className="heading heading--5 line-tabs__tab">{__('due_dilligence_checks')}</Tab>
          <Tab className="heading heading--5 line-tabs__tab">{__('fa_notes')}</Tab>
          <Tab className="heading heading--5 line-tabs__tab">{__('internal_documents')}</Tab>
        </TabList>
        <TabPanel className={`line-tabs__tab-panel`}>
          <BusinessProfile
            portfolioUuid={portfolioUuid}
            profileUuid={profileUuid}
            profile={client}
            portfolio={portfolio}
          />
        </TabPanel>
        <TabPanel className={`line-tabs__tab-panel`}>
          <FMATable profileUuid={profileUuid} portfolioUuid={portfolioUuid} />
        </TabPanel>
        <TabPanel className={`line-tabs__tab-panel`}>
          <HubinsRisks portfolioUuid={portfolioUuid} />
        </TabPanel>
        <TabPanel className={`line-tabs__tab-panel`}>
          <DueDiligenceResponses profileUuid={profileUuid} portfolioUuid={portfolioUuid} />
        </TabPanel>
        <TabPanel className={`line-tabs__tab-panel`}>
          <FaNotes portfolioUuid={portfolioUuid} />
        </TabPanel>
        <TabPanel className={`line-tabs__tab-panel`}>
          <InternalDocuments portfolioUuid={portfolioUuid} profileUuid={profileUuid} />
        </TabPanel>
      </Tabs>
    </>
  );
};

export default RiskTool;
