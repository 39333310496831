import React from 'react';

// Import React Table
import SimpleTable from 'modules/Tables/SimpleTable';
import LoadingBar from '@hubins/components/LoadingIndicators';
import { getMoney, formatPercent } from 'helpers';
import './index.scss';
import __ from 'localisation';

const loadingObj = {
  type: <LoadingBar />,
  share: <LoadingBar />,
  sum: <LoadingBar />,
};

const loadingState = [
  loadingObj,
  loadingObj,
  loadingObj,
];

const DistributionTable = ({ data, isLoading, currency, marketValue }) => {

  const headers = [
    {
      Header: __('access_law'),
      accessor: 'type',
      Cell: ({ row }) => __(row.original.type),
    },
    {
      Header: __('market_value'),
      accessor: 'sum',
      Cell: ({ row }) => getMoney(row.original.sum, currency),
    },
    {
      Header: __('distribution'),
      accessor: 'share',
      Cell: ({ row }) => formatPercent(row.original.share),
    },
  ];

  const generateHeaders = () => {
    if (isLoading) return headers;

    let newHeaders = headers;

    const footers = { share: 100, sum: marketValue };

    newHeaders[1].Footer = getMoney(footers.sum, currency);
    newHeaders[2].Footer = formatPercent(footers.share);
    return newHeaders;

  };

  return (
    <SimpleTable
      className="ReactTable--tight"
      headers={generateHeaders()}
      data={isLoading ? loadingState : data}
      footer
    />
  );
};

export default DistributionTable;
