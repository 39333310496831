import React, { useEffect } from "react";
import ReactModal from "react-modal";
import Button from "@hubins/components/Button";
import "./index.scss";
import { $ } from "helpers";

//This is needed for screenReaders to ignore the content of the page when modal is open.
ReactModal.setAppElement(
  document.getElementById("app") || document.getElementById("root")
);

type ModalProps = {
  callback?: any;
  className?: string;
  overlayClassName?: string;
  open: boolean;
  shouldCloseOnOverlayClick?: boolean;
  children: React.ReactNode;
  contentLabel?: string;
  closeButton?: boolean;
  contentClass?: string;
  noWrapper?: boolean;
};

/**
 * [Modal description]
 * @prop {className} string adds extra class for use in css
 * @prop {open} bool true if it should be open.
 */

const Modal = ({
  callback,
  className = "",
  overlayClassName,
  open = false,
  shouldCloseOnOverlayClick,
  children,
  contentLabel,
  closeButton = false,
  contentClass = "",
  noWrapper = false,
  ...restProps
}: ModalProps) => {
  const closeModal = () => {
    callback && callback(false);
  };

  useEffect(() => {
    const handleEsc = event => {
      if (event.keyCode === 27) {
        closeModal();
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return (
    <ReactModal
      data={restProps}
      onRequestClose={callback && closeModal}
      className={`modal ${className}`}
      isOpen={open}
      overlayClassName={`modal__overlay ${overlayClassName} ${open ? "modal__overlay--open" : "modal__overlay--closed"
        }`}
      closeTimeoutMS={$.t.base}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      contentLabel={contentLabel}
    // closeTimeoutMS={10000}
    >
      <div className={`${noWrapper ? "" : "modal__content"} ${contentClass}`}>
        {closeButton && (
          <Button
            onClick={closeModal}
            label="Close modal"
            onlyIcon
            icon="remove"
            className="button-icon--absolute"
          />
        )}
        {children}
      </div>
    </ReactModal>
  );
};

export default Modal;
