import React, {useState, useEffect} from 'react';
import ApprovalProfilePortfolioSummary from '../ApprovalProfilePortfolioSummary';
import Box from 'components/Box';
import Heading from '@hubins/components/Heading';
import Icon from '@hubins/components/IconNew';
import { getSummary } from "helpers/form";
import { useCollapse } from 'react-collapsed';
import FormSummary from 'modules/FormsSummary/Summary';
import AccordeonButton from '@hubins/components/AccordeonButton';
import __ from 'localisation';

type Props = {
  portfolio?: any,
  portfolioUuid: string,
  endDate: string,
}

const ApprovalPortfolioSummaryWithPurpose = ({
  portfolio,
  portfolioUuid,
  endDate,
}: Props) => {

  const [purposeSummary, setPurposeSummary] = useState<any>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  useEffect(() => {
    if (portfolio) {
      const policyHolder = portfolio.policy_holder[0];
      const isCompany = policyHolder.type === 'COMPANY';
      const purposeKey = isCompany ? 'c_da_purpose' : 'p_da_purpose';
      getSummary(purposeKey, policyHolder.uuid, portfolioUuid, setPurposeSummary, false, { endDate })
    }
  }, [portfolio])

  const handleClick = () => { setIsExpanded(!isExpanded); };

  return (
    <Box className="border">
      <AccordeonButton {...getToggleProps({ onClick: handleClick })} isExpanded={isExpanded} className="hover-pointer wide flex flex--row space-between">
        <div className="">
          <Heading element="span" size="5" className="c-">
            {__('portfolio_information')}
          </Heading>
          <span className="s-left-m body-small c-primary--500">{portfolio.short_name}</span>
        </div>
      </AccordeonButton>
      <div {...getCollapseProps()}>
      <ApprovalProfilePortfolioSummary portfolio={portfolio} portfolioUuid={portfolioUuid} />
      {purposeSummary && (
          <FormSummary
            content={purposeSummary.content}
            response={purposeSummary.response}
            title={__('purpose')}
            className="s-top-m"
          />
      )}
      </div>
    </Box>
  )

};

export default ApprovalPortfolioSummaryWithPurpose;