import React, { useState, useEffect } from 'react';
import Button from '@hubins/components/Button';
import SearchInput from 'components/SearchInput';
import { formatMoney } from 'helpers';
import { LoadingBar } from '@hubins/components/LoadingIndicators';
import __ from 'localisation';
import ApiPaginationTable from 'modules/Tables/ApiPaginationTable';
import { Columns, Column } from 'components/Columns';

import middleman from 'helpers/middleman';

const loadingObj = {
  'toolsBeginning': <LoadingBar small />,
  'user_name': <LoadingBar small />,
  'shortName': <LoadingBar small />,
  'portfolio': {
    available_balance: <LoadingBar small />,
    market_value: <LoadingBar small />,
  }
};

const loadingState = [
  loadingObj,
  loadingObj,
  loadingObj,
];

const headers = [
  {
    Header: '',
    accessor: 'toolsBeginning',
    className: 'rt-cell-button',
    width: 40
  },
  {
    Header: __('name'),
    accessor: 'name',
  },
  {
    Header: __('ssn_org_num'),
    accessor: "national_identity_number"
  },
  {
    Header: __('distributor'),
    accessor: "portfolio.distributor"
  },
  {
    Header: __('policy'),
    accessor: 'portfolio.short_name',
  },
  {
    Header: __('account_balance'),
    accessor: 'portfolio.available_balance',
    Cell: ({ row }) => formatMoney(row.original.portfolio.available_balance, row.original.currency)
  },
  {
    Header: __('market_value'),
    accessor: 'portfolio.market_value',
    Cell: ({ row }) => formatMoney(row.original.portfolio.market_value, row.original.currency)
  },
  {
    Header: __('updated_at'),
    accessor: 'portfolio.updated_at'
  },
];

const AllClients = () => {
  const [clients, setClients] = useState(false);
  const [amountOfPages, setAmountOfPages] = useState(-1);
  const [search, setSearch] = useState('');
  const [selectedPage, setSelectedPage] = useState(0);
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchPage = (page, searchParam = false) => {
    setSelectedPage(page - 1);
    setLoading(true);
    middleman.get(`/ql/crm/search?page=${page}${searchParam ? `&search=${searchParam}` : ''}`)
      .then(res => {

        const formattedRes = res.data.data.reduce((users, item) => {
          if (item.ins_portfolios && Array.isArray(item.ins_portfolios)) {
            item.ins_portfolios.forEach((policy) => {
              users.push({
                ...item,
                portfolio: policy,
                toolsBeginning: <Button
                  href={`/dashboard/clients/${item.uuid}/${policy.uuid}/tabs`}
                  className={`transparent`}
                  icon="arrow-right"
                  onlyIcon
                />
              });
            });
          }
          return users;

        }, []);
        setAmountOfPages(res.data.last_page);
        setClients(formattedRes);
        setLoading(false);
      });
  };

  const searchCallback = () => {
    fetchPage(1, search);
  };

  useEffect(() => {
    if (init) {
      const timeout = setTimeout(searchCallback, 1500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [search]);

  const handlePageFetch = (index) => {
    fetchPage(index + 1, search);
    if (!init) {
      setInit(true);
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <Columns>
      <Column s="12" className="flex flex--row flex-end align-items-center">
        <SearchInput onChange={handleSearchChange} value={search} placeholder="Search client name, policy or SSN/Org-nr" loading={loading} />
      </Column>
      <Column s="12">
        <ApiPaginationTable
          data={clients ? clients : loadingState}
          headers={headers}
          fetchData={handlePageFetch}
          pageCount={amountOfPages}
          index={selectedPage}
        />
        {/* <ReactTable
        data={clients ? clients : loadingState}
        className={`ReactTable--striped ReactTable--highlight numbers`}
        columns={headers}
        pages={amountOfPages}
        pageSize={pageSize}
        manual
        previousText={__('previous')}
        nextText={__('next')}
        loadingText={<LoadingBar />}
        noDataText={__('no_data_found')}
        pageText={__('page')}
        ofText={__('of')}
        rowsText={__('rows')}
        loading={loading}
        onFetchData={handlePageFetch}
        showPageSizeOptions={false}
        page={selectedPage}
        showPageJump={false}
        sortable={false}
      /> */}
      </Column>
    </Columns>
  );
};

export default AllClients;
