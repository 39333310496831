import React from 'react';

const ExpandedRow = ({row, component, colSpan, isExpanded}) => {

  return (
    <>
      <tr style={{width: '100%', display: isExpanded ? 'table-row' : 'none'}}>
        <td colSpan={colSpan} style={{width: '100%'}}>
            {component(row)}
        </td>
      </tr>
    </>

  )

};

export default ExpandedRow;