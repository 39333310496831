import React from 'react';
import Button from '@hubins/components/Button';
import LoadingBar from '@hubins/components/LoadingIndicators';
import NameInitialsBadge from '@hubins/components/NameInitialsBadge/NameInitialsBadge';
import __ from 'localisation';

export const roleOptions = [
  { title: 'All', id: '' },
  { title: 'Hubins backoffice', id: 'hubins_backoffice' },
  { title: 'Hubins compliance', id: 'hubins_compliance' },
  { title: 'Quantum leben backoffice', id: 'quantum_backoffice' },
  { title: 'Quantum leben compliance', id: 'quantum_compliance' },
];

export const actionOptions = [
  { title: 'All', id: '' },
  { title: __('policy_approval'), id: 'portfolio_approval' },
  { title: __('sof_approval'), id: 'sof_approval' },
  { title: __('deposit_approval'), id: 'deposit_approval' },
  { title: __('tof_approval'), id: 'tof_approval' },
];

export const typeOptions = [
  { title: 'All', id: '' },
  { title: 'Source of funds', id: 'sof' },
  { title: 'Custom', id: 'custom' },
];

export const statusOptions = [
  { title: 'All', id: '' },
  { title: 'Active', id: 'active' },
  { title: 'Pending', id: 'pending' },
  { title: 'Approved', id: 'approved' },
  { title: 'Rejected', id: 'denied' },
];

const loadingRow =
  {
    created_at: <LoadingBar medium />,
    actions: [{ type: <LoadingBar medium key="loadingbar" /> }],
    profile: { name: <LoadingBar medium /> },
    portfolio: { short_name: <LoadingBar medium /> },
    navigation: <LoadingBar medium />,
    roles: [],
  };

export const loadingState = [
  loadingRow,
  loadingRow,
  loadingRow,
  loadingRow,
  loadingRow,
  loadingRow,
  loadingRow,
  loadingRow,
  loadingRow,
  loadingRow,
];

export const getStatusColor = (status) => {
  if (!status) {
    return 'neutral-3';
  }

  if (status === 'active') {
    return 'info';
  }

  if (status === 'approved') {
    return 'success';
  }
  if (status === 'comment') {
    return 'warning';
  }
  if (status === 'denied') {
    return 'danger';
  }
  if (status === 'pending') {
    return 'neutral-4';
  }

  return 'i-purple-2';
};

export const tableHeaders = [
  {
    Header: '',
    accessor: 'navigation',
    maxWidth: 40,
    Cell: ({ row }) => (
      <Button className='transparent small' onlyIcon icon="arrow-right" href={`/dashboard/approvals/${row.original.uuid}`} />
    )
  },
  {
    Header: __("creation_date"),
    accessor: "created_at",
  },
  {
    Header: __('type'),
    accessor: 'actions',
    Cell: ({ row }) => row.original.actions.map((action, index) => (typeof action.type === 'string' ? `${__(action.type)}${index < row.original.actions.length - 1 ? ', ' : ''}` : ''))
  },
  {
    Header: __('client'),
    accessor: 'profile.name'
  },
  {
    Header: __('policy'),
    accessor: 'portfolio.short_name'
  },
  {
    Header: __('verdicts'),
    accessor: 'verdicts',
    Cell: ({ row }) => (
      <div>
        {(row.original.verdicts || []).map((verdict, index) => (
          <NameInitialsBadge className='inline-flex' size="small" name={verdict.user_name} color={getStatusColor(verdict.status)} key={`ver-${index}`} />
        ))}
      </div>
    )
  },
  {
    Header: __('status'),
    accessor: 'status',
    Cell: ({ row }) => {
      try {

        const archived = row.original.status === 'archived';

        if (archived) {
          return __('archived');
        }

        const isCompleted = row.original.actions.every((action) => {
          return action.status === 'executed';
        });
        if (isCompleted) {
          return __('completed');
        } else {
          const isRejected = row.original.roles.some((role) => {
            return role.status === 'denied';
          });
          if (isRejected) {
            return __('denied');
          } else {
            const isInProgress = row.original.verdicts.length > 0;
            if (isInProgress) {
              return __('in_progress');
            } else {
              return __('pending');
            }
          }
        }
      } catch {
        return __('loading');
      }
    }
  },
  {
    Header: __('stage'),
    accessor: 'stage',
    Cell: ({ value }) => __(value)
  },
  {
    Header: __('priority'),
    accessor: 'priority',
    Cell: ({ row }) => (
      __(row.original.priority || 'standard')
    )
  },
  {
    Header: __('assigned'),
    accessor: 'assigned',
    Cell: ({ row }) => {
      try {
        const roles = row.original.roles;
        const foundActiveRole = roles.find((role) => role.status === 'active');
        const assignedUser = (foundActiveRole && foundActiveRole.user_name) ? foundActiveRole.user_name : '';

        return (
          <span>
            {assignedUser}
          </span>
        );} catch {
        return '';
      }
    }

  },
  {
    Header: __('due_date'),
    accessor: 'deadline',
  },
  // {
  //   Header: "Processing time",
  //   accessor: "processing_time",
  //   Cell: ({ row }) => (
  //     <span>
  //       TODO
  //     </span>
  //   )
  // },
  // {
  //   Header: 'Priority',
  //   accessor: 'priority',
  //   Cell: ({ row }) => (
  //     <span>
  //       TODO
  //     </span>
  //   )
  // },
];



const roleKeys = {
  'hubins_backoffice': 'hubinsBackoffice',
  'hubins_compliance': 'hubinsCompliance',
  'quantum_backoffice': 'quantumBackoffice',
  'quantum_compliance': 'quantumCompliance',
};

export const hasApproved = (userRoles, approval) => {
  return userRoles.some((role) => {
    if (typeof roleKeys[role] === 'undefined') return false;
    return approval[roleKeys[role]] === 'approved';
  });
};

export const keyToIcon = {
  'a_policy_1': 'Common File Text by Streamlinehq',
  'a_policy_2': 'Graph Stats Ascend by Streamlinehq',
  'a_policy_3': 'Products Briefcase by Streamlinehq',
  'a_policy_4': 'Upload Bottom by Streamlinehq',
  'a_policy_5': 'Withdrawal',
  'a_policy_6': 'Common File Stack by Streamlinehq',
  'a_policy_7': 'Saving Money Flower by Streamlinehq',
  'a_policy_verdict': 'check signle',
};
