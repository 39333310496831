import React, { useState } from 'react';
import __ui from 'localisation';
import { useCollapse } from 'react-collapsed';
import Button from '@hubins/components/Button';
import './ReadMore.scss';


type Props = {
  children: React.ReactNode,
  visibleContent: React.ReactNode,
  hiddenContent: React.ReactNode,
  collapsedHeight: number,
  inverted: boolean,
  className: string,
  expanded: boolean,
  noBleed: boolean,
}

const ReadMore = ({ children, visibleContent, hiddenContent, collapsedHeight = 0, inverted = false, className = '', expanded = false, noBleed = false }: Props) => {

  const [isExpanded, setExpanded] = useState(expanded);

  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded, collapsedHeight });

  const handleClick = () => {
    setExpanded(!isExpanded);
  };

  return (
    <>
      <div className={`readmore ${isExpanded ? 'readmore--open' : ''} ${noBleed ? 'readmore--no-bleed' : ''} ${inverted ? 'readmore--inverted' : ''} ${className ? className : ''}`} >
        {visibleContent}
        <div {...getCollapseProps()} className="readmore__hidden" >
          <div className="s-top-md">
            {hiddenContent || children}
          </div>
        </div>
      </div>

      <Button
        {...{ ...getToggleProps({ onClick: handleClick }), ref: undefined}}
        className="transparent small withicon withicon--left s-top-md"
        icon={isExpanded ? 'subtract' : 'add'}
      >
        {__ui(isExpanded ? 'show_less' : 'show_more')}
      </Button>
    </>
  );

};

export default ReadMore;
