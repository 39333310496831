// https://docs.google.com/document/d/1H-osG5RRTCTo-6hhLg_yFzJ2Pti4ClpMl6koMmoMAvY/edit
// Private: http://localhost:8080/registrering/behovsanalys/2
// Company: http://localhost:8080/företag/behovsanalys/2
import React, { useState } from 'react';
import { __SE as __ } from 'localisation/hubins/registration';
import { __SE as __ui } from 'localisation/hubins';
import Heading from '@hubins/components/Heading';
import Box from 'components/Box';
import { Columns, Column } from 'components/Columns';
import ReadMore from '@hubins/components/Accordeon/ReadMore';
import Wrapper from '@hubins/components/Wrapper';
import { hasSelected } from 'helpers/form';
import { formatMoneyInline, getAge, formatPercent, formatMoney } from 'helpers';
import InsuranceVsDirect, { generateData as generateInsuranceVsDirectData } from 'components/Charts/InsuranceVsDirect';
import InsuranceVsDirectBars from 'components/Charts/InsuranceVsDirect/InsuranceVsDirectBars';
import { generateData as generateInsuranceVsDirectBarsData } from 'components/Charts/InsuranceVsDirect/InsuranceVsDirectBars/generateData';
import { InsuranceVsDirectBarsLabels } from 'components/Charts/InsuranceVsDirect/InsuranceVsDirectBarsLabels';
// import { calcRiskPremium } from 'helpers/calculations';
import image_knowledge from '../../../projects/hubins/assets/illustrations/kb-result.svg';
import image_documents from '../../../projects/hubins/public/DAIcons/Task List Text 1.svg';
import image_knowledgetest_start from '../../../projects/hubins/public/DAIcons/Wild Bird Owl Body.svg';
import image_confirmation from '../../../projects/hubins/public/DAIcons/Target Path.svg';
import image_proof_of_funds from '../../../projects/hubins/public/DAIcons/Data Transfer Horizontal.svg';
import image_products from '../../../projects/hubins/public/DAIcons/Common File Stack by Streamlinehq.svg';
// import image_onoterade_aktier from '../../../projects/hubins/assets/illustrations/onoterade_aktier.svg';
import image_noterade_aktier from '../../../projects/hubins/public/icons/24px/primary/Common File Text Cash.svg';
// import image_onoterade_obligationer_direktlan from '../../../projects/hubins/assets/illustrations/onoterade_obligationer_direktlan.svg';
import image_noterade_obligationer from '../../../projects/hubins/public/icons/24px/primary/Real Estate Market Building Increase.svg';
import image_funds from '../../../projects/hubins/public/icons/24px/primary/Money Bags by Streamlinehq.svg';
import image_crypto_currency from '../../../projects/hubins/public/icons/24px/primary/Crypto Currency Bitcoin Bubble-1.svg';
import other from '../../../projects/hubins/public/icons/24px/primary/Cash Briefcase.svg';
// import image_tax_fees from '../../../projects/hubins/assets/illustrations/tax_fees.svg';
import image_financial_situation from '../../../projects/hubins/public/DAIcons/Accounting Coins Stack by Streamlinehq.svg';
import IconInterestIndicator from 'components/Icon/IconInterestIndicator';
import image_beneficiary from '../../../projects/hubins/public/DAIcons/Multiple Actions Money.svg';
import GraphDisclaimer from 'views/NewDA/GraphDisclaimer';
import FormInfo from '@hubins/components/FormInfo';
import InputAutofill from 'components/InputAutofill';
import Icon from '@hubins/components/IconNew';
import { staticValues, getTaxationByYear } from 'helpers/taxation';

// Used by middleman pdf generator to create new page breakpoints
const PageBreak = () => (<div className="page-break" />);

// The content
export const Content = ({ info, footer, decoration = true, isCompany = false, national_identity_number, pdf, name, minimal, expanded, stamp, year = "current" }) => {

  const [noNeedOfInsurance, setNoNeedOfInsurance] = useState(false); // If return of insurance is less then normal deposit account

  const prefix = isCompany ? 'c_' : '';

  const deposits = info['premium_summary'] || false;
  const withdrawals = info['withdrawal_summary'] || false;
  const marketValue = info['current_value_policy'] || false;

  const answers = {
    'total_premium': Number(info[`total_premium_payments_coming_year`]),
    'yearly_return': Number(info[`expected_yearly_target_return`]),
    'inv_hor': info[`investment_horizon`],
    'fixed cost': info[`confirm_fixed_and_transaction_cost`],
    '4.1.A': hasSelected(info[`planned_usage_of_insurance`], 'transfer_cash_and_do_investments'),
    '4.1.B': hasSelected(info[`planned_usage_of_insurance`], 'transfer_securities_and_assets'),
    '4.1.C': hasSelected(info[`planned_usage_of_insurance`], 'both_alternatives'),
    '5.1.A.1 JA': hasSelected(info[`insurance_prefered_assets`], 'unlisted_shares') && hasSelected(info[`confirm_shares_held_in_insurance`], 'confirmed'),
    '5.1.A.1 NEJ': hasSelected(info[`insurance_prefered_assets`], 'unlisted_shares') && hasSelected(info[`confirm_shares_held_in_insurance`], 'not_confirmed'),
    '5.1.A.2 JA': !isCompany ? true : (hasSelected(info[`insurance_prefered_assets`], 'unlisted_shares') && hasSelected(info[`confirm_company_held_shares_profit`], 'confirmed')),
    '5.1.A.2 NEJ': !isCompany ? false : (hasSelected(info[`insurance_prefered_assets`], 'unlisted_shares') && hasSelected(info[`confirm_company_held_shares_profit`], 'not_confirmed')),
    '5.1.A': hasSelected(info[`insurance_prefered_assets`], 'unlisted_shares'),
    '5.1.B.1 JA': hasSelected(info[`insurance_prefered_assets`], 'listed_shares') && hasSelected(info[`need_of_faster_then_48h_trades`], 'yes'),
    '5.1.B.1 NEJ': hasSelected(info[`insurance_prefered_assets`], 'listed_shares') && hasSelected(info[`need_of_faster_then_48h_trades`], 'no'),
    '5.1.B': hasSelected(info[`insurance_prefered_assets`], 'listed_shares'),
    '5.1.C': hasSelected(info[`insurance_prefered_assets`], 'unlisted_bonds_and_direct_loans'),
    '5.1.D': hasSelected(info[`insurance_prefered_assets`], 'listed_bonds'),
    '5.1.E': hasSelected(info[`insurance_prefered_assets`], 'funds'),
    '5.1.F': hasSelected(info[`insurance_prefered_assets`], 'other_alternatives_products'),
    '5.1.G': hasSelected(info[`insurance_prefered_assets`], 'crypto'),
    '7.1 JA': hasSelected(info[`need_of_flat_rate`], 'yes'),
    '7.1 NEJ 7.1.1 noneed': hasSelected(info[`need_of_flat_rate`], 'no') && hasSelected(info[`taxation`], 'noneed'),
    '8.2 JA': !isCompany && hasSelected(info[`decide_beneficiary_of_insurance`], 'yes'),
    '8.2 NEJ': !isCompany && hasSelected(info[`decide_beneficiary_of_insurance`], 'no'), // TODO: antagligen 8.3 NEJ för företag
    '8.3 JA': hasSelected(info[`accept_beneficiaries_order_above`], 'yes'),
    '8.3 NEJ': hasSelected(info[`accept_beneficiaries_order_above`], 'no'),
  };

  // Purpose open
  let savingPurposes = info['matching_alternatives_savings_insurance']
    .reduce((all, item) => {
      if (item === 'saving_for_specific_purpose') {
        all.push(__(item) + ': ' + info[`specifie_saving_purpose`]);
      } else if (item === 'saving_other') {
        all.push(__(item) + ': ' + info[`specifie_saving_other`]);
      } else {
        all.push(__(item));
      }
      return all;
    }, []);

  // Purpose savings
  let filteredPurposeSavings = info['purpose_of_insurance'];

  if (filteredPurposeSavings.includes('other')) {
    filteredPurposeSavings[filteredPurposeSavings.length - 1] = info[`specifie_insurance_purpose`];
  }

  const openingPurposes = filteredPurposeSavings.map(item => __(item));

  const _age = getAge(national_identity_number);

  const STATIC_VALUES = staticValues(year, answers['fixed cost'], isCompany, _age >= 65);
  const taxations = getTaxationByYear(year);

  // Just to prevent infinity loop
  const handleNoNeed = (noneed) => {
    if (!noNeedOfInsurance && noneed) {
      setNoNeedOfInsurance(noneed);
    }
  };

  const amount1 = info['total_premium_transfer_coming_year'];
  const amount2 = info['total_premium_cash_coming_year'];
  const amount = !amount1 && !amount2 ? answers['total_premium'] : Number(amount1 || 0) + Number(amount2 || 0);
  const dataChart = generateInsuranceVsDirectData(amount, 12 + 1, answers['yearly_return'], STATIC_VALUES, handleNoNeed);
  const resultOfInsranceVsDirect = dataChart[dataChart.length - 1];
  const dirResult = resultOfInsranceVsDirect.z;
  const insResult = resultOfInsranceVsDirect.y;
  const dataBars = generateInsuranceVsDirectBarsData(insResult, dirResult, amount, answers['yearly_return'], STATIC_VALUES);
  dataBars[0].returnPercent = formatPercent((dataBars[0].netRetDir / amount) * 100);
  dataBars[1].returnPercent = formatPercent((dataBars[1].netRetIns / amount) * 100);

  // Diff numbers:
  const diffMoney = dataBars[1].netRetIns - dataBars[0].netRetDir;
  const diff = {
    raw: diffMoney,
    money: formatMoney(diffMoney),
    percent: formatPercent((diffMoney / dataBars[0].netRetDir) * 100)
  };

  // decoration && image_knowledge

  return (
    <Wrapper>
      <div className='basic-padding-small'>
        <Columns>
          {stamp && (
            <Column s="12">
              <Heading size="4">{stamp}</Heading>
            </Column>
          )}
          <Column s="12">
            <Heading
              element="h1"
              size="2"
              className="s-bottom-xl "
            >
              {__("demand_assesment")}
            </Heading>
            <div className='button success large'>
              <Icon color="white" icon="Check Circle 1 by Streamlinehq" size="24" className='s-right-m' /> Dina svar visar att du har ett behov av en kapitalförsäkring!
            </div>
          </Column>
          <Column l="6">
            {!minimal && (
              <>
                <Heading
                  element="h2"
                  size="3"
                >
                  {isCompany ? __(`da_signoff_title1`) : __(`da_signoff_title1`)}
                </Heading>

                <p className="">
                  {__(`${prefix}da_signoff_text1`)}
                </p>
                <Heading element="h3" size="4">{__(`da_signoff_title2`)}</Heading>
                <p>{__(`${prefix}da_signoff_text2`)}</p>
              </>
            )}
            <div className='s-top-xl'>
              <FormInfo
                color='success'
                title={__(`${prefix}da_signoff_text3`)}
                text={
                  <ul>
                    <li>{__(`${prefix}da_purpose_in_summary`)}</li>
                    <li>{__(`${prefix}da_needed_transactions`)}</li>
                    {answers['5.1.A.1 JA'] && answers['5.1.A.2 JA'] && <li>{__(`${prefix}da_unlisted_shares`)}</li>}
                    {answers['5.1.B.1 NEJ'] && <li>{__(`${prefix}da_listed_shares`)}</li>}
                    {answers['5.1.C'] && <li>{__(`${prefix}da_unlisted_bonds_and_direct_loans`)}</li>}
                    {answers['5.1.D'] && <li>{__(`${prefix}da_listed_bonds`)}</li>}
                    {answers['5.1.E'] && <li>{__(`${prefix}da_funds`)}</li>}
                    {answers['5.1.G'] && <li>{__(`${prefix}da_crypto_text`)}</li>}
                    {answers['5.1.F'] && <li>{__(`${prefix}da_other_alternatives_products`)}</li>}
                    <li>{__(`${prefix}da_investment_horizon`)}</li>
                    {!noNeedOfInsurance && <li>{__(`${prefix}da_need_of_flat_rate`)}</li>}
                    {answers['7.1 JA'] && <li>{!noNeedOfInsurance ? __(`${prefix}da_need_of_flat_rate_2`) : __(`${prefix}da_need_of_flat_rate_3`)}</li>}
                    {answers['8.2 JA'] && <li>{__(`${prefix}da_decide_beneficiary_of_insurance`)}</li>}
                  </ul>
                } />
            </div>
            {(answers['5.1.A.1 NEJ'] || answers['5.1.A.2 NEJ'] || answers['5.1.B.1 JA'] || answers['7.1 NEJ 7.1.1 noneed'] || answers['8.2 NEJ']) && (
              <div className='s-top-xl'>
                <FormInfo
                  color="danger"
                  title={__(`${prefix}da_signoff_text4`)}
                  text={
                    <>
                      <ul>
                        {(answers['5.1.A.1 NEJ'] || answers['5.1.A.2 NEJ']) && <li>{__(`${prefix}da_confirm_shares_held_in_insurance_not_confirmed`)}</li>}
                        {answers['5.1.B.1 JA'] && <li>{__(`${prefix}da_faster_then_48h_trades_false`)}</li>}
                        {answers['7.1 NEJ 7.1.1 noneed'] && <li>{!noNeedOfInsurance ? __(`${prefix}da_taxation_noneed_1`) : __(`${prefix}da_taxation_noneed_1`)}</li>}
                        {answers['8.2 NEJ'] && <li>{__(`${prefix}da_decide_beneficiary_of_insurance_false`)}</li>} {/* TODO: 8.3 NEJ för företag */}
                      </ul>
                    </>
                  }
                />
              </div>
            )}
            <div className='s-top-xl'>
              <FormInfo
                text={
                  <>
                    <div className=" p">
                      <Heading element="h4" size="5">{__(`${prefix}da_signoff_title5`)}</Heading>
                      <p>{__(`${prefix}da_signoff_text5`)}</p>
                    </div>
                    <div className=" p">
                      <Heading element="h4" size="5">{__(`${prefix}da_signoff_title6`)}</Heading>
                      <p>{__(`${prefix}da_signoff_text6`)}</p>
                    </div>
                    <FormInfo
                      color="success"
                      text={<>
                        <Heading element="h2" size="4" className="flex flex--row align-center"><Icon icon="Check Circle 1 by Streamlinehq" size="24" className='s-right-m' /> {__(`da_signoff_title7`)}</Heading>
                        <p>{__(`${prefix}da_signoff_text7`)}</p>

                      </>}
                    />
                  </>
                }
              />
            </div>
          </Column>
          <Column l="6">
            {decoration && (
              <img src={image_knowledge} alt="" /> // replace
            )}
          </Column>
        </Columns>
        <PageBreak />

        <ReadMore collapsedHeight={500} expanded={expanded}>
          <section className="fc">
            <Heading size="3">{__(`da_signoff_title8`)}</Heading>
            <div className='maxwidth'>
              {decoration && <img src={image_documents} alt="" className="s-top-lg s-bottom-sm iconbg" />}
              <Heading element="h3" size="4">{__(`da_signoff_title9`)}</Heading>
              <div className="">
                <p>{__(`${prefix}da_signoff_text9_1`)}</p>
                <p className="clarification">{__(`da_signoff_text9_2`)}</p>
              </div>

              {decoration && <img src={image_knowledgetest_start} alt="" className="s-top-lg s-bottom-sm iconbg" />}
              <Heading element="h3" size="4">{__(`da_signoff_title10`)}</Heading>
              <div className=" p">
                {__(`${prefix}da_signoff_text10`)}
              </div>

              {decoration && <img src={image_confirmation} alt="" className="s-top-lg s-bottom-sm iconbg" />}
              <Heading element="h3" size="4">{__(`da_signoff_title11`)}</Heading>

              {/* <PurposeSection info={info} /> */}
              <div className=" p ">
                <p><strong>{__(`${prefix}da_signoff_purpose_open`)}</strong></p>
                <ul>
                  {openingPurposes.map(item => <li key={item}>{item}</li>)}
                </ul>

                <p><strong>{__(`${prefix}da_signoff_purpose_savings`)}</strong></p>
                <ul>
                  {savingPurposes.map(item => <li key={item}>{item}</li>)}
                </ul>
              </div>
              <p className="clarification ">{__(`${prefix}da_signoff_purpose_disclaimer`)}</p>

              {decoration && <img src={image_proof_of_funds} alt="" className="s-top-lg s-bottom-sm iconbg" />}
              <Heading element="h3" size="4">{__(`da_signoff_title12`)}</Heading>
              <div className=" p">
                <p><strong>{__(`${prefix}da_signoff_text12`)}</strong></p>
                <ul>
                  {answers['4.1.A'] && <li>{__(`transfer_cash_and_do_investments`)}</li>}
                  {answers['4.1.B'] && <li>{__(`transfer_securities_and_assets`)}</li>}
                  {answers['4.1.C'] && <li>{__(`da_signoff_text12_c`)}</li>}
                  {/* TODO WITHDRAWALS DEPOSIT AND VALUE HERE! */}
                  {(deposits && deposits !== '0') && (
                    <li>{__(`${prefix}da_deposits_summary`)} {deposits} SEK</li>
                  )}
                  {(withdrawals && withdrawals !== '0') && (
                    <li>{__(`${prefix}da_withdrawals_summary`)} {withdrawals} SEK</li>
                  )}
                  {(marketValue && marketValue !== '0') && (
                    <li>{__(`${prefix}da_market_value_summary`)} {marketValue} SEK</li>
                  )}
                  {(answers['4.1.B'] || answers['4.1.C']) && <li>{__(`${prefix}da_signoff_text12_bc_1`)}</li>}
                  {(answers['4.1.B'] || answers['4.1.C']) && <li>{__(`${prefix}da_signoff_text12_bc_2`)}</li>}
                  <li>{__(`${prefix}da_signoff_text13_1`, { amount: formatMoneyInline(amount) })}</li>
                  <li>{__(`${prefix}da_signoff_text13_2`, { value: __(`da_` + info[`deposit_interval_of_cash_to_deposit`][0]) })}</li>
                  <li>{__(`${prefix}da_signoff_text13_3`, { value: __(`da_` + info[`withdrawal_interval_of_cash`][0]) })}</li>
                </ul>
              </div>

              <p className="clarification ">{__(`${prefix}da_signoff_text14`)}</p>

              <PageBreak />

              {decoration && <img src={image_products} alt="" className="s-top-lg s-bottom-sm iconbg" />}
              <Heading element="h3" size="4">{__(`da_signoff_title15`)}</Heading>
              <p><strong>{__(`${prefix}da_signoff_text15`)}</strong></p>
              {(answers['5.1.A.1 JA'] && answers['5.1.A.2 JA']) && (
                <>
                  {decoration && <img src={image_funds} alt="" className="iconbg iconbg--small" />}
                  <Heading element="h5" size="5">{__(`unlisted_shares`)}</Heading>
                  <div className="p ">
                    {__(isCompany ? 'c_da_signoff_51a1_yes_51a2_yes' : 'da_signoff_51a1_yes')}
                  </div>
                </>
              )}
              {(isCompany && answers['5.1.A.1 JA'] && answers['5.1.A.2 NEJ']) && (
                <>
                  {decoration && <img src={image_funds} alt="" className="iconbg iconbg--small" />}
                  <Heading element="h5" size="5">{__(`unlisted_shares`)}</Heading>
                  <div className="p ">
                    {__('c_da_signoff_51a1_yes_51a2_no')}
                  </div>
                </>
              )}
              {answers['5.1.A.1 NEJ'] && (
                <>
                  {decoration && <img src={image_funds} alt="" className="iconbg iconbg--small" />}
                  <Heading element="h5" size="5">{__(`unlisted_shares`)}</Heading>
                  <div className="p ">
                    {__(`${prefix}da_signoff_51a1_no`)}
                  </div>
                </>
              )}
              {answers['5.1.B.1 NEJ'] && (
                <>
                  {decoration && <img src={image_noterade_aktier} alt="" className="iconbg iconbg--small" />}
                  <Heading element="h5" size="5">{__ui('listedstock')}</Heading>
                  <div className="p ">
                    {__(`${prefix}da_signoff_51b1_no`)}
                  </div>
                </>
              )}
              {answers['5.1.B.1 JA'] && (
                <>
                  {decoration && <img src={image_noterade_aktier} alt="" className="iconbg iconbg--small" />}
                  <Heading element="h5" size="5">{__ui('listedstock')}</Heading>
                  <div className="p ">
                    {__(`${prefix}da_signoff_51b1_yes`)}
                  </div>
                </>
              )}
              {answers['5.1.C'] && (
                <>
                  {decoration && <img src={image_funds} alt="" className="iconbg iconbg--small" />}
                  <Heading element="h5" size="5">{__(`unlisted_bonds_and_direct_loans`)}</Heading>
                  <div className="p ">
                    {__(`${prefix}da_signoff_51c`)}
                  </div>
                </>
              )}
              {answers['5.1.D'] && (
                <>
                  {decoration && <img src={image_noterade_obligationer} alt="" className="iconbg iconbg--small" />}
                  <Heading element="h5" size="5">{__(`listed_bonds`)}</Heading>
                  <div className="p ">
                    {__(`${prefix}da_signoff_51d`)}
                  </div>
                </>
              )}
              {answers['5.1.E'] && (
                <>
                  {decoration && <img src={image_funds} alt="" className="iconbg iconbg--small" />}
                  <Heading element="h5" size="5">{__(`funds_new_name`)}</Heading>
                  <div className="p ">
                    {__(`${prefix}da_signoff_51e`)}
                  </div>
                </>
              )}
              {answers['5.1.G'] && (
                <>
                  {decoration && <img src={image_crypto_currency} alt="" className="iconbg iconbg--small" />}
                  <Heading element="h5" size="5">{__(`da_crypto_title`)}</Heading>
                  <p>{__(`${prefix}da_signoff_51g`)}</p>
                  {__(`${prefix}da_crypto_check`)}
                  <div className="p ">
                    <em>
                      {__(`${prefix}da_signoff_51g_clari`)}
                    </em>
                  </div>
                </>
              )}
              {answers['5.1.F'] && (
                <>
                  {decoration && <img src={other} alt="" className="iconbg iconbg--small" />}
                  <Heading element="h5" size="5">{__(`da_other_alternatives`)}</Heading>
                  <p>{__(`da_other_alternatives_ex`)}</p>
                  <div className="p ">
                    {__(`${prefix}da_signoff_51f`)}
                  </div>
                </>
              )}

              {/* 6. Avkastning och Risk */}
              {/* {decoration && <img src={image_tax_fees} alt="" className="illustration illustration--medium s-top-lg s-bottom-sm" />} */}
              <Heading element="h5" size="5">{__(`da_signoff_title16`)}</Heading>
              <div className="p ">
                {__(`${prefix}da_signoff_text16`, {
                  percent: info[`expected_yearly_target_return`],
                  years: info[`investment_horizon`]
                })}
              </div>
            </div>



            <PageBreak />
            <div className='maxwidth'>
              {/* 7. Skatt och Avgifter */}
              {decoration && <img src={image_financial_situation} alt="" className="s-top-lg s-bottom-sm iconbg" />}
              <Heading element="h5" size="5">{__(`da_signoff_title17`)}</Heading>
              <p className="">{__(`${prefix}da_signoff_text17_1`)}</p>
              <div className="p ">
                {__(`${prefix}da_signoff_text17_2`, taxations)}
              </div>

              {answers['7.1 JA'] && (
                <p className="">{__(`${prefix}da_signoff_71_yes`)}</p>
              )}
              {answers['7.1 NEJ 7.1.1 noneed'] && (
                <p className="">{__(`${prefix}da_signoff_71_no_noneed`)}</p>
              )}

              <div className="p ">
                {__(`${prefix}da_signoff_text17_3`)}
              </div>
            </div>

            <div className="s-bottom-m">
              <Heading className="s-top-m" element="h5" size="5">{__(`da_signoff_title18`)}</Heading>
              <GraphDisclaimer prefix={prefix} tax={STATIC_VALUES.DIRECT_INVESTMENT_TAX} />
            </div>

            {!pdf && (
              <>
                {/* Graphs */}


                <div className='maxwidth s-top-l s-bottom-l'>
                  <FormInfo
                    color='warning'
                    text="Vänligen notera att samtliga beräkningar nedan är baserade på din angivna målavkastning och dessa beräkningar kommer endast stämma om din målavkastning förverkligas."
                  />
                </div>

                <p className="s-top-xl">
                  <strong>Avkastning under första 12 månaderna</strong><br />
                </p>

                <Columns className="s-top-m">
                  <Column s="12" m="2" align="end">
                    <InputAutofill
                      label={__(`${prefix}planned_deposits`)}
                      suffix="SEK"
                      value={formatMoney(amount, ' ')}
                    />
                  </Column>
                  <Column s="12" m="2" align="end">
                    <InputAutofill
                      label={__(`${prefix}your_target_return`)}
                      suffix="%"
                      value={answers['yearly_return']}
                    />
                  </Column>
                  <Column s="12" m="2" align="end">
                    <InputAutofill
                      label="Fast kostnad &amp; Transaktionsavgift"
                      suffix="SEK"
                      value={formatMoney(STATIC_VALUES.INSURANCE_FIXED_FEE + STATIC_VALUES.TRANSACTION_FEE, ' ')}
                    />
                  </Column>
                  <Column s="12" m="2" align="end">
                    <InputAutofill
                      label="Rörlig avgift"
                      suffix="%"
                      value={STATIC_VALUES.INSURANCE_VARIABLE_FEE * 100}
                    />
                  </Column>

                  <Column s="12" m="2" align="end">
                    <InputAutofill
                      label="Riskpremie (Över 65 år)"
                      suffix="%"
                      value={STATIC_VALUES.RISK_PREMIUM * 100}
                    />
                  </Column>
                </Columns>

                <InsuranceVsDirect
                  data={dataChart}
                />
                <p className="s-top-md"><strong>Avkastning efter första 12 månaderna</strong></p>

                <Columns className="s-md no-padding-sm">
                  <Column m="8">
                    <InsuranceVsDirectBars
                      data={dataBars}
                    />
                  </Column>

                  <Column m="4" align="grow" className="flex">
                    <p className="chart-label">&nbsp;</p>
                    <Box size="medium" bg="neutral-3" className="flex-grow no-space body-small">
                      <IconInterestIndicator size="large" value={diff.raw < 0 ? 'down' : 'up'} className="s-md" />
                      <p className="flex-center"><strong >{__(`${prefix}insurance_return_be`)}:</strong><br />
                        <span className="s-top-sm graph-badge">{dataBars[1] && dataBars[1].returnPercent}</span>
                        <br /><span className="s body-tiny">motsvarande</span><br />
                        <span className="graph-badge">{formatMoney(dataBars[1] && dataBars[1].netRetIns)}</span>
                      </p>
                      <p className="flex-center">
                        <strong>{diff.raw < 0 ? __(`${prefix}insurance_vs_direct_inv_fail`) : __(`${prefix}insurance_vs_direct_inv_success`)}</strong>
                        <span className="s-top-lg graph-badge graph-badge--grey">{diff.percent}</span>
                        <br /><span className="s body-tiny">motsvarande</span><br />
                        <span className="graph-badge graph-badge--grey">{diff.money}</span>
                      </p>
                    </Box>
                  </Column>
                  <Column s="12">
                    <InsuranceVsDirectBarsLabels
                      data={dataBars}
                    />
                  </Column>

                </Columns>
              </>
            )}
            <div className="maxwidth p">
              {__(`${prefix}da_signoff_graph_text`)}
              {answers['7.1 JA'] && (
                <p className=" clarification">{__(`${prefix}da_signoff_graph_71_yes_${!noNeedOfInsurance ? '1' : '2'}`)}</p>
              )}
              {answers['7.1 NEJ 7.1.1 noneed'] && (
                <p className="">{__(`${prefix}da_signoff_graph_71_no_noneed_${!noNeedOfInsurance ? '1' : '2'}`)}</p>
              )}
            </div>
            <PageBreak />
            {/* 8. Förmånstagare */}
            <div className="p maxwidth">
              {decoration && <img src={image_beneficiary} alt="" className="s-top-lg s-bottom-sm iconbg" />}
              <Heading element="h5" size="5">{__(`da_signoff_title19`)}</Heading>
              {__(`${prefix}da_signoff_text19`, { name })}

              {answers['8.2 JA'] && (
                <p>{__(`${prefix}da_signoff_82_yes`)}</p>
              )}
              {answers['8.2 NEJ'] && (
                <p>{__(`${prefix}da_signoff_82_no`)}</p>
              )}

              {!isCompany && __(`da_signoff_text19_2`)}

              {answers['8.3 JA'] && (
                <p>{__(`${prefix}da_signoff_83_yes`)}</p>
              )}
              {(answers['8.3 JA'] && isCompany) && (
                <p className="clarification">{__(`c_da_signoff_ben_83_yes`)}</p>
              )}
              {answers['8.3 NEJ'] && (
                <p>{__(`${prefix}da_signoff_83_no`)}</p>
              )}
              {answers['8.2 JA'] && (
                <p className="clarification">{__(`${prefix}da_signoff_ben_82_yes`)}</p>
              )}
              {answers['8.2 NEJ'] && (
                <p className="clarification">{__(`${prefix}da_signoff_ben_82_no`)}</p>
              )}
            </div>
          </section>
        </ReadMore>
        {!minimal && (
          <>
            <Heading className="s-md" element="h4" size="4">{__(`da_signoff_title_20`)}</Heading>
            <p className="maxwidth">{__(`${prefix}da_signoff_text_20`)}</p>
          </>
        )}
      </div>
      {footer && footer}
    </Wrapper>
  );
};
