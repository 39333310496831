import React from 'react';
import Icon from '@hubins/components/IconNew';
import { Link } from "react-router-dom";

const SummaryInfo = ({ summary, href }) => {

  return (
    <Link
      to={href}
      className="s-top-s flex flex--row"
    >

      <p className='flex flex--row align-center'>
        <Icon
          icon="Time Clock Circle by Streamlinehq"
          size="16"
          className="s-right-s"
        /> Senaste version {summary.updated_at}
        {summary.isFake && (
          <Icon icon="alert circle" size="16" className='s-left-s' />
        )}
      </p>
    </Link>
  );

};

export default SummaryInfo;
