/**
   * props.type could be set to 'countdown' or 'heading'
   */

import React, { Component, Fragment } from 'react';
import Countdown from 'components/Countdown';
import PropTypes from 'prop-types';
import Imgix from 'react-imgix';
import Button from '@hubins/components/Button';
import Heading from '@hubins/components/Heading';
import './index.scss';

class Hero extends Component {

  subheading() {
    if (this.props.type === 'img') {
      return (
        <Heading
          size={this.props.textSmall ? 5 : 3}
          className={`
          hero__preheading
          no-hyphens
          ${!this.props.headingBlack ? "c-white" : ""}
        `}>
          {this.props.subtitle}
        </Heading>
      );
    } else if (this.props.type === 'heading') {
      return (
        <Heading size="3" className={`
          hero__preheading
          no-hyphens
          ${!this.props.headingLeft ? "center" : ""}
          ${!this.props.headingBlack ? "c-white" : ""}
        `}>
          {this.props.subtitle}
        </Heading>
      );
    }
  }

  headingOrder() {
    if (this.props.headingReversed && this.props.subtitle) {
      return (
        <Fragment>
          {this.props.teaser && (
            <div className="teaser">{this.props.teaser}</div>
          )}
          <Heading element="h1" size={this.props.textSmall ? 2 : 1}>{this.props.title}</Heading>
          {this.subheading()}
        </Fragment>
      );
    } else if (this.props.subtitle) {
      return (
        <Fragment>
          {this.subheading()}
          <Heading element="h1" size={this.props.textSmall ? 2 : 1}>{this.props.title}</Heading>
        </Fragment>
      );
    } else {
      return <Heading element="h1" size={this.props.textSmall ? 2 : 1}>{this.props.title}</Heading>;
    }
  }
  render() {
    let bgImage = { backgroundImage: `url(${this.props.image})` };
    let heroContent = '';

    if (this.props.type === 'countdown') {
      heroContent = (
        <div>
          <div className="hero__countdown">
            <Countdown givenDate={this.props.givenDate} />
          </div>
          {this.props.title && <Heading element="h1" size="1">{this.props.title}</Heading>}
        </div>
      );
    } else if (this.props.type === 'img') {
      heroContent = (
        <Fragment>
          <div className={`
            hero__img-heading
            ${this.props.headingBlack ? "hero--heading-black" : ""}
            ${this.props.biggerImg ? 'biggerImg' : ''}
          `}>
            {this.headingOrder()}
            {this.props.url &&
              <div className="hero__bottomtext">
                <Button href={this.props.url} className="cta s-top-l maxwidthzero centeronmobile">{this.props.btnText}</Button>
                <p className={`
                  clarification
                  `}>{this.props.bottomText}</p>
              </div>

            }
          </div>
          <div className={`hero__img ${this.props.biggerImg ? 'biggerImg' : ''}`} style={bgImage} aria-hidden />
        </Fragment>
      );
    } else {
      heroContent = (
        <div
          data-shiftonsidebaropen={this.props.shiftonsidebaropen}
          className={`
            hero__heading
            ${this.props.headingLeft ? "hero__heading--left" : ""}
            ${this.props.headingBlack ? "hero--heading-black" : ""}
          `}>
          {this.headingOrder()}
        </div>
      );
    }

    return (
      <Fragment>
        {this.props.type === "img" ? (
          <div
            id={this.props.id}
            className={`
                 hero hero--${this.props.type}-wrapper
                 ${this.props.type === 'heading' && this.props.headingLeft ? "hero__heading-wrapper--left" : ""} ${this.props.bgColor}
               `}
            style={this.props.type !== 'img' ? bgImage : {}}>
            {heroContent}
            {this.props.children &&
              <div className="hero__content">
                {this.props.children}
              </div>
            }
          </div>

        ) : (
          this.props.image ? (
            <div>
              <Imgix
                id={this.props.id}
                src={this.props.image + '?w=1920'}
                imgProps={{
                  role: "img",
                  "aria-label": this.props.alt
                }}
                type="bg"
                className={`
                  hero hero--${this.props.type}-wrapper
                  ${this.props.type === 'heading' && this.props.headingLeft ? "hero__heading-wrapper--left" : ""} ${this.props.bgColor}
                  `}
                style={this.props.type !== 'img' ? bgImage : {}}>
                {heroContent}
                {this.props.children &&
                    <div className="hero__content">
                      {this.props.children}
                    </div>
                }
              </Imgix>
            </div>
          ) : (
            <div className={`
              hero ${this.props.className === "auto-height-hero-landingpage" ? "auto-height-hero-landingpage" : ""} hero--${this.props.type}-wrapper hero--noimage
              ${this.props.type === 'heading' && this.props.headingLeft ? "hero__heading-wrapper--left" : ""} ${this.props.bgColor}
              `}
            >
              {heroContent}
              {this.props.children &&
                    <div className="hero__content">
                      {this.props.children}
                    </div>
              }
            </div>
          )
        )}
      </Fragment>
    );
  }

}

Hero.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  image: PropTypes.string,
  headingLeft: PropTypes.bool,
  headingBlack: PropTypes.bool,
  headingReversed: PropTypes.bool,
  givenDate: PropTypes.object, // needs to be a date
  bgColor: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

Hero.defaultProps = {
  type: 'heading',
  headingLeft: false,
  headingBlack: false,
  headingReversed: false,
  teaser: false,
  alt: "",
  id: undefined,
  shiftonsidebaropen: false,
  bgColor: false,
};

export default Hero;
