import React from 'react';
import SimpleTable from 'modules/Tables/SimpleTable';
import Modal from '@hubins/components/Modal';
import { useRisksHistory } from 'queries/risks';
import LoadingBar from '@hubins/components/LoadingIndicators';
import __ from 'localisation';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  portfolioUuid: string;
}

const translationKeys = {
  'deposit_amount': 'deposit_amount_risk',
  'occupation': 'occupation_risk',
  'pep': 'pep_risk',
  'tax_country': 'tax_country_risk',
  'nationality': 'nationality_risk',
  'company_founded_country': 'company_country_founded_risk',
  'company_registered_country': 'company_country_registered_risk',
  'manual_update': 'manual_update_risk',
}

const headers = [
  {
    Header: 'Date',
    accessor: 'created_at',
    width: 60
  },
  {
    Header: 'Type',
    accessor: 'type',
    Cell: ({ value }) => __(translationKeys[value]),
  },
  {
    Header: 'Score',
    accessor: 'score',
    width: 30,
  },
  {
    Header: 'Risk group',
    accessor: 'scoreAtThisPoint',
    width: 30,
  },
];

const loadingObj = {
  created_at: <LoadingBar small />,
  type: <LoadingBar small />,
  score: <LoadingBar small />,
  scoreAtThisPoint: <LoadingBar small />,
};

const loadingState = [
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
];

const RisksHistory = ({ open, setOpen, portfolioUuid }: Props) => {

  const { data, isLoading, isFetched } = useRisksHistory(portfolioUuid, open);

  return (
    <Modal
      open={open}
      callback={setOpen}
      closeButton={true}
    >
      <div className='s-top-xl'>
        <SimpleTable
          headers={headers}
          data={isLoading || !isFetched ? loadingState : data}
        />
      </div>
    </Modal>
  )
};

export default RisksHistory;