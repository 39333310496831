import React from 'react';
import SimpleTable from 'modules/Tables/SimpleTable';
import { formatNumberByThousands } from 'helpers';
import __ from 'localisation';

const transactionHeaders = [
  {
    Header: __('created_at'),
    accessor: 'created_at',
  },
  {
    Header: __('updated_at'),
    accessor: 'updated_at',
  },
  {
    Header: __('amount'),
    accessor: 'amount',
    Cell: ({ row }) => formatNumberByThousands(row.original.amount / 100),
  },
  {
    Header: __('currency'),
    accessor: 'currency',
  },
  {
    Header: 'Status',
    accessor: 'status',
  }
];

const ApprovalTransactionsTable = ({transactions}) => {
  return (<SimpleTable
    headers={transactionHeaders}
    data={transactions}
  />)
};

export default ApprovalTransactionsTable;