import React from 'react';

import './index.scss';


const FixedFooter = ({
  className = '',
  expanded = true,
  above = false,
  col1,
  col2,
  fill = false,
  children,
}) => {

  return (
    <>
      <div className="flex-pusher" />
      <div className={`fixed-footer ${expanded ? 'fixed-footer--expanded' : ''} ${className} ${above ? 'fixed-footer--above' : ''} ${fill ? ' fixed-footer--fill' : ''}`}>
        <div className="fixed-footer__col1">
          {col1 || children}
        </div>
        {col2 && (
          <div className="fixed-footer__col2">
            {col2}
          </div>
        )}
      </div>
    </>
  );
};

export default FixedFooter;
