import React from 'react';
import { Columns, Column } from 'components/Columns';
import { CustomLegend } from 'components/Charts/InsuranceVsDirect';
import { formatMoney } from 'helpers';
import { Colors } from 'settings';

export const InsuranceVsDirectBarsLabels = ({ small, data }) => {

  const media = small
    ? { s: '12', m: '6' }
    : { s: "4", l: "2" };

  return (
    <Columns className={small ? 's-md' : ''}>
      <Column {...media}>
        <CustomLegend
          name="Avkastning Direktinvestering"
          value={formatMoney(data[0] && data[0].netRetDir)}
          stroke={Colors.chartline4}
        />
      </Column>

      <Column {...media}>
        <CustomLegend
          name="Avgifter Direktinvestering"
          value={formatMoney(0)}
          stroke={Colors.success} strokeDasharray="4 4"
        />
      </Column>

      <Column {...media}>
        <CustomLegend
          name="Skatt Direktinvestering"
          value={formatMoney(data[0] && data[0].taxDirInv)}
          stroke={Colors.success} strokeDasharray="4 4"
        />
      </Column>

      <Column {...media}>
        <CustomLegend
          name="Avkastning Kapitalförsäkring"
          value={formatMoney(data[1] && data[1].netRetIns)}
          stroke={Colors.secondary}
        />
      </Column>

      <Column {...media}>
        <CustomLegend
          name="Avgifter Kapitalförsäkring"
          value={formatMoney(data[1] && data[1].feeIns)}
          stroke={Colors.warning} strokeDasharray="4 4"
        />
      </Column>

      <Column {...media}>
        <CustomLegend
          name="Skatt kapitalförsäkring"
          value={formatMoney(data[1] && data[1].taxIns)}
          stroke={Colors.chartline1}
        />
      </Column>
    </Columns>
  );
};
