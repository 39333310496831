import React, { useRef, useState, useEffect } from 'react';
import EditableSummary from 'modules/EditableSummary';
import BasicFixedFooter from 'components/BasicFixedFooter';
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import { getSummary } from 'helpers/form';
import SummaryInfo from 'components/SummaryInfo';
import Heading from '@hubins/components/Heading';
import { Columns, Column } from 'components/Columns';
import Button from '@hubins/components/Button';
import { createAnswer } from 'helpers/form';
import FormModal from '@hubins/components/FormModal';


const CRMEditableSummary = ({ formKey, title, name, profileUuid, portfolioUuid, userId = false, editable = true }) => {

  const [summary, setSummary] = useState(false);
  const [loading, setLoading] = useState(false);
  const submitRef = useRef(null);


  useEffect(() => {
    setSummary(false);
    getSummary(formKey, profileUuid, portfolioUuid, setSummary, userId);
  }, [formKey, profileUuid, portfolioUuid, userId]);

  const submit = () => {
    submitRef.current.click();
  };

  const handleSubmit = async (form) => {
    setLoading(true);
    const formBody = {
      profileUuid,
      portfolioUuid,
      ...form,
      status: 'success'
    };
    createAnswer(formBody);
    setLoading(false);
  };

  if (summary === false) return <LoadingBox />;

  if (typeof summary.response !== 'undefined' && summary.response.length === 0) {
    return <>No results found</>;
  }

  return <>
    <Columns className="no-side-padding">
      <Column xs="12" className="s-top-s-womq">
        <Heading size="3">{title}</Heading>
      </Column>
      <Column s="12" className="flex flex--row space-between">
        {!userId && (
          <SummaryInfo
            summary={summary}
            name={name}
            href={`/dashboard/clients/${profileUuid}/${portfolioUuid}/history#${formKey.substr(2)}`}
          />
        )}
        {editable && (
          <FormModal
            content={summary.content}
            response={summary.response}
          />
        )}
      </Column>
      <Column s="12">
        <EditableSummary
          summary={summary}
          submit={handleSubmit}
          submitRef={submitRef}
          editable={editable}
          hideSubmit
        />
      </Column>
    </Columns>
    {editable && (
      <BasicFixedFooter>
        <Button
          onClick={submit}
          className="cta"
          loading={loading}
          disabled={loading}
        >
          Update
        </Button>
      </BasicFixedFooter>
    )}
  </>;

};

export default CRMEditableSummary;
