import {
  useQuery,
} from 'react-query';

import middleman from 'helpers/middleman';

export const useFormFiles = (tags: string[], page: number) => useQuery(['form_files', tags, page], () => {
  return middleman.get(
    `/v1/file/terms?filter[tags.name]=${tags.join(',')}&page=${page}`
  ).then((response) => {
    return response.data;
  });
},{ enabled: !!tags, refetchOnWindowFocus: false});