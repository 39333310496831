import React, { useMemo } from 'react';

// Import React Table
import { useTable, useResizeColumns, useFlexLayout } from 'react-table';
import './index.scss';

const SimpleTable = (props) => {

  const columns = useMemo(() => props.headers, [props.headers]);
  const data = useMemo(() => props.data, [props.data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
  } = useTable({
    columns,
    data,
  },
  useResizeColumns,
  useFlexLayout);

  return (
    <div className='table__wrapper'>
      <table {...getTableProps} className="table">
        <thead>
          {headerGroups.map((headerGroup, index) => {
            return (
              <tr {...headerGroup.getHeaderGroupProps()} key={`header-tr-${index}`}>
                {headerGroup.headers.map((column, subIndex) => (
                  <th {...column.getHeaderProps()} key={`header-th-${subIndex}`}>{column.render('Header')}</th>
                ))}
              </tr>
            );})}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="align-items-center" key={`${index}-tbody-tr`}>
                {row.cells.map((cell, subIndex) => {
                  return (
                    <td {...cell.getCellProps()} key={`${subIndex}-tbody-td`}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {(props.footer) && (
          <tfoot>
            {footerGroups.map((footerGroup, index) => (
              <tr {...footerGroup.getFooterGroupProps()} key={`footer-tr-${index}`}>
                {footerGroup.headers.map((column, subIndex) => (
                  <td {...column.getFooterProps()} key={`footer-td-${subIndex}`}>{column.render('Footer')}</td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
    </div>
  );

};

export default SimpleTable;
