import React, { useEffect, useState, useRef } from 'react';
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import { getHistory, crmGetApprovalHistory, createAnswerPromise, getLanguage, formatContent } from 'helpers/form';
import { deconstructForm, displayStatement, getFieldValue, appendValue, resetFieldValue } from 'helpers/formscms';
import Button, { ButtonWrap } from '@hubins/components/Button';
import Icon from '@hubins/components/IconNew';
import Switch from 'components/Switch';
import Dropdown from '@hubins/components/Dropdown';
import FormModal from '@hubins/components/FormModal';
import { Columns, Column } from 'components/Columns';
import FormContentDocuments from '@hubins/components/FormContentDocuments';
import './index.scss';

const handleTemplateRow = (templateRow, item, index) => {
  const { field } = templateRow;
  let newField = resetFieldValue(field);
  const fieldWithValue = appendValue(newField, item.response);
  const value = getFieldValue(fieldWithValue);
  const hasChanged = index === 0
    ? false
    : value !== templateRow?.values[index - 1]?.value;

  const result = {
    hasChanged,
    value
  };

  templateRow.values.push(result);
  return templateRow;
};

const generateRowsData = (form, history) => {
  const fields = deconstructForm(form.content);
  const template = fields
    .filter((field) => {
      return !displayStatement(field);
    })
    .map(field => {
      return {
        field,
        values: []
      };
    });

  return history.reduce((temp, item, index) => {
    return temp.reduce((results, templateRow) => {
      if (templateRow.field.type === 'repeater') {

        // If we want to show all repeater rows in order to properly display the history
        // const maxRepeaterLength = templateRow.field.max || item?.response[templateRow.field.id]?.length || 0;
        const maxRepeaterLength = history.reduce((currentMax, resp) => {
          const respOfRepeater = resp?.response[templateRow.field.id];
          if (respOfRepeater && Array.isArray(respOfRepeater) && respOfRepeater.length > currentMax) {
            return respOfRepeater.length;
          }
          return currentMax;
        }, 0);
        for (let i = 0; i < maxRepeaterLength; i++) {
          const existingResponse = item?.response[templateRow.field.id];
          const existingRowResponse = (existingResponse && existingResponse[i]) ? existingResponse[i] : {};
          templateRow.field.fields.forEach((repeaterField) => {
            const newTemplateRow = { field: repeaterField, values: []};
            results.push({
              ...handleTemplateRow(newTemplateRow, { response: existingRowResponse }, index),
              isRepeater: true,
              repeaterId: templateRow.field.id,
              repeaterIndex: i
            });
          });
        }

        // else if we only want to show the amount of repeater rows based on latest response
        // if (item.response[templateRow.field.id] && Array.isArray(item.response[templateRow.field.id])) {
        /*
          item.response[templateRow.field.id].forEach((repeaterItem, rIndex) => {
            templateRow.field.fields.forEach((repeaterField) => {
              const newTemplateRow = { field: repeaterField, values: []};
              results.push({
                ...handleTemplateRow(newTemplateRow, { response: repeaterItem }, index),
                isRepeater: true,
                repeaterId: templateRow.field.id,
                repeaterIndex: rIndex
              });
            });
          });
        }*/
      } else if (templateRow.isRepeater) {
        const existingResponse = item?.response[templateRow.repeaterId];
        const existingRowResponse = (existingResponse && existingResponse[templateRow.repeaterIndex]) ? existingResponse[templateRow.repeaterIndex] : {};
        results.push(handleTemplateRow(templateRow, { response: existingRowResponse }, index));
      } else {
        results.push(handleTemplateRow(templateRow, item, index));
      }

      return results;
    }, []);
  }, template).filter(row => row.field.type !== 'repeater');

};

const FormHistory = ({ formKey, profileUuid, portfolioUuid, userId, contactName, caseId, type, canEdit = true, portfolioOrProfile = false, options = {}}) => {

  const [response, setResponse] = useState(false);
  const [formattedResponse, setFormattedResponse] = useState(false);
  const [selected, setSelected] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(4);
  const [clientOnly, setClientOnly] = useState(false);
  const [historyLength, setHistoryLength] = useState(0);
  const [availableVersions, setAvailableVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(false);
  const [latestResponse, setLatestResponse] = useState(false);
  const [activeForm, setActiveForm] = useState(false);
  const [refetch, setRefetch] = useState(true);

  const wrapperRef = useRef(null);

  // Maybe use intersectObserver here instead, this will only respect the initial width of the element
  const calcResponsiveness = () => {
    if (wrapperRef.current && formattedResponse) {
      const width = wrapperRef.current.clientWidth;
      if (width < 701 && itemsToShow > 3) {
        setItemsToShow(3);
      }

      if (width < 601 && itemsToShow > 2) {
        setItemsToShow(2);
      }

      if (width < 501 && itemsToShow > 1) {
        setItemsToShow(1);
      }

    }
  };

  const calibrateLength = (history) => {
    const responseLength = history.length;
    if (responseLength < 4) {
      setItemsToShow(responseLength);
      calcResponsiveness();
    }
    setHistoryLength(responseLength);
  };

  useEffect(() => {
    if (refetch) {
      setRefetch(false);
      setResponse(false);
      setFormattedResponse(false);
      if (caseId) {
        crmGetApprovalHistory(formKey, caseId, setResponse);
      } else {
        getHistory(formKey, profileUuid, portfolioUuid, setResponse, userId, type, portfolioOrProfile);
      }
    }

  }, [refetch]);

  useEffect(() => {
    if (response !== false) {
      calibrateLength(response.history);
      setAvailableVersions(response.forms);
      const initForm = response.history[0] ? response.history[0] : (response.forms[0] || {});
      const selectedForm = response.forms.find((form) => form.key === initForm.key && form.language === initForm.language) ;
      setSelectedVersion(selectedForm?._id || response.forms[0]?._id);
      const allActiveForms = response.forms.filter((form) => !!form.form_settings);
      const selectedLanguage = getLanguage();
      let currentActiveForm = allActiveForms.find((form) => form.language === selectedLanguage);
      if (!currentActiveForm) {
        currentActiveForm = allActiveForms.find((form) => form.language === 'sv');
      }
      if (!currentActiveForm) {
        currentActiveForm = allActiveForms[0];
      }
      setActiveForm(currentActiveForm);
      const currentResponse = response.history[0] ? response.history[0]?.response : {};
      setLatestResponse(currentResponse);
      setFormattedResponse(
        generateRowsData(selectedForm, response.history)
      );
    }
  }, [response]);

  useEffect(() => {
    calcResponsiveness();
  }, [wrapperRef, formattedResponse]);

  useEffect(() => {
    if (response && selectedVersion) {
      const selectedForm = response.forms.find((form) => form._id === selectedVersion);
      if (clientOnly) {
        const filteredHistory = response.history.filter((item) => {
          if (!item.updated_by) return true;
          return item.updated_by === contactName;
        });
        calibrateLength(filteredHistory);
        setFormattedResponse(
          generateRowsData(selectedForm, filteredHistory)
        );
        return;
      } else {
        calibrateLength(response.history);
        setFormattedResponse(
          generateRowsData(selectedForm, response.history)
        );
        return;
      }
    }


  }, [clientOnly, selected, response, selectedVersion]);

  const handleSwitch = (e) => {
    setClientOnly(e.target.checked);
  };

  if (response === false || (response && !formattedResponse)) {
    return <LoadingBox />;
  }

  const setNewSelectedVersion = (formId) => {
    setSelectedVersion(formId);
  };

  const submitNewAnswer = async (values) => {
    const formBody = {
      key: activeForm.key,
      language: activeForm.language,
      version: activeForm.version,
      type: activeForm.type,
      profileUuid,
      portfolioUuid,
      data: values,
      userId,
    };
    await createAnswerPromise(formBody);
    setRefetch(true);
  };

  return (
    <div className="history-table" ref={wrapperRef}>
      <Columns>
        {/* <Column s="12">
          <Heading size="3">
            {__(formKey)}
          </Heading>
        </Column> */}
        <Column s="12" className="flex flex--row space-between flex-horizontal-center">
          <div className='maxwidth wide'>
            <Dropdown
              label="Select version"
              className=""
              options={
                availableVersions.map(
                  (form) => (
                    { title: `${form.version}${form.form_settings ? ` (Active)` : ''} - ${form.language}`, id: form._id }
                  )
                )
              }
              value={selectedVersion}
              callback={setNewSelectedVersion}
            />
          </div>
          <div className="history-table__filters">
            <span className="history-table__all">
              Filter by client only
            </span>
            <span>
              <Switch
                onChange={handleSwitch}
                checked={clientOnly}
              />
            </span>
          </div>
          <ButtonWrap>
            {canEdit && (
              <FormModal
                content={formatContent(activeForm)}
                response={latestResponse}
                callback={submitNewAnswer}
              />
            )}
          </ButtonWrap>
        </Column>
        <Column m="6">
          <FormContentDocuments formContent={activeForm.content} />
        </Column>
        <Column s="12">
          <div className="history-table__selected">
            <Button
              onlyIcon
              disabled={selected === 0}
              onClick={() => setSelected(selected - 1)}
            >
              <Icon label="back" icon="arrow left 1" size={16} />
            </Button>
            <span>{selected + itemsToShow}/{historyLength}</span>
            <Button
              onlyIcon
              disabled={selected + itemsToShow >= historyLength}
              onClick={() => setSelected(selected + 1)}
            >
              <Icon label="forward" icon="arrow right 1" size={16} />
            </Button>
          </div>
        </Column>



        <table className="history-table__table">
          <thead>
            <tr>
              <th></th>
              {response.history.filter((item) => {
                if (!clientOnly || !item.updated_by) return true;
                return item.updated_by === contactName;
              })
                .slice(selected, selected + itemsToShow)
                .map((item, index) => {
                  return (<th key={`thead-${index}`} className={((item.status && item.status === 'failed') ? "c-danger" : "")} >
                    {item.updated_at} <br />
                    {item.updated_by || item.edited_by} <br />
                  v{item.version}
                  </th>);
                })
              }
            </tr>
          </thead>
          <tbody>
            {formattedResponse.map((row, index) => {
              const { field } = row;
              return (
                <tr key={`row-${index}`} className="history-table__row">
                  <th>
                    {field.title || field.label || field.heading || field.description || ''}
                  </th>
                  {row.values.slice(selected, selected + itemsToShow).map((value, vIndex) => {
                    return (
                      <td key={`value-${index}-${vIndex}`} className={value.hasChanged ? 'hasChanged' : ''}>
                        {value.value}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Columns>
    </div>);

};

export default FormHistory;
