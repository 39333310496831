import React from 'react';
import { motion } from 'framer-motion';
import __ from 'localisation';

import './interest-indicator.scss';

const transition = {
  duration: 0.6,
  ease: [0.11, 0.29, 0.18, 0.98],
};

const rotateAnim = {
  up: {
    rotate: -45,
    // x: 0,
    // y: 0,
    transition,
  },
  middle: {
    rotate: 0,
    // x: 0,
    // y: 0,
    transition,
  },
  down: {
    rotate: 45,
    // x: 0,
    // y: 0,
    transition,
  },
};

const IconInterestIndicator = (props) => {
  return (
    <div className={`interest-indicator__wrap interest-indicator__wrap--${props.style} ${props.className}`}>
      <motion.span
        variants={rotateAnim}
        animate={props.value}
        initial="middle"
        className={`
          icon icon--${props.size} icon--${props.style} interest-indicator
          interest-indicator--${props.value}
        `}
      >
        <>
          <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <title>{`${__('development')}: ${props.value === 'start' ? __('loading') : __('ii_' + props.value)}`}</title>
            </defs>
            <circle fill={'currentColor'} className="icon__nostroke" cx="36" cy="36" r="36" />
            <path vectorEffect="non-scaling-stroke" className="icon__inverted" d="M43.6746 44.4853L48.4476 39.7123M48.4476 39.7123L43.6746 34.9393M48.4476 39.7123H37.2201C37.0231 39.7124 36.8279 39.6736 36.6459 39.5982C36.4638 39.5228 36.2984 39.4123 36.159 39.273C36.0197 39.1336 35.9091 38.9682 35.8338 38.7861C35.7584 38.604 35.7196 38.4089 35.7197 38.2118V33.7882C35.7197 33.5911 35.681 33.396 35.6056 33.2139C35.5302 33.0318 35.4197 32.8664 35.2803 32.727C35.141 32.5877 34.9755 32.4772 34.7935 32.4018C34.6114 32.3264 34.4163 32.2876 34.2192 32.2877H24.0524" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </>
      </motion.span>
      {props.text && <span className={`interest-indicator__value interest-indicator__value--${props.value}`}>{props.formatedValue}</span>}
    </div>
  );
};

export default IconInterestIndicator;
