import React, { useEffect, useState, useCallback } from "react";
import Button from "@hubins/components/Button";
import Modal from '@hubins/components/Modal';
import middleman from "@hubins/middleman";
import { __ } from "localisation";
import { UserData } from "./User.types";

type Role = {
    id: number;
    name: string;
}

interface AddRoleModalProps {
    user: UserData | null;
    open: boolean;
    setOpen: (open: boolean) => void;
}

const AddRoleModal: React.FC<AddRoleModalProps> = ({ user, open, setOpen }) => {
    const [allRoles, setAllRoles] = useState<Role[]>([]);
    const [userRoles, setUserRoles] = useState<string[]>([]);
    const [responseMessage, setResponseMessage] = useState<string>("");
    const [userRoleHistory, setUserRoleHistory] = useState<string[]>([]);
    const [loading, setLoading] = useState({
        roles: false,
        history: false,
        action: false
    });

    const fetchAllRoles = useCallback(async () => {
        setLoading(prev => ({ ...prev, roles: true }));
        try {
            const res = await middleman.getUserRoles();
            setAllRoles(res || []);
        } catch (error: any) {
            console.error("Error fetching all roles:", error);
            const message = error?.error || "Error fetching all roles. Please try again.";
            setResponseMessage(message);
        } finally {
            setLoading(prev => ({ ...prev, roles: false }));
        }
    }, []);

    const fetchUserRoles = useCallback(async () => {
        if (!user?.uuid) return;
        setLoading(prev => ({ ...prev, roles: true }));
        try {
            const res = await middleman.getRolesForUser(user.uuid);
            setUserRoles((res || []).map((role: Role) => role.name));
        } catch (error: any) {
            console.error("Error fetching user roles:", error);
            const message = error?.response?.data?.error || "Error fetching user roles. Please try again.";
            setResponseMessage(message);
        } finally {
            setLoading(prev => ({ ...prev, roles: false }));
        }
    }, [user?.uuid]);

    const fetchUserRoleHistory = useCallback(async () => {
        if (!user?.uuid) return;
        setLoading(prev => ({ ...prev, history: true }));
        try {
            const res = await middleman.getUserRoleHistory(user.uuid);
            setUserRoleHistory(res || []);
        } catch (error: any) {
            console.error("Error fetching user role history:", error);
            const message = error?.response?.data?.error || "Error fetching user role history. Please try again.";
            setResponseMessage(message);
        } finally {
            setLoading(prev => ({ ...prev, history: false }));
        }
    }, [user?.uuid]);

    const handleRoleChange = useCallback(async (roleName: string, action: 'add' | 'remove') => {
        if (!user?.uuid) return;
        setLoading(prev => ({ ...prev, action: true }));
        setResponseMessage("");
        try {
            if (action === 'add') {
                await middleman.addUserRole(user.uuid, roleName);
            } else {
                await middleman.removeUserRole(user.uuid, roleName);
            }
        
            await Promise.all([
                fetchUserRoles(),
                fetchUserRoleHistory()
            ]);
            setResponseMessage(`Role successfully ${action === 'add' ? 'added' : 'removed'}.`);
        } catch (error: any) {
            const message = error?.response?.data?.error || `Error ${action === 'add' ? 'adding' : 'removing'} role. Please try again.`;
            setResponseMessage(message);
        } finally {
            setLoading(prev => ({ ...prev, action: false }));
        }
    }, [user?.uuid, fetchUserRoles, fetchUserRoleHistory]);

    useEffect(() => {
        if (open && user?.uuid) {
            Promise.all([
                fetchAllRoles(),
                fetchUserRoles(),
                fetchUserRoleHistory()
            ]);
        }
    }, [open, user?.uuid, fetchAllRoles, fetchUserRoles, fetchUserRoleHistory]);

    if (!user || !open) {
        return null;
    }

    return (
        <Modal
            open={open}
            callback={() => setOpen(false)}
            overlayClassName="some-overlay-class"
            shouldCloseOnOverlayClick={true}
            contentLabel="Manage User Roles"
            closeButton
        >
            <h2>Manage Roles for {user.name}</h2>
            {loading.roles && <div>Loading roles...</div>}
            {responseMessage && (
                <div className={`response-message ${responseMessage.includes('Error') ? 'error' : 'success'}`}>
                    {responseMessage}
                </div>
            )}
            {!loading.roles && allRoles.length > 0 && (
                <table className="roles-list">
                    <thead>
                        <tr>
                            <th>Role</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allRoles.map((role) => (
                            <RoleItem
                                key={role.id}
                                role={role}
                                isUserRole={userRoles.includes(role.name)}
                                onRoleChange={handleRoleChange}
                                disabled={loading.action}
                            />
                        ))}
                    </tbody>
                </table>
            )}
            <h3>Role History</h3>
            {loading.history && <div>Loading history...</div>}
            {!loading.history && (
                <ul className="role-history-list">
                    {(userRoleHistory || []).map((log, index) => (
                        <li key={index}>{log}</li>
                    ))}
                    {userRoleHistory?.length === 0 && (
                        <li>No role history available</li>
                    )}
                </ul>
            )}
        </Modal>
    );
};

interface RoleItemProps {
    role: Role;
    isUserRole: boolean;
    onRoleChange: (roleName: string, action: 'add' | 'remove') => void;
    disabled: boolean;
}

const RoleItem: React.FC<RoleItemProps> = ({ role, isUserRole, onRoleChange, disabled }) => (
    <tr>
        <td>
            <span>{__(role.name)}</span>
        </td>
        <td>
            <Button
                size="small"
                onClick={() => onRoleChange(role.name, isUserRole ? 'remove' : 'add')}
                className={`s-left-sm ${isUserRole ? 'remove-role' : 'add-role'}`}
                disabled={disabled}
            >
                {isUserRole ? 'Remove Role' : 'Add Role'}
            </Button>
        </td>
    </tr>
);

export default AddRoleModal;