import React, { useState } from 'react';
import Heading from '@hubins/components/Heading';
import './index.scss'

interface SliderProps {
  label?: string;
  min: number;
  max: number;
  title?: string;
  maxValueLabel?: string;
  minValueLabel?: string;
  suffix?: string;
  value?: number;
  step: number;
  onChange?: (value: number, id?: string) => void;
  id?: string;
  controlled?: boolean;
}

export const Slider = ({ label, min, title, max, minValueLabel, maxValueLabel, suffix, value: propValue, step, onChange, id, controlled }: SliderProps) => {
  const [value, setValue] = useState(propValue || min);
  const [inputValue, setInputValue] = useState(propValue || min);
  const [focused, setFocused] = useState(false);
  const maxWidth = (max.toString().length * 25) + 60;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    if (onChange) {
      onChange(newValue);
    }
    setInputValue(newValue);
  };

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = Number(event.target.value);
    setValue(newValue);
    setInputValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  }

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    let newValue = Number(event.target.value);
    if (newValue < min || newValue > max) {
      newValue = newValue > max ? max : min;
    };
    setValue(newValue);
    setInputValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleFocus = () => {
    setFocused(true);
  }

  const handleInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
  };

  return (
    <>
      {title && (
        <Heading size="4" className="s-bottom-md">
          {title}
        </Heading>
      )}
      <label className='fully-slider'>
        {label && (
          <span className="fully-slider__label">
            {label}:
          </span>
        )}
        <div className='fully-slider__input-wrapper' style={{ maxWidth: `${maxWidth}px` }}>
          <input value={
            controlled
              ? focused
                ? inputValue
                : propValue
              : inputValue}
              className="fully-slider__input"
              type="text"
              onFocus={handleFocus}
              onBlur={handleOnBlur}
              onInput={handleInput}
              onChange={handleChange}
              style={{ maxWidth: `${maxWidth}px` }}
            />
          <span className="fully-slider__suffix">{suffix}</span>
        </div>
        <input step={step} value={controlled ? propValue : value} onChange={handleSliderChange} className="fully-slider__range" type="range" min={min} max={max} />
        <div className='fully-slider__labels'>
          <span className='fully-slider__min'>{minValueLabel || min} {suffix}</span>
          <span className='fully-slider__max'>{maxValueLabel || max} {suffix}</span>
        </div>
      </label>
    </>
  )
};

Slider.defaultProps = {
  min: 0,
  max: 100,
  step: 1,
  suffix: '',
}

export default Slider;