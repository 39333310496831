import React, { useState, useEffect } from 'react';
import DocumentsList from './DocumentsList';
import middleman from 'helpers/middleman';

type Props = {
  tags?: string,
  excludeTag?: string,
  path: string,
  auth?: boolean,
  languageSupport?: boolean,
  title?: string,
  compact?: boolean,
  className?: string,
}

const Documents = ({
  tags,
  excludeTag,
  path,
  auth = true,
  languageSupport,
  title,
  compact,
  className = '',
}: Props) => {

  const [documents, setDocuments] = useState([]);

  const getDocumentsInfo = (response) => {
    let newDocuments = response.data[0].documents;
    if (tags) {
      const tagsArray = tags.split(',');
      newDocuments = newDocuments.filter(
        doc => tagsArray.every((tag) => doc.tags.includes(tag))
      );
    }
    if (excludeTag) {
      newDocuments = newDocuments.filter(
        doc => !doc.tagsAsSet.includes(excludeTag)
      );
    }
    setDocuments(newDocuments);
  };

  useEffect(() => {
    const authUrl = auth ? '' : 'unauth/';
    const marketLanguage = languageSupport ? `/SE/SE` : '';
    const uri = `/v1/fa/${authUrl}documents/${path}${marketLanguage}`;
    middleman.post(uri, {}, getDocumentsInfo);
  }, []);

  return (
    <DocumentsList
      documents={documents}
      auth={auth}
      title={title}
      compact={compact}
      className={className}
    />
  );
};

export default Documents;
