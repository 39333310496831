import {
  useQuery,
} from 'react-query';

import middleman from 'helpers/middleman';

export const usePortfolios = (national_identity_number) => useQuery(['portfolios', national_identity_number], () => {
  return middleman.get(
    `/portfolios?status=issued`
  ).then((response) => {
    return response.data;
  });
},{ enabled: !!national_identity_number, refetchOnWindowFocus: false});

export const usePortfolioStats = (portfolioUuid) => useQuery(['portfolio_stats', portfolioUuid], () => {
  return middleman.get(
    `/premium/stats/${portfolioUuid}`
  ).then((response) => {
    return response.data;
  });
}
,{ enabled: !!portfolioUuid, refetchOnWindowFocus: false });

export const usePortfolioSearch = (search, page) => useQuery(['portfolio_search', search, page], () => {
  return middleman.get(
    `/ql/crm/portfolio/search?page=${page}${search ? `&search=${search}` : ''}`
  ).then((response) => {
    return response.data;
  });
}, { enabled: true, refetchOnWindowFocus: false });

export const usePortfolioFilter = (initiated: boolean, type: string | undefined = undefined, portfolioUuid: string | undefined  = undefined, shortName: string | undefined = undefined, positiveAccountBalance: boolean | undefined = undefined, page: number | undefined = undefined) => useQuery(['portfolio_filter', type, portfolioUuid, shortName, positiveAccountBalance, page], () => {
  return middleman.post(
    `/ql/crm/portfolios/filter${page ? `?page=${page}` : ''}`,
    { type, portfolioUuid, shortName, positiveAccountBalance }
  ).then((response) => {
    return response.data;
  });
}, { enabled: initiated, refetchOnWindowFocus: false });

export const usePaidPremiumAccount = (profileUuid) => useQuery(['paid_premium_account', profileUuid], () => {
  return middleman.get(
    `/ql/crm/portfolio/paidpremiumaccount/${profileUuid}`
  ).then((response) => {
    return response.data;
  });
}, { enabled: !!profileUuid, refetchOnWindowFocus: false });

export const useSubAccounts = (portfolioUuid) => useQuery(['sub_accounts', portfolioUuid], () => {
  return middleman.get(
    `/ql/crm/portfolio/subaccounts/${portfolioUuid}`
  ).then((response) => {
    return response.data;
  });
}, { enabled: !!portfolioUuid, refetchOnWindowFocus: false });
