import { FmaRisk, Portfolio, RiskProfile } from "./riskTypes";

export const createPortfolio = (portfolio: Partial<Portfolio>): Portfolio => {
    const defaultPortfolio: Portfolio = {
        uuid: '1234',
        name: 'FBS001',
        riskScore: 3,
    }
    return {... defaultPortfolio, ...portfolio};
}

export const createConnection = () => {
    return {
        uuid: '1234',
        type: 'company' as const,
        role: 'admin',
        meta: [
            {
                key: 'risk',
                value: 'Risk increased by PEP',
                created_at: '2021-01-01 22:32',
                origin: 'Form Response'
            }
        ]
    }
}

export const createFmaRisk = (custom: Partial<FmaRisk>): FmaRisk => {
    return {
        uuid: '1234',
        ruleUuid: '1234',
        index: '1.1',
        name: 'FMA001',
        riskScore: 3,
        ...custom
    }
}



export const createRiskProfile = (portfolio: Partial<Portfolio>): RiskProfile => {
    return {
        portfolio: createPortfolio(portfolio),
        connections: [createConnection()],
        fmaRisk: [
            createFmaRisk({index: '1.1', name: 'FMA001', riskScore: 3}),
            createFmaRisk({index: '1.2', name: 'FMA002', riskScore: 3}),
            createFmaRisk({index: '1.3', name: 'FMA003', riskScore: 3}),

        ]
    }
}