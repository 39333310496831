import React from 'react';
import FormHistory from 'modules/FormHistory';

const CompanyBasicInfo = ({ contact, profileUuid, portfolioUuid = undefined }) => {
  const key = 'c_bi';
  return <FormHistory
    formKey={key}
    contactName={contact.name}
    portfolioUuid={portfolioUuid}
    profileUuid={profileUuid}
  />;


};

export default CompanyBasicInfo;
