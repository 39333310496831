import React, { useState, useEffect } from 'react';
import Input from '@hubins/components/Input';
import Button, { ButtonWrap } from '@hubins/components/Button';
import Modal from '@hubins/components/Modal';
import ChoosePortfolio from '@hubins/components/ChoosePortfolio';
import __ from 'localisation';

type Props = {
  callback: (security: object) => void;
  value?: any;
  controlled?: boolean;
}

const ChoosePortfolioInput = ({ callback, value, controlled = false }: Props) => {

  const [open, setOpen] = useState<boolean>(false);
  const [portfolio, setPortfolio] = useState<any>(value || null);

  useEffect(() => {
    callback(portfolio);
    if (portfolio) {
      setOpen(false);
    }
  }, [portfolio])

  return (
    <>
      <Modal
        callback={setOpen}
        open={open}
        closeButton
      >
        <ChoosePortfolio callback={setPortfolio} />
      </Modal>
      <div className='flex-horizontal-center'>
        <div className="wide">
          <Input
            label={__('portfolio')}
            value={controlled ? value?.short_name || '' : portfolio?.short_name || ''}
            disabled={true}
            controlled
          />
        </div>
        <div className="s-left-m s-right-m wide">
          <ButtonWrap>
            <Button
              onClick={() => setOpen(true)}
              icon="Common File Stack by Streamlinehq"
            >
              {__('choose_portfolio')}
            </Button>
            {portfolio && (
              <Button
                className='danger'
                icon="Bin by Streamlinehq"
                iconColor='white'
                onClick={() => setPortfolio(null)}
              >
                {__('delete')}
              </Button>
            )}
          </ButtonWrap>
        </div>
      </div>
    </>
  )
};

export default ChoosePortfolioInput;