import React from 'react';
import __, { __route } from 'localisation';
// import { connect } from 'react-redux';
import Hero from 'modules/Hero';
import ErrorBoundary from 'components/ErrorBoundary';
import CaseDescription from 'modules/CaseDescription';
import RenderFunctions from './RenderFunctions';
import draftToHtml from 'draftjs-to-html';
import Main from 'components/Main';
import Wrapper from '@hubins/components/Wrapper';
import CaseFixedFooter from 'modules/CaseFixedFooter';
import Button from '@hubins/components/Button';
import Heading from '@hubins/components/Heading';
import { useHistory } from 'react-router-dom';
import './index.scss';
// import { formatMoney } from 'helpers';
import { generateTableData_NEW, generateTableData } from 'helpers/investmentCards.js';

const CreateCmsCase = (props) => {

  const history = useHistory();

  const convertData = (caseData) => {

    let convertedData = caseData;
    caseData.forEach(item => {
      switch (item.type) {
        case 'text':
          item.value = draftToHtml(item.value);
          break;
        case 'textColumn':
          item.right = draftToHtml(item.right);
          item.left = draftToHtml(item.left);
          break;
        case 'detail-table':
          item.rows = item.rows.map(row => {
            return { ...row, text: draftToHtml(row.text) };
          });
          break;
        default:
          break;
      }
    });

    return convertedData;
  };

  const description = draftToHtml(props.description.value);

  let fixedFooterData = props.new ? generateTableData_NEW(props.security) : generateTableData(props.security);

  let buttonUrl = "#";

  if (props.type === 'STOCK') {
    buttonUrl = `${__route('subscription_stock_switch')}/${props.id}`;
  }

  return (
    <>
      <Main>
        <Wrapper>
          <Button
            className="secondary s-bottom-xl"
            onClick={() => { history.goBack(); }}
            icon="arrow left 1"
            size='large'
            iconLeft
          >
        Tillbaka
          </Button>
          <Heading size="2" className="s-bottom-xl">
            {props.security.name}
          </Heading>
          <ErrorBoundary>
            <Hero
              alt={props.heroAlt}
              image={props.heroImage}
              title={props.heroTitle}
              data-shiftonsidebaropen
            />
          </ErrorBoundary>

          <CaseDescription
            logo={props.logo}
            alt={props.logoAlt}
            text={description}
          />
          <section className="columns section section--wide">
            {convertData(props.caseData).map((field, nr) => (
              RenderFunctions[field.type](nr, { ...field }, props.id)
            ))}
          </section>
        </Wrapper>
      </Main>
      {!props.hideFooter && (
        <CaseFixedFooter
          ms_diff={props.ms_diff || 0}
          buttonUrl={buttonUrl}
          buttonText={'Invest now'}
          disabled={false}
          buttonStyle="cta"
          data={fixedFooterData}
        />
      )}
    </>
  );
};

CreateCmsCase.defaultProps = {
  currency: '',
};
export default CreateCmsCase;
// function mapStateToProps(state) {
//   return {
//     registrationOK: state.auth.User.tags.registrationOK,
//   };
// }

// export default connect(
//   mapStateToProps
// )(CreateCmsCase);
