import React from 'react';
import Documents from '@hubins/components/Documents';
import PrivateDocuments from '@hubins/components/Documents/PrivateDocuments';
import __ from 'localisation';
import { Columns, Column } from 'components/Columns';

const ClientDocuments = ({ portfolioUuid, profileUuid }) => {

  return (
    <Columns>
      <Column m="6" id="documents">
        <Documents
          title={__('documents_terms')}
          tags=''
          path='terms'
          languageSupport={true}
          compact
        />
      </Column>
      <Column m="6" id="private-documents">
        <PrivateDocuments
          title={__('your_documents')}
          compact
          portfolioUuid={portfolioUuid}
          profileUuid={profileUuid}
        />
        <PrivateDocuments
          compact
          portfolio
          portfolioUuid={portfolioUuid}
        />
      </Column>
    </Columns>
  );

};

export default ClientDocuments;
