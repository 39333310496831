import React from 'react';
import __ from "localisation";
import LoadingBar from '@hubins/components/LoadingIndicators';
import { formatNumberByThousands, getMoney } from "helpers";
import AuthDownloadLink from '@hubins/components/AuthDownloadLink';
import { formatDate } from "helpers";
import Button from '@hubins/components/Button';
import middleman from 'helpers/middleman';

export const appendUsedAmountToTransactionSources =
  (transactionUuid: string, sources) => {
    return sources.map((source) => {
      source.transactionAmount = source.transactions.reduce((sum, transaction) => {
        if (transaction.transaction_uuid === transactionUuid) {
          return sum + transaction.amount;
        }
        return sum;
      }, 0)
      return source;
    })
  }

export const calculateTransactionTradeAmount = (transaction: any) => {
  try {
    const { type, amount, units, unit_price, accrued_interest, exchange_rate, security } = transaction;
    if (type === 'PRE') {
      return Number(amount) / 100;
    }

    const multiplier = security?.multiplier || 1;

    const calculatedUnitPrice = multiplier ? Number(unit_price) / Number(multiplier) : Number(unit_price);

    return Number(units) * calculatedUnitPrice * Number(exchange_rate) + ((accrued_interest === '?' || !accrued_interest) ? 0 : Number(accrued_interest) * Number(exchange_rate));
  } catch (e) {
    return 0;
  }
}

export const deleteTransaction = async (transactionUuid: string, refetch: () => void) => {
  if (window.confirm(__('are_you_sure_you_want_to_delete_this_transaction'))) {
    await middleman.promiseDelete(`/ql/crm/transaction/delete/${transactionUuid}`);
    refetch();
  }
}

export const deleteHeader = (refetch: () => void) => ({
  Header: '',
  accessor: 'delete',
  width: 55,
  Cell: ({ row }) => {
    const type = row.original.type;
    const status = row.original.status;
    if (type === 'PRE' || type === 'TOF' && status !== 'completed') {
      return <Button onClick={() => deleteTransaction(row.original.uuid, refetch)} onlyIcon icon="Bin by Streamlinehq" />;
    }
    return <></>;
  }
})

export const getClientTransactionStatus = (transaction: any) => {
  if (transaction.status === 'completed') {
    return __('completed');
  }
  if (transaction.status === 'draft') {
    return __('started');
  }

  return __('awaits_approval');
}

export const loadingObj = {
  amount: '',
  currency: <LoadingBar />,
  type: <LoadingBar />,
  security_id: <LoadingBar />,
  security_name: <LoadingBar />,
  status: <LoadingBar />,
  created_at: <LoadingBar />,
  delete: <LoadingBar />,
  trade_amount: <LoadingBar />,
  units: <LoadingBar />,
  portfolio: <LoadingBar />,
  security: <LoadingBar />,
}

export const loadingTableHeaders = [
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
];

export const filterTableHeadersWithDelete = (refetch: () => void) => [
  deleteHeader(refetch),
  ...filterTableHeaders,
];

export const filterTableHeaders = [
  {
    Header: __('transaction_date'),
    accessor: "transaction_date",
  },
  {
    Header: __("portfolio"),
    accessor: "portfolio.short_name",
    width: 220
  },
  {
    Header: __('policy_holder'),
    accessor: "portfolio.policy_holder",
    Cell: ({ value }) => {
      return value ? value.map((policyHolder: any) => policyHolder.name).join(', ') : ''}
  },
  {
    Header: __("security"),
    accessor: "security.name",
  },
  {
    Header: __("isin"),
    accessor: "security.isinCode",
  },

  // {
  //   Header: __("amount"),
  //   accessor: "amount",
  //   Cell: ({ value }) => formatNumberByThousands(value),
  // },
  {
    Header: __('type'),
    accessor: "type",
  },
  {
    Header: __('currency'),
    accessor: "currency",
  },
  {
    Header: __('exchange_rate'),
    accessor: "exchange_rate",
  },
  {
    Header: __('units'),
    accessor: "units",
  },
  {
    Header: __('unit_price'),
    accessor: "unit_price",
  },
  {
    Header: __('accrued_interest'),
    accessor: "accrued_interest",
  },
  // {
  //   Header: __('files'),
  //   accessor: "files",
  //   Cell: ({ row }) => {
  //     return row?.original?.files ? row.original.files.map((file) => <AuthDownloadLink key={file.uuid} url={`/ql/crm/transaction/download-file/${row.original.uuid}/${file.uuid}`} icon="Office File Pdf 1 by Streamlinehq" filename={file.name} onlyIcon={true} />) : '';
  //   }
  // },
  {
    Header: __('trade_amount'),
    accessor: "trade_amount",
    // if type is PRE just show amount / 100 else use units * unit_price + accrued_interest(if accrued_interest is = '?' or not set ignore it)
    Cell: ({ row, value }) => {
      if (value) return value;
      return getMoney(calculateTransactionTradeAmount(row.original), 'SEK');
    }
  },
  // {
  //   Header: __('booking_status'),
  //   accessor: "booking_status",
  //   Cell: ({ row }) => {

  //     if (row?.original?.booked_by_transaction && row?.original?.booked_by_transaction?.length > 0) {
  //       return __('reserved_by_transaction');
  //     }

  //     if (row?.original?.paid_premium_transaction) {
  //       return __('security_booked');
  //     }

  //     return '';
  //   }
  // },
  {
    Header: __("status"),
    accessor: "status",
  },
  // {
  //   Header: __('approval_status'),
  //   accessor: "approval_status",
  // },
  // {
  //   Header: 'Batch',
  //   accessor: "batch.name",
  //   Cell: ({ row }) => {
  //     return row?.original?.batch ? row.original.batch.name || row.original.batch.id : '';
  //   }
  // },
  // {
  //   Header: 'Batch status (Hubins)',
  //   accessor: "batch.hubins_status",
  // },
  // {
  //   Header: 'Batch status (QL)',
  //   accessor: "batch.quantum_status",
  // },
  {
    Header: __('updated_at'),
    accessor: "updated_at",
    Cell: ({ value }) => value && typeof value === 'string' ? formatDate(value, true) : ''
  },
  {
    Header: __('created_at'),
    accessor: "created_at",
    Cell: ({ value }) => value && typeof value === 'string' ? formatDate(value, true) : ''
  }
]

export const unbookedTransactionsTableHeaders = [
  {
    Header: __("portfolio"),
    accessor: "portfolio.short_name",
  },
  {
    Header: __("type"),
    accessor: "type",
  },
  {
    Header: __("security"),
    accessor: "security.name",
  },
  {
    Header: __("units"),
    accessor: "units",
  },
  // {
  //   Header: __('files'),
  //   accessor: "files",
  //   Cell: ({ row }) => {
  //     return row?.original?.files ? row.original.files.map((file) => <AuthDownloadLink key={file.uuid} url={`/ql/crm/transaction/download-file/${row.original.uuid}/${file.uuid}`} icon="Office File Pdf 1 by Streamlinehq" filename={file.name} onlyIcon={true} />) : '';
  //   }
  // },
]

export const defaultTableHeaders = [
  {
    Header: __("amount"),
    accessor: "amount",
    Cell: ({ value }) => formatNumberByThousands(value),
  },
  {
    Header: __('type'),
    accessor: "type",
  },
  {
    Header: __("currency"),
    accessor: "currency",
  },
  {
    Header: __("security_id"),
    accessor: "security_id",
  },
  {
    Header: __("security_name"),
    accessor: "security.name",
  },
  {
    Header: __("status"),
    accessor: "status",
  },
  {
    Header: __("approval_status"),
    accessor: "approval_status",
  },
  {
    Header: __("created_at"),
    accessor: "created_at",
  },
  {
    Header: __("delete"),
    accessor: "delete",
  },
];

export const typeOptions = [
  {
    id: '',
    title: ''
  },
  {
    id: 'PRE',
    title: __('deposit'),
  },
  {
    id: 'TOF',
    title: __('tof'),
  },
  // {
  //   id: 'DEP',
  //   title: 'DEP',
  // },
  // {
  //   id: 'ADD',
  //   title: 'ADD',
  // }
];

export const statusOptions = [
  {
    id: '',
    title: ''
  },
  {
    id: 'pending',
    title: __('pending'),
  },
  {
    id: 'completed',
    title: __('completed'),
  },
  {
    id: 'draft',
    title: __('draft'),
  },
  {
    id: 'paused',
    title: __('paused'),
  },
  {
    id: 'ready-for-execution',
    title: __('ready-for-execution'),
  },
  {
    id: 'pending_fa_booking',
    title: __('pending_fa_booking'),
  },
  {
    id: 'pending_fa_tax_booking',
    title: __('pending_fa_tax_booking'),
  },
]

export const paidPremiumTransactionOptions = (transactions: any) => {
  return transactions.filter((transaction) => transaction?.type?.typeCode !== 'REM' && transaction?.type?.typeCode !== 'WD').map((transaction: any) => ({
    id: transaction.id,
    title: `${transaction.amount} ${__('units')} - ${transaction?.type?.typeCode || ''} - ${transaction?.securityName || ''} - ${transaction?.securityCode || ''}`
  }));
}

export const approvalTransactionHeaders = [
  {
    Header: __('transaction_date'),
    accessor: 'transaction_date',
  },
  {
    Header: __('status'),
    accessor: 'status',
    Cell: ({ value }) => <span className={
      value === 'ready-for-execution' ?
        'c-success' :
        value === 'pending'
        ? 'c-info' :
        ''
    }>
      {__(value)}
    </span>,
  },
  {
    Header: __('investment'),
    accessor: 'security.name'
  },
  {
    Header: __('isin'),
    accessor: 'security.isinCode'
  },
  {
    Header: __('trade_amount'),
    accessor: 'amount',
    Cell: ({ row }) => getMoney(calculateTransactionTradeAmount(row.original), 'SEK')
  },
  {
    Header: __('units'),
    accessor: 'units'
  },
  {
    Header: __('origin'),
    accessor: 'origin'
  },
  {
    Header: __('origin_account'),
    accessor: 'origin_account'
  },
  {
    Header: __('updated_at'),
    accessor: 'updated_at',
    Cell: ({ value }) => value ? formatDate(value, true) : ''
  }
]
