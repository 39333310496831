import React from 'react';
import SettingsExpandableFormSummary from '@hubins/components/SettingsExpandableFormSummary';

const privateKeys = [
  'p_kt',
  'p_kyc',
];

const companyKeys = [
  'c_kt',
  'c_kyc',
  'c_bi',
];

const ProfileRegistration = ({ profile, profileUuid, endDate }) => {
  const isCompany = profile.type === 'COMPANY';
  const keys = isCompany ? companyKeys : privateKeys;

  return (
    <>
      {keys.map((key) => (
        <SettingsExpandableFormSummary
          key={key}
          formKey={key}
          profileUuid={profileUuid}
          titleSize="5"
          endDate={endDate}
        />
      ))}
    </>
  );

};

export default ProfileRegistration;
