import React, { useState, useEffect } from 'react';
import './index.scss';

type notificationType = {
  message: any;
  type: string;
}

interface ContextValues {
  notification?: notificationType;
  setNotification: (notification: notificationType) => void;
}

const initState: ContextValues = {
  notification: undefined,
  setNotification: () => {},
}

const Context = React.createContext(initState)

const NotificationContext = ({ children }) => {
  const [notification, setNotification] = useState<any>(null);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [notification]);

  const triggerNotification = () => {
    setNotification('This is a notification!');
  };

  return (
    <Context.Provider value={{setNotification}}>
    <div className="notification-overlay">
      {notification && (
        <div className={`notification-overlay__notification notification-overlay__type-${notification.type}`}>
          <p>{notification.message}</p>
        </div>
      )}
    </div>
    {children}
    </Context.Provider>
  );
};

export const useNotificationContext = () => React.useContext(Context);

export default NotificationContext;