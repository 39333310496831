import React from 'react';
import PortfolioProfileApprovals from '../../../../projects/backoffice/views/NewApprovals/PortfolioProfileApprovals';
import CreateApproval from '@hubins/components/CreateApproval';

const Approvals = ({ profileUuid, portfolioUuid }) => {

  return (
    <>
      <CreateApproval
        profileUuid={profileUuid}
        portfolioUuid={portfolioUuid}
      />
      <PortfolioProfileApprovals
        profileUuid={profileUuid}
        portfolioUuid={portfolioUuid}
      />
    </>
  );

};

export default Approvals;
