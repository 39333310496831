import React, { useState } from 'react';
import Button from '@hubins/components/Button';

import middleman from 'helpers/middleman';
import { connect } from 'react-redux';
import { authOperations } from '../../state/ducks/auth';
import __ from 'localisation';
import ActivateTFAModal from '@hubins/components/ActivateTFAModal';



const AuthSettings = ({ tfa, updateUser }) => {

  const [loading, setLoading] = useState(false);
  const [QRCode, setQRCode] = useState(false);

  const activate = async () => {
    try {
      setLoading(true);
      const res = await middleman.get('/auth/tfa/enable');
      setQRCode(res.data.svg);
      setLoading(false);
    } catch (e) {
      console.error(e.message);
      setLoading(false);
    }
  };

  return (
    <div className='flex justify-start align-start'>
      <Button
        loading={loading}
        onClick={activate}
        disabled={tfa}
      >
        {__('tfa_required_title')}
      </Button>
      <ActivateTFAModal
        open={!!QRCode}
        setOpen={setQRCode}
        QRCode={QRCode}
        updateUser={updateUser}
      />

    </div>
  );

};

function mapStateToProps(state) {
  return {
    tfa: state.auth.User.tfa,
  };
}

export default connect(
  mapStateToProps,
  {
    updateUser: authOperations.updateUser,
  }
)(AuthSettings);
