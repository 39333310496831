import React from 'react';
import './index.scss';


export const LoadingBar = (props: { [key: string]: any }) => {
  let modifier = Object.keys(props).map(prop => 'loading-bar--' + prop).join(' ');

  return (
    <span className={`loading-bar ${modifier}`}></span>
  );
};

export const LoadingInput = () => {
  return (
    <LoadingBar input />
  );
};

export const LoadingDots = ({ text }: { text: string }) => (
  <span>
    {text} <span className="anim-dot">.</span>
    <span className="anim-dot">.</span>
    <span className="anim-dot">.</span>
  </span>
);
