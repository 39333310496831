import React from 'react';
import { useReportContext } from '@hubins/components/ReportContext';
import { useClientTofTransactions } from 'queries/transactions';
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import TOFCard from '../TOFCard';
import Heading from '@hubins/components/Heading';
import __ from 'localisation';
import ReadMore from '@hubins/components/Accordeon/ReadMore';

type Props = {
  portfolioUuid?: string;
};

const OverviewTofTransactions = ({ portfolioUuid: propsPortfolioUuid }: Props) => {

  const { portfolioUuid } = useReportContext();
  const { data, isLoading, isFetched } = useClientTofTransactions(
    propsPortfolioUuid
      ? propsPortfolioUuid
      : portfolioUuid
  );

  if (isLoading) return <LoadingBox />;

  return (
    <>
      {(isFetched && data.length > 0) && (
        <>
          <Heading size="4">{__('tof')}</Heading>
          {/* Take the first 3 transactions here and use ReadMore for the rest */}
          {data?.slice(0, 3).map((transaction: any) => (
            <TOFCard key={transaction.uuid} {...transaction} />
          ))}
          {data?.length > 3 && (
            <div className="s-bottom-xl">
            <ReadMore
              className=""
            hiddenContent={
              data?.slice(3).map((transaction: any) => (
                <TOFCard key={transaction.uuid} {...transaction} />
              ))
              }
            />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default OverviewTofTransactions;