import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'components/Tabs';
import useTabs from 'hooks/useTabs';
import AssetManagerPortfolios from '../AssetManagerPortfolios';
import BrokerOrders from '../BrokerOrders';
import __ from 'localisation';

const tabs = [
  'clients',
  'orders'
];

const BrokerClients = () => {
  const [index, setIndex] = useState(0);
  useTabs(setIndex, index, tabs);


  return (
    <Tabs
      className="line-tabs"
      selectedTabClassName="line-tabs__tab--selected"
      selectedTabPanelClassName="line-tabs__tab-panel--selected"
      onSelect={setIndex}
      selectedIndex={index}
    >
      <TabList className="line-tabs__tab-list line-tabs__submenu">
        <Tab className="heading heading--5 line-tabs__tab">{__('clients')}</Tab>
        <Tab className="heading heading--5 line-tabs__tab">{__('orders')}</Tab>
      </TabList>
      <TabPanel>
        <AssetManagerPortfolios />
      </TabPanel>
      <TabPanel>
        <BrokerOrders />
      </TabPanel>
    </Tabs>
  );
};

export default BrokerClients;