import React, { useCallback, useState, useEffect } from 'react';
import __, { __route } from 'localisation';
import { Columns, Column } from 'components/Columns';
import Heading from '@hubins/components/Heading';
import Icon from '@hubins/components/IconNew';
import Button, { ButtonWrap } from '@hubins/components/Button';
import { useCollapse } from 'react-collapsed';
import AddSource from 'modules/AddSource';
import InputCurrency from '@hubins/components/Input/Currency';
import { formatMoney, getMoney } from 'helpers';
import HoverTooltip from '@hubins/components/HoverTooltip';
import { keyToIcon } from 'helpers/sof';
import './index.scss';

import middleman from 'helpers/middleman';

const statusTranslations = {
  'verified': 'Godkänd',
  'unverified': 'Bevis mottaget',
  'unproven': 'Bevis ej mottaget'
};

const statusColors = {
  'verified': 'success',
  'unverified': 'info',
  'unproven': 'warning'
};

const SourceCard = ({
  source,
  index,
  callback,
  deleteSource,
  value,
  max,
  premium,
  portfolioUuid,
  profileUuid,
  isHighRisk,
  showAvailableAmount,
  canSetAmounts,
  backoffice,
}) => {

  const availableAmount = isHighRisk ? source.highRiskAvailableAmount || 0 : source?.availableAmount || 0;
  const negativeAvailableAmount = availableAmount < 0;
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(true);
  };

  const handleValueChange = useCallback((val) => {
    val = Number(val);
    const maxForMe = max + value;
    if (val > maxForMe) {
      callback(index, maxForMe);
      return;
    }

    callback(index, val);
  }, [max, value, index]);

  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded: isExpanded }
  );

  const editUrl = backoffice ?
    `/sof/edit/${source.type}/${source.uuid}/${profileUuid}`
    : `${__route('sof')}/${portfolioUuid}/${source.type}/${source.uuid}`;

  const isFirstCard = index === 0;

  return (
    <Column
      xs="12"
      s="6"
      m="6"
      l="4"
      className={
        `source-card ${negativeAvailableAmount ? 'source-card--danger' : ''} ${source.type === 'old_source' ? 'old_source' : ''}`
      }
      align="start"
      id={isFirstCard ? 'tutorial-example-wrapper' : undefined}

    >
      <div className='source-card__header'>
        <Heading size="5" className="flex-center--horizontal">
          <Icon icon={keyToIcon[source.type] || "Common File Stack by Streamlinehq"} size={16} className='s-right-s' /> {__(`sof_${source.type}`)}
        </Heading>

        <div className='source-card__icons'>
          {(source.usedAmount === 0) && (
            <Button
              onlyIcon
              small
              onClick={() => {
                deleteSource(index);
              }}
            >
              <Icon icon="Bin by Streamlinehq" size={16} />
            </Button>
          )}
        </div>

      </div>

      <p>{source?.source?.name === 'Old sources' ? __('old_sof_desc') : (source?.source?.name || '')}</p>

      <div className='source-card__content'>
        <div className='source-card__info'>
          <ul className='source-card__list'>
            {(premium || showAvailableAmount) ? (
              <>
                {/* <li>
                  <span className='body-small clarification'>Källa till kapital:</span>
                  <span><strong>{getMoney(source?.source?.amounts?.source_of_fund || 0)}</strong></span>
                </li> */}
                <li id={isFirstCard ? 'tutorial-example-used' : undefined}>
                  <span className='body-small clarification'>Använt:</span>
                  {/* TODO: getMoney is deprecated. Better handling of money format in the future ? */ }
                  <span><strong>{/* getMoney(source.usedAmount) */ formatMoney(source.usedAmount, 'SEK', 2)}</strong></span>
                </li>
                <li id={isFirstCard ? 'tutorial-example-available' : undefined}>
                  <span className='body-small clarification'>Tillgängligt:</span>
                  <span>{getMoney(negativeAvailableAmount ? 0 : availableAmount)}</span>
                </li></>
            ) : (
              <>
                <li>
                  <span className='body-small clarification'>Källa till förmögenhet:</span>
                  <span><strong>{getMoney(source?.source?.amounts?.source_of_wealth || 0)}</strong></span>
                </li>
              </>
            )}

            <li id={isFirstCard ? 'tutorial-example-status' : undefined}>
              <span className='body-small clarification'>Status:</span>
              <HoverTooltip
                className={`c-${statusColors[source.status]} flex-horizontal-center`}
                hoverContent={__(`sof_statuses_${source.status}`)}
              >
                {statusTranslations[source.status]} <Icon className='s-left-s' icon="Question Circle by Streamlinehq" size={16} />
              </HoverTooltip>
            </li>
          </ul>
        </div>
        <ButtonWrap pushtosides>
          {canSetAmounts && (
            <Button
              {...getToggleProps({ onClick: () => {
                handleClick();
                handleValueChange(max);
              } })}
              ref={undefined}
              small
              className="secondary spacing"
            >
              Välj källa
            </Button>
          )}
          {(!canSetAmounts && (source.type !== 'old_source' || backoffice)) && (
            <Button
              small
              className="secondary spacing"
              href={editUrl}
            >
              Uppdatera och bevisa
            </Button>
          )}
        </ButtonWrap>
        {canSetAmounts && (
          <div className='source-card__more' {...getCollapseProps()}>
            {isExpanded && (
              <InputCurrency
                className="s-bottom-m"
                value={value}
                label="Belopp"
                suffix={"SEK"}
                callback={handleValueChange}
                controlled
                min={0}
                required
              />
            )}


          </div>
        )}
      </div>
    </Column>
  );
};



const SourceCards = ({
  totalAmount,
  sourceAmounts = [],
  sources,
  setSources,
  setSourceAmounts = () => {},
  portfolioUuid,
  profileUuid,
  isHighRisk,
  showAvailableAmount,
  canSetAmounts,
  backoffice,
  hideAddSource,
  premium,
  isCompany,
}) => {

  const [deletedIndex, setDeletedIndex] = useState(false);

  const handleSumChange = useCallback((index, value) => {
    const newSourceAmount = [...sourceAmounts];
    newSourceAmount[index] = value;
    setSourceAmounts(newSourceAmount);
  }, [sourceAmounts, totalAmount]);

  const syncSources = (index) => {
    const newSources = [...sources.map((item) => { return { ...item }; })];
    const newSourceAmounts = [...sourceAmounts];
    newSources.splice(index, 1);
    newSourceAmounts.splice(index, 1);
    setSources(newSources);
    setSourceAmounts(newSourceAmounts);
  };

  const deleteSource = async (index) => {
    const sourceToDelete = sources[index];
    await middleman.promiseDelete(backoffice
      ? `/ql/crm/source-of-funds/delete/${profileUuid}/${sourceToDelete.uuid}`
      : `/source-of-funds/${portfolioUuid}/${sourceToDelete.uuid}/delete`);
    setDeletedIndex(index);
  };

  useEffect(() => {
    if (deletedIndex !== false) {
      syncSources(deletedIndex);
      setDeletedIndex(false);
    }
  }, [deletedIndex]);

  const totalSelected = sourceAmounts.reduce((sum, item) => {
    sum += Number(item);
    return sum;
  }, 0);

  return (
    <Columns className="source-cards">
      {sources.map((source, index) => {
        // const selectedWithoutSelf = sourceAmounts.reduce((sum, item, itemIndex) => {
        //   if (itemIndex === index) return sum;
        //   sum += item;
        //   return sum;
        // }, 0);
        const availableForTransactions = totalAmount - totalSelected;
        const availableAmount = isHighRisk ? source.highRiskAvailableAmount || 0 : source?.availableAmount || 0;
        const maxVal = availableForTransactions > availableAmount ? availableAmount : availableForTransactions;
        const max = maxVal > 0 ? maxVal : 0;

        return (
          <SourceCard
            key={`sourcecard-${index}`}
            isHighRisk={isHighRisk}
            source={source}
            index={index}
            callback={handleSumChange}
            value={sourceAmounts[index]}
            deleteSource={deleteSource}
            max={max}
            portfolioUuid={portfolioUuid}
            profileUuid={profileUuid}
            showAvailableAmount={showAvailableAmount}
            canSetAmounts={canSetAmounts}
            backoffice={backoffice}
            premium={premium}
          />
        );})}
      {(!canSetAmounts && !hideAddSource) && (
        <Column xs="12" s="6" m="6" l="4">
          <AddSource
            portfolioUuid={portfolioUuid}
            profileUuid={profileUuid}
            existingSources={sources}
            backoffice={backoffice}
            isCompany={isCompany}
          />
        </Column>
      )}
    </Columns>
  );
};

export default SourceCards;
