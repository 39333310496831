import React, { useEffect, useState } from 'react';
import __ from 'localisation';
import 'modules/Tables/index.scss';
import Button from '@hubins/components/Button';
import { LoadingBar } from '@hubins/components/LoadingIndicators';
import { connect } from "react-redux";
import { formatMoney } from 'helpers';
import SearchInput from 'components/SearchInput';
import { Columns, Column } from 'components/Columns';
import SimpleTable from 'modules/Tables/SimpleTable';
import Heading from '@hubins/components/Heading';
import ApiPaginationTable from 'modules/Tables/ApiPaginationTable';
import './index.scss';

import middleman from 'helpers/middleman';

const loadingObj = {
  'toolsBeginning': <LoadingBar small />,
  'user_name': <LoadingBar small />,
  'shortName': <LoadingBar small />,
};

const loadingState = [
  loadingObj,
  loadingObj,
  loadingObj,
];

const headers = [
  {
    Header: '',
    accessor: 'toolsBeginning',
    className: 'rt-cell-button',
    width: 40
  },
  {
    Header: __('name'),
    accessor: 'user.name',
  },
  {
    Header: __('ssn_org_num'),
    accessor: "user.national_identity_number"
  },
  {
    Header: __('distributor'),
    accessor: "distributor"
  },
  {
    Header: __('policy'),
    accessor: 'short_name',
  },
  {
    Header: __('account_balance'),
    accessor: 'available_balance',
    Cell: ({ row }) => formatMoney(row.original.available_balance, row.original.currency)
  },
  {
    Header: __('market_value'),
    accessor: 'market_value',
    Cell: ({ row }) => formatMoney(row.original.market_value, row.original.currency)
  },
  {
    Header: __('updated_at'),
    accessor: 'updated_at'
  },
];

const summaryHeaders = [
  {
    Header: __('the_currency'),
    accessor: 'currency',
  },
  {
    Header: __('market_value'),
    accessor: 'market_value',
    minWidth: 200,
    Cell: ({ row }) => formatMoney(row.original.market_value, row.original.currency, 0)
  },
  {
    Header: __('account_balance'),
    accessor: 'account_balance',
    minWidth: 200,
    Cell: ({ row }) => formatMoney(row.original.account_balance, row.original.currency, 0)
  }
];

const summaryLoadingState = [
  {
    'currency': <LoadingBar small />,
    'market_value': <LoadingBar small />,
    'account_balance': <LoadingBar small />,
  }
];

const AssetManagerPortfolios = ({ userId }) => {
  const [clients, setClients] = useState(false);
  const [amountOfPages, setAmountOfPages] = useState(-1);
  const [search, setSearch] = useState('');
  const [portfolioSummary, setPortfolioSummary] = useState(false);
  const [selectedPage, setSelectedPage] = useState(0);
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchSummary = async () => {
    const res = await middleman.get(`/crm/broker/${userId}/clients/summary`);
    if (res.data) {
      const filteredSummary = res.data.filter((item) => {
        return !(item.account_balance === 0 & item.market_value === 0);
      });
      setPortfolioSummary(filteredSummary);
    }
  };

  const fetchPage = (page, searchParam = false) => {
    setSelectedPage(page - 1);
    setLoading(true);
    middleman.get(`/crm/broker/${userId}/clients?page=${page}${searchParam ? `&search=${searchParam}` : ''}`).then(res => {
      const formattedRes = res.data.data.reduce((users, item) => {
        if (item.policy_holder && Array.isArray(item.policy_holder)) {
          item.policy_holder.forEach((policyHolder) => {
            users.push({
              user: policyHolder,
              toolsBeginning: <Button
                href={`/dashboard/clients/${policyHolder.uuid}/${item.uuid}/tabs`}
                className={`transparent`}
                icon="arrow-right"
                iconSize={16}
                onlyIcon
              />,
              ...item,
            });
          });
        }

        return users;

      }, []);
      setAmountOfPages(res.data.last_page);
      setClients(formattedRes);
      setLoading(false);
    });
  };

  const searchCallback = () => {
    fetchPage(1, search);
  };

  useEffect(() => {
    if (init) {
      const timeout = setTimeout(searchCallback, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [search]);

  const handlePageFetch = (index) => {
    fetchPage(index + 1, search);
    if (!init) {
      fetchSummary();
      setInit(true);
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <Columns>
      <Column s="12" className="flex flex--row flex-end align-items-center">
        <SearchInput
          loading={loading}
          onChange={handleSearchChange}
          value={search}
          placeholder="Search client name, policy or SSN/Org-nr"
        />
      </Column>
      <Column s="12" className="amp_summary">
        <div>
          <Heading size="4" className="s-bottom-s">
            {__('a_summary')}
          </Heading>
          <SimpleTable
            headers={summaryHeaders}
            data={portfolioSummary ? portfolioSummary : summaryLoadingState}
          />
        </div>
      </Column>
      <Column s="12" className="s-top-xl">
        <ApiPaginationTable
          headers={headers}
          data={clients ? clients : loadingState}
          fetchData={handlePageFetch}
          pageCount={amountOfPages}
          index={selectedPage}
        />
      </Column>
    </Columns>
  );

};

function mapStateToProps(state) {
  return {
    userId: state.auth.User.id
  };
}

export default connect(mapStateToProps)(AssetManagerPortfolios);
