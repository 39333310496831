import React, { useMemo} from 'react';
import Input from '@hubins/components/Input';
import { getMoney } from 'helpers';
import __ from 'localisation';

type Props = {
  units: number | string;
  unitPrice: number | string;
  exchangeRate: number | string;
  currency: string;
  accruedInterest?: number | string;
  multiplier?: number;
}

const TradeAmountInput = ({ units, unitPrice, exchangeRate, currency, accruedInterest = 0, multiplier = 1 }: Props) => {

  const amount = useMemo(() => {
    if (!units || !unitPrice || !exchangeRate) {
      return 0;
    }
    try {
      const calculatedUnitPrice = multiplier ? Number(unitPrice) / multiplier : Number(unitPrice);
      const calculatedAmount = Number(units) * calculatedUnitPrice * Number(exchangeRate) + Number(accruedInterest);
      return calculatedAmount;
    } catch {
      return 0;
    }

  }, [units, unitPrice, exchangeRate, accruedInterest, multiplier])

  return (
    <Input
      label={__('trade_amount')}
      value={getMoney(amount, currency || 'SEK')}
      suffix={currency || 'SEK'}
      disabled
      controlled
    />
  )
};

export default TradeAmountInput;