import React from 'react';
import Heading from '@hubins/components/Heading';
import Button from '@hubins/components/Button';

import './index.scss';


const SummaryTable = ({
  className = '',
  titleSize = 5,
  heading = '',
  editButton,
  editRoute,
  children,
}) => {

  return (
    <div className={`summary-table__wrap ${className} s-bottom-xl`}>
      <header className="summary-table__header s-bottom-md">
        <Heading  size={titleSize}>{heading || ''}</Heading>{editButton}
        {editRoute && (
          <Button icon="Common File Text by Streamlinehq">
            Ändra svar
          </Button>
        )}
      </header>
      <ul className="summary-table">
        {children}
      </ul>
    </div>
  );
};

export default SummaryTable;
