import React from "react";
import CompactFormTemplate from './CompactFormTemplate';
import Init from './init';

export const CompactForm = (props) => (
  <Init
    Form={CompactFormTemplate}
    {...props}
  />
);

export default CompactForm;
