import {
  useQuery,
} from 'react-query';

import middleman from 'helpers/middleman';

// When no pagination needed set page to true
export const useBatches = (type: string, status: string, page: number | boolean = false) => useQuery(['batches', type, status, page], () => {
  return middleman.promisePost(`/ql/crm/batches/filter${page && typeof page === 'number' ? `?page=${page}` : ''}`, {type: type || undefined, status: status || undefined}).then(res => res.data);
}, { enabled: !!page, refetchOnWindowFocus: false });

export const useBatch = (batchId: string) => useQuery(['batch', batchId], () => {
  return middleman.promiseGet(`/ql/crm/batches/${batchId}`).then(res => res.data);
}, { enabled: !!batchId, refetchOnWindowFocus: false });
