import React, { useState } from 'react';
import Heading from '@hubins/components/Heading';
import './index.scss'

interface SliderProps {
  label?: string;
  min: number;
  max: number;
  title?: string;
  maxValueLabel?: string;
  minValueLabel?: string;
  suffix?: string;
  value?: { min: number, max: number };
  step: number;
  onChange?: (value: { min: number, max: number }, id?: string) => void;
  id?: string;
}

export const Slider = ({ label, min, max, title, minValueLabel, maxValueLabel, suffix, value: propValue, step, onChange, id }: SliderProps) => {
  const [maxValue, setMaxValue] = useState(typeof propValue !== "undefined" ? propValue.max : max);
  const [maxInputValue, setMaxInputValue] = useState(typeof propValue !== "undefined" ? propValue.max : max);
  const [minValue, setMinValue] = useState(typeof propValue !== "undefined" ? propValue.min : min);
  const [minInputValue, setMinInputValue] = useState(typeof propValue !== "undefined" ? propValue.min : min);
  const maxWidth = (max.toString().length * 25) + 60;

  const handleMaxSliderChage = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = Number(event.target.value);
    if (newValue <= minValue) return;
    if (onChange) {
      onChange({
        max: newValue,
        min: minValue,
      });
      setMaxInputValue(newValue);
      setMaxValue(newValue);
    }
  }

  const handleMinSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = Number(event.target.value);
    if (newValue >= maxValue) return;
    if (onChange) {
      onChange({
        max: maxValue,
        min: newValue,
      });
    }
    setMinInputValue(newValue);
    setMinValue(newValue);
  }

  const handleMinOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    let newValue = Number(event.target.value);
    if (newValue < min || newValue >= maxValue) {
      newValue = newValue >= maxValue ? maxValue - 1 : Number(min);
    }
    if (onChange) {
      onChange({
        max: maxValue,
        min: newValue,
      });
    }
    setMinValue(newValue);
    setMinInputValue(newValue);
  }

  const handleMaxOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    let newValue = Number(event.target.value);
    if (newValue > max || newValue <= minValue) {
      newValue = newValue <= minValue ? minValue + 1 : Number(max);
    }
    if (onChange) {
      onChange({
        max: newValue,
        min: minValue,
      });
    }
    setMaxValue(newValue);
    setMaxInputValue(newValue);
  }

  const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    if (onChange) {
      onChange({
        max: newValue,
        min: minValue,
      });
    }
    setMaxInputValue(newValue);
  }

  const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    if (onChange) {
      onChange({
        max: maxValue,
        min: newValue,
      });
    }
    setMinInputValue(newValue);
  }

  const handleInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
  };

  return (
    <>
      {title && (
        <Heading size="4" className="s-bottom-md">
          {title}
        </Heading>
      )}
      <label className='min-max-slider'>
        <span className="min-max-slider__label">
          {label}:
        </span>
        <div className='min-max-slider__input-wrapper'>
          <div style={{ maxWidth: `${maxWidth}px` }} className='min-max-slider__single-input-wrapper'>
            <input style={{ maxWidth: `${maxWidth}px` }} value={minInputValue} onChange={handleMinChange} onBlur={handleMinOnBlur} className="min-max-slider__input" type="text" onInput={handleInput} />
            <span className="min-max-slider__suffix">{suffix}</span>
          </div>
          <div style={{ maxWidth: `${maxWidth}px` }} className='min-max-slider__single-input-wrapper'>
            <input style={{ maxWidth: `${maxWidth}px` }} value={maxInputValue} onChange={handleMaxChange} onBlur={handleMaxOnBlur} className="min-max-slider__input" type="text" onInput={handleInput} />
            <span className="min-max-slider__suffix">{suffix}</span>
          </div>
        </div>
        <div className='min-max-slider__slider-wrapper'>
          <input step={step} value={minValue} onChange={handleMinSliderChange} className="min-max-slider__range min-max-slider__range__min" type="range" min={min} max={max} />
          <div className='min-max-slider__progress' />
          <div className='min-max-slider__diff' style={{
            left: `${((minValue - min) / (max - min)) * 100}%`,
            width: `${((maxValue - minValue) / (max - min)) * 100}%`,
          }} />
          <input step={step} value={maxValue} onChange={handleMaxSliderChage} className="min-max-slider__range min-max-slider__range__max" type="range" min={min} max={max} />
        </div>
        <div className='min-max-slider__labels'>
          <span className='min-max-slider__min'>{minValueLabel || min}{suffix}</span>
          <span className='min-max-slider__max'>{maxValueLabel || max}{suffix}</span>
        </div>
      </label>
    </>
  )
};

Slider.defaultProps = {
  min: 0,
  max: 100,
  step: 1,
  suffix: '',
}

export default Slider;