

export const getRiskGroup = (score: number) => {
  if (score < 0) {
    return 1;
  } else if (score < 3) {
    return 2;
  } else if (score < 25) {
    return 3;
  } else if (score >= 25) {
    return 4;
  } else {
    return 2;
  }
}