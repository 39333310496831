import React, { useEffect, useState } from 'react';
import { useProfilePortfolioApprovals } from 'queries/approvals';
import { loadingState, tableHeaders } from 'helpers/approvals';
import SimpleTable from 'modules/Tables/SimpleTable';



const AllPortfolioAndProfileApprovalsTable = ({ profileUuid, portfolioUuid }) => {

  const [mergedApprovals, setMergedApprovals] = useState(loadingState);

  const { data: profileData, isFetched: profileIsFetched } = useProfilePortfolioApprovals(profileUuid, undefined, 'executed');
  const { data: portfolioData, isFetched: portfolioIsFetched } = useProfilePortfolioApprovals(undefined, portfolioUuid, 'executed');

  useEffect(() => {
    if (profileIsFetched && portfolioIsFetched) {
      const portfolioResult = portfolioData?.result || [];
      const filteredProfileData = profileData?.result?.filter((profile) => !portfolioResult.find((portfolio) => portfolio.uuid === profile.uuid)) || [];
      const mergedData = [...filteredProfileData, ...(portfolioData.result || [])];
      setMergedApprovals(mergedData);
    }
  }, [profileData, portfolioData, profileIsFetched, portfolioIsFetched]);

  return (
    <SimpleTable
      headers={tableHeaders}
      data={mergedApprovals}
    />
  );

};

export default AllPortfolioAndProfileApprovalsTable;