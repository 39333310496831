import React, { useEffect, useState } from 'react';
import __ from 'localisation';
import SummaryTable from 'components/SummaryTable';
import SummaryItem from 'components/SummaryItem';
import Button from '@hubins/components/Button';
import Icon from '@hubins/components/IconNew';
import CreateForm from 'modules/CreateForm';
import Modal from '@hubins/components/Modal';
import { sendToParent } from 'modules/CreateForm/FilterMethods';
import { formDataToCollection } from 'helpers/filters';
import { getInitialFormState } from 'helpers/createForm';
import { breakFormDataReflection, validateAll } from 'modules/CreateForm/createFormMethods';
import checkRules from 'modules/CreateForm/checkRules';

import {
  appendAllFieldValues,
  appendAndResetAllFieldValues,
  appendValue,
  deconstructSlides,
  displayStatement,
  formatFormData,
  getFieldValue,
} from 'helpers/formscms';

const EditAbleSummary = ({
  summary,
  title,
  submit,
  validate = true,
  submitRef,
  submitText,
  hideSubmit,
  editable = true,
}) => {

  const [formData, setFormData] = useState(false);
  const [formState, setFormState] = useState({});
  const [attributes, setAttributes] = useState(false);
  const [edit, setEdit] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const hasSlides = Array.isArray(summary.content[0]);
    const originalForm = [...breakFormDataReflection(hasSlides ? deconstructSlides(summary.content) : summary.content)];
    const newForm = appendAllFieldValues(originalForm, summary.response);
    const initState = sendToParent(getInitialFormState(newForm));
    setFormState(initState);
    setFormData(newForm);
  }, []);

  const abortEdit = () => {
    setEdit(false);
  };

  const editForm = (data) => {
    setEdit(data);
  };

  const revalidateErrors = (values) => {
    const keys = Object.keys(values);
    let newErrors = errors;
    keys.forEach((key) => {
      if (newErrors.includes(key)) {
        newErrors.splice(newErrors.indexOf(key), 1);
      }
    });
    setErrors(newErrors);
  };

  const handleEditResponse = (values) => {
    const originalForm = [...breakFormDataReflection(summary.content)];
    const currentAttributes = attributes ? attributes : summary.response;
    const simpleAttributes = { ...currentAttributes, ...formDataToCollection(values) };
    const newForm = appendAndResetAllFieldValues(originalForm, simpleAttributes);
    revalidateErrors(values);
    setAttributes(simpleAttributes);
    setFormState({ ...formState, ...values });
    setFormData(newForm);
    abortEdit();
  };

  const handleSubmit = (e) => {

    e.preventDefault();

    let newErrors = validate ? validateAll(formState, setFormState) : [];
    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }

    const submitBody = {
      key: summary.key,
      type: summary.type,
      data: formState,
      version: summary.version,
    };

    submit(submitBody);

  };

  const generateRepeaterFields = (field) => {
    if (!field.value || field.value.length === 0) {
      return [];
    }

    const fieldsCollection = field.fields.reduce((collection, repeaterField) => {
      collection[repeaterField.id] = repeaterField;
      return collection;
    }, {});

    return field.value.reduce((arr, valueSet) => {
      const valueSetKeys = Object.keys(valueSet);
      valueSetKeys.forEach(key => {
        const repeaterField = fieldsCollection[key];
        const updatedField = appendValue(repeaterField, valueSet);
        arr.push([
          <SummaryItem
            key={updatedField.id}
            title={updatedField.title || updatedField.label || updatedField.heading}
            value={getFieldValue(updatedField)}
            handleEdit={false}
            depth={1}
            error={false}
          />
        ]);
      });
      return arr;
    }, []);

  };

  return (
    <>
      {formData !== false && (
        <SummaryTable
          key={title}
          heading={title}
        >
          {formData.reduce((arr, section) => {

            if (section.fields) {
              section.fields.forEach(field => {

                const hasVisibleConditions = field.visibleConditions && field.visibleConditions.length > 0;
                const isVisible = checkRules(field.visibleConditions, formState);

                if (
                  displayStatement(field)
                  || !isVisible
                ) {
                  return;
                }

                const handleEdit = (hasVisibleConditions || !editable) ? false : () => editForm(formatFormData([field.id], formData));
                arr.push([
                  <SummaryItem
                    key={field.id}
                    title={field.title || field.label || field.heading}
                    value={getFieldValue(field)}
                    handleEdit={handleEdit}
                    depth={hasVisibleConditions ? 1 : 0}
                    error={errors.includes(field.id)}
                  />,
                ]);

                if (field.type === 'repeater') {
                  const summaryItems = generateRepeaterFields(field);
                  arr.push([...summaryItems]);
                }

              });
            }

            return arr;
          }, [])}
        </SummaryTable>
      )}
      {edit !== false && (
        <Modal
          open
          noWrapper
        >
          {edit !== false && (
            <div className="relative wrapper wrapper--narrow form">
              <Button
                onlyIcon
                type="button"
                className="medium top-right"
                onClick={(e) => { e.preventDefault(); abortEdit(); }}
              >
                <Icon icon="remove" color="primary" size={16} label={__('close')} />
              </Button>
              <CreateForm
                buttonStyle="cta"
                className="wrapper form"
                formData={edit}
                submitType={handleEditResponse}
                submitText={__('save')}
                // buttonProps={{ icon: 'save' }}
              />
            </div>
          )}
        </Modal>
      )}
      {console.log('submitRef', submitRef)}
      <Button
        onClick={
          handleSubmit
        }
        className={hideSubmit ? 'hidden' : ''}
        innerRef={submitRef}
      >
        {submitText}
      </Button>
    </>
  );

};

export default EditAbleSummary;
