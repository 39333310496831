import React, { useState } from 'react';
import Heading from '@hubins/components/Heading';
import Dropdown from '@hubins/components/Dropdown/Dropdown';
import Input from '@hubins/components/Input';
import Button from '@hubins/components/Button';
import Badge from '@hubins/components/Badge';
import { getStatusColor } from 'helpers/approvals';
import NameInitialsBadge from '@hubins/components/NameInitialsBadge';
import FilesTable from '@hubins/components/FilesTable';
import FileUpload from 'components/FileUpload';
import { Columns, Column } from 'components/Columns';
import DateInput from '@hubins/components/DateInput';
import __ from 'localisation';

const options = [
  {
    'id': 'approved',
    'title': __('approved'),
  },
  {
    'id': 'denied',
    'title': __('denied'),
  },
  {
    'id': 'comment',
    'title': __('comment'),
  },
];

const priorityOptions = [
  {
    'id': 'low',
    'title': __('low'),
  },
  {
    'id': 'standard',
    'title': __('Standard'),
  },
  {
    'id': 'urgent',
    'title': __('urgent'),
  },
];

const stageOptions = [
  {
    'id': 'processing',
    'title': __('processing'),
  },
  {
    'id': 'waiting_on_quantum',
    'title': __('waiting_on_quantum'),
  },
  {
    'id': 'waiting_on_client',
    'title': __('waiting_on_client'),
  },
  {
    'id': 'waiting_on_third_party',
    'title': __('waiting_on_third_party'),
  },
  {
    'id': 'waiting_on_payment',
    'title': __('waiting_on_payment'),
  },
  {
    'id': 'waiting_on_settlement',
    'title': __('waiting_on_settlement'),
  },
  {
    'id': 'waiting_on_management',
    'title': __('waiting_on_management'),
  },
  {
    'id': 'suspected',
    'title': __('suspected'),
  },

];

const Verdicts = ({
  verdicts,
  submit,
  loading,
  role,
  files: propFiles,
  canEdit,
  dueDate,
  setDueDate,
  priority,
  setPriority,
  isExecuted,
  archive,
  archiveLoading,
  approvalStatus,
  message,
  setMessage,
  stage,
  setStage,
}) => {

  const [status, setStatus] = useState('placeholder');
  const [files, setFiles] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (status === 'approved') {
      const confirmation = window.confirm("Are you sure you want to approve this case?");
      if (!confirmation) {
        return;
      }
    }

    if (status === 'denied') {
      const confirmation = window.confirm("Are you sure you want to reject this case?");
      if (!confirmation) {
        return;
      }
    }

    submit(status, files);
  };

  return (
    <Columns>
      <Column m="6">
        {propFiles && propFiles.length > 0 && (
          <div className='box-padding s-bottom-l'>
            <Heading size="4" className="s-bottom-xl">
              {__('uploaded_files')}
            </Heading>
            <FilesTable files={propFiles} downloadUrl="/ql/crm/approvals/download" />
          </div>
        )}
        <div className='box-padding neutral-1'>
          <Heading size="4" className="s-bottom-xl">
            {__('leave_your_verdict')}
          </Heading>
          <form onSubmit={handleSubmit}>
            <Columns>
              {canEdit && (
                <>
                  <Column m="6">
                    <DateInput
                      label={__('due_date_label')}
                      value={(dueDate && typeof dueDate === 'string') ? dueDate.substring(0, 10) : ''}
                      callback={setDueDate}
                    />
                  </Column>
                </>
              )}
              <Column m="6">
                <Dropdown
                  label={__('priority_label')}
                  options={priorityOptions}
                  value={priority}
                  callback={setPriority}
                  placeholder={__('select_priority')}
                />
              </Column>
              <Column m="6">
                <Dropdown
                  label={__('stage_label')}
                  options={stageOptions}
                  value={stage}
                  callback={setStage}
                />
              </Column>
              <Column m="6">
                <Dropdown
                  label={__("verdict")}
                  options={options}
                  value={status}
                  callback={setStatus}
                  placeholder={__('select_verdict')}
                  required
                />
              </Column>
              <Column s="12">
                <Input
                  label={__("new_comment")}
                  className='s-top-md'
                  value={message}
                  callback={setMessage}
                  placeholder={__("new_comment")}
                  controlled
                  required
                />
                <div>
                  <FileUpload
                    controlled
                    value={files}
                    callback={setFiles}
                  />
                </div>
              </Column>
            </Columns>

            <Button
              type="submit"
              className='cta s-top-xl s-bottom-xl spacing'
              icon="Messages Bubble Square Typing by Streamlinehq"
              iconColor='white'
              loading={loading}
              disabled={!canEdit && (loading || role.status !== 'active')}
              size="large"
            >
              {__('save_and_continue')}
            </Button>
            <Button href="/dashboard/approvals" size="large" icon="remove" className='s-left-m'>
              {__('exit')}
            </Button>
            {(canEdit && !isExecuted) && (
              <>
                {approvalStatus === 'archived' ? (
                  <Button
                    className='success large s-left-m'
                    onClick={() => archive(false)}
                    loading={archiveLoading}
                    disabled={archiveLoading}
                  >
                    {__('remove_from_archive')}
                  </Button>
                ) : (
                  <Button
                    className=' transparent large s-left-m'
                    icon="Bin by Streamlinehq"
                    // iconColor='white'
                    onClick={() => archive(true)}
                    loading={archiveLoading}
                    disabled={archiveLoading}
                  >
                    {__('archive')}
                  </Button>
                )}

              </>
            )}

          </form>
        </div>
      </Column>
      <Column offsetM="1" m="5">
        <Heading size="4" className="s-bottom-xl s-top-l">
          {__('verdicts')}
        </Heading>
        {(verdicts && verdicts.length > 0) ? verdicts
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .map((verdict) => {
            const splitCreationDate = verdict.created_at?.split('T');
            const creationDate = splitCreationDate[0];
            const creationTime = splitCreationDate[1]?.split('.')[0];
            const theDate = `${creationDate} ${creationTime}`;
            return(
              <div key={verdict.uuid} className='verdict-card--wrapper'>
                <NameInitialsBadge name={verdict.user_name} className="s-right-m" />
                <div key={verdict.uuid} className='verdict-card'>
                  <div className='verdict-card__header'>
                    <div>
                      <p>{verdict.user_name} <span className="body-small s-left-s c-neutral-5">{theDate}</span> <br /><span className='clarification'>{__(verdict.role)}</span></p>
                    </div>
                    <Badge color={getStatusColor(verdict.status)}>
                      {__(verdict.status)}
                    </Badge>
                  </div>
                  <p className='s-top-l'>{verdict.message}</p>
                </div>
              </div>
            );}) : (
          <div className='box box-padding border-dashed verdicts-placeholder'>
            <p>{__('be_the_first_to_leave_a_verdict')}</p>
          </div>
        )}
      </Column>
    </Columns>
  );
};

export default Verdicts;
