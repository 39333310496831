import React from 'react';
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import './index.scss';

const LoadingPage = () => {
  return (
    <div className='hc-loading-page'>
    <LoadingBox />
    </div>
  )
};

export default LoadingPage;