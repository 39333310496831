import React, { useEffect, useState } from 'react';
import middleman from 'helpers/middleman';
import CRMEditableSummary from 'modules/CRMEditableSummary';
import __reg from 'localisation/hubins/registration';
import __ from 'localisation';

const CompanyPersonnel = ({ profileUuid }) => {

  const [selected, setSelected] = useState('placeholder');
  const [personnel, setPersonnel] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState({});

  const fetchData = async () => {
    const profile = await middleman.get(`/profile/${profileUuid}`);

    console.log('profile', profile);

    const signatories = profile.data.signatories ? profile.data.signatories.map((signatory) => {
      signatory.isSignatory = true;
      return signatory;
    }) : [];

    const beneficiaries = profile.data.beneficial_owners || [];

    const investmentRepresentatives = profile.data.investment_representatives || [];
    const formattedInvestmentRepresentatives = investmentRepresentatives.map((rep) => {
      rep.isInvestmentRepresentative = true;
      return rep;
    });

    setPersonnel([...signatories, ...beneficiaries, ...formattedInvestmentRepresentatives]);
  };

  useEffect(() => {
    setSelectedPerson(personnel.find(person => person.uuid === selected));
  }, [personnel, selected]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleSelect = (e) => {
    setSelected(e.target.value);
  };

  return (
    <>
      <label className='s-top-xl'>
        <span className='s-bottom-m'>{__reg('company_personnel')}</span>
        <select selected={selected} onChange={handleSelect} className='s-bottom-xl'>
          <>
            <option value="placeholder">{__reg('choose_person')}</option>
            {personnel.map((item, index) => (
              <option key={`${item.uuid}-${index}`} value={item.uuid}>{item.name} {item.isSignatory ? `(${__('signatory')})` : item.isInvestmentRepresentative ? `(${__('inv_rep')})` : `(${__('beneficial_owner')})`}</option>
            ))}
          </>
        </select>
        {selectedPerson && (
          <>
            <CRMEditableSummary
              title={__("kyc")}
              formKey="p_kyc"
              userId={selectedPerson.national_identity_number}
              editable={false}
            />
            {!selectedPerson.isInvestmentRepresentative && (
              <CRMEditableSummary
                title={__("proxy")}
                formKey={selectedPerson.isSignatory ? "s_signoff" : "b_signoff"}
                profileUuid={profileUuid}
                userId={selectedPerson.national_identity_number}
                editable={false}
              />
            )}
          </>
        )}
      </label>

    </>
  );

};

export default CompanyPersonnel;
