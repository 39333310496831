import React from 'react';
import FormHistory from 'modules/FormHistory';

const Beneficiaries = ({ contact, portfolioUuid, profileUuid }) => {
  const isCompany = contact.type === 'COMPANY';
  const key = isCompany
    ? 'c_da_beneficiaries'
    : 'p_da_beneficiaries';

  return <FormHistory
    formKey={key}
    contactName={contact.name}
    portfolioUuid={portfolioUuid}
    profileUuid={profileUuid}
  />;
};

export default Beneficiaries;
