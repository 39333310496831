// initialize bugsnag ASAP, before other imports
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import './polyfills';
import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import configureStore from './state/store';

Bugsnag.start({
  enabledReleaseStages: [ 'production', 'beta', 'staging', 'demo'],
  releaseStage: import.meta.env.VITE_MODE,
  appVersion: VITE_VERSION,
  apiKey: import.meta.env.VITE_BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
});
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
const reduxStore = configureStore();
const container = document.getElementById('app');
const root = createRoot(container);
root.render(
  <ErrorBoundary>
    <Provider store={reduxStore}>
      <App />
    </Provider>
  </ErrorBoundary>
);
