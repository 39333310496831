import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'components/Tabs';
import { __ } from 'localisation';
import useTabs from 'hooks/useTabs';
import Overview from 'views/Client/Overview';
import Holdings from 'views/Client/Holdings';
import Transactions from 'views/Client/Transactions';
import Report from 'views/Client/Report';
import YearlyReport from 'views/Client/YearlyReport';
import SourceOfFunds from 'views/Client/SourceOfFunds';
import Documents from 'views/Client/Documents';


const tabs = [
  'overview',
  'holdings',
  'transactions',
  'report',
  'yearly-report',
  'documents',
  'sof'
];

type Props = {
  portfolio: any;
  client: any;
  profileUuid: string;
  portfolioUuid: string;
}

const ClientView = ({
  portfolio,
  client,
  profileUuid,
  portfolioUuid,
}: Props) => {

  const [index, setIndex] = useState(0);
  useTabs(setIndex, index, tabs);


  return (
    <Tabs
      className="line-tabs"
      selectedTabClassName="line-tabs__tab--selected"
      selectedTabPanelClassName="line-tabs__tab-panel--selected"
      onSelect={setIndex}
      selectedIndex={index}
    >
      <TabList className="line-tabs__tab-list line-tabs__submenu">
        <Tab className="heading heading--5 line-tabs__tab">{__('overview')}</Tab>
        <Tab className="heading heading--5 line-tabs__tab">{__('holding')}</Tab>
        <Tab className="heading heading--5 line-tabs__tab">{__('transactions')}</Tab>
        <Tab className="heading heading--5 line-tabs__tab">{__('report')}</Tab>
        <Tab className="heading heading--5 line-tabs__tab">{__('yearly_report')}</Tab>
        <Tab className="heading heading--5 line-tabs__tab">{__('document')}</Tab>
        <Tab className="heading heading--5 line-tabs__tab">{__('sof')}</Tab>
      </TabList>
      <TabPanel>
        <Overview
          portfolioUuid={portfolioUuid}
          portfolio={portfolio}
        />
      </TabPanel>
      <TabPanel>
        <Holdings
          portfolioUuid={portfolioUuid}
          portfolio={portfolio}
        />
      </TabPanel>
      <TabPanel>
        <Transactions
          portfolioUuid={portfolioUuid}
          portfolio={portfolio}
        />
      </TabPanel>
      <TabPanel>
        <Report
          portfolioUuid={portfolioUuid}
          portfolio={portfolio}
        />
      </TabPanel>
      <TabPanel>
        <YearlyReport
          portfolio={portfolio}
          portfolioUuid={portfolioUuid}
        />
      </TabPanel>
      <TabPanel>
        <Documents
          profileUuid={profileUuid}
          portfolioUuid={portfolioUuid}
        />
      </TabPanel>
      <TabPanel>
        <SourceOfFunds
          isCompany={client.type === 'COMPANY'}
          profileUuid={profileUuid}
          portfolioUuid={portfolioUuid}
        />
      </TabPanel>
    </Tabs>
  )
};

export default ClientView;