import React from 'react';
import FormHistory from 'modules/FormHistory';

const KYC = ({ contact, profileUuid }) => {
  const isCompany = contact.type === 'COMPANY';
  const key = isCompany
    ? 'c_kyc'
    : 'p_kyc';

  return <FormHistory
    formKey={key}
    contactName={contact.name}
    profileUuid={profileUuid}
  />;


};

export default KYC;
