import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from 'components/ErrorMessage';
import { __ } from 'localisation';
import SidebarTrigger from 'components/Sidebar/SidebarTrigger';
import { generateColumnClasses } from 'components/Columns';
import Heading from '@hubins/components/Heading';

const Template = ({
  description,
  rerenderKey = '',
  handleChange,
  columns = true,
  multiple = false,
  title = '',
  error_message = __('select_error'),
  locked = false,
  lockedText = false,
  spacing = true,
  optionsState = {
    validation: 'standard',
    error: false,
    value: {},
    visible: true,
    type: 'select', //is used in validation,
    disabled: false,
  },
  options = [],
  id,
  partId,
  tooltip,

}) => {

  const renderButtons = () => {
    let buttons = options.map((item, index) => {

      let buttonClassName = '';

      if (locked) {
        buttonClassName = 'disabled';
      }

      const checked = optionsState.value[item.id].value;
      return (
        <div key={id + item.id + index + rerenderKey}
          className={
            `formselect__wrapper ${columns === 'one'
              ? ''
              : columns
                ? `column ${item.media ? generateColumnClasses(item.media) : ''}`
                : 'flex'}
            `
          }>
          <label className="formselect__label">
            <input
              onChange={(event) => handleChange(partId, item.id, false, event)}
              className="formselect visuallyhidden"
              type={multiple ? 'checkbox' : 'radio'}
              name={partId}
              value={optionsState.value[item.id].id}
              checked={checked}
              disabled={item.disabled || locked}
            />
            <span
              data-id={partId + '_' + optionsState.value[item.id].id}
              className={columns === "one" ? `button fullwidth withicon ${buttonClassName}` : columns ? `button withicon fullwidth ${buttonClassName}` : `button withicon fullwidth stack picker`}
            >
              <strong>{item.title}</strong>
            </span>
          </label>
          {item.tooltip && (
            <SidebarTrigger
              id={`${id + item.id}_tooltip`}
              text={item.tooltip}
              icon="question"
            />
          )}
        </div>
      );
    });

    return buttons;
  };

  const throwError = optionsState.error;
  return (
    <>
      <fieldset id={id} name={id} className={
        `form-group column column--xs12 ${spacing
          ? (typeof (spacing) === 'string'
            ? (' ' + spacing)
            : ' s-top-lg')
          : ''}`
      }>
        <Heading element="legend" size="4" className="form-group__title s-bottom-l">
          {title}
          {tooltip && (
            <SidebarTrigger
              id={`${id}_tooltip`}
              text={tooltip}
              icon="question"
            />
          )}
        </Heading>
        {description && (
          <p className="form-group__desc clarification">{description}</p>
        )}
        {columns ? (
          <div className={columns === "one" ? "formselect__oneline" : `columns formselect__columns`}>
            {renderButtons()}
          </div>) :
          renderButtons()
        }
        {lockedText && (
          <p className="clarification center section-padding-small">{lockedText}</p>
        )}

        <ErrorMessage text={error_message} showError={throwError} />

      </fieldset>
    </>
  );
};

Template.propTypes = {
  options: PropTypes.array,
  id: PropTypes.string.isRequired,
  multiple: PropTypes.bool.isRequired,
  columns: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ])
};

export default Template;
