import axios from "axios";
import { getItem } from 'helpers';

export const apiConnector = baseUrl => {
  const middlemanURI = baseUrl;
  const defaultHeaders = () => {
    return {
      headers: { Authorization: `Bearer ${getItem("token")}` },
      // withCredentials: true
    };
  };
  const mergeHeaders = (headers = {}) => {
    return { headers: { ...defaultHeaders().headers, ...headers.headers }};
  };


  const promiseGet = url => {
    return axios.get(middlemanURI + url, defaultHeaders());
  };

  const promiseDelete = url => {
    return axios.delete(middlemanURI + url, defaultHeaders());
  };

  const get = (url, config = {}) => {
    const headers = mergeHeaders(config);
    return (
      axios
        .get(middlemanURI + url, headers)
        // For debug temp
        .then(data => {
          return data;
        })
        .catch(err => {
          console.error("api err", err);
          throw err;
        })
    );
  };

  const getBlob = (url) => {
    const conf = {
      method: 'GET',
      responseType: 'blob',
      ...defaultHeaders()
    };
    return (
      axios.get(middlemanURI + url, conf)
    );
  };

  const promiseKeepAlivePost = (url, body, signal = undefined) => {
    const config = defaultHeaders();
    config.headers['Content-Type'] = `application/json`;
    config.keepalive = true;
    return fetch(middlemanURI + url, {
      method: 'POST',
      body: JSON.stringify(body),
      signal,
      ...config
    }).then(async res => {
      const result = await res.json();
      result.httpStatus = res.status;
      return result;
    });
  };

  const postFormData = (url, body) => {
    const config = defaultHeaders();
    config.headers['Content-Type'] = `multipart/form-data`;
    return axios.post(middlemanURI + url, body, config);
  };

  const promisePost = (url, body = {}, config = {}) => {
    return axios.post(middlemanURI + url, body, { ...config,  ...defaultHeaders() });
  };

  const promisePut = (url, body = {}, config = {}) => {
    return axios.put(middlemanURI + url, body, { ...config,  ...defaultHeaders() });
  };

  const patch = (url, body = {}) => {
    return axios.patch(middlemanURI + url, body, defaultHeaders());
  };

  const put = (url, body = {}, config = {}) => {
    return axios.put(middlemanURI + url, body, { ...config,  ...defaultHeaders() });
  };

  const post = (url, body = {}, callback) => {
    return axios
      .post(middlemanURI + url, body, defaultHeaders())
      .then(data => {
        if (callback) callback(data);
        return data;
      })
      .catch(err => {
        console.error("api err", err);
        if (callback) callback(err.name + ': ' + err.message);
        throw err;
        //return err;
      });
  };

  const deleteResource = (url, body = {}) => {
    const config = defaultHeaders();
    return axios.delete(middlemanURI + url, defaultHeaders(), body);
  };
  return { get, promiseGet, promiseDelete, post, postFormData, promisePost, patch, getBlob, promiseKeepAlivePost, deleteResource, promisePut, put };
};

export default apiConnector;
