import React, { useState } from 'react';
import __ from 'localisation';
import Heading from '@hubins/components/Heading';
import Icon from '@hubins/components/IconNew';
import AddSourceModal from 'modules/AddSourceModal';
import Button from '@hubins/components/Button';
import './index.scss';


const AddSource = ({ portfolioUuid, profileUuid, existingSources, backoffice, isCompany }) => {

  const [open, setOpen] = useState(false);

  const toggleModal = () => {
    setOpen(!open);
  };

  return (
    <>
      <AddSourceModal
        isCompany={isCompany}
        callback={toggleModal}
        open={open}
        portfolioUuid={portfolioUuid}
        profileUuid={profileUuid}
        existingSources={existingSources}
        backoffice={backoffice}
      />
      <div className='add_source' onClick={toggleModal} >
        <div className='add_source__header'>
          <Heading size="4">
            Lägg till källa
          </Heading>
          <Icon size={16} icon="add" color="primary" />
        </div>
        <div className="add_source__footer">
          <Button icon="add">
            Lägg till källa
          </Button>
        </div>
      </div>
    </>
  );

};

export default AddSource;
