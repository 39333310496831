import { useEffect, useState } from 'react';
import { usePortfolioSearch as usePortfolioSearchQuery } from 'queries/portfolios';

function usePortfolioSearch(search, page) {

  const [amountOfPages, setAmountOfPages] = useState(-1);
  const [portfolios, setPortfolios] = useState([]);
  const [storedSearch, setStoredSearch] = useState(search);

  const { data, isLoading } = usePortfolioSearchQuery(storedSearch, page);

  useEffect(() => {
    if (data) {
      setPortfolios(data.data);
      setAmountOfPages(data.last_page);
    }
  }, [data]);

  const searchCallback = () => {
    setStoredSearch(search);
  };

  useEffect(() => {
    const timeout = setTimeout(searchCallback, 1500);
    return () => {
      clearTimeout(timeout);
    };
  }, [search]);

  return {
    portfolios: portfolios || [],
    amountOfPages,
    loading: isLoading,
  };
}

export default usePortfolioSearch;
