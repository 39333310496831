import React, { useState } from 'react';
import { useSourceOfFunds } from 'queries/sourceOfFunds';
import Dropdown from '@hubins/components/Dropdown';
import { LoadingBar } from '@hubins/components/LoadingIndicators';
import SourceOfFundHistory from '@hubins/components/SourceOfFundHistory';
import __ from 'localisation';
type Props = {
  profileUuid: string;
  contact: any;
}

const SourceOfFundsHistory = ({ profileUuid, contact }: Props) => {
  const [selectedSource, setSelectedSource] = useState('');
  const { data, isLoading, isFetched } = useSourceOfFunds(profileUuid);

  const foundSource = selectedSource ? data.find((item) => item.uuid === selectedSource) : null;

  return (
    <>
      {(isLoading || !isFetched) ? (
        <LoadingBar medium />
      ) : (
        <>
        <div className='s-left-s'>
          <Dropdown
            placeholder={__('choose_source')}
            className="input-max-width"
            label={__('choose_source')}
            options={data.map((item) => ({
              id: item.uuid,
              title: `${__(`sof_${item.type}`)} ${item?.source?.name ? `- ${item.source.name}` : ''}`,
            }))}
            value={selectedSource}
            callback={setSelectedSource}
            />
          </div>
          <div key={foundSource?.uuid}>
            {foundSource && <SourceOfFundHistory source={foundSource} profileUuid={profileUuid} contact={contact} />}
          </div>
        </>
      )}
    </>
  )
};

export default SourceOfFundsHistory;