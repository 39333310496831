import React, { useState, useEffect } from 'react';
import Button from '@hubins/components/Button';
import { Column, Columns } from 'components/Columns';
import Dropdown from '@hubins/components/Dropdown';
import { useProfilePortfolioApprovals } from 'queries/approvals';
import { tableHeaders, loadingState } from 'helpers/approvals';
import ApiPaginationTable from 'modules/Tables/ApiPaginationTable';

const entities = [
  { id: 'portfolio', title: 'Portfolio' },
  { id: 'profile', title: 'Profile' },
];

const PortfolioProfileApprovals = ({ profileUuid, portfolioUuid }) => {

  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasFetched, setHasFetched] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(false);
  const [value, setValue] = useState('portfolio');

  const { data, isFetched, isLoading, refetch } = useProfilePortfolioApprovals(
    selectedEntity === 'profile'
      ? profileUuid
      : undefined,
    selectedEntity === 'portfolio'
      ? portfolioUuid
      : undefined,
    undefined,
    page + 1);

  useEffect(() => {
    if (isFetched && !hasFetched) {
      setTotalPages(data.pages);
      setHasFetched(true);
    }
  }, [isFetched]);

  useEffect(() => {
    setSelectedEntity(value);
    setPage(0);
  }, [value]);

  const refresh = () => {
    refetch();
  };


  return (
    <Columns>
      <Column s="4">
        <Dropdown
          label={"Filter by either profile or portfolio"}
          options={entities}
          value={value}
          callback={setValue}
          placeholder="Choose entity"
        />
      </Column>
      <Column s="12">
        <Button className="medium" onClick={refresh} icon="Button Refresh Arrows by Streamlinehq">
          Refresh
        </Button>
      </Column>
      <Column s="12">
        <ApiPaginationTable
          headers={tableHeaders}
          data={(!data || !data.result || isLoading || !isFetched) ? loadingState : data.result}
          fetchData={setPage}
          pageCount={totalPages}
          index={page}
        />
      </Column>
    </Columns>
  );

};

export default PortfolioProfileApprovals;
