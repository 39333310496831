import React, { useState } from 'react';
import { Columns, Column } from 'components/Columns';
import { useTransactions } from '../../../../packages/queries/report';
import TransactionDatePicker from 'modules/TransactionDatePicker';
import Heading from '@hubins/components/Heading';
import TransactionsTable from 'modules/Tables/TransactionsTable';

import {
  formatDate,
  filterType,
  filterSecurity,
  firstDateOfYear
} from 'helpers';

const Transactions = ({ portfolio, portfolioUuid }) => {

  const [arg1, setArg1] = useState('*');
  const [arg2, setArg2] = useState('*');
  const [startDate, setStartDate] = useState(portfolio.start_date ? firstDateOfYear(portfolio.start_date) : '2020-01-01');
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));

  const { data, isLoading, isFetched } = useTransactions(startDate, endDate, portfolioUuid);

  const handleChange = (from, to, argOne, argTwo) => {
    setArg1(argOne);
    setArg2(argTwo);

    if (from instanceof Date) {
      from = formatDate(from.toISOString());
    }

    if (to instanceof Date) {
      to = formatDate(to.toISOString());
    }

    setStartDate(from);
    setEndDate(to);
  };

  const filterData = (dataToFilter) => {
    if (arg1 === '*' && arg2 === '*') {
      return dataToFilter;
    } if (arg1 !== '*') {
      dataToFilter = filterType(dataToFilter, arg1);
    } if (arg2 !== '*') {
      dataToFilter = filterSecurity(dataToFilter, arg2);
    }
    return dataToFilter;
  };

  const result = isFetched ? filterData(data.transactions) : [];

  return (
    <Columns>
      <Column s="12">
        <Heading size="3" className="s-bottom-xl">Dina konton</Heading>
      </Column>
      <Column S="12">
        <TransactionDatePicker
          from={startDate}
          to={endDate}
          data={isFetched ? data.transactions : []}
          onClick={handleChange}
          isLoading={isLoading}
        />
      </Column>
      <Column s="12" className="s-top-xl">
        <TransactionsTable
          data={isFetched ? result : []}
          isLoading={isLoading}
          currency={portfolio.currency}
        />
      </Column>
    </Columns>
  );

};

export default Transactions;
