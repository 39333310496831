import React, { useState, useEffect } from 'react';
import Heading from '@hubins/components/Heading';
import { getForm } from 'helpers/form';
import SettingsExpandableFormSummary from '@hubins/components/SettingsExpandableFormSummary';
import SimpleTable from 'modules/Tables/SimpleTable';
import ExpandableTable from '@hubins/components/ExpandableTable';
import ExpandedComponent from './ExpandedComponent';
import AuthDownloadLink from '@hubins/components/AuthDownloadLink/AuthDownloadLink';
import { useCollapse } from 'react-collapsed';
import Box from 'components/Box';
import FileUpload from 'components/FileUpload';
import __ from 'localisation';
import { getMoney } from 'helpers';
import Button from '@hubins/components/Button';
import ErrorMessage from 'components/ErrorMessage';
import middleman from 'helpers/middleman';
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import EditProofOfFund from '@hubins/components/EditProofOfFund';
import { useNotificationContext } from '@hubins/components/NotificationContext/NotificationContext';
import FormModal from '@hubins/components/FormModal';
import { mergeAllFiles, filterFilesToUpload } from 'helpers/createForm';
import { formDataToCollection } from 'helpers/filters';
import AccordeonButton from '@hubins/components/AccordeonButton';
import SofChangeNotes, { SofChangeNotesList } from '@hubins/components/SofChangeNotes';
import { dynamicHeaders } from 'helpers/sof';

const proofOfFundHeaders = [
  {
    Header: __('download'),
    accessor: 'download'
  },
  {
    Header: __('edit'),
    accessor: 'edit'
  },
  {
    Header: __('name'),
    accessor: 'name'
  },
  {
    Header: __('created_at'),
    accessor: 'created_at'
  },
  {
    Header: __('delete'),
    accessor: 'delete'
  },
];

const SofItem = ({
  profile_uuid,
  form,
  uuid,
  source,
  availableAmount,
  usedAmount,
  proofOfFunds,
  type,
  refetch,
  canEdit,
  changeNotes,
}) => {

  const [summary, setSummary] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filesLoading, setFilesLoading] = useState(false);
  const [filesError, setFilesError] = useState(false);
  const [editProofOfFund, setEditProofOfFund] = useState(false);
  const [openChangeNotes, setOpenChangeNotes] = useState(false);
  const [formResponse, setFormResponse] = useState(false);
  const [changeNoteLoading, setChangeNoteLoading] = useState(false);
  const [changeNoteError, setChangeNoteError] = useState(false);
  const [events, setEvents] = useState(source.events.map(event => {
    return {
      ...event,
      className: event.approval_status ? 'c-success' : ''
    };
  }));

  const headers = dynamicHeaders(events[0], true);

  const [ isExpanded, setExpanded ] = useState(false);
  const handleClick = () => {setExpanded(!isExpanded);};
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  const [files, setFiles] = useState([]);

  const { setNotification } = useNotificationContext();

  const fetchInitData = async () => {
    setLoading(true);
    try {
      const formContent = await getForm(form.key, form.type, profile_uuid, null, { version: form.version });
      formContent.response = form.response;
      setSummary(formContent);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchInitData();
  }, []);

  const updateEvent = (eventUuid, updateBody) => {
    // setEvents(events.map((event) => {
    //   if (event.uuid === eventUuid) {
    //     const updatedEvent = {
    //       ...event,
    //       ...updateBody,
    //     };

    //     const className = updatedEvent.approval_status ? 'c-success' : '';

    //     return {
    //       ...updatedEvent,
    //       className,
    //     };
    //   }
    //   return event;
    // }
    // ));
    setNotification({ type: 'success', message: <>Event has been updated</> });
    refetch();
  };

  if (loading) return (<LoadingBox />);

  const data = events;
  const summaryHeaders = [
    {
      Header: '',
      accessor: 'type',
    },
    {
      Header: '',
      accessor: 'value',
    },
  ];

  const amounts = source.amounts;

  const summaryData = [
    {
      type: __('sow'),
      value: getMoney(amounts.source_of_wealth),
    },
    {
      type: __('sof'),
      value: getMoney(amounts.source_of_fund),
    },
    {
      type: __('available_amount'),
      value: getMoney(availableAmount),
    },
    {
      type: __('used_amount'),
      value: getMoney(usedAmount),
    }
  ];

  const uploadFilePromise = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('filename', file.name);
    return middleman.postFormData(`/ql/crm/proof-of-funds/create/${profile_uuid}/${source.uuid}`, formData);
  };

  const upload = async (e) => {
    e.preventDefault();
    const filePromises = files.map((item) => {
      return uploadFilePromise(item);
    });

    try {
      setFilesLoading(true);
      setFilesError(false);
      await Promise.all(filePromises);
      setNotification({ type: 'success', message: <>Files uploaded</> });
      setFilesLoading(false);
      refetch();
    } catch (Err) {
      console.error('File upload error', Err.message);
      setFilesLoading(false);
      setFilesError(true);
    }
  };

  const deleteProofOfFund = async (fileUuid) => {
    try {
      await middleman.promiseDelete(`/ql/crm/source-of-funds/proof-of-funds/${profile_uuid}/${source.uuid}/${fileUuid}`);
      refetch();
    } catch (e) {
      console.error(e.message);
    }
  };

  const proofOfFundsTableData = proofOfFunds ? proofOfFunds.map((file) => {
    return {
      ...file,
      download: (<AuthDownloadLink
        className="transparent button-icon small s-top-sm"
        key={file.uuid}
        url={`/ql/crm/source-of-funds/download/${profile_uuid}/${uuid}/${file.uuid}`}
        filename={file.name}
      />
      ),
      edit: (<Button onClick={() => setEditProofOfFund(file)} className='s-top-sm' size="small" disabled={!canEdit}>{__('edit')}</Button>),
      delete: (<Button onClick={() => deleteProofOfFund(file.uuid)} className='s-top-sm' size="small" disabled={!canEdit}>{__('delete')}</Button>),
    };
  }) : [];

  const submitOriginalForm = async (changeLog) => {
    setChangeNoteLoading(true);
    try {
      const values = formResponse;
      setChangeNoteError(false);
      const formPromise = middleman.promisePost(`/ql/crm/source-of-funds/update/${profile_uuid}/${uuid}`, {
        key: summary.key,
        type: summary.type,
        version: summary.version,
        changeNote: {
          text: changeLog,
        },
        data: formDataToCollection(values),
      });

      const newFiles = filterFilesToUpload(mergeAllFiles(values));
      const filePromises = newFiles.map((item) => {
        return uploadFilePromise(item);
      });
      await Promise.all([...filePromises, formPromise]);
      refetch();
    } catch (e) {
      setChangeNoteError("Something went wrong submitting the form");
    }
    setChangeNoteLoading(false);
  };

  const submitForm = (values) => {
    setOpenChangeNotes(true);
    setFormResponse(values);
  };

  return (
    <>
      <SofChangeNotes
        open={openChangeNotes}
        close={setOpenChangeNotes}
        callback={submitOriginalForm}
        loading={changeNoteLoading}
        error={changeNoteError}
      />
      <Box className="border">
        <AccordeonButton {...getToggleProps({ onClick: handleClick })} isExpanded={isExpanded} className="hover-pointer">
          <Heading element="span" size="5" className="">
            {__(`sof_${type}`)} {source.name ? (<span className="body-small s-left-s c-neutral-5" style={{ fontWeight: "400" }}>{source.name}</span>) : ''}
          </Heading>
        </AccordeonButton>
        <div {...getCollapseProps()}>
          {summary && (
            <>
              {canEdit && (
                <>
                  <div className="s-top-xl" />
                  <FormModal content={summary.content} response={summary.response} buttonText={__("edit_form")} callback={submitForm} />
                </>
              )}
              <SettingsExpandableFormSummary summary={summary} title={__("form_response")} className="s-top-xl" titleSize={5} />
            </>
          )}
          <ExpandableTable
            data={data}
            headers={headers}
            component={(row) => (<ExpandedComponent canEdit={canEdit} row={row} proofOfFunds={proofOfFunds} sourceOfFundUuid={uuid} updateEvent={updateEvent} />)}
          />
          <Heading size="4" className="s-bottom-m s-top-xl">{__('summary')}</Heading>
          <SimpleTable data={summaryData} headers={summaryHeaders} />
          <EditProofOfFund proofOfFund={editProofOfFund} setProofOffund={setEditProofOfFund} refetch={refetch} />
          {(proofOfFunds && proofOfFunds.length > 0) && (
            <>
              <Heading size="4" className="s-bottom-m s-top-xl">{__('pof')}</Heading>
              <SimpleTable data={proofOfFundsTableData} headers={proofOfFundHeaders} />
            </>
          )}
          {canEdit && (
            <>
              <Heading size="4" className="s-top-xl">{__('upload_pof')}</Heading>
              <FileUpload
                controlled
                value={files}
                callback={setFiles}
              />
              <Button loading={filesLoading} disabled={filesLoading} onClick={upload} icon="Upload Bottom by Streamlinehq">{__('upload')}</Button>
            </>
          )}
          <ErrorMessage text={"Something went wrong uploading the files"} showError={!!filesError} />
          <SofChangeNotesList
            changeNotes={changeNotes}
          />
        </div>
      </Box>
    </>
  );
};

export default SofItem;
