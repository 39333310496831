import React from 'react';
import DownloadLink from '@hubins/components/DownloadLink';
import Heading from '@hubins/components/Heading';
import SimpleTable from 'modules/Tables/SimpleTable';
import './index.scss';

const headers = [
  { Header: '', accessor: 'downloadLink', width: 10 },
  { Header: '', accessor: 'fileName' },
];

type Props = {
  documents: any[],
  auth?: boolean,
  title?: string,
  compact?: boolean,
  className?: string,
}

const DocumentsList = ({ documents, auth = true, title, compact, className = '' }: Props) => {

  const renderList = () => {
    if (!documents || documents.length === 0) {
      return [];
    }

    return documents.map((document, index) => {
      return (
        {
          fileName: document.fileName,
          downloadLink: (<DownloadLink
            auth={auth}
            secured={auth}
            key={index}
            onlyIcon={true}
            hideName={true}
            name={document.fileName}
            path={document.fullPath.substring(1)}
            icon="Common File Stack by Streamlinehq"
          />),
        }
      );
    });
  };

  return (
    <div className={compact ? '' : `documents ${className}`}>
      {title &&
        <Heading size="3" className="s-bottom-m s-top-m">
          {title}
        </Heading>
      }
      <SimpleTable
        headers={headers}
        data={renderList()}
      />
    </div>
  );
};

export default DocumentsList;
