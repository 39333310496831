import React from 'react';
import SimpleTable from 'modules/Tables/SimpleTable';
import AuthDownloadLink from '@hubins/components/AuthDownloadLink';
import __ from 'localisation';

const headers = [
  {
    Header: '',
    accessor: 'downloadLink',
    width: 10,
  },
  {
    Header: '',
    accessor: 'name'
  },
]

const FilesTable = ({ downloadUrl, files }) => {

  const theFiles = files || [];

  return (
    <SimpleTable
      headers={headers}
      data={theFiles.map(file => ({
        downloadLink: <AuthDownloadLink
          url={`${downloadUrl}/${file.uuid}`}
          filename={file.name}
          onlyIcon
        />,
        name: file.name,
      }))}
    />
  )
};

export default FilesTable;