import {
  useQuery,
} from 'react-query';

import middleman from 'helpers/middleman';

export const useFmaHistory = (profileUuid, portfolioUuid, shouldRun) => useQuery(['fmaHistory', profileUuid, portfolioUuid], () => {
  return middleman.promiseGet(`/ql/crm/risk-assessment/fma/history/${profileUuid}/${portfolioUuid}`).then(response => response.data);
}, {
  enabled: shouldRun,
});