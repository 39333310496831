import React from 'react';
import { Route } from 'react-router-dom';
import ErrorBoundary from 'components/ErrorBoundary';
import PageFooter  from 'components/PageFooter';

import CrmMenuHeading from 'modules/Menu/CrmMenuHeading';
import __ from 'localisation';
import SkipToContentLink from 'components/SkipToContentLink';
import { Logo } from 'settings';

const LayoutDefault = (props) => {

  return (
    <>
      <SkipToContentLink
        target="MainContent"
        text={__('skip_to_main_content')}
      />
      <ErrorBoundary>
        <CrmMenuHeading
          title={props.title}
          btnType={props.btnType}
          backBtn={props.backBtn}
          logo={Logo}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <Route {...props} />
      </ErrorBoundary>
      <PageFooter />
    </>
  );

};

export default LayoutDefault;
