import React, { useState, useEffect } from 'react';
import { useFilteredTransactions } from 'queries/transactions';
import ApiPaginationTable from 'modules/Tables/ApiPaginationTable';
import { filterTableHeadersWithDelete, loadingTableHeaders } from 'helpers/transactions';
import ChoosePortfolioInput from '@hubins/components/ChoosePortfolioInput';
import ChooseSecuritiesInput from '@hubins/components/ChooseSecuritiesInput';
import {Column, Columns} from 'components/Columns';
import Button, { ButtonWrap } from '@hubins/components/Button';
import Dropdown from '@hubins/components/Dropdown';
import DateInput from '@hubins/components/DateInput';
import { currencyOptions } from 'helpers/currency';
import { statusOptions, typeOptions } from 'helpers/transactions';
import MultipleDropdown from '@hubins/components/MultipleDropdown';
import LoadingBar from '@hubins/components/LoadingIndicators';
import { calculateTransactionTradeAmount } from 'helpers/transactions';
import { getMoney } from 'helpers';
import __ from 'localisation';

type Props = {
  portfolioUuids?: string[];
}

const Transactions = ({ portfolioUuids }: Props) => {

  const [page, setPage] = useState<number>(-1);
  const [amountOfPages, setAmountOfPages] = useState<number>(1);
  const [initiated, setInitiated] = useState<boolean>(false);
  const [selectedPortfolio, setSelectedPortfolio] = useState<any>(undefined);
  const [filterPortfolioUuid, setFilterPortfolioUuid] = useState<any>(undefined);
  const [selectedSecurity, setSelectedSecurity] = useState<any>(undefined);
  const [securityId, setSecurityId] = useState<any>(undefined);
  const [statuses, setStatuses] = useState<any>(undefined);
  const [filterStatus, setFilterStatus] = useState<any>(undefined);
  const [type, setType] = useState<any>(undefined);
  const [filterType, setFilterType] = useState<any>(undefined);
  const [currency, setCurrency] = useState<any>(undefined);
  const [filterCurrency, setFilterCurrency] = useState<any>(undefined);
  const [startDate, setStartDate] = useState<any>(undefined);
  const [endDate, setEndDate] = useState<any>(undefined);
  const [filterStartDate, setFilterStartDate] = useState<any>(undefined);
  const [filterEndDate, setFilterEndDate] = useState<any>(undefined);


  const { data, isLoading, isFetched, refetch } = useFilteredTransactions(initiated, filterStatus, filterType, filterPortfolioUuid, portfolioUuids, securityId, filterCurrency, filterStartDate, filterEndDate, page + 1);

  useEffect(() => {
    if (data) {
      setAmountOfPages(data.last_page);
    }
  }, [data]);

  const search = () => {
    setSecurityId(selectedSecurity?.id || undefined);
    setFilterStatus(statuses && statuses.length > 0 ? statuses : undefined);
    setFilterType(type || undefined);
    setFilterCurrency(currency || undefined);
    setFilterStartDate(startDate || undefined);
    setFilterEndDate(endDate || undefined);
  }

  // const reset = () => {
  //   // setSelectedPortfolio(undefined);
  //   // setSelectedSecurity(undefined);
  //   setStatus(undefined);
  //   setType(undefined);
  //   setCurrency(undefined);
  //   setStartDate(undefined);
  //   setEndDate(undefined);
  //   // setFilterPortfolioUuid(undefined);
  //   // setSecurityId(undefined);
  //   setFilterStatus(undefined);
  //   setFilterType(undefined);
  //   setFilterCurrency(undefined);
  //   setFilterStartDate(undefined);
  //   setFilterEndDate(undefined);
  // }

  const handlePageFetch = (index) => {
    if (!initiated) {
      setInitiated(true);
    }
    setPage(index);
  };

  return (
    <Columns>
      {!portfolioUuids && (
        <Column xl="6">
          <ChoosePortfolioInput callback={setSelectedPortfolio} />
        </Column>
      )}
      <Column xl="6">
        <ChooseSecuritiesInput callback={setSelectedSecurity} />
      </Column>
      <Column l="6">
        <DateInput label={__('start_date')} value={startDate} callback={setStartDate} />
      </Column>
      <Column l="6">
        <DateInput label={__('end_date')} value={endDate} callback={setEndDate} />
      </Column>
      <Column l="6">
        <Dropdown
          label={__('type')}
          options={typeOptions}
          value={type}
          callback={setType}
          controlled={true}
        />
      </Column>
      <Column l="6">
        <MultipleDropdown
          label={__('status')}
          options={statusOptions}
          values={statuses}
          callback={setStatuses}
        />
      </Column>
      <Column l="6">
        <Dropdown
          label={__('currency')}
          options={currencyOptions}
          value={currency}
          callback={setCurrency}
          controlled={true}
        />
      </Column>
      <Column s="12" className='flex flex--row space-between align-end'>
        <ButtonWrap>
          <Button onClick={search}>
            Search
          </Button>
        </ButtonWrap>
        <p>
          <strong>{__('total')}:</strong> {isLoading || !isFetched || !data?.data ? <LoadingBar medium /> : getMoney(data?.data.reduce((acc, curr) => {
            return acc + calculateTransactionTradeAmount(curr);
          }, 0), 'SEK')}
        </p>
      </Column>
      <Column s="12">
      <ApiPaginationTable
          data={isLoading || !isFetched ? loadingTableHeaders : data?.data}
          headers={filterTableHeadersWithDelete(refetch)}
          pageCount={amountOfPages}
          index={page}
          fetchData={handlePageFetch}
      />
      </Column>
    </Columns>
  )
};

export default Transactions;