

const availableCurrencies = [
  'SEK',
  'NOK',
  'USD',
  'EUR',
];

export const currencyOptions = [
  {
    id: '',
    title: '',
  },
  ...availableCurrencies.map((currency) => ({
    id: currency,
    title: currency,
}))];