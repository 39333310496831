import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Menu from 'modules/Menu/Menu';
import MenuTitle from 'modules/Menu/MenuTitle';
import { __, __route } from 'localisation';
import Button from '@hubins/components/Button';
import Flag from 'components/Flag';
// import { connect } from 'react-redux';
// import { authOperations } from 'state/ducks/auth';
import { setItem } from 'helpers';
import { convertRoute } from "localisation/localisationFunctions";

import { uiContext } from 'components/UiContext';

import './MenuHeading.scss';
import './MenuCancel.scss';
import './MenuSignup.scss';
import './MenuCRM.scss';

const LANGUAGES = [
  {
    flag: "SE",
    title: __('lang_SE')
  },
  {
    flag: "EN",
    title: __('lang_EN')
  },
];

const MenuHeading = ({ title = '', logo, btnType, backBtn, hideSignup, clearUser, ...restProps }) => {
  const { login } = useContext(uiContext);

  const changeLanguage = (language, e) => {
    e.preventDefault();
    const convertedRoute = convertRoute(language);
    history.replaceState({}, "Quantum leben", convertedRoute);
    setItem("language", language);
    location.reload();
  };

  const renderLanguages = () => {
    return LANGUAGES.map((item, index) => (
      <Button key={index}
        type="button"
        onClick={(e) => changeLanguage(item.flag, e)}
        className="flag" onlyIcon
      >
        <Flag flag={item.flag} label={__(item.title)} />
      </Button>
    ));
  };

  return (
    <Menu className="menu--heading" homeLink={true} logo={logo} {...restProps}>
      {title && <MenuTitle
        className={`
        ${btnType === 'signup' ? 'with_signup_btn' : ''}
      `}
        title={title}
      />}
      <div className="menu__crm">
        {renderLanguages()}
        {btnType === 'cancel' && (
          <div className="menu__cancel">
            {/* Should be a <Link>, but is some bugg with the slider form
        that it is keeping its state, so needs a hard refresh */}
            <Link to={backBtn || '/'} className="link">{__('cancel')}</Link>
          </div>
        )}
        {btnType === 'signup' && (
          <div className="menu__signup">
            <Button className="small link" onClick={() => {
              clearUser && clearUser();
              login();
            }}>
              {__('login')}
            </Button>
            {/* temp hidden since jool cant accept new customers */}
            {!hideSignup &&
            <Button className="small cta" id="signup-2" href={__route('signup')}>
              {__('create_account')}
            </Button>
            }

          </div>
        )}
        {btnType === 'logout' && (
          <div className="menu__cancel">
            <Link to={__route('logout')} className="link">{__('logout')}</Link>
          </div>
        )}
      </div>
    </Menu>
  );
};

// const mapDispatchToProps = {
//   switchLanguage: authOperations.switchLanguage
// };

export default MenuHeading;

// export default connect(null, mapDispatchToProps)(MenuHeading);
