import React, { useState } from 'react';
import ApiPaginationTable from 'modules/Tables/ApiPaginationTable';
import Button from '@hubins/components/Button';
import SearchInput from 'components/SearchInput';
import usePortfolioSearch from 'hooks/usePortfolioSearch';
import { formatMoney } from 'helpers';
import __ from 'localisation';

type Props = {
  callback: (portfolio: any) => void;
}

const ChoosePortfolio = ({ callback }: Props) => {
  const [search, setSearch] = useState('');
  const [selectedPage, setSelectedPage] = useState(1);
  const { portfolios, amountOfPages, loading } = usePortfolioSearch(search);

  const headers = [
    {
      Header: '',
      accessor: 'toolsBeginning',
      className: 'rt-cell-button',
      Cell: ({ row }) => <Button icon="arrow right 1" onClick={() => callback(row.original)}>{__('select')}</Button>
    },
    {
      Header: __('portfolio'),
      accessor: 'short_name'
    },
    {
      Header: __('policy_holder'),
      accessor: 'policy_holder.name',
      Cell: ({ row }) => row.original.policy_holder.map((profile) => profile.name).join(', ')
    },
    {
      Header: __('account_balance'),
      accessor: 'available_balance',
      Cell: ({ row }) => formatMoney(row.original.available_balance, row.original.currency)
    },
    {
      Header: __('market_value'),
      accessor: 'market_value',
      Cell: ({ row }) => formatMoney(row.original.market_value, row.original.currency)
    },
    {
      Header: __('updated_at'),
      accessor: 'updated_at'
    }
  ]

  const handlePageFetch = (index) => {
    setSelectedPage(index);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <div className="flex flex--row flex-start align-items-center">
        <SearchInput onChange={handleSearchChange} value={search} placeholder="Search client name, policy or SSN/Org-nr" loading={loading} />
      </div>
      <ApiPaginationTable
        data={portfolios}
        headers={headers}
        pageCount={amountOfPages}
        index={selectedPage}
        fetchData={handlePageFetch}
      />
    </>
  )
};

export default ChoosePortfolio;