import React, { useState, useEffect } from 'react';
import Modal from '@hubins/components/Modal';
import Button, { ButtonWrap } from '@hubins/components/Button';
import Input from '@hubins/components/Input';
import Heading from '@hubins/components/Heading';
import SourceProgress from 'components/SourceProgress';
import SourceCards from 'components/SourceCards';
import { Columns, Column } from 'components/Columns';
import middleman from 'helpers/middleman';
import ErrorMessage from 'components/ErrorMessage';
import InputCurrency from '@hubins/components/Input/Currency';
import __ from 'localisation';

const ApprovalEditTransactionSources = ({transaction, sourceOfFunds, usedSources, isHighRisk, reset}) => {

  const [open, setOpen] = useState(false);
  const [amountConfirmed, setAmountConfirmed] = useState(false);
  const [amount, setAmount] = useState(transaction.amount / 100);
  const [sources, setSources] = useState<any>([]);
  const [sourceAmounts, setSourceAmounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (sourceOfFunds && usedSources && open) {
      const updatedSources = sourceOfFunds.map((source) => {
        const used = usedSources.find((usedSource) => usedSource.uuid === source.uuid);
        return {
          ...source,
          availableAmount: used ? source.availableAmount + used.transactionAmount : source.availableAmount,
          highRiskAvailableAmount: used ? source.highRiskAvailableAmount + used.transactionAmount : source.highRiskAvailableAmount,
          usedAmount: used ? source.usedAmount - used.transactionAmount : source.usedAmount,
        }
      });
      const currentSourceAmounts = updatedSources.map(() => 0);
      setSources(updatedSources);
      setSourceAmounts(currentSourceAmounts);
    }
  }, [sourceOfFunds, usedSources, open])

  const closeModal = () => {
    setOpen(false);
    setAmountConfirmed(false);
    setAmount(transaction.amount / 100);
  }

  const submit = async () => {
    setLoading(true);
    setError('');
    try {
      const newUsedSources = sources.map((source, index) => {
        return {
          'uuid': source.uuid,
          'amount': sourceAmounts[index]
        };
      }).filter(source => {
        return source.amount > 0;
      });

      const newUsedAmount = newUsedSources.reduce((sum, item) => {
        sum += Number(item.amount);
        return sum;
      }, 0);

      if (newUsedAmount !== amount) {
        setError('The total amount of the sources does not match the transaction amount');
        setLoading(false);
        return;
      }

      await middleman.post(`/ql/crm/transaction/amount/${transaction.uuid}`, {
        amount: amount,
        sources: newUsedSources
      })

      setLoading(false);
      reset();
    } catch (e) {
      setError('Something went wrong');
      setLoading(false);
    }
  }

  const handleInputChange = (val) => {
    console.log('val', val);
    setAmount(val ? Number(val) : 0);
  }

  const totalSelectedSum = sourceAmounts.reduce((current, value) => {
    current += value;
    return current;
  }, 0);

  return (
    <>
      <Button onClick={() => setOpen(true)}>{__('edit_amount')}</Button>
      <Modal
        open={open}
        callback={closeModal}
        closeButton
        >
          {!amountConfirmed ? (
            <>
              <Heading size="3" className="s-bottom-l s-top-l">Amount of the transaction</Heading>
              <InputCurrency
                className='input-max-width'
                value={amount}
                label="Amount"
                callback={handleInputChange}
                suffix="SEK"
              />
              <ButtonWrap>
                <Button className='s-top-l' size="large" icon="remove" onClick={closeModal}>Cancel</Button>
                <Button className='cta s-top-l' size="large" iconColor="white" icon="arrow right 1" onClick={() => setAmountConfirmed(true)}>Confirm</Button>
              </ButtonWrap>
            </>
          ) : (
            <Columns>
              <Column s="12">
                <Heading size="4" className="s-bottom-xl">Choose what source of funds that should be used for this transaction</Heading>
              </Column>
              <Column s="12">
                <SourceProgress
                  totalSelectedSum={totalSelectedSum}
                  totalAmount={amount}
                  sourceAmounts={sourceAmounts}
                  sources={sources}
                />
              </Column>
              <Column s="12">
                <SourceCards
                  totalAmount={amount}
                  sourceAmounts={sourceAmounts}
                  sources={sources}
                  setSources={setSources}
                  setSourceAmounts={setSourceAmounts}
                  premium={true}
                  portfolioUuid={transaction.portfolio_uuid}
                  totalSelectedSum={totalSelectedSum}
                  isHighRisk={isHighRisk}
                  canSetAmounts={true}
                />
              </Column>
              <Column s="12">
                <ButtonWrap>
                  <Button
                    icon="remove"
                    size="large"
                    onClick={closeModal}
                    loading={loading}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                  <Button
                    icon="arrow right 1"
                    iconColor='white'
                    size="large"
                    className='cta'
                    onClick={submit}
                    loading={loading}
                    disabled={loading}
                  >
                      Save
                  </Button>
                </ButtonWrap>
                <ErrorMessage text={error} showError={!!error} />
              </Column>
            </Columns>
          )}
        </Modal>
    </>
  )
};

export default ApprovalEditTransactionSources;