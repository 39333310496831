import React, { useState, useEffect } from 'react';
import __ from 'localisation';
import Modal from '@hubins/components/Modal';
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import Button, { ButtonWrap } from '@hubins/components/Button';
import Heading from '@hubins/components/Heading';
import middleman from 'helpers/middleman';

import { getMoney } from 'helpers';

const SourceConfirmation = ({ body, saveResponse, edit, loading, setError, type }) => {

  const [result, setResult] = useState(false);

  const init = async () => {
    try {
      const amount = await middleman
        .promisePost('/source-of-funds/amount', body)
        .then(res => res.data);
      setResult(amount);
    } catch(e) {
      console.error('Error:', e);
      setError(__('common_error_validation'));
      edit();
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Modal open>
      {result === false ? (
        <LoadingBox />
      ) : (
        <>
          <Heading size="4">
            {__(`sof_${type}`)}
          </Heading>

          <p>Enligt dina svar uppgår din källa <strong>{__(`sof_${type}`)}</strong> till följande belopp:</p>
          <ul className='s-bottom-md'>
              <li className='s-top-md'><strong>Källa till kapital</strong>: {getMoney(result.source_of_fund)}<br />
                <span className='c-neutral-6'>Detta är likvida medel som har realiserats och utgör belopp som kan användas för insättningar. OBS! Försäkringsbolaget kan kräva att ursprunget bevisas innan de kan användas för insättningar.</span>
            </li>
            <li className='s-top-md'><strong>Källa till förmögenhet</strong>: {getMoney(result.source_of_wealth)}<br />
                <span className='c-neutral-6'>Detta belopp utgör din förmögenhet och inkluderar både realiserade och orealiserade tillgångar. Detta belopp kan inte alltid användas för insättningar.</span>
            </li>
          </ul>
          <ButtonWrap>
            <Button
              className="cta"
              onClick={saveResponse}
              loading={loading}
              disabled={loading}
            >
              Spara källa
            </Button>
            <Button
              className="secondary"
              onClick={edit}
              loading={loading}
              disabled={loading}
            >
              Ändra
            </Button>
          </ButtonWrap>
        </>
      )}
    </Modal>
  );
};

export default SourceConfirmation;
