import { useEffect, useState } from 'react';

function useTabs(callback, parentSelected, tabs = [], defaultIndex = 0) {

  const [selected, setSelected] = useState(defaultIndex);

  const setIndex = (newIndex) => {
    setSelected(newIndex);
    callback(newIndex);
  };

  const tabIdToHash = tabs.reduce((collection, tab, index) => {
    collection[index] = tab;
    return collection;
  }, {});

  const indexes = tabs.reduce((collection, tab, index) => {
    collection['#' + tab] = index;
    return collection;
  }, {});

  const getIndexFromHash = (target = window.location.hash) => {
    return typeof indexes[target] === 'undefined' ? defaultIndex : indexes[target];
  };

  useEffect(() => {
    setIndex(getIndexFromHash());
  }, []);

  useEffect(() => {
    if (indexes[location.hash] !== selected) {
      const newIndex = typeof indexes[location.hash] === 'undefined' ? defaultIndex : indexes[location.hash];
      setIndex(newIndex);
    }
  }, [location.hash]);

  useEffect(() => {
    if (parentSelected !== selected) {
      window.location.hash = tabIdToHash[parentSelected];
      setSelected(parentSelected);
    }
  }, [parentSelected]);

}

export default useTabs;
