import React from 'react';
import Heading from '@hubins/components/Heading';
import __ from 'localisation';

const ChangeNote = ({ text, user_name, event, created_at }) => {
  return (
    <div className='box neutral-2 s-bottom-lg maxwidth'>
      <p className='body-small'>{event} <br /> {user_name} - {created_at} </p>
      <p className=''>{text}</p>
    </div>
  )
}

export const SofChangeNotesList = ({ changeNotes }) => {

  return (
    <>
      {(changeNotes && changeNotes.length > 0) && (
        <>
          <Heading size="4" className="s-top-xl">
            {__('change_notes')}
          </Heading>
          {changeNotes.map((note, index) => (
            <ChangeNote key={index} {...note} />
          ))}
        </>
      )}
    </>
  )

};

export default SofChangeNotesList;