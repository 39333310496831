import React, { useState, useEffect } from 'react';
import middleman from 'helpers/middleman';
import SimpleTable from 'modules/Tables/SimpleTable';
import Checkbox from 'components/Checkbox';
import { formatNumberByThousands } from 'helpers';
import __ from 'localisation';

const ApprovalChooseSubAccount = ({ selectedAccount, setSelectedAccount, canEdit, portfolioUuid }) => {

  const [subAccounts, setSubAccounts] = useState([]);

  const fetchSubAccounts = async () => {
    const response = await middleman.get(`/ql/crm/portfolio/subaccounts/${portfolioUuid}`);
    setSubAccounts(response.data);
  }

  useEffect(() => {
    fetchSubAccounts();
  }, []);

  const headers = [
    {
      Header: '',
      accessor: 'checkbox',
      Cell: ({ row }) => (
        <Checkbox
          optionsState={{
            error: false,
            value: selectedAccount === row.original.uuid,
          }}
          disabled={!canEdit}
          callback={() => setSelectedAccount(selectedAccount === row.original.uuid ? false : row.original.uuid)}
        />
      )
    },
    {
      Header: __('insurance_number'),
      accessor: 'short_name',
    },
    {
      Header: __('currency'),
      accessor: 'currency',
    },
    {
      Header: __('market_value'),
      accessor: 'market_value',
      Cell: ({ value }) => value ? formatNumberByThousands(value) : value,
    },
  ]

  return (<SimpleTable
    headers={headers}
    data={subAccounts}
  />)
};

export default ApprovalChooseSubAccount;