import React, { useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

type Props = {
  portfolioUuid?: string;
  profileUuid?: string | undefined;
}

const CreateEntry = ({ portfolioUuid, profileUuid: propsProfileUuid }: Props) => {

  const [selectedTransaction, setSelectedTransaction] = useState<any>(null);
  const [createNew, setCreateNew] = useState<boolean>(false);
  const [chooseSources, setChooseSources] = useState<boolean>(false);
  const [profileUuid, setProfileUuid] = useState<string | undefined>(propsProfileUuid);

  const reset = () => {
    setSelectedTransaction(null);
    setCreateNew(false);
    setChooseSources(false);
  }

  if (chooseSources) {
    return <Step3
      selectedTransaction={selectedTransaction}
      setChooseSource={setChooseSources}
      profileUuid={profileUuid}
      reset={reset}
    />;
  }

  if (createNew || selectedTransaction) {
    return <Step2
      selectedTransaction={selectedTransaction}
      setSelectedTransaction={setSelectedTransaction}
      setCreateNew={setCreateNew}
      setChooseSources={setChooseSources}
      portfolioUuid={portfolioUuid}
      setProfileUuid={setProfileUuid}
    />;
  }
  return <Step1
    portfolioUuid={portfolioUuid}
    setSelectedTransaction={setSelectedTransaction}
    setCreateNew={setCreateNew}
  />;
};

export default CreateEntry;