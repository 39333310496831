import React from 'react';
import './index.scss';

const BasicFixedFooter = ({ children, className }) => (
  <div className={`basic-fixed-footer ${className}`}>
    {children}
  </div>
);

export default BasicFixedFooter;
