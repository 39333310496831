import React, { useState, useRef } from 'react'
import Dropdown from '../Dropdown';
import Button from '../Button';

type Props = {
  options: any,
  callback: Function,
  values: string[],
  id?: string,
  label?: string,
  placeholder?: string,
  disabled?: boolean,
}

const MultipleDropdown = ({ options, callback, values = [], id, label, placeholder = 'Välj', disabled = false }: Props) => {
  const [selected, setSelected,] = useState(values);

  const handleChange = (value: string) => {
    const newSelected = [...selected, value];
    setSelected(newSelected);
    if (callback) callback(newSelected);
  }

  const remove = (index: number) => {
    return () => {
      const newSelected = [...selected];
      newSelected.splice(index, 1);
      setSelected(newSelected);
      if (callback) callback(newSelected);
    }
  }

  const notSelectedOptions = options.filter((item) => (
    !selected.includes(item.id)
  ));

  const indexedOptions = options.reduce((acc: any, item: any) => {
    acc[item.id] = item.title;
    return acc;
  }
    , {});

  return (
    <label>
      <div className="label">{label}:</div>
      {notSelectedOptions.length > 0 && (
        <Dropdown
          placeholder={placeholder}
          callback={handleChange}
          id={id}
          options={notSelectedOptions}
          disabled={disabled}
        />
      )}
      <ul className='s-bottom-l s-top-m'>
        {selected.map((item, index) => (
          <li className='s-bottom-s' key={`${item}_${index}_${id}`}>{indexedOptions[item] || 'Not found'} {!disabled && (<Button icon="remove" size='small' className='s-left-l' onClick={remove(index)}>Ta bort</Button>)}</li>
        ))}
      </ul>
    </label>
  )
}

export default MultipleDropdown;