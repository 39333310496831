import React from 'react';
import { __ } from 'localisation';
import { useTradeOrders } from 'queries/tradeOrders';
import Heading from '@hubins/components/Heading';
import { Columns, Column } from 'components/Columns';
import EventCards from 'modules/EventCards';
import OverviewPremiumTransactions from '@hubins/components/OverviewPremiumTransactions';
import OverviewTofTransactions from '@hubins/components/OverviewTofTransactions';
import { getMoney } from 'helpers';
import './index.scss';

// changeTab, uid, portfolio, client, backoffice

const Overview = ({ portfolio, portfolioUuid }) => {

  const { data } = useTradeOrders(portfolioUuid);


  return (
    <Columns>
      <Column s="12">
        <Heading size="4" className="s-top-xl">
          Totalt värde
        </Heading>
        <Heading size="2" className="s-bottom-xl">
          {getMoney(portfolio?.market_value)}
        </Heading>
      </Column>
      <Column s="12" m="6">
        <OverviewTofTransactions portfolioUuid={portfolioUuid} />
        <OverviewPremiumTransactions portfolioUuid={portfolioUuid} />
        <Heading size="4" className="s-bottom-m">
          {__('current_events')}
        </Heading>
        <EventCards
          data={data}
          showemptystate
        />
      </Column>
    </Columns>
  );
};

export default Overview;
