// Unite Linked insureance vs Direct Investment
import React from 'react';
import LoadingAnimation from "@hubins/components/HubinsLoadingAnimation";
import { formatMoney } from 'helpers';
import { __plural } from 'localisation';
import { getValuesPerMonth } from "@hubins/calculations";
import DynamicLoadPage from '@hubins/components/DynamicLoadPage';

// export const INSURANCE_VARIABLE_FEE = 0.01; // TODO fetch from CRM
// export const INSURANCE_FIXED_FEE = 2100; // 1800, varav 300 är transatktionsavgift TODO fetch from CRM - Also different if the user wants a fixedFee or not
// export const YIELD_TAXATION = 0.3;

// staticValues
// export const STATIC_VALUES = {
//   INSURANCE_VARIABLE_FEE: 0.01,
//   INSURANCE_FIXED_FEE: 1800, // 1800, varav 300 är transatktionsavgift
//   TRANSACTION_FEE: 300,
//   YIELD_TAXATION: 0.3
// };

/**
 *
 * @param {number} amount - Planned size of policy during the next 12 month
 * @param {number} months - Then time period in months
 * @param {number} yearlyReturn - How much you will excpect to get in return each year
 * @param {object} staticValues - Fees and other static values
 * @param {function} setNoNeed - UseState function that takes a bool that triggers a noNeed modal
 * @returns {number} -
 */
export const generateData = (amount = 1000000, months = 12, yearlyReturn = 12, staticValues, setNoNeed) => {

  const data = getValuesPerMonth({
    initialValue: amount, // Size of investment, or initial planned investment
    numberOfMonths: months, //he number of months that you want to invest
    expectedReturn: yearlyReturn, //he return in percent the user expect to get each year
    riskPremium: staticValues && staticValues.RISK_PREMIUM || 0, //f the user is +65 years, add a risk premium
    fixedFee: staticValues ? (staticValues.INSURANCE_FIXED_FEE || 0) + (staticValues.TRANSACTION_FEE || 0) : 0, // fixed fee for the entire portofolio
    directInvestmentTax: staticValues.DIRECT_INVESTMENT_TAX,
    transactionFee: 0,
    variableFee: staticValues && staticValues.INSURANCE_VARIABLE_FEE || 0.01, // variable fee for the entire portofolio
  });

  let graphArray = [];

  const showYear = months > 25;
  // 12 24 36
  // If up to 24 months, samling rate should be the number of months,
  // if up to 10 years, it should be every quarter
  // else it should be on per year
  const samplingRate = months <= 24 ? 1 : 12; // Always have 12 samples

  for (let i = 0; i < data.length; i++) {
    // only calculate 12 samples in total, regardles of the number of months
    if (i % samplingRate !== 0) continue;
    // const x = expectedReturnBeforeFees(amount, i, montlyRate);
    const y = data[i].returnAfterFees;
    const z = data[i].directInvestmentReturn;

    if ((z > y) && setNoNeed) setNoNeed(true);

    const nameValue = showYear ? Math.round(i / 12 * 100) / 100 : i;

    graphArray.push({
      name: showYear
        ? __plural('year_string', nameValue, { number: nameValue })
        : __plural('month_string_short', nameValue, { number: nameValue }),
      // x,
      y, // Nettoavkastning Kapitalförsäkring
      z, // Nettoavkastning Direktinvestering
    });
  }

  return graphArray;
};

// const exampleData = [
//   { name: '0 mån', x: 1009488.79, y: 1008185.05, z: 1006642.16 },
//   { name: '1 mån', x: 1009488.79, y: 1008185.05, z: 1006642.16 },
//   { name: '2 mån', x: 1019067.62, y: 1016443.59, z: 1013347.34 },
//   { name: '12 mån', x: 1120000.00, y: 1103213.06, z: 1084000.00 },
// ];

export const CustomTooltip = (props) => {
  if (!props.label || !props.payload[0]) return null;
  let payload = props.payload;
  if (props.barChart) payload = payload.reverse();
  return (
    <div className="chart-line__tooltip">
      <p className="chart-line__tooltiplabel body-tiny">{props.label}</p>
      {payload.map((tooltip, index) => {
        if (props.barChart && tooltip.value === 0) return;
        return (
          //<strong className="c-white body-tiny">{tooltip.name}</strong><br/>
          <span key={index} className="chart-line__tooltipvalue" style={{
            background: props.barChart ? tooltip.stroke : tooltip.color,
            color: props.textColors ? props.textColors[index] : '#fff'
          }}>{formatMoney(tooltip.value)}</span>
        );
      })}
    </div>
  );
};

// Usage: <CustomLegend name="NettoAvkastning direktinvestering" value={formatMoney(103869.44)} />
export const CustomLegend = ({ name, value, ...svg }) => (
  <div>

    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg"
      // style={{ display: 'block', outline: "1px solid " + Colors.primary }}
      style={{ display: 'block' }}
    >
      <line y1="2" x2="14" y2="2" strokeWidth="4" {...svg} />
    </svg>

    <strong className="body-tiny s-top-sm">{name}</strong><br />
    <span className="clarification body-small">{value}</span>
  </div>
);

const StackedBarChartLoaded = DynamicLoadPage({
  loader: () => import('./Chart'),
  loadingComponent: LoadingAnimation,
});

const StackedBarChart = (props) => {
  return <StackedBarChartLoaded {...props} />;
};

export default StackedBarChart;
