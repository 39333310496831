import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import LoggedinMenuQLCrm from 'modules/Menu/LoggedinMenuQLCrm';
import ErrorBoundary from 'components/ErrorBoundary';
import __ from 'localisation';
import SkipToContentLink from 'components/SkipToContentLink';
import { Logo } from 'settings';
import Main, { MainTitle } from 'components/Main';
import Wrapper from '@hubins/components/Wrapper';
import PageFooter from 'components/PageFooter';

import './LayoutDefault.scss';

const LayoutDefault = (props) => {
  return (
    <>
      <SkipToContentLink
        target="MainContent"
        text={__('skip_to_main_content')}
      />
      <ErrorBoundary>
        <LoggedinMenuQLCrm path={props.path} logo={Logo} />
      </ErrorBoundary>
      <ErrorBoundary>
        <Main noSpace className="relative">
          <Wrapper rounded stretch tight className="backoffice-wrapper">
            {props.title && (
              <MainTitle className="c-primary" title={props.title} />
            )}
            <Route {...props} />
          </Wrapper>
        </Main>
      </ErrorBoundary>
      <PageFooter />
    </>
  );

};

function mapStateToProps(state) {
  return {
    name: state.auth.User.name,
    userRoles: state.auth.User.userRoles,
    contact_type: state.auth.User.roles && state.auth.User.roles[0].name,
  };
}

export default connect(
  mapStateToProps,
)(LayoutDefault);
