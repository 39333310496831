import React, { useEffect, useState } from 'react';
import Modal from '@hubins/components/Modal';
import Heading from '@hubins/components/Heading';
import Input from '@hubins/components/Input';
import middleman from 'helpers/middleman';
import Button, { ButtonWrap } from '@hubins/components/Button';
import ErrorMessage from 'components/ErrorMessage';

type Props = {
  proofOfFund?: any;
  setProofOffund: any;
  refetch?: any;
};

const EditProofOfFund = ({proofOfFund, setProofOffund, refetch}: Props) => {

  const [value, setValue] = useState<string | number | undefined>('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (proofOfFund) {
      setValue(proofOfFund.name);
    }
  }, [proofOfFund]);

  const submit = async () => {
    setLoading(true);
    setError('');
    try {
      await middleman.promisePost(`/ql/crm/proof-of-funds/update/${proofOfFund.uuid}`, {
        name: value
      });
      setProofOffund(false);
      if (refetch) {
        refetch();
      }
    } catch(e) {
      setError(e.message);
    }
    setLoading(false);
  }

  return (
    <Modal open={!!proofOfFund}>
      <Heading size="3">Edit proof of fund</Heading>
      <Input
        controlled
        value={value}
        callback={setValue}
        label="Filename"
      />
      <ButtonWrap>
        <Button
          className='cta'
          onClick={submit}
          loading={loading}
          disabled={!value || loading}
          >
            Save
        </Button>
        <Button
          onClick={() => setProofOffund(false)}
          disabled={loading}
          loading={loading}
        >
          Cancel
        </Button>
      </ButtonWrap>
      <ErrorMessage text={error} showError={!!error} />
    </Modal>
  )

};

export default EditProofOfFund;