import React, { useState, useEffect } from 'react';
import SourceCards from 'components/SourceCards';
import middleman from 'helpers/middleman';
import { useNotificationContext } from '@hubins/components/NotificationContext/NotificationContext';
import SourceOfFundsSummary from '@hubins/components/SourceOfFundsSummary';
import Icon from '@hubins/components/IconNew';
import __ from 'localisation';

const SourceOfFunds = ({ isCompany, profileUuid, portfolioUuid }) => {
  const [sources, setSources] = useState([]);
  const [error, setError] = useState(false);
  const [isHighRisk, setIsHighRisk] = useState(false);
  const { setNotification } = useNotificationContext();

  const fetchSources = async () => {
    try {
      setError(false);
      const response = await middleman.promiseGet(`/ql/crm/source-of-funds/${profileUuid}`);
      const riskRes = await middleman.get(`/risk-assessment/${portfolioUuid}`);
      setIsHighRisk(riskRes.data && riskRes.data > 2);
      setSources(response.data);
    } catch(e) {
      setError(e.message);
    }
  };

  useEffect(() => {
    if (error) {
      setNotification({ type: 'error', message: <div className="center"><Icon icon="Sign Warning" size={24} />Something went wrong when fetching Source of funds: {error}</div> });
    }
  }, [error]);

  useEffect(() => {
    fetchSources();
  }, []);

  return (
    <>
      <SourceOfFundsSummary
        sources={sources}
        isHighRisk={isHighRisk}
      />
      <p>{__('manage_sof_description')}</p>
      <div className='s-bottom-xl' />
      <SourceCards
        sources={sources}
        setSources={setSources}
        isCompany={isCompany}
        profileUuid={profileUuid}
        backoffice
        premium={true}
        showAvailableAmount={true}
        isHighRisk={isHighRisk}
      />
    </>
  );

};

export default SourceOfFunds;
