export const generateData = (totalInsValue, totalDirValue, amount, targetReturn, staticValues) => {


  const netRetDir = totalDirValue - amount;
  const netRetIns = totalInsValue - amount;
  const targetReturnSek = (amount * (targetReturn / 100));
  const { GOVERNMENT_BORROWING_RATE, YIELD_TAXATION } = staticValues;
  const taxIns = amount * (GOVERNMENT_BORROWING_RATE + 0.01) * YIELD_TAXATION;


  const dataArray = [
    {
      name: 'Direktinvestering',
      netRetDir: netRetDir,
      taxDirInv: (amount * (targetReturn / 100)) - netRetDir,
      taxIns: 0,
      netRetIns: 0,
      feeIns: 0,
    },
    {
      name: 'Kapitalförsäkring',
      netRetDir: 0,
      taxDirInv: 0,
      taxIns: taxIns,
      netRetIns: netRetIns,
      feeIns: targetReturnSek - netRetIns - taxIns,
    },
  ];

  return dataArray;
};
