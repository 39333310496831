import React from 'react';
import SimpleTable from 'modules/Tables/SimpleTable';
import matchSorter from 'match-sorter';
import ErrorBoundary from 'components/ErrorBoundary';
import { __ } from "localisation";
import Button from '@hubins/components/Button';
import Icon from '@hubins/components/IconNew';
import LoadingPage from 'views/LoadingPage';
import { getLanguage } from 'localisation/localisationFunctions';
import { useAllSecurities } from 'queries/securities';

import './index.scss';

const TabSecurities = () => {

  const { data: securities, isLoading: securitiesLoading } = useAllSecurities();

  const filterMethod = (key) => {
    return (
      {
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: [key]}),
        filterAll: true
      }
    );
  };

  const headers = [
    {
      // Header: `${__('edit')} / ${__('create')}`,
      Header: '',
      accessor: 'tools',
      filterable: false,
      maxWidth: 50,
      className: 'rt-cell-button',
    },
    {
      Header: __('name'),
      accessor: 'name',
      ...filterMethod('name'),
    },
    {
      Header: __('isin'),
      accessor: 'isinCode',
      ...filterMethod('isinCode'),
    },
    {
      Header: __('security_code'),
      accessor: 'securityCode',
      ...filterMethod('securityCode'),
    },
    {
      Header: __('id'),
      accessor: 'id',
      ...filterMethod('id'),
    },
    {
      Header: __('status'),
      accessor: 'tagList',
      Cell: ({ row }) => (
        __(row.original.tagList[0])
      ),
      filterMethod: (filter, row) => {
        if (filter.value === 'all') {
          return true;
        }
        return row[filter.id].includes(filter.value);
      },
      // eslint-disable-next-line react/display-name
      Filter: ({ filter, onChange }) =>
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">{__('all')}</option>
          <option value={'Issue - On Sale'}>{__('primary_market')}</option>
          <option value={'Issue - Secondary market'}>{__('issue - secondary market')}</option>
          <option value={'Issue - Marketing'}>{__('upcoming_investments')}</option>
          <option value={'Issue - Closed'}>{__('draft')}</option>
        </select>
    }
  ];


  if (securitiesLoading) {
    return (
      <LoadingPage />
    );
  }

  return (
    <ErrorBoundary>
      <SimpleTable
        data={securities.map(security => {
          security.tools =
            <Button
              href={`/dashboard/cms/${security.id}/${getLanguage()}`}
              onlyIcon
              className="fullwidth fullheight"
            >
              <Icon icon="pencil write 2" label="Edit" />
            </Button>;
          return (security);
        })}
        headers={headers}
        filterable
      />
    </ErrorBoundary>
  );
};

export default TabSecurities;
