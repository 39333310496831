import React, { useState } from 'react';
import Modal from '@hubins/components/Modal';
import Button, { ButtonWrap } from '@hubins/components/Button';
import middleman from 'helpers/middleman';
import __ from 'localisation';
import Input from '@hubins/components/Input';

type Props = {
  portfolioUuid: string;
  refresh: () => void;
}

const ManualUpdateRisk = ({ portfolioUuid, refresh }: Props) => {
  const [open, setOpen] = useState(false);
  const [risk, setRisk] = useState<any>(0);
  const [loading, setLoading] = useState(false);

  const handleManualUpdate = async () => {
    setLoading(true);
    try {
      await middleman.promisePost(`/ql/crm/risk-assessment/risk/manual-update/${portfolioUuid}`, { value: Number(risk) });
      refresh();
      setOpen(false);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        icon="add"
        className='s-bottom-xl'
      >
        {__('manual_update_risk')}
      </Button>
      <Modal
        open={open}
        callback={setOpen}
        closeButton={true}
      >
        <div className='input-max-width'>
        <Input
          label={__('risk_score')}
          value={risk}
          callback={setRisk}
        />
        </div>
        <ButtonWrap>
          <Button
            onClick={handleManualUpdate}
            loading={loading}
          >
            {__('update')}
          </Button>
        </ButtonWrap>
      </Modal>
    </>
  );
};

export default ManualUpdateRisk;