import React, { useState } from 'react';
import BasicInfo from './BasicInfo';
import CompanyBasicInfo from './CompanyBasicInfo';
import DemandAssessment from './DemandAssessment';
import InsuranceKYC from './InsuranceKYC';
import Knowledge from './Knowledge';
import Purpose from './Purpose';
import Transactions from './Transactions';
import Products from './Products';
import TargetReturn from './TargetReturn';
import Tax from './Tax';
import Beneficiaries from './Beneficiaries';
import Presale from './Presale';
import DaTerms from './DaTerms';
import Aftersale from './Aftersale';
import DAApproval from './DAApproval';
import CompanyPersonnel from './CompanyPersonnel';
import KYC from './KYC';
import Dropdown from '@hubins/components/Dropdown';
import __ from 'localisation/hubins/registration';
import __ui from 'localisation';

import Terms from './Terms';


const privateTabs = [
  {
    id: 'roaring',
    title: __('p_roaring'),
  },
  {
    id: 'presale',
    title: __('p_presale'),
  },
  {
    id: 'kt',
    title: __('p_kt'),
  },
  {
    id: 'da_terms',
    title: __('p_da_terms'),
  },
  {
    id: 'da_purpose',
    title: __('p_da_purpose'),
  },
  {
    id: 'da_transactions',
    title: __('p_da_transactions'),
  },
  {
    id: 'da_products',
    title: __('p_da_products'),
  },
  {
    id: 'da_target_return',
    title: __('p_da_target_return'),
  },
  {
    id: 'da_tax',
    title: __('p_da_tax'),
  },
  {
    id: 'da_beneficiaries',
    title: __('p_da_beneficiaries'),
  },
  {
    id: 'da',
    title: __('da_result'),
  },
  {
    id: 'da_so',
    title: __('da_so'),
  },
  {
    id: 'kyc',
    title: __('p_kyc'),
  },
  {
    id: 'ins',
    title: __('p_ins'),
  },
  {
    id: 'terms',
    title: __('p_terms'),
  },
  {
    id: 'aftersale',
    title: __('p_aftersale'),
  },
];

const companyTabs = [
  {
    id: 'presale',
    title: __('c_presale'),
  },
  {
    id: 'kt',
    title: __('c_kt'),
  },
  {
    id: 'da_terms',
    title: __('c_da_terms'),
  },
  {
    id: 'da_purpose',
    title: __('p_da_purpose'),
  },
  {
    id: 'da_transactions',
    title: __('c_da_transactions'),
  },
  {
    id: 'da_products',
    title: __('c_da_products'),
  },
  {
    id: 'da_target_return',
    title: __('c_da_target_return'),
  },
  {
    id: 'da_tax',
    title: __('c_da_tax'),
  },
  {
    id: 'da_beneficiaries',
    title: __('c_da_beneficiaries'),
  },
  {
    id: 'da',
    title: __('da_result'),
  },
  {
    id: 'da_so',
    title: __('da_so'),
  },
  {
    id: 'bi',
    title: __('c_bi'),
  },
  {
    id: 'kyc',
    title: __('p_kyc'),
  },
  {
    id: 'ins',
    title: __('c_ins'),
  },
  {
    id: 'terms',
    title: __('c_terms'),
  },
  {
    id: 'aftersale',
    title: __('c_aftersale'),
  },
  {
    id: 'personnel',
    title: __('company_personnel'),
  },

  // 'presale',
  // 'kt',
  // 'da_terms',
  // 'da_purpose',
  // 'da_transactions',
  // 'da_products',
  // 'da_target_return',
  // 'da_tax',
  // 'da_beneficiaries',
  // 'da',
  // 'da_so',
  // 'bi',
  // 'kyc',
  // 'ins',
  // 'hm',
  // 'terms',
  // 'aftersale',
  // 'personnel',
];

const Registration = ({ portfolioUuid, profileUuid, contact, portfolio }) => {
  const isCompany = contact.type === 'COMPANY';

  const [selectedTab, setSelectedTab] = useState('');

  const components = {
    roaring: <BasicInfo contact={contact} />,
    presale: <Presale contact={contact} portfolioUuid={portfolioUuid} profileUuid={profileUuid} />,
    kt: <Knowledge contact={contact} portfolioUuid={portfolioUuid} profileUuid={profileUuid} />,
    da_terms: <DaTerms contact={contact} portfolioUuid={portfolioUuid} profileUuid={profileUuid} />,
    da_purpose: <Purpose contact={contact} portfolioUuid={portfolioUuid} profileUuid={profileUuid} />,
    da_transactions: <Transactions contact={contact} portfolioUuid={portfolioUuid} profileUuid={profileUuid} />,
    da_products: <Products contact={contact} portfolioUuid={portfolioUuid} profileUuid={profileUuid} />,
    da_target_return: <TargetReturn contact={contact} portfolioUuid={portfolioUuid} profileUuid={profileUuid} />,
    da_tax: <Tax contact={contact} portfolioUuid={portfolioUuid} profileUuid={profileUuid} />,
    da_beneficiaries: <Beneficiaries contact={contact} portfolioUuid={portfolioUuid} profileUuid={profileUuid} />,
    da: <DemandAssessment contact={contact} portfolioUuid={portfolioUuid} profileUuid={profileUuid} />,
    da_so: <DAApproval contact={contact} portfolioUuid={portfolioUuid} profileUuid={profileUuid} />,
    bi: <CompanyBasicInfo contact={contact} portfolioUuid={portfolioUuid} profileUuid={profileUuid} />,
    kyc: <KYC contact={contact} profileUuid={profileUuid} />,
    ins: <InsuranceKYC contact={contact} portfolioUuid={portfolioUuid} profileUuid={profileUuid} />,
    terms: <Terms contact={contact} portfolioUuid={portfolioUuid} profileUuid={profileUuid} />,
    aftersale: <Aftersale contact={contact} portfolioUuid={portfolioUuid} profileUuid={profileUuid} />,
    personnel: <CompanyPersonnel contact={contact} portfolioUuid={portfolioUuid} profileUuid={profileUuid} portfolio={portfolio} />,
  };

  return (
    <>
      <div className='s-left-s s-bottom-l'>
        <Dropdown
          className={"input-max-width"}
          label={__ui('form')}
          options={isCompany ? companyTabs : privateTabs}
          value={selectedTab}
          callback={setSelectedTab}
          placeholder={__ui('choose_form')}
        />
      </div>
      {components[selectedTab]}
    </>
  );

};

export default Registration;
