import React, { useState } from 'react';
import __ from 'localisation';
import Heading from '@hubins/components/Heading';
import Modal from '@hubins/components/Modal';
import Button, { ButtonWrap } from '@hubins/components/Button';
import { Columns, Column } from 'components/Columns';
import getAvailableRoutes from '../layouts/getAvailableRoutes';
import { connect } from 'react-redux';
import Box from 'components/Box';
// import { Link, Route } from 'react-router-dom';
import Icon from '@hubins/components/IconNew';
import './index.scss';

const Dashboard = ({ tfa, userRoles }) => {

  const [tfaAlert, setTfaAlert] = useState(!tfa);

  const closeTfaAlert = () => {
    setTfaAlert(false);
  };

  const routes = getAvailableRoutes(userRoles, false);

  return (
    <>
      {!tfa && (
        <Modal
          open={tfaAlert}
        >
          <Heading size="3">
            {__('tfa_required_title')}
          </Heading>
          <p>{__('tfa_required_text')}</p>
          <p>{__('tfa_required_text_2')}</p>
          <ButtonWrap center>
            <Button href="/dashboard/settings" className="cta">
              {__('settings')}
            </Button>
            <Button onClick={closeTfaAlert}>
              {__('do_later')}
            </Button>
          </ButtonWrap>
        </Modal>
      )}
      <Columns className="side-padding">
        {routes.map((route) => (
          <Column s="12" m="6" l="4" xl="3" key={route.title}>
            <Box size="medium" bg="primary" className="flex-center" borderRadius={false}>
              <Icon size={24} color="white" icon={route.iconName} className="s-lg" />
              <Button external={!!route.external} href={route.route} className="white">{route.title}</Button>
            </Box>
          </Column>
        ))}
      </Columns>
    </>
  );
};

function mapStateToProps(state) {
  return {
    tfa: state.auth.User.tfa,
    userRoles: state.auth.User.userRoles,
  };
}

export default connect(
  mapStateToProps,
)(Dashboard);
