import React, { Component, Fragment } from 'react';
import { Column } from 'components/Columns';
import Heading from '@hubins/components/Heading';
import './index.scss';
class CaseTable extends Component {

  constructor(props) {
    super(props);
  }

  renderTable(props){
    const array = [];
    let i = 1;
    let className = '';
    props.rows.forEach( (row, index) => {
      if(i % 2 === 0){
        className = 'case-table__content --even';
      }else {
        className = 'case-table__content --odd';
      }
      array.push(
        <tr key={index}>
          <th className="case-table__header">{row.title}:</th>
          <td className={className} dangerouslySetInnerHTML={{ __html: row.text }} />
        </tr>
      );
      i++;
    });
    return array;
  }

  render() {

    return (
      <Fragment>
        {this.props.title && <Heading size="3" className="center">{this.props.title}</Heading>}
        <Column s="12">
          <table className="case-table numbers section-padding">
            <tbody>
              {this.renderTable(this.props)}
            </tbody>
          </table>
        </Column>
      </Fragment>
    );
  }

}

export default CaseTable;
