import React, { Suspense, lazy, ComponentType } from 'react';
import LoadingPage from '@hubins/components/LoadingPage';


type DynamicLoadPageProps = {
  loader: () => Promise<{ default: ComponentType<any>; }>;
  loadingComponent?: ComponentType<any>;
};

const DynamicLoadPage = ({ loader, loadingComponent: LoadingComponent = LoadingPage }: DynamicLoadPageProps) => {
  const Component = lazy(loader);

  return (props: any) => (
    <Suspense fallback={<LoadingComponent />}>
      <Component {...props} />
    </Suspense>
  );
};

export default DynamicLoadPage;