import React from 'react';
import FormHistory from 'modules/FormHistory';

const Knowledge = ({ contact, /*portfolioUuid,*/ profileUuid }) => {
  const isCompany = contact.type === 'COMPANY';
  const key = isCompany
    ? 'c_kt'
    : 'p_kt';

  return <FormHistory
    formKey={key}
    contactName={contact.name}
    // portfolioUuid={portfolioUuid}
    profileUuid={profileUuid}
  />;
};

export default Knowledge;
