import React, { useState } from 'react';
import Button from '../Button/Button';
import middleman from 'helpers/middleman';
import __ from 'localisation';

const SyncUserButton = ({ profileUuid }) => {

  const [loading, setLoading] = useState(false);

  const syncUser = async () => {
    try {
      setLoading(true);
      await middleman.get(`/ql/crm/sync/${profileUuid}`);
      window.location.reload();
      // setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <Button
      className='secondary'
      loading={loading}
      disabled={loading}
      onClick={syncUser}
      icon={"loading"}
    >
      {__('sync_user')}
    </Button>
  );
};

export default SyncUserButton;