import { routes } from './route';
import { getItem, setItem } from 'helpers';
import { LANG_DEFAULT } from 'settings';

export const availableLanguages = {
  SE: 'SE',
  NO: 'NO',
  FI: 'FI',
  EN: 'EN',
};


export const getKey = ({ string, vars, localisationObject, market, commonObject }) => {

  if (typeof string !== 'string') {
    return string;
  }

  let localisationString = string.toLowerCase();

  // Check if key is in market, or in default, or in default object else return just the string
  if (typeof localisationObject(vars).MARKET_STRINGS[market][localisationString] !== "undefined") {
    localisationString = localisationObject(vars).MARKET_STRINGS[market][localisationString];
  }
  else if (typeof localisationObject(vars).STRINGS[localisationString] !== "undefined") {
    localisationString = localisationObject(vars).STRINGS[localisationString];
  }
  else if (typeof commonObject(vars).MARKET_STRINGS[market][localisationString] !== "undefined") {
    localisationString = commonObject(vars).MARKET_STRINGS[market][localisationString];
  }
  else if (typeof commonObject(vars).STRINGS[localisationString] !== "undefined") {
    localisationString = commonObject(vars).STRINGS[localisationString];
  }

  if (localisationString === '') localisationString = string;
  return localisationString;
};

// Usage: __plural('day', 10, {number: 10})
// or use the number value in the object: __plural('day', false, {number: 10})
export const getPlural = function ({ string, vars, localisationObject, commonObject, market, number }) {
  if (number === false && 'number' in vars) {
    number = vars.number;
  }
  if (number >= -1 && number <= 1 && number !== 0) { // everything between -1 och 1 except 0
    return getKey({ string: string + '_singular', vars, localisationObject, commonObject, market });
  } else {
    return getKey({ string: string + '_plural', vars, localisationObject, commonObject, market });
  }
};

// Get's the current language set in localStorage
// Usage:
// const LANGUAGE = getLanguage(MARKET);
// TODO: Set fallback to browser language, not market?
export const setLanguage = (language) => {
  let docLang = language === 'SE' ? 'SV' : language || 'SV';
  document.documentElement.setAttribute('lang', docLang.toLowerCase());
  setItem('language', language);
  return language;
};

// make sure the stored language in the database i correct
// by checking against the availableLanguages object
export const getLanguage = () => {

  let lang = getItem('language');

  if (!lang || !VITE_LANG_SUPPORT) {
    lang = LANG_DEFAULT;
    setItem('language', lang);
  }

  return lang;
};

export const fallback = () => ({
  MARKET_STRINGS: {
    SE: {},

    // Norway
    NO: {},

    // Finland
    FI: {}
  },

  // Default strings
  STRINGS: {}
});

export const convertRoute = (newLang) => {
  const lastLocation = decodeURI(window.location.pathname);
  const lastLanguage = getItem('language');
  const lastRouteObject = routes[lastLanguage]();
  const newRouteObject = routes[newLang]();
  const translatedRouteKey = Object.keys(lastRouteObject).find(key => lastRouteObject[key] === lastLocation);
  const translatedRoute = newRouteObject[translatedRouteKey];
  return translatedRoute;
};
