import React, { useRef, useState } from 'react';
import Input from '@hubins/components/Input';
import DateInput from '@hubins/components/DateInput';
import InputCurrency from '@hubins/components/Input/Currency';
import { editableKeys } from 'helpers/sof';

const getInputTypeByKey = (key) => {
  switch (key) {
    case 'startYear':
    case 'endYear':
    case 'percentage':
      return 'number';
    case 'amount':
    case 'investment_amount':
    case 'profit_amount':
      return 'currency';
    case 'description':
    case 'holding':
    case 'from':
    case 'purpose':
      return 'text';
    case 'date':
      return 'date';
    default:
      return 'text';
  }
};

const EditableField = ({ fieldKey, value: propsValue, onChange, disabled }) => {
  const [value, setValue] = useState(propsValue);

  const type = getInputTypeByKey(fieldKey);

  const handleChange = (val) => {
    setValue(val);
    onChange(val, fieldKey);
  };

  const defaultProps = {
    type,
    value,
    callback: handleChange,
    label: fieldKey,
    disabled,
  };

  return (
    <>
      {type === 'currency' ? (
        <InputCurrency {...defaultProps} />
      ) : type === 'date' ? (
        <DateInput {...defaultProps} value={value ? value.split('T')[0] : undefined} />
      ) : (
        <Input {...defaultProps} />
      )}
    </>
  );

};

const EditableFields = ({ fields, onChange, disabled }) => {
  const foundEditableKeys = useRef(editableKeys.filter((key) => fields[key] || fields[key] === 0));
  const handleChange = (val, key) => {
    onChange(val, key);
  };

  return (
    <>
      {foundEditableKeys.current.map((key) => (
        <EditableField key={key} fieldKey={key} value={fields[key]} onChange={handleChange} disabled={disabled} />
      ))}
    </>
  );

};

export default EditableFields;
