import React from 'react';
import FormHistory from 'modules/FormHistory';

type Props = {
  source: any;
  profileUuid: string;
  contact: any;
}

const SourceOfFundHistory = ({ source, profileUuid, contact }: Props) => {
  return (
    <>
      <FormHistory
        formKey={source?.form?.key}
        contactName={contact.name}
        profileUuid={profileUuid}
        options={{ sourceOfFundsUuid: source.uuid }}
        type="sof"
        canEdit={false}
      />
    </>
  )
};

export default SourceOfFundHistory;