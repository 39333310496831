import React, { useEffect, useState, useCallback } from 'react';
import SourceOfFundListItem from '../SourceOfFundListItem';
import middleman from 'helpers/middleman';
import LoadingBar from '@hubins/components/LoadingIndicators';
import { formatNumberByThousands } from 'helpers';
import SimpleTable from 'modules/Tables/SimpleTable';
import __ from 'localisation';
import Heading from '@hubins/components/Heading';

const SourceOfFundsList = ({ profileUuid, endDate, risk }) => {

  const [loading, setLoading] = useState(true);
  const [sources, setSources] = useState<any>([]);

  const fetchSources = useCallback(async (theEndDate) => {
    setLoading(true);
    try {
      const sofResults = await middleman.get(`/ql/crm/source-of-funds/filter/${profileUuid}${theEndDate ? `/${theEndDate}` : ''}`);
      setSources(sofResults.data);
    } catch (error) {
      console.error('Error fetching sources of funds', error);
    }
    setLoading(false);
  }, [profileUuid, setSources, setLoading]);

  useEffect(() => {
    fetchSources(endDate);
  }, [endDate])

  if (loading) {
    return <LoadingBar large />;
  }

  const sourceOfFundsHeaders = [
    {
      Header: __('type'),
      Cell: ({ row }) => __(`sof_${row.original.type}`),
    },
    {
      Header: __('available_amount'),
      accessor: (risk && risk > 2) ? 'highRiskAvailableAmount' : 'availableAmount',
      Cell: ({ value }) => formatNumberByThousands(value),
      Footer: sources ? formatNumberByThousands(sources.reduce((res, item) => {
        const availableAmount = (risk && risk > 2) ? item.highRiskAvailableAmount : item.availableAmount;
        res += availableAmount;
        return res;
      }, 0)) : 0
    },
    {
      Header: __('used_amount'),
      accessor: 'usedAmount',
      Cell: ({ value }) => formatNumberByThousands(value),
      Footer: sources ? formatNumberByThousands(sources.reduce((res, item) => {
        res += item.usedAmount;
        return res;
      }, 0)) : 0
    }
  ];

  const sourceOfWealthHeaders = [
    {
      Header: __('sow'),
      Cell: ({ row }) => __(`sof_${row.original.type}`),
    },
    {
      Header: __('amount'),
      accessor: 'source.amounts.source_of_wealth',
      Cell: ({ value }) => formatNumberByThousands(value),
      Footer: sources ? formatNumberByThousands(sources.reduce((res, item) => {
        res += item.source.amounts.source_of_wealth;
        return res;
      }, 0)) : 0
    }
  ];

  return (<>
    <Heading size="4" className="s-top-lg">{__('sources')}</Heading>
  {sources.map((source) => (
    <SourceOfFundListItem key={source.uuid} source={source} />
  ))}
    <Heading size="4" className="s-top-xl s-bottom-lg">
      {__('sof')}
    </Heading>
    <SimpleTable data={sources} headers={sourceOfFundsHeaders} footer />
    {sources && (
      <>
        <Heading size="4" className="s-bottom-lg s-top-xl">
          {__('sow')}
        </Heading>
        <SimpleTable
          className="s-top-xl"
          headers={sourceOfWealthHeaders}
          data={sources}
          title="Source of wealth"
          footer
        />
      </>
    )}
  </>)

};

export default SourceOfFundsList;