import React, { useState } from 'react';
import Box from 'components/Box';
import Main from 'components/Main';
import Heading from '@hubins/components/Heading';
import Input from '@hubins/components/Input';
import Button from '@hubins/components/Button';
import __ from 'localisation';
import middleman from 'helpers/middleman';


const ChangePassword = ({ match }) => {

  const { token } = match.params;
  const email = new URLSearchParams(window.location.search).get("email");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [password, setPassword] = useState('');
  const [repeat, setRepeat] = useState(false);
  const [error, setError] = useState(false);

  const submit = (e) => {
    e.preventDefault();

    if (repeat !== password) {
      setError('Passwords dosnt match');
    }

    setLoading(true);

    const body = {
      password,
      password_confirmation: repeat,
      email,
      token,
    };

    middleman.promisePost('/reset-password/update', body).then(() => {
      setLoading(false);
      setSuccess(true);
    }).catch(err => {
      console.error(err.message);
      setError(__('reset_password_error'));
      setLoading(false);
    });

  };
  if (success) {
    return (
      <Main center>
        <Box size="medium" bg="white" style={{ width: 480, maxWidth: "100%" }}>
          <Heading size="3">
            {__('reset_password_success')}
          </Heading>
          <p>
            {__('reset_password_success_text')}
          </p>
          <Button href="/">
            {__('to_login')}
          </Button>
        </Box>
      </Main>
    );
  }

  return (
    <>
      <Main center>
        <Box size="medium" bg="white" style={{ width: 480, maxWidth: "100%" }}>
          <form onSubmit={submit}>
            <Input
              type="password"
              label="New password"
              value={password}
              callback={setPassword}
              className="s-md"
            />
            <Input
              type="password"
              label="Repeat password"
              value={repeat}
              callback={setRepeat}
              className="s-md"
            />
            {error && (
              <p className='c-danger'>
                {error}
              </p>
            )}
            <Button
              className="spacing primary center"
              type="submit"
              loading={loading}
              disabled={loading}
            >
              {__('request_reset')}
            </Button>

          </form>
        </Box>
      </Main>
    </>
  );
};

export default ChangePassword;
