import __ from 'localisation';

export const channelOptions = [
  {
    title: __('hubins_backoffice'),
    id: 'hubins_backoffice',
  },
  {
    title: __('hubins_compliance'),
    id: 'hubins_compliance',
  },
  {
    title: __('quantum_backoffice'),
    id: 'quantum_backoffice',
  },
  {
    title: __('quantum_compliance'),
    id: 'quantum_compliance',
  },
  {
    title: __('broker'),
    id: 'broker',
  }
];
