import React from 'react';
import Modal from '@hubins/components/Modal';
import __ from 'localisation';
import ApproveTOFTransactionForm from '@hubins/components/ApproveTOFTransactionForm';

const EditBatchTofTransaction = ({ transaction, setTransaction, refetch, canEdit = false }) => {
  return (
    <Modal
      open={!!transaction}
      callback={setTransaction}
      closeButton={true}
      shouldCloseOnOverlayClick={true}
    >
      <ApproveTOFTransactionForm
        transaction={transaction}
        setTransaction={setTransaction}
        refetch={refetch}
        canEdit={canEdit}
      />
    </Modal>
  );
};

export default EditBatchTofTransaction;