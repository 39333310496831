import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from 'components/ErrorMessage';
import __ from 'localisation';
import Heading from '@hubins/components/Heading';

import './index.scss';

class Checkbox extends Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(sectionId, partId, itemName, event) {
    if (this.props.createFormCallback) {
      this.props.createFormCallback(
        sectionId, partId, partId, false, event, this.props.optionsState.validation
      );
    }
    if (this.props.callback) {
      const error = this.props.required && !event.target.checked;
      this.props.callback(event.target.checked, error, event);
    }
  }

  render() {
    const throwError = this.props.optionsState.error || this.props.error;
    let classModifier = this.props.heading ? 'checkbox--with-heading' : '';
    if (this.props.inverted) classModifier += ' checkbox--inverted';

    return (
      <Fragment>
        {this.props.title && <Heading size="4" className="s-bottom-lg">{this.props.title}</Heading>}
        <label id={this.props.id} className={`checkbox ${classModifier} ${this.props.className}`}>
          <input
            disabled={this.props.disabled || this.props.locked}
            required={this.props.required}
            className="checkbox__trigger visuallyhidden"
            name={this.props.id}
            type="checkbox"
            checked={this.props.optionsState.value}
            onChange={
              typeof this.props.onChange !== 'undefined' ? this.props.onChange :
              (event) => this.handleChange(
                this.props.sectionId,
                this.props.partId,
                this.props.optionsState.value,
                event
              )} />
          <span className="checkbox__symbol">
            <svg className='icon-checkbox icon-checkbox--checked' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.666748 9.4235L3.66119 13.4597C3.89165 13.7867 4.27423 13.9877 4.68831 13.9995C5.10239 14.0112 5.49693 13.8322 5.74753 13.5189L15.3334 2" stroke="currentColor" strokeLinejoin="round" />
            </svg>
          </span>
          <div className="checkbox__textwrapper body-small">
            {this.props.heading && (<p><strong>{this.props.heading}</strong></p>)}
            {this.props.highlight ?
              <strong>{this.props.label}</strong> :
              <span>{this.props.label}</span>
            }
          </div>
        </label>
        {this.props.lockedText && (
          <p className="clarification section-padding-small">{this.props.lockedText}</p>
        )}

        <ErrorMessage text={this.props.error_message || this.props.error} showError={throwError} />

      </Fragment>
    );
  }
}

Checkbox.propTypes = {
  heading: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  optionsState: PropTypes.object,
  highlight: PropTypes.bool,
  className: PropTypes.string,
};

Checkbox.defaultProps = {
  label: '',
  id: '',
  error_message: __('checkbox_error'),
  disabled: false,
  locked: false,
  lockedText: false,
  optionsState: {
    value: false,
    error: false
  },
  required: undefined,
  highlight: false,
  className: '',
};

export default Checkbox;
