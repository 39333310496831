import React, { Fragment } from 'react';
import { motion } from 'framer-motion';
import SidebarTrigger from 'components/Sidebar/SidebarTrigger';
import { Columns } from 'components/Columns';
import Heading from '@hubins/components/Heading';
import {
  getSectionChilds,
} from "modules/CreateForm/createFormMethods";


const RenderForm = (props) => {

  return (
    props.data.map((section, index) => {
      const children = getSectionChilds(section, props);

      return (
        <Fragment key={`key_${section.id}-${index}-${props.rerenderKey}`}>
          <motion.section animate={props.animate} data-section-animate={section.id}>
            {('title' in section && section.title)  &&
              <Heading element="h3" size={3} className="s-top-md s-bottom-l basic-padding-small">
                {section.title}
              </Heading>
            }
            {section.tooltip && (
              <SidebarTrigger
                id={section.id}
                icon="question"
                text={section.tooltip}
              />
            )}
          </motion.section>

          {children.length >= 1 &&
            <motion.section animate={props.animate} className={section.class ? section.class : ''} data-section-content={section.id}>
              <Columns>
                {children}
              </Columns>
            </motion.section>
          }
        </Fragment>
      );
    })
  );
};

export default RenderForm;
