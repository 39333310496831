import React from 'react';
import __ from 'localisation';
import LoadingBar from '@hubins/components/LoadingIndicators';

const loadiingObj = {
  amount: <LoadingBar small />,
  unitPrice: <LoadingBar small />,
  tradeAmount: <LoadingBar small />,
  fxRate: <LoadingBar small />,
  securityName: <LoadingBar small />,
  type: <LoadingBar small />,
  portfolio: <LoadingBar small />,
  currency: <LoadingBar small />,
  settlementDate: <LoadingBar small />,
  // maturityDate: <LoadingBar small />,
}

export const loadingState = [
  loadiingObj,
  loadiingObj,
  loadiingObj,
  loadiingObj,
  loadiingObj,
  loadiingObj,
  loadiingObj,
  loadiingObj,
  loadiingObj,
  loadiingObj,
  loadiingObj,
  loadiingObj,
  loadiingObj,
  loadiingObj,
  loadiingObj,
  loadiingObj,
  loadiingObj,
  loadiingObj,
  loadiingObj,
  loadiingObj,
  loadiingObj,
  loadiingObj,
  loadiingObj,
  loadiingObj,
];

export const tableHeaders = [
  {
    Header: __('transaction_date'),
    accessor: 'transactionDate',
    maxWidth: 150
  },
  {
    Header: __('settlement_date'),
    accessor: 'settlementDate',
    maxWidth: 150
  },
  {
    Header: __('portfolio'),
    accessor: 'portfolioShortName',
    width: 200
  },
  {
    Header: __('security_name'),
    accessor: 'securityName'
  },
  {
    Header: __('isin'),
    accessor: 'securityCode'
  },
  {
    Header: __('type'),
    accessor: 'type.typeName'
  },
  {
    Header: __('currency'),
    accessor: 'currency.name',
    maxWidth: 50
  },
  {
    Header: __('exchange_rate'),
    accessor: 'fxRate',
    maxWidth: 100
  },
  {
    Header: __('units'),
    accessor: 'amount',
    maxWidth: 100
  },
  {
    Header: __('unit_price'),
    accessor: 'unitPrice',
    maxWidth: 100
  },
  {
    Header: __('trade_amount'),
    accessor: 'tradeAmount'
  },
  {
    Header: __('trade_amount_in_portfolio_currency'),
    accessor: 'tradeAmountInPortfolioCurrency'
  },
  {
    Header: __('cash_flow'),
    accessor: 'cashFlow'
  },
  // {
  //   Header: __('maturity_date'),
  //   accessor: 'maturityDate'
  // },
];

export const typeCodeOptions = [
  { title: "Account Transfer", id: "ACCTR" },
  { title: "Add", id: "ADD" },
  { title: "Add Contract", id: "CADD" },
  { title: "Add correction", id: "ADDC" },
  { title: "Add position", id: "ADDPOS" },
  { title: "Asset management fee", id: "AMF" },
  { title: "Bank fee", id: "BANKFEE" },
  { title: "Brokerage", id: "BROKERAGE" },
  { title: "Buy", id: "B" },
  { title: "Buy-From-Client", id: "BFC" },
  { title: "Capital return", id: "CAPR" },
  { title: "Cash Adjustment", id: "ADJPRE" },
  { title: "Cash Death Payment", id: "CDEATH" },
  { title: "Cashflow in", id: "CIN" },
  { title: "Cashflow out", id: "COUT" },
  { title: "Charge", id: "CHARGE" },
  { title: "Commitment", id: "COM" },
  { title: "Compensation", id: "CMP" },
  { title: "Cost", id: "COST" },
  { title: "Coupon", id: "COUP" },
  { title: "Credit", id: "CREDIT" },
  { title: "Credit Repayment", id: "CREREP" },
  { title: "Death payment", id: "DEATHPAY" },
  { title: "Debt payment", id: "DEBPAY" },
  { title: "Deposit", id: "DEP" },
  { title: "Deposit - Repayment", id: "DEPRESALE" },
  { title: "Deposit - Sale", id: "DEPSALE" },
  { title: "Deposit correction", id: "DEPOSITCORR" },
  { title: "Discretionary Management Fee", id: "DMF" },
  { title: "Dividend", id: "DIV" },
  { title: "Dividend as shares", id: "DIVAS" },
  { title: "Exchange", id: "EXCH" },
  { title: "Exchange (issue)", id: "EXCHISS" },
  { title: "Exchange partially", id: "EXCHPART" },
  { title: "Expire", id: "EXP" },
  { title: "Fee Receivable", id: "FEED" },
  { title: "Fees correction", id: "FEECORR" },
  { title: "Fixed Policy Cost QL", id: "FPCQL" },
  { title: "Fixed Policy Cost QL2", id: "FPCQL2" },
  { title: "Future cashflow in", id: "FCIN" },
  { title: "Future cashflow out", id: "FCOUT" },
  { title: "Interest", id: "INT" },
  { title: "Invoice Payment", id: "INVC" },
  { title: "Issue", id: "ISS" },
  { title: "Management fee IFA", id: "AMFIFA" },
  { title: "Management fee Other IFA", id: "AMFOIFA" },
  { title: "Management fee QL", id: "AMFQL" },
  { title: "Management fee QL2", id: "AMFQL2" },
  { title: "Mature", id: "MAT" },
  { title: "Premium", id: "PRE" },
  { title: "Premium Internal Transfer", id: "PREIT" },
  { title: "Premium Security", id: "PRESEC" },
  { title: "Premium Security Internal", id: "PRESECIT" },
  { title: "Profit correction", id: "PROFITCORR" },
  { title: "Remove", id: "REM" },
  { title: "Remove positions", id: "REMPOS" },
  { title: "Repayment", id: "REPAY" },
  { title: "Repayment correction", id: "REPAYCORR" },
  { title: "Risk Premium", id: "RISKPRE" },
  { title: "Safello Custody Fee", id: "SCF" },
  { title: "Sell", id: "S" },
  { title: "Sell-To-Client", id: "STC" },
  { title: "Split", id: "SP" },
  { title: "Stamp Duty", id: "" },
  { title: "Subscription", id: "SUBS" },
  { title: "Subscription (C)", id: "SUBSC" },
  { title: "Subscription payment", id: "Payment" },
  { title: "Subscription rights issue", id: "SRI" },
  { title: "Subsription rights issue (C)", id: "SRIC" },
  { title: "Surrender", id: "SURR" },
  { title: "Surrender sec", id: "SURRSEC" },
  { title: "Tax", id: "TAX" },
  { title: "Transaction Charge QL1", id: "TCQL1" },
  { title: "Transaction Charge QL2", id: "TCQL2" },
  { title: "Value Adjustment", id: "VA" },
  { title: "Withdrawal", id: "WD" },
  { title: "Withdrawal - Buy", id: "WDB" },
  { title: "Withdrawal correction", id: "WDCORR" },
  { title: "Withdrawal Internal Transfer", id: "WDIT" },
  { title: "Withdrawal Sec", id: "WDSEC" },
  { title: "Witholding TAX", id: "WHT" }
];