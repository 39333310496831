import apiConnector from "api-connector";
import axios from "axios";

const middlemanURL = import.meta.env.VITE_MIDDLEMAN_URL;
let middleman = apiConnector(middlemanURL + "/api");

middleman = {
  ...middleman,
  addUserProfileRole: async (profileUuid, userUuid, roleName) => {
    return await middleman.post(`/userprofileroles/${profileUuid}`, {
      userUuid,
      roleName,
    });
  },
  removeUserProfileRole: async (profileUuid, userUuid, roleName) => {
    return await middleman.promisePost(`/userprofileroles/delete/${profileUuid}`,
      {
        userUuid,
        roleName,
      }
    );
  },
  addPolicyProfileRole: async (portfolioUuid, profileUuid, roleName) => {
    return await middleman.post(`/policyroles/${portfolioUuid}`, {
      profileUuid,
      roleName,
    });
  },
  removePolicyProfileRole: async (portfolioUuid, profileUuid, roleName) => {
    console.log('removePolicyProfileRole', portfolioUuid, profileUuid, roleName); 
    return await middleman.promisePost(`/policyroles/delete/${portfolioUuid}`,
      {
        profileUuid,
        roleName,
      }
    );
  },
};
export const csrf = () => {
  const csrf_url = middlemanURL + "/sanctum/csrf-cookie";
  return axios.get(csrf_url, { withCredentials: true });
};

export default middleman;
