import React from 'react';
import { connect } from 'react-redux';
import { isBackofficeUser } from 'helpers/userRoles';
import AllClients from '../AllClients';
import AllPortfolios from '../AllPortfolios';
import BrokerClients from '../BrokerClients';
import { useFeature } from '@growthbook/growthbook-react';

const Clients = ({ userRoles }) => {

  const PORTFOLIO_LIST = useFeature('backoffice-portfolios-list').on;

  if (isBackofficeUser(userRoles)) {
    if (PORTFOLIO_LIST) return <AllPortfolios />;
    return <AllClients />;
  }

  return <BrokerClients />;
};

function mapStateToProps(state) {
  return {
    userRoles: state.auth.User.userRoles,
  };
}

export default connect(
  mapStateToProps,
)(Clients);
