import React, { useState, useEffect } from 'react';
import middleman from 'helpers/middleman';
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import FormsSummary from 'modules/FormsSummary/Summary';
import SimpleTable from 'modules/Tables/SimpleTable';
import { getSummary } from 'helpers/form';
import Heading from '@hubins/components/Heading';
import ApprovalProfilePortfolioSummary from '@hubins/components/ApprovalProfilePortfolioSummary';
import ClientDocumentsAccordeon from '@hubins/components/ClientDocumentsAccordeon';
import { useTransactions } from 'queries/report';
import TransactionsTable from 'modules/Tables/TransactionsTable';
import ReadMore from '@hubins/components/Accordeon/ReadMore';
import CompanyPersonnelPowersOfAttorney from '@hubins/components/CompanyPersonnelPowersOfAttorney';
import { getDefaultTransactionSourceOfFundsHeaders, getTransactionSourceOfWealthHeaders } from 'helpers/sof';
import { UTCIntoGMTPlusTwo } from 'helpers/date';
import __ from 'localisation';
import FMATable from '@hubins/components/FMATable/FMATable';
import HubinsRisks from 'views/Client/RiskTool/HubinsRisks';
import ExpandableButton from '@hubins/components/ExpandableButton';
import { approvalTransactionHeaders } from 'helpers/transactions';
import ApproveTOFTransactionForm from '@hubins/components/ApproveTOFTransactionForm';
import Comments from '@hubins/components/Comments';

const Tof = ({ portfolio_uuid: portfolioUuid, transaction_uuid: transactionUuid, endDate, canEdit, isLastPerson, setInvalid }) => {
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState(false);
  const [approvalTransaction, setApprovalTransaction] = useState(false);
  const [purposeSummary, setPurposeSummary] = useState(false);
  const [portfolio, setPortfolio] = useState(false);
  const [policyHolder, setPolicyHolder] = useState(false);
  const [signatories, setSignatories] = useState([]);
  const [beneficialOwners, setBeneficialOwners] = useState([]);
  const [risk, setRisk] = useState(false);
  const [sourceOfWealth, setSourceOfWealth] = useState(false);

  const {
    data: faTransactions,
    isLoading: faTransactionsLoading,
    isFetched: faTransactionsFetched
  } = useTransactions(
    '2010-01-01',
    endDate ? endDate : new Date().toISOString().slice(0, 10),
    portfolio
      ? portfolio.uuid : null
  );

  const fetchTransactions = async (shouldSetState = false) => {
    const response = await middleman.promisePost('/ql/crm/transactions/filter?withSecurity=true&withSources=true&withPortfolio=true', {
      portfolioUuid,
      // status: 'pending',
      type: 'TOF',
      endDate: UTCIntoGMTPlusTwo(endDate)
    });

    const result = response.data.filter(transaction => transaction.status !== 'draft');
    const foundTransaction = result.find(transaction => transaction.uuid === transactionUuid);
    setApprovalTransaction(foundTransaction);

    if (shouldSetState) {
      setTransactions(result);
    } else {
      return result;
    }
  };

  useEffect(() => {
    if (isLastPerson) {
      let invalid = true;
      if (approvalTransaction && approvalTransaction.status === 'ready-for-execution') {
        invalid = false;
      }
      setInvalid(invalid, 'tof', __('tof_not_ready_for_execution'));
    }
  }, [isLastPerson, approvalTransaction]);

  const fetchInitData = async () => {
    setLoading(true);
    try {
      const riskPromise = middleman.get(`/risk-assessment/${portfolioUuid}`);
      const portfolioPromise = middleman.get(`/portfolio/${portfolioUuid}`);

      const [transactionsRes, riskRes, portfolioRes] = await Promise.all([
        fetchTransactions(),
        riskPromise,
        portfolioPromise
      ]);

      const foundPolicyHolder = portfolioRes.data.policy_holder[0] || false;

      if (foundPolicyHolder && foundPolicyHolder.type === 'COMPANY') {
        const profileRes = await middleman.get(`/profile/${foundPolicyHolder.uuid}?with=powersofattorney`);
        setSignatories(profileRes.data.signatories);
        setBeneficialOwners(profileRes.data.beneficial_owners);
      }

      const riskCategory = riskRes.data;
      setRisk(riskCategory);
      setPolicyHolder(foundPolicyHolder);
      const isCompany = foundPolicyHolder.type === 'COMPANY';
      const purposeFormKey = isCompany ? 'c_da_purpose' : 'p_da_purpose';
      getSummary(purposeFormKey, foundPolicyHolder.uuid, portfolioUuid, setPurposeSummary, undefined, { endDate });
      const sourceOfWealthRes = await middleman.get(`/ql/crm/source-of-funds/${foundPolicyHolder.uuid}`);
      setSourceOfWealth(sourceOfWealthRes.data);
      setPortfolio(portfolioRes.data);
      setTransactions(transactionsRes);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchInitData();
  }, []);

  if (loading) return (<LoadingBox />);

  return (
    <>
      <ApprovalProfilePortfolioSummary portfolioUuid={portfolio?.uuid} />
      <ExpandableButton heading={__('risk_tool')} className="s-top-xl">
        <FMATable profileUuid={policyHolder?.uuid} portfolioUuid={portfolio?.uuid} endDate={endDate} withoutSearch />
      </ExpandableButton>
      <ExpandableButton heading={__('risk_profile')}>
        <HubinsRisks portfolioUuid={portfolio?.uuid} endDate={endDate} withoutSearch />
      </ExpandableButton>
      <div className='maxwidth s-top-xl'>
        <CompanyPersonnelPowersOfAttorney
          signatories={signatories}
          beneficialOwners={beneficialOwners}
          profileId={policyHolder?.id}
        />
      </div>
      <ClientDocumentsAccordeon
        portfolioUuid={portfolio?.uuid}
        profileUuid={policyHolder?.uuid}
        policyHolderName={policyHolder?.name}
      />
      {purposeSummary ? (
        <FormsSummary
          className="s-top-xl"
          content={purposeSummary.content}
          response={purposeSummary.response}
          title={__("purpose")}
        />
      ) : <LoadingBox />}
      <Heading size="4" className="s-top-xl s-bottom-lg">
        {__('sof')}
      </Heading>
      <SimpleTable data={sourceOfWealth} headers={getDefaultTransactionSourceOfFundsHeaders(risk, sourceOfWealth)} footer />
      {sourceOfWealth && (
        <>
          <Heading size="4" className="s-bottom-lg s-top-xl">
            {__('sow')}
          </Heading>
          <SimpleTable
            className="s-top-xl"
            headers={getTransactionSourceOfWealthHeaders(sourceOfWealth)}
            data={sourceOfWealth}
            title="Source of wealth"
            footer
          />
        </>
      )}
      <Heading size="4" className={'s-top-xl'}>
        {__('transactions')}
      </Heading>

      <ReadMore collapsedHeight={100}>
        <TransactionsTable
          data={
            (faTransactionsFetched && faTransactions && faTransactions.transactions)
              ? faTransactions.transactions
              : []}
          isLoading={faTransactionsLoading}
          currency={portfolio.currency}
        />
      </ReadMore>
      <Heading size="4" className='s-top-xl'>
        {__('tof')}
      </Heading>
      <SimpleTable
        headers={approvalTransactionHeaders}
        data={transactions}
      />
      <div className='s-top-xl'>
        <ApproveTOFTransactionForm
          transaction={approvalTransaction}
          refetch={() => fetchTransactions(true)}
          canEdit={canEdit}
        />
        {approvalTransaction && (
          <Comments
            model='Transaction'
            modelUuid={approvalTransaction.uuid}
            presetChannels={['hubins_backoffice', 'hubins_compliance', 'quantum_backoffice', 'quantum_compliance']}
          />
        )}
      </div>
    </>
  );
};

export default Tof;
