import React from 'react';
import '../Input/index.scss';

type Props = {
  label?: string;
  labelDescription?: string;
  className?: string;
  value: string | number;
  name?: string;
  disabled?: boolean;
  id?: string;
  min?: number;
  max?: number;
  step?: number;
  placeholder?: string;
  required?: boolean;
  callback: (value: string | number) => void;
}

const DecimalInput = ({
  label,
  labelDescription,
  className,
  value,
  name,
  disabled,
  id,
  min,
  max,
  step,
  placeholder,
  required,
  callback,
}: Props) => {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;
    // Replace commas with periods
    newValue = newValue.replace(/,/g, '.');
    callback(newValue);
  }

  return (
    <label className={className}>
      <div className='label-wrapper'>
        {label && (
          <div className="label">{label}</div>
        )}
        {labelDescription && (<div className="label c-neutral-4">{labelDescription}</div>)}
      </div>
      <div className="input__wrap">
        <input
          value={value || ''}
          name={name}
          disabled={disabled}
          id={id}
          min={min}
          max={max}
          step={step}
          className="input"
          placeholder={placeholder}
          required={required}
          type="number"
          onChange={handleChange}
        />
      </div>
    </label>
  )

};

export default DecimalInput;