import React, { Component, Fragment } from 'react';
import Button from '@hubins/components/Button';
import Placeholder from 'components/Placeholder';
import { __, __route } from 'localisation';
import Main from 'components/Main';
import Heading from '@hubins/components/Heading';

class NoMatch extends Component {
  render() {
    let SuggestionPath = false;

    // if (import.meta.env.MODE === 'development') {
    //   SuggestionPath = __route(this.props.location.pathname.slice(1));
    // }
    return (
      <Main>

        <Placeholder image="/illustrations/404.svg">
          <Heading size="4">
            {__('page_not_found')}
          </Heading>
          <p>
            {__('page_not_found_check_address', { path: this.props.location.pathname })}
          </p>
          {this.props.editLink && <p><Button icon="link_out" href={this.props.editLink}>Create the page</Button></p>}
          {SuggestionPath &&
            <Fragment>
              <Heading element="h5" size="5">Menade du:</Heading>
              <p><a href={SuggestionPath}>{SuggestionPath}</a></p>
            </Fragment>
          }
          <Button href={__route('root')} className="cta">
            {__('startpage')}
          </Button>
        </Placeholder>

      </Main>

    );
  }

}

export default NoMatch;
