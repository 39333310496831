import React, { useState, useEffect } from 'react';
import { useSourceOfFunds } from 'queries/sourceOfFunds';
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import SourceProgress from 'components/SourceProgress';
import SourceCards from 'components/SourceCards';
import Heading from '@hubins/components/Heading';
import Button from '@hubins/components/Button';
import middleman from 'helpers/middleman';
import ErrorMessage from 'components/ErrorMessage';
import __ from 'localisation';

type Props = {
  selectedTransaction: any;
  setChooseSource: (boolean) => void;
  profileUuid: string | undefined;
  reset: () => void;
};

const add = (current, value) => {
  current += value;
  return current;
};

const Step3 = ({
  selectedTransaction,
  setChooseSource,
  profileUuid,
  reset,
}: Props) => {

  const [sourceAmounts, setSourceAmounts] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isHighRisk, setIsHighRisk] = useState(false);
  const [initiated, setInitiated] = useState(false);
  const { data, isLoading, isFetched } = useSourceOfFunds(profileUuid);

  const fetchRisk = () => {
    return middleman
      .get(`/risk-assessment/${selectedTransaction.portfolio_uuid}`)
      .then(res => {
        return res.data;
      });
  };

  const init = async () => {
    const risk = await fetchRisk();
    const shouldBeHighRisk = typeof risk === 'number' && risk > 2;
    setIsHighRisk(shouldBeHighRisk);
    setInitiated(true);
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    if (isFetched && data) {
      setSourceAmounts(data.map(() => 0))
    }
  }, [isFetched, data])

  if (isLoading || !initiated) {
    return <LoadingBox />;
  }

  const totalSelectedSum = sourceAmounts.reduce(add, 0);

  const completeEntry = async () => {
    setLoading(true);
    try {
      const usedSources = data.map((source, index) => {
        return {
          'uuid': source.uuid,
          'amount': sourceAmounts[index]
        };
      }).filter(source => {
        return source.amount > 0;
      });
      await middleman.promisePost(`/ql/crm/transaction/update/${selectedTransaction.uuid}`, {
        sources: usedSources,
        status: 'pending',
        amount: selectedTransaction.amount / 100
      });
      reset();
      return;
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  }

  const amount = selectedTransaction?.amount ? selectedTransaction.amount / 100 : 0;

  return (
    <>
      <Button
        onClick={() => setChooseSource(false)}
        className='cta s-top-xl'
        icon='arrow left 1'
        iconColor='white'
        iconLeft
      >
        {__('back')}
      </Button>
      <Heading size="4" className="s-top-xl">
        {__('choose_source_of_funds_for_entry')}
      </Heading>
      <SourceProgress
        totalSelectedSum={totalSelectedSum}
        totalAmount={amount}
        sourceAmounts={sourceAmounts}
        sources={data}
      />
      <SourceCards
        sources={data}
        sourceAmounts={sourceAmounts}
        totalAmount={amount}
        totalSelectedSum={totalSelectedSum}
        setSourceAmounts={setSourceAmounts}
        isHighRisk={isHighRisk}
        canSetAmounts
        premium
        hideAddSource
      />
      <Button
        className='cta large s-top-xl'
        icon="arrow right 1"
        iconColor='white'
        disabled={totalSelectedSum !== amount || loading}
        loading={loading}
        onClick={completeEntry}
      >
        {__('create_entry')}
      </Button>
      <ErrorMessage
        text={__('error_creating_entry')}
        showError={error}
      />
    </>
  )
};

export default Step3;