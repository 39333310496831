const taxaxationsPerYear = {
  "default": (year) => ({
    "year": year,
    "GOVERNMENT_BORROWING_RATE": {
      "value": 0.0025,
      "rendered": "0.25%"
    },
    "CAPITAL_INSURANCE_TAX": {
      "value": 0.0125,
      "rendered": "1.25%"
    },
    "STANDARD_TAX": {
      "value": 0.00375,
      "rendered": "0.375%"
    },
  }),
  "2023": {
    "year": "2023",
    "GOVERNMENT_BORROWING_RATE": {
      "value": 0.0194,
      "rendered": "1.94%"
    },
    "CAPITAL_INSURANCE_TAX": {
      "value": 0.0294,
      "rendered": "2.94%"
    },
    "STANDARD_TAX": {
      "value": 0.00882,
      "rendered": "0.882%"
    },

  },
  "2024": {
    "year": "2024",
    "GOVERNMENT_BORROWING_RATE": {
      "value": 0.0262,
      "rendered": "2,62%"
    },
    "CAPITAL_INSURANCE_TAX": {
      "value": 0.0362,
      "rendered": "3.62%"
    },
    "STANDARD_TAX": {
      "value": 0.01086,
      "rendered": "1.086%"
    }
  },
  "2025": {
    "year": "2025",
    "GOVERNMENT_BORROWING_RATE": {
      "value": 0.0196,
      "rendered": "1,96%"
    },
    "CAPITAL_INSURANCE_TAX": {
      "value": 0.0296,
      "rendered": "2.96%"
    },
    "STANDARD_TAX": {
      "value": 0.00888,
      "rendered": "0.888%"
    }
  }
};

const currentYear = "2024";

export const getTaxationByYear = (year) => {

  if (year === 'current') {
    year = currentYear;
  }

  if (typeof taxaxationsPerYear[year] === "undefined") {
    return taxaxationsPerYear["default"](year);
  }

  return taxaxationsPerYear[year];
};

export const staticValues = (year, fixedCost, isCompany, ageHigherOrEqualTo65) => {
  const yearlyTax = getTaxationByYear('current');

  return {
    INSURANCE_VARIABLE_FEE: 0.01, // %
    INSURANCE_FIXED_FEE: fixedCost ? 1800 : 0, // SEK
    TRANSACTION_FEE: fixedCost ? 300 : 0, // SEK
    YIELD_TAXATION: 0.3, // %
    DIRECT_INVESTMENT_TAX: isCompany ? 0.2060 : 0.3, // %
    GOVERNMENT_BORROWING_RATE: yearlyTax.GOVERNMENT_BORROWING_RATE.value,
    RISK_PREMIUM: (!isCompany && ageHigherOrEqualTo65) ? 0.001 : 0, // %
  };

};
