import './index.scss';
import { __, __route } from 'localisation';
import { Link } from 'react-router-dom';
import AdviceCard from './AdviceCard';
import StockCard from './StockCard';
import Button from '@hubins/components/Button';
import DepositCard from './DepositCard';
import Card, { CardContent } from 'components/Card';
import Heading from '@hubins/components/Heading';
import Icon from '@hubins/components/IconNew';
import Thumbnail from 'components/Thumbnail';
import Media from 'react-media';
import PayCard from './PayCard';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TradeOrderCard from './TradeOrderCard';
import { useCollapse } from 'react-collapsed';
import  ErrorBoundary  from "components/ErrorBoundary";

/**
 *
 * @param {string} status - ex this.data.status - se availible in const statusClasses
 * @param {string} type - ex 'Buy', 'Sell
 * @returns {object} - JSX <Badge> component
 */

const defaultData = {
  id: '',
  externalId: '',
  name: '',
  status: '',
  assignment: '',
  timeLimit: '',
  maxVol: '',
  period: '',
  interest: '',
  reference: '',
  isin: '',
  volume: '',
  investmentValue: '',
  yearlyRate: '',
};

const EventCard = ({ data = defaultData, className = '', ...props }) => {

  const [isExpanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  const handleClick = () => setExpanded(!isExpanded);

  return (
    <ErrorBoundary>
      <Media query={`(min-width: 576px)`}>
        {matches => (
          <Card className={`event-card ${className} ${props.cardtype ? 'event-card--' + props.cardtype.toLowerCase() : ''}`}>
            <CardContent className={`event-card__content ${props.thumbnail || data.thumbnail ? 'event-card__content--thumbnail' : ''} ${props.footer ? 'event-card__content--footer' : ''}`}>
              {props.thumbnail
                ? props.thumbnail
                : data.thumbnail && (
                  <div className="event-card__thumbnail">
                    <Thumbnail src={data.thumbnail}
                      fill
                      alt={(data.hero && data.hero.text !== null && data.hero.text) ?
                        data.hero.text :
                        data.name || "Thumbnail"}
                    />
                  </div>
                )
              }
              <div className="event-card__text">
                <div className="event-card__top">
                  <strong className="event-card__meta">{props.meta}</strong>
                  {matches && (
                    <span className="event-card__badge">
                      {props.status}
                    </span>
                  )}
                </div>

                <header className="event-card__header">
                  {props.header ? props.header : (
                    <>
                      <Heading element="h3" size="4">{data.security ? data.security.name : ''}</Heading>
                      <Link to={`${__route('case')}/${data.security ? data.security.id : ''}`} className="link">{__('view_productpage')}</Link>
                      {props.text && <p className="clarification">{props.text}</p>}
                    </>
                  )}
                  {props.canBeDeleted && (
                    <div className='flex-end'>
                      <Button
                        loading={loading}
                        icon="Bin by Streamlinehq"
                        onClick={() => {
                          setLoading(true);
                          props.deleteCallback();
                        }}>
                        {__('abort_purchase')}
                      </Button>
                    </div>
                  )}
                </header>
              </div>
            </CardContent>

            {!matches && (
              <footer className="event-card__footer">
                <span className="event-card__badge">
                  {props.status}
                </span>
                {!matches && props.footer &&
                <Button
                  {...getToggleProps({ onClick: handleClick })}
                  className="event-card__expandbutton withicon small link"
                >
                  {__(isExpanded ? 'show_less' : 'show_more')}
                  <Icon
                    icon="arrow down 1"
                    anim={isExpanded ? 'rotate-cw-180' : ''}
                    size={8}
                    label={__('details')}
                  />
                </Button>
                }
              </footer>
            )}
            {props.footer && !matches
              ? (
                <div {...getCollapseProps()} className={className || 'event-card__accordeon'}>
                  {props.footer}
                </div>
              )
              : props.footer
            }
          </Card>
        )}
      </Media>
    </ErrorBoundary>
  );
};

export {
  EventCard,
  AdviceCard,
  TradeOrderCard,
  DepositCard,
  StockCard,
  PayCard,
};
export default EventCard;
