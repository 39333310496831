import * as types from 'states/auth/types';
import { createReducer } from 'states/utilities/independent';
import { combineReducers } from 'redux';
import 'localisation';
import middleman from 'helpers/middleman';

const market = 'SE';

export const initialUser = {
  market,
  language: market,
  ssn: false,
  signicatUrl: false,
  signicatProcessId: false
};

// Reducers

export const authReducer = createReducer(initialUser)({
  [types.GET]: (state, action) => {
    console.log('action.payload', action.payload);
    return { ...state, ...action.payload };
  },
  [types.REFRESHTOKEN]: (state, action) => {
    const {
      payload: { data: { authToken, tokenExpires, tokenType } = {}} = {}
    } = action;

    if (tokenExpires) {
      return {
        ...state,
        authToken,
        tokenExpires,
        tokenType,
        tokenUpdated: Date.now()
      };
    }
    return { ...state };
  },
  [types.LOGOUT]: () => {
    return { ...initialUser };
  }
});

export const reducer = combineReducers({
  User: authReducer,
});

// Actions

export const updateUser = (user) => {
  return {
    type: types.GET,
    payload: user
  };
};

export const logout = () => (
  {
    type: types.LOGOUT,
    payload: false,
  }
);

export const refreshToken = () => {
  const request = middleman.promisePost(`/v1/fa/token/refresh`, null);
  return {
    type: types.REFRESHTOKEN,
    payload: request
  };
};

export const authOperations = {
  updateUser,
  logout,
};
