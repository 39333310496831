import React, { useState } from 'react';
import Dropdown from '@hubins/components/Dropdown';
import MultipleDropdown from '@hubins/components/MultipleDropdown';
import { Columns, Column } from 'components/Columns';
import Button from '@hubins/components/Button';
import middleman from 'helpers/middleman';
import ErrorMessage from 'components/ErrorMessage';
import EditableFields from './EditableFields';
import SofChangeNotes from '@hubins/components/SofChangeNotes';

const statusOptions = [
  {
    id: 'approved',
    title: 'approved'
  },
  {
    id: 'rejected',
    title: 'rejected'
  },
  {
    id: 'pending',
    title: 'pending'
  },
  {
    id: 'suspected',
    title: 'suspected'
  }
];

const ExpandedComponent = ({ row, proofOfFunds, sourceOfFundUuid, updateEvent, canEdit }) => {
  const [status, setStatus] = useState(row.approval_status || row.status);
  const [linkedDocuments, setLinkedDocuments] = useState(row.proof_of_funds ? row.proof_of_funds.map((proof) => proof.uuid) : []);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [editableValues, setEditableValues] = useState({});
  const [openChangeNotes, setOpenChangeNotes] = useState(false);

  const linkedDocumentsOptions = proofOfFunds.map((document) => ({
    id: document.uuid,
    title: document.name,
  }));

  const handleEditableChange = (value, key) => {
    setEditableValues({
      ...editableValues,
      [key]: value,
    });
  };

  const submitChanges = async (changeNotes) => {
    setLoading(true);
    setError(false);
    try {
      const updateBody = {
        approval_status: status === 'approved' ? 'approved' : null,
        status: status !== 'approved' ? status : row.status,
        proof_of_funds: linkedDocuments,
        changeNote: { text: changeNotes },
        ...editableValues,
      };
      await middleman.post(`/ql/crm/source-of-funds/events/${sourceOfFundUuid}/${row.uuid}`, updateBody);
      updateEvent(row.uuid, updateBody);
    } catch (e) {
      setError(true);
      console.error(e);
    }
    setLoading(false);
  };



  return (
    <Columns className="wide">
      <SofChangeNotes
        open={openChangeNotes}
        close={setOpenChangeNotes}
        callback={submitChanges}
        loading={loading}
        error={error}
      />
      <Column s="12">
        <EditableFields
          fields={row}
          onChange={handleEditableChange}
          disabled={!canEdit}
        />
      </Column>
      <Column s="6">
        <Dropdown
          label="Status"
          options={statusOptions}
          value={status}
          callback={setStatus}
          disabled={!canEdit}
        />
      </Column>
      <Column s="6">
        <MultipleDropdown
          label="Linked documents"
          options={linkedDocumentsOptions}
          value={linkedDocuments}
          callback={setLinkedDocuments}
          values={linkedDocuments}
          disabled={!canEdit}
        />
      </Column>
      <Column s="12">
        <Button
          className="cta medium"
          onClick={() => setOpenChangeNotes(true)}
          loading={loading}
          disabled={loading || !canEdit}
        >
          Save
        </Button>
        <ErrorMessage
          text="Something went wrong, please try again."
          showError={!!error}
        />
      </Column>
    </Columns>
  );
};

export default ExpandedComponent;
