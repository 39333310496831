export const getSelectedDatePlusOneDay = (date = '') => {
  const selectedDate = date ? new Date(date) : new Date();
  selectedDate.setDate(selectedDate.getDate() + 1);
  const newEndDate = selectedDate.toISOString().split('T')[0];
  return newEndDate;
};

export const UTCIntoGMTPlusTwo = (date) => {
  if (!date) return date;

  // Parse the input date string
  const [datePart, timePart] = date.split('T');
  const [year, month, day] = datePart.split('-');
  const [hours, minutes, seconds] = timePart.split(':');
  const milliseconds = seconds.split('.')[1].replace('Z', '');

  // Create Date object in UTC
  const utcDate = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds.split('.')[0], milliseconds));

  // Add 2 hours
  const gmtPlus2Date = new Date(utcDate.getTime() + (2 * 60 * 60 * 1000));

  // Format the date
  const formattedDate = gmtPlus2Date.toISOString().replace('Z', '');

  // Ensure the formatted date has 6 decimal places for milliseconds
  return formattedDate.replace(/\.\d{3}/, '.000000');
};
