import React, { useState} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'components/Tabs';
import __ from 'localisation';
import ApprovalsByRole from './ApprovalsByRole';
const HubinsBO = () => {

  const [index, setIndex] = useState(0);

  return (
    <Tabs
      className="line-tabs"
      selectedTabClassName="line-tabs__tab--selected"
      selectedTabPanelClassName="line-tabs__tab-panel--selected"
      onSelect={setIndex}
      selectedIndex={index}
    >
      <TabList className="line-tabs__tab-list line-tabs__submenu">
        <Tab className="heading heading--5 line-tabs__tab">{__('all')}</Tab>
        <Tab className="heading heading--5 line-tabs__tab">{__('to_be_approved')}</Tab>
        <Tab className="heading heading--5 line-tabs__tab">{__('follow_up')}</Tab>
        <Tab className="heading heading--5 line-tabs__tab">{__('await_payment')}</Tab>
        <Tab className="heading heading--5 line-tabs__tab">{__('await_third_party')}</Tab>
      </TabList>
      <TabPanel>
        <ApprovalsByRole role="hubins_backoffice" />
      </TabPanel>
      <TabPanel>
        <ApprovalsByRole role="hubins_backoffice" stage="processing" />
      </TabPanel>
      <TabPanel>
        <ApprovalsByRole role="hubins_backoffice" stage={[
          "waiting_on_quantum",
          "waiting_on_client",
          "waiting_on_third_party",
          "waiting_on_payment",
          "waiting_on_settlement",
          "waiting_on_management",
          "suspected",
          ]} />
      </TabPanel>
      <TabPanel>
        <ApprovalsByRole role="hubins_backoffice" stage="waiting_on_payment" />
      </TabPanel>
      <TabPanel>
        <ApprovalsByRole role="hubins_backoffice" stage="waiting_on_third_party" />
      </TabPanel>
    </Tabs>
  );
};

export default HubinsBO;