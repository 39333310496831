import React, { useState } from 'react';
import Heading from '@hubins/components/Heading';
import SimpleTable from 'modules/Tables/SimpleTable';
import { useTofTransactions } from 'queries/transactions';
import __ from 'localisation';
import Dropdown from '@hubins/components/Dropdown';
import LoadingBar from "@hubins/components/LoadingIndicators";
import Button from '@hubins/components/Button';
import middleman from 'helpers/middleman';
import { useNotificationContext } from '@hubins/components/NotificationContext/NotificationContext';
import { getMoney } from 'helpers';
import Icon from '@hubins/components/IconNew';

const statusOptions = [
  {
    id: 'pending',
    title: __('pending_fa')
  },
  {
    id: 'completed',
    title: __('completed')
  }
];

const loadingItem = {
  amount: <LoadingBar />,
  currency: <LoadingBar />,
  status: <LoadingBar />,
  delete: <LoadingBar />,
};

const loadingState = [loadingItem, loadingItem];

const PendingTofList = () => {

  const [status, setStatus] = useState('pending');
  const [loading, setLoading] = useState(false);

  const {data, isLoading, refetch} = useTofTransactions(status, 'approved');
  const { setNotification } = useNotificationContext();

  const changeStatus = async (transactionUuid, status) => {
    setLoading(true);
    try {
      if (window.confirm(__('are_you_sure_tof_approve'))) {
        await middleman.post(`/ql/crm/transaction/update/${transactionUuid}`, { status });
        setNotification({ type: 'success', message: <div className="center">Status was successfully changed.</div> });
        refetch();
      }
    } catch (err) {
      setNotification({ type: 'error', message: <div className="center"><Icon icon="Sign Warning" size={24} />Something went wrong, please try again.</div> });
    }
    setLoading(false);
  }

  const headers = [
    {
      Header: '',
      accessor: 'approve',
      width: 200,
      Cell: ({ row }) => {
        return (
          <Button
            className={`small ${row.original.status !== 'completed' ? 'success' : 'danger'}`}
            onClick={() => changeStatus(row.original.uuid, row.original.status !== 'completed' ? 'completed' : 'pending')}
            loading={loading}
            disabled={loading}
          >
            {row.original.status !== 'completed' ? __('complete') : __('set_to_pending')}
          </Button>
        )
      }
    },
    {
      Header: __("amount"),
      accessor: "amount",
      Cell: ({ value }) => value ? getMoney(value / 100) : null,
    },
    {
      Header: __('type'),
      accessor: "type",
    },
    {
      Header: __("currency"),
      accessor: "currency",
    },
    {
      Header: __("security_id"),
      accessor: "security_id",
    },
    {
      Header: __("security_name"),
      accessor: "security.name",
    },
    {
      Header: __("status"),
      accessor: "status",
    },
    {
      Header: __("portfolio"),
      accessor: "portfolio.short_name",
    },
    {
      Header: __("created_at"),
      accessor: "created_at",
    },
  ]

  return (
    <>
      <div className='maxwidth s-bottom-xl'>
        <Heading size="2" className="s-top-xl s-bottom-md">{__("pending_tof")}</Heading>
        <p className='s-bottom-xl'>{__('pending_tof_desc')}</p>
        <Dropdown
          className='input-max-width'
          label={__('choose_status')}
          options={statusOptions}
          value={status}
          callback={setStatus}
        />
      </div>
      <SimpleTable
        headers={headers}
        data={isLoading ? loadingState : data}
      />
    </>
  )
};

export default PendingTofList;