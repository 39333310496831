import Icon from '@hubins/components/IconNew';
import Button from '@hubins/components/Button';
import __ from 'localisation';
import Modal from '@hubins/components/Modal';
import PropTypes from 'prop-types';
import Heading from '@hubins/components/Heading';
// import './index.scss';

import React, { Component } from 'react';

class FormModal extends Component {

  constructor(props) {
    super(props);
    this.handleModal = this.handleModal.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  handleModal(value) {
    const id = 'partId' in this.props ? this.props.partId : 'modal';
    this.props.callback(id, value);
  }

  goBack() {
    window.history.back();
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        className={this.props.className}
        callback={this.handleModal}
        contentClass="body-big"
      >
        <Icon icon={this.props.icon} size={24} color="primary" className="s-bottom-m" />
        <Heading
          element="h4"
          size={4}
          className="modal__content__title"
        >
          {this.props.title}
        </Heading>
        <div className="modal__content__text p">{this.props.text}</div>

        {'email' in this.props && (
          <p>
            <strong>{__('email')}: </strong>
            <a className="link" href={`mailto:${this.props.email}`} target="_top">{this.props.email}</a>
          </p>
        )}

        {'tel' in this.props && (
          <p>
            <strong>{__('tel')}: </strong>
            <a className="link" href={`tel:${this.props.tel}`} target="_top">{this.props.tel}</a>
          </p>
        )}

        <div className="button__wrap center">
          <Button className="cta spacing" onClick={() => { this.handleModal(false); }}>{this.props.closeButton}</Button>
          {'backButton' in this.props && (<Button className="spacing" onClick={this.goBack}>{this.props.backButton}</Button>)}
        </div>
      </Modal>
    );
  }
}

FormModal.propTypes = {
  open: PropTypes.bool,
  callback: PropTypes.func.isRequired,
  icon: PropTypes.string,
  title: PropTypes.string,
  email: PropTypes.string,
  tel: PropTypes.string,
  closeButton: PropTypes.string,
  backButton: PropTypes.string
};






export default FormModal;
