import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import __ from 'localisation';
import { connect } from 'react-redux';

const Layout = (props) => {

  const { layout: TheLayout, auth = true, authToken, ...restProps } = props;

  useEffect(() => {
    // Update the document title using the browser API
    document.title = props.title ? props.title + ' - ' + __('app_name') : __('app_name');
  }, [props.title]);

  if (auth) {
    if (!authToken) {
      return <Redirect to={'/'} />;
    }
  }

  return <TheLayout auth={auth} {...restProps} />;
};

function mapStateToProps(state) {
  return {
    authToken: state.auth.User.authToken,
  };
}

export default connect(
  mapStateToProps,
)(Layout);
