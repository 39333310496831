import React, { useState, useEffect } from 'react';
import Button from '@hubins/components/Button';
import { Column, Columns } from 'components/Columns';
import Dropdown from '@hubins/components/Dropdown';
import { useActiveApprovalsForRole } from 'queries/approvals';
import { tableHeaders, actionOptions, loadingState } from 'helpers/approvals';
import Checkbox from 'components/Checkbox';
import { connect } from 'react-redux';
import ApiPaginationTable from 'modules/Tables/ApiPaginationTable';
import { getBackofficeRole } from 'helpers/userRoles';

const ApprovalsByRole = ({ role, uuid, userRoles, stage = undefined }) => {
  const [args, setArgs] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasFetched, setHasFetched] = useState(false);
  const [userUuid, setUserUuid] = useState(false);
  const { data, isFetched, isLoading, refetch } = useActiveApprovalsForRole(role, args, userUuid, stage, page + 1);
  const backofficeRole = getBackofficeRole(userRoles);
  const isMyRole = backofficeRole === role;

  useEffect(() => {
    if (isFetched && !hasFetched) {
      setTotalPages(data.pages);
      setHasFetched(true);
    }
  }, [isFetched]);

  const search = (value) => {
    setArgs(value);
    setPage(0);
  };

  const refresh = () => {
    refetch();
  };

  const handleUserUuidCheckBox = (value) => {
    setUserUuid(value ? uuid : false);
  };


  return (
    <Columns>
      <Column s="4">
        <Dropdown
          label={"Filter by action"}
          options={actionOptions}
          value={args}
          callback={search}
          placeholder="Action"
        />
      </Column>
      <Column s="4">
        {isMyRole && (
          <Checkbox
            className="justify-start s-top-xl"
            optionsState={{
              error: false,
              value: !!userUuid
            }}
            label="Show only my approvals"
            callback={handleUserUuidCheckBox}
          />
        )}
      </Column>
      <Column s="12">
        <Button className="medium" onClick={refresh} icon="Button Refresh Arrows by Streamlinehq">
          Refresh
        </Button>
      </Column>
      <Column s="12">
        <ApiPaginationTable
          headers={tableHeaders}
          data={(!data || !data.result || isLoading || !isFetched) ? loadingState : data.result}
          fetchData={setPage}
          pageCount={totalPages}
          index={page}
        />
      </Column>
    </Columns>
  );

};

function mapStateToProps(state) {
  return {
    uuid: state.auth.User.uuid,
    userRoles: state.auth.User.userRoles
  };
}

export default connect(mapStateToProps)(ApprovalsByRole);
