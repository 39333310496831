import React from 'react';
import './icon.scss'

const publicUrl = `/icons`;

export type IconSizes = 8 | 16 | 24 | 32;

const getIcon = (icon:string, size:IconSizes, color:string) => {

  const url = `${publicUrl}/${size}px/${color}/${icon}.svg`;

  return url;
}


export interface IconProps  {
  ariaHidden?: boolean;
  spacing?: string,
  size: IconSizes,
  anim?: string,
  color?: 'primary' | 'white',
  className?: string,
  label?: string,
  icon: string,
  backgroundColor?: string,
  border?: boolean,
  src?: string, // for future use if you want to bypass the getIcon function
}

const Icon = ({
  icon = 'fallback',
  size = 16,
  anim = '',
  spacing = '',
  className = '',
  ariaHidden = false,
  label = '',
  color = 'primary',
  border = false,
  backgroundColor,
  src,
}: IconProps) => {

  return (
    <img
      aria-hidden={ariaHidden}
      className={`
            icon-new ${spacing ? `s-${spacing}` : ''}
            icon-new--${size} ${anim}
            ${className}
            ${backgroundColor ? ` icon-new--with-bg ${backgroundColor}` : ''}
            ${border ? ` icon-new--border` : ''}
          `}
      width="16px" height="16px"
      alt={label}
      src={src || getIcon(icon, size, (color || "primary"))}
    />
  )
}

export default Icon;