// SWEDISH localisation file for default keys

import React, { Fragment } from 'react';

export const keys = (v = {}) => ({
  // Customized strings for different markets - these will override the default STRINGS
  MARKET_STRINGS: {
    // Sweden
    SE: {},

    // Norway
    NO: {},

    // Finland
    FI: {
    },
  },

  // Default strings
  STRINGS: {
    signed_with_bankid: `Signerat med BankID ${v.date}`,

    'confirm': 'Bekräfta',
    abort: "Avbryt",

    accept_and_proceed: "Godkänn och gå vidare",
    accept_terms_confirm: "Jag är medveten om och bekräftar:",
    accepted: "Accepterad",
    accomplished: "Avklarad",
    account_balance: "Kontobalans",
    account_number: "Kontonummer",
    account: "Konto",
    accrued_interest: "Upplupen ränta",
    acquisition_cost: "Anskaffningsvärde",
    actions: "Verktyg", // used in CRM for som tools to edit
    active_investments: "Handla", // Aktiva investeringar
    add_row: "Lägg till rad",
    add: "Tillägg",
    advice: "Rådgivning",
    aftersale: "Efterköpsinformation",
    all_accounts: "Alla konton",
    all: "Alla",
    amortization: "Amortering",
    amount_exkl_court: `Belopp (${v.unit}, exklusive courtage)`,
    amount: "Antal",
    and: "och",
    annual_interest_rate: "Årlig räntesats",
    annually: "Årsvis",
    answer: "Svara",
    aptitude_test: "Passandeprövning",
    aptitude_assessment: "Passandebedömning",
    arranger: "Arrangör",
    at_maturity: "Vid förfallodag",
    attach_file_desc:
  <Fragment>
    eller <strong>släpp den här</strong>
  </Fragment>
    , // As in <button>Attach file</button> or drag and drop
    attach_file: "Bifoga fil",
    autogiro_transfer_info:
      "Vid överföring via autogiro kommer vi att hämta pengar direkt från ditt konto om 1–3 bankdagar. Det betyder att du behöver ha tillräckligt med likvida medel på det konto du har angett vid registrering för autogiro. Om du saknar teckning, kommer vi göra ett nytt försök vid ett senare tillfälle.",
    await_assignment: "Invänta tilldelning",
    await_delivery: "Invänta leverans",
    await_match: "Invänta matchning", // new meaning, before "Betala"
    await_payment: "Invänta betalning",
    ba_modal_text:
      "För att kunna ge dig och andra kunder så bra råd som möjligt önska vi att du meddelar din rådgivare med varför du nekade just detta rådet. Tack på för hand.",
    ba_modal_title: "Du valde att neka till ditt råd",
    back_to_start: "Tillbaka till startsidan",
    balance: "Saldo",
    begin_transfer: "Påbörja flytt",
    beneficial_owner: "Bolagsperson",
    beneficial_owners: "Bolagspersoner",
    block_sale: "BLOCK SALE",
    branch: "Bransch",
    brokerage: "Courtage",
    buy: "Köp",
    ca: "ca", // Ex ca 6-8%
    call_dates: "Förtidsinlösen",
    "calyear-0": "Innevarande år",
    cancel: "Avsluta",
    cancelled: "Makulerad order",
    case: "Produkt",
    cash: "Kontant",
    "cash adjustment": "Justering av likvid",
    "cashflow out": "Kassaflöde out",
    "cashflow in": "Kassaflöde in",
    "fees correction": "Fees correction",
    change_lang: "Ändra språk",
    choose_payment_method: "Välj investeringssätt",
    classification: "Klassifikation",
    clearing_number: "Clearingnummer",
    click_here: "klicka här",
    close: "Stäng",
    collateral: "Säkerheter",
    company_customer: "Företagskund",
    company_registration: "Företagsregistrering",
    complete_order: "Genomför order",
    complete_registration_to_see_history:
      "Slutför hela registreringen för att se din portfölj, transaktioner och historik",
    complete_registration_to_see_payment_options:
      "Slutför hela registreringen för att se dina betalningsalternativ",
    confirm_bankid: "Bekräfta med BankID", // TODO: "Dev copy" Behåll om du gillar det
    contact_us_we_get_back:
      "Hör av dig till oss så återkommer vi så snart vi kan.",
    continue: "Gå vidare",
    "cost correction": "Korrigering",
    cost_fee: "Avgifter",
    cost: "Kostnad",
    costs: "Kostnader",
    country: "Land",
    coupon_percentage: "Kupongränta",
    coupon: "Kupong",
    create_account: "Skapa konto",
    create: "Skapa",
    current_events: "Mina händelser",
    current_investments: "Aktuella investeringar",
    customer_diligence_account_opening: "Kundkännedom & Kontoöppning",
    customer_registration: "Kundregistrering",
    delete_draft: "Radera utkast",
    deposit_money: "Sätt in pengar",
    deposit: "Insättning",
    deposits: "Insättningar",
    details: "Detaljer",
    development: "Utveckling",
    direct_loans: "Direktlån",
    distribution: "Fördelning",
    document: "Dokument",
    documents: "Dokument",
    download_file: "Ladda ner fil",
    draft: "Utkast",
    drag_n_drop: "Klicka eller släpp dina filer här",
    drop_files_here: "Släpp filer här",
    dropdown_effect_title: "Hur påverkar avgifterna investeringens avkastning?",
    dropzone_text: "Klicka eller dra din fil hit", //en: Click ore drop your file here
    economic_situation: "Ekonomisk situation",
    edit_draft: "Redigera utkast",
    edit: "Redigera",
    effective_annual_rate: "Effektiv årsränta",
    endowment: "Resultat",
    enter_your_ssn: "Fyll i ditt personnummer",
    even_posts: "Poster om",
    "exchange-rate": "Senaste kurs",
    "exchange partially": "Konvertering",
    executed: "Genomförd order",
    facts_and_fees: "Fakta & avgifter",
    fetching_securities: "Hämtar investeringar...",
    final_balance: "Försäkringsvärde slutbalans",
    form_sent: "Formuläret är skickat",
    from_account: "Från konto", // used in withdrawal view'
    from: "Från",
    given: "Vald period",
    go_back: "Tillbaka",
    goals_economic_situation: "Lämplighetsprövning", // inside scentence for broker advice // TODO: move this when gets back from translators
    goals_excess: "överskottslikviditet", // inside scentence for broker advice // TODO: move this when gets back from translators
    goals_speculative: "medel som är öronmärkta för spekulativa investeringar", // inside scentence for broker advice // TODO: move this when gets back from translators
    good_to_know: "Bra att veta",
    help: "Hjälp", // For the help button
    hide_info: "Dölj information",
    high_lowercase: "hög",
    high: "Hög",
    holding: "Innehav",
    hours: "Timmar",
    how_do_you_wanna_use_the_service: "Hur vill du använda tjänsten?",
    how_it_works: "Så funkar det",
    icon: "ikon",
    id: " Id", // eg id on a security
    if_it_does_not: "Om det inte gör det,",
    image_or_video: "Bild eller Video",
    image: "Bild",
    increase_return:
      "Sprid dina risker och höj din avkastning tillsammans med andra investerare",
    increment_in_step: `% i steg om ${v.number}`, // v.number = a step value. ex 0.5 - used in secondary market trade order view
    interest_interval: "Ränteintervall",
    interest_payments: "Ränteutbetalningar",
    interest: "Ränta",
    invest_now: "Investera nu",
    invest: "Investera",
    investment_amount: "Investeringsbelopp",
    investment: "Investering",
    investments: "Investeringar",
    isin: "ISIN",
    issue_date: "Emissionsdag",
    issuer: "Emittent",
    key_points: "Nyckelpunkter",
    knowledge_test: "Kunskapstest",
    lang_en: "Engelska",
    lang_fi: "Finska",
    lang_no: "Norska",
    lang_se: "Svenska",
    last_coupon: "Senaste ränteutbetalningen",
    last_maturity_date: "Sista förfallodag",
    latest_messages: "Senaste meddelanden",
    latest_rate: "Senaste kurs",
    learning_material: "Inläsningsmaterial",
    leave_form_msg:
      "Är du säker på att du vill avsluta? Informationen du fyllt i kommer i så fall inte att sparas.",
    legal_advisor: "Legal rådgivare",
    liquid_funds: "Likvida medel",
    live: "Öppen",
    loading: "Laddar",
    login_button: "Logga in med BankID",
    login_error_alert: `Något gick fel. Vänligen kontrollera ditt personnummer och försök igen. Om felet kvarstår, kontrollera att du har skapat ett konto.`,
    login_registration_in_progress: "Registreringen är inte färdig",
    login_window_opens_automatically:
      "Inloggningsfönstret kommer öppnas automatiskt.",
    logout_modal_button: "Fortsätt vara inloggad",
    logout_modal_desc: "Du kommer snart att loggas ut på grund av inaktivitet.",
    logout_modal_title: "Du kommer snart att loggas ut",
    token_has_expired: "Du har loggats ut",
    token_has_expired_desc: "Du har loggats ut på grund av inaktivitet.",
    logout: "Logga ut",
    low: "Låg",
    "management fee other ifa":
      "Rörlig avgift - Tidigare försäkringsdistributör",

    market_value: "Marknadsvärde",
    market: "Marknad",
    maturity_date: "Förfallodag",
    maturity_in: "Förfaller",
    max_volume: "Max volym",
    medium_high: "medelhög",
    medium: "Mellan",
    messages: "Meddelanden",
    min_investment: "Min. investering",
    min: "Min", // Like in Minimum investment
    minimize: "Minimera", // as in hide all posts
    minimum_investment: "Minsta investering",
    monthly: "Månadsvis",
    "months-3": "3 mån",
    my_accounts: "Mina konton",
    my_pages: "Mina sidor",
    "my-portfolio": "Min portfölj",
    name: "Namn",
    need_adequate_funds:
      "För att köpet ska kunna genomföras behöver du ha tillräckligt med kontanter på ditt konto senast",
    need_help: "Behöver du hjälp?",
    new_deposit: "Premieinsättning",
    next_coupon: "Nästa ränteutbetalning",
    next_question: "Nästa fråga",
    next_step: "Nästa steg",
    next: "Nästa",
    no_acccounts_available: "Inga konton tillgängliga",
    no_data_found_in_period: "Ingen data hittad inom den valda perioden",
    no_data_found: "Vi hittade ingen data",
    no_data_yet: "Det finns ingen data att visa än.",
    no_events_at_the_moment: "Just nu finns inga aktuella händelser.",
    no_holdings_at_this_point:
      "Just nu har du inga investeringar i din portfölj.",
    no_holdings: "Inget innehav",
    no_investments_on_primary_market:
      "Det finns inte tillgängliga investeringar. Kontakta din investeringsrådgivare för mer information.",
    no_subject: "Inget ämne",
    nominal_amount: "Nominellt Belopp",
    notify_at_opening: "Meddela vid öppning",
    nprof: "Jag önskar att bli klassificerad som icke-professionell kund",
    observe_terms: "OBS! Alla villkor är indikativa",
    observe_values: "OBS! Alla värden är indikativa",
    of: "av",
    ongoing_payments: "Pågående betalningar",
    other_costs: "Övriga kostnader",
    overview: "Översikt",
    page_not_found_check_address:
  <Fragment>
    Vi hittade tyvärr inte sidan <strong>{v.path}</strong>. Kolla att du
    skrivit in rätt adress.
  </Fragment>
    ,
    page_not_found: "Hittade tyvärr inte sidan",
    page: "Sida",
    part_plural: `Del ${v.number}`, // Ex Part 2
    part_singular: `Del ${v.number}`, // Ex Part 1
    pay_now: "Betala in",
    pay: "Betala",
    payment_options: "Betalningsalternativ",
    payment_reference: "Betalningsreferens",
    payment: "Betalning", // Used in PayCard (event cards - ongoing investments)
    percent: "Procent",
    percent_today: "% idag",
    period: "Period",
    pnote: "Direktlån",
    policy_information: "Försäkringsinformation",
    policy: "Försäkring",
    policy_application: "Försäkringsansökan",
    prepare_investment: "Förbered investering",
    previous: "Föregående",
    price: "Kurs",
    purchase_price: "Inköpskurs",
    primary_market: "Primärmarknad",
    private_customer: "Privatkund",
    proceeds: "Avkastning",
    product_page: "Prod. sida",
    prof: "Jag önskar att bli klassificerad som professionell kund",
    profile_settings: "Mina uppgifter",
    promissory_note: "Direktlån",
    proxy: "Fullmakt",
    purpose: "Syfte",
    quarterly: "Kvartalsvis",
    questions_plural: `${v.number} frågor`, // Ex: 6 frågor
    questions_singular: `${v.number} fråga`, // Ex: 1 fråga
    rate: "Kurs", // Val av kurs, ex 98%
    read_more: "Läs mer",
    rec_period: "Rek. period",
    received_assignment: "Du har fått tilldelning om",
    records_of_min_investment: `Poster om ${v.number}`, // number = blocksize with currency ex "10 0000 SEK"
    redo_test: "Gör om testet",
    reduction_of_return: "Reducering av avkastning",
    register_for_autogiro: "Registrera autogiro",
    register_new_company: "Registrera nytt företag",
    registration: "Registrering",
    reload_page: "Ladda om sidan",
    rem_annual_interest_rate: "Åters. ränta",
    rem_running_time: "Åters. löptid",
    remaining_return: "Återstående avkastning",
    remaining_running_duration: "Löptid återstående",
    remove_file: "Ta bort",
    remove_row: "Ta bort rad",
    remove: "Makulering",
    repayment: "Återbetalning",
    report_error: "Rapportera fel",
    report: "Rapport",
    result_since_start: "Utveckling sedan start",
    result_this_year: "Utveckling hittills i år",
    result: "Resultat",
    rows: "rader",
    save_and_continue: "Spara och fortsätt",
    save_draft: "Spara utkast",
    save_test: "Spara testet",
    save: "Spara",
    saved_documents: "Sparade dokument",
    saving: "Sparar",
    secondary_updated_info:
      "Vi vill göra dig uppmärksam på att informationen som presenteras i emissionens marknadsföringsmaterial utarbetades i samband med emissionens lansering på primärmarknaden. Informationen i vardera emissionens marknadsföringsmaterial kan ha ändrats och slutliga fullständiga villkor finns i produktens slutliga term sheet.",
    seconds: "Sekunder",
    sector: "Bransch",
    securities: "Värdepapper",
    security_code: "Kod",
    security: "Investering",
    see_full_report: "Se fullständig rapport",
    see_more_info: "Se mer information",
    see_investments: "Se alla investeringar",
    select: "Välj",
    select_error_increment: `Välj en procentsats i steg om ${v.number}`, // number = a step value. ex 0.5
    select_error: "Vänligen välj ett eller flera alternativ",
    sell_entire_holding: "Sälj hela innehavet",
    sell: "Sälj",
    semi_annually: "Halvårsvis",
    send: "Skicka",
    sending: "Skickar",
    settlement_date: "Handelsdatum", //NEW!
    "share-of-portfolio": "Andel av portfolio",
    share: "Andel",
    shares: "Andelar",
    show_all: "Visa alla", // As in show all posts
    show_less: "Visa mindre",
    show_more_items: "Visa fler", // As in show more posts
    show_more: "Visa mer",
    show_productpage: "Visa produktsida",
    signatory: "Firmatecknare",
    signatories: "Firmatecknare",
    skip_to_main_content: "Hoppa till huvudinnehållet", // For a11y button to jump to the main content
    sold_out: "Slutsålt",
    sold: "Såld", // When a sell trade order has status executed
    something_went_wrong: "Något gick fel.",
    ssn_placeholder: "ååååmmddxxxx",
    start_test: "Starta testet",
    startpage: "Startsidan",
    status: "Status",
    structured_product: "Strukturerad Produkt",
    subscription_completed_info:
      "Din investeringsorder är mottagen. Du kan följa din investering via Mina konton.",
    subscription_completed_info_direct:
      "The Fully Arcade AB kommer inom kort att skicka ut en elektronisk teckningsanmälan till dig via Scrive där du kan signera via BankID.",
    subscription_completed: "Investeringsorder genomförd",
    subscription_completed_direct: "Intresseanmälan inskickad",
    subscriptions: "Teckningsanmälan",
    "subsription rights issue (c)": "Teckningsrätt",
    "subsription rights issue": "Teckningsrätt",

    sum: "Summa",
    summary_in_percent: "Sammanfattning i procent",
    summary: " Summering",
    terms: "Villkor",
    test_aptitude: "Passandeprövning",
    test_knowledge: "Kunskapstest", // dublicate of knowledge_test
    test_objective: "Lämplighetsprövning",
    test_terms: "Villkor & signering",
    tests_heading: "Investerartester", // kyc
    to_account: "Till konto", // used in withdrawal view
    to: "Till",
    tools: "Verktyg",
    total_fees: "Totala avgifter",
    // 'total_including_courtage': 'Totalt belopp* (inklusive avgifter)',
    including_courtage: "(inklusive avgifter)",
    "total_post-cost_fee": "Total avkastning efter avgifter",
    "total_pre-cost_fee": "Total avkastning före avgifter",
    total_value: "Totalt värde",
    total_volume: "Total volym",
    total: "Totalt belopp",
    trade_amount: "Belopp",
    trade_amount_new: "Belopp",
    transaction_fee: "Transaktionskostnad",
    transaction: "Transaktionstyp",
    transactions_and_notes: "Historiska transaktioner och notor",
    transactions: "Transaktioner",
    trustee: "Agent",
    try_again: "Försök igen",
    type: "Typ",
    upcoming_investments: "Kommande investeringar",
    update_report: "Uppdatera rapport",
    update: "Uppdatera", // Ex Money origin modal trigger button in /deposit
    upload_confirmation:
  <Fragment>
    Vi har tagit emot ditt document. Du kan see dina upladdade document
    under {v.link}
  </Fragment>
    , // link = profilinställningar
    use_of_capital: "Kapitalanvändning",
    value: "Värde",
    video: "Video",
    volume: "Volym",
    waitlist: "Väntelista",
    withdraw_money: "Ta ut pengar",
    withdrawal: "Uttag",
    "yearly-rate": "Årsränta",
    "years-1": "1 år",
    yield: "Avkastning",
    yield_percent: "Avkastning %",
    yield_currency: `Avkastning ${v.currency}`,
    you_will_see_messages_here:
      "Här kommer du snart att kunna se dina meddelanden.",
    your_account_balance: "Din kontobalans efter kommande köp:",
    your_answers: "Dina svar",
    your_documents: "Dina dokument",
    your_portfolio: "Din portfölj",
    zone_currency: "SEK",

    // Holding types
    bond: "Företagsobligationer",
    promnote: "Direktlån",
    "pe fund": 'Private Equity / Venture Capital',
    stock: "Onoterade Aktier",
    listedstock: "Noterade aktier",
    fund: "Fonder",
    currency: "Valuta",
    structured: "Strukturerade produkter",

    //Markets
    se: "Sverige",
    no: "Norge",
    fi: "Finland",

    // Sidebar helper
    email: "Epost",
    tel: "Tel",

    // Date and time
    bullet: "Allt på slutdagen",
    date: "Datum",
    day_plural: "dagar",
    day_singular: "dag",
    day_string_plural: `${v.number} dagar`, // ${v.number} will be replace with a plural number, ex -10 or 1.5
    day_string_short_plural: `${v.number} dagar`, // ${v.number} will be replace with a plural number, ex -10 or 1.5
    day_string_short_singular: `${v.number} dag`, // ${v.number} will be replace with a signular number, ex -1 or 0.5
    day_string_singular: `${v.number} dag`, // ${v.number} will be replace with a signular number, ex -1 or 0.5
    days: "Dagar",
    in_days_string_plural: `Om ${v.number} dagar`, // ${v.number} will be replace with a plural number, ex -10 or 1.5
    in_days_string_singular: `Om ${v.number} dag`, // ${v.number} will be replace with a signular number, ex -1 or 0.5
    minutes: "Minuter",
    month_string_plural: `${v.number} månader`, // ${v.number} will be replace with a plural number, ex -10 or 1.5
    month_string_short_plural: `${v.number} mån`, // ${v.number} will be replace with a plural number, ex -10 or 1.5
    month_string_short_singular: `${v.number} mån`, // ${v.number} will be replace with a signular number, ex -1 or 0.5
    month_string_singular: `${v.number} månad`, // ${v.number} will be replace with a signular number, ex -1 or 0.5
    month: "mån",
    time_left_to_open: "Tid kvar till öppning",
    year_string_plural: `${v.number} år`, // ${v.number} will be replace with a plural number, ex -10 or 1.5
    year_string_singular: `${v.number} år`, // ${v.number} will be replace with a signular number, ex -1 or 0.5
    year: "år",

    // Money
    millions_string: `${v.number} M${v.currency}`, // ex: 123 MSEK, ${v.number} - value in millions, ${v.currency} - the current currency
    percent_per_year: `${v.number}% per år`, // number = procent. ex 10% per year

    // Login view
    become_customer: "Bli kund",
    choose_account_login: "Välj konto att logga in med",
    companies: "Företag",
    login_company_registration_in_progress:
      "Företagsregistreringen är inte färdig",
    login_failed_message: "Inloggning misslyckades",
    login: "Logga in",
    private: "Privat",
    terms_conditions: "Villkor & signering",
    welcome_info:
      "Ditt konto är nu redo att användas. Om du har några frågor kan du alltid klicka på frågetecknet nere till höger.",
    choose_login_option: "Välj inloggningssätt",
    bankid_mobile: "Mobilt Bankid",
    bankid_device: "BankID med dosa",
    login_to_slow:
      "Du var för långsamt med att logga in. Du behöver slutföra inlogget inom 2min från det att du började logga in.",
    bankid_title: "Öppna BankID på din enhet",
    bankid_title_new: "Öppna BankID på din mobil",
    bankid_open: "Öppna BankID på denna enhet",
    bankid_start: `Du kan nu öppna BankID och signera dig mot ${v.platform} genom att scanna QR-koden ovan.`,
    bankid_button: "Öppna BankID på den här enheten",
    bankid_abort: "Avbryt signering",
    bankid_error:
  <>
    Något gick fel under inloggningen. Vänligen försök igen. Om problemet
    kvarstår, vänligen kontakta oss på{" "}
    <a
      href="mailto:kundservice@hubins.com"
      target="_blank"
      rel="noreferrer"
    >
      kundservice@hubins.com
    </a>{" "}
    eller{" "}
    <a target="_blank" href="tel:0317971939" rel="noreferrer">
      031-797 19 39
    </a>
  </>
    ,
    registered_user_title: "Välkommen",
    login_user_text: 'Hos oss kan du logga in tryggt och säkert med ditt svenska BankID. Tänk ändå på att alltid hantera dina uppgifter varsamt.',
    here_login: 'Här loggar du in',
    registered_user_text:
      "Du har redan ett konto hos Hubins. Klicka på knappen nedan för att komma till dina sidor:",

    // Friendly reminder
    fr_description:
      "Det finns några steg kvar att göra innan du kan använda tjänsten. Du kan genomföra stegen när du vill.",
    fr_title: "Du är igång!",
    yearly_reminder: "Årlig påminnelse",

    // Logout view
    logging_out: "Loggar ut",
    logout_description: "Omdirigerar dig till startsidan",
    logout_title: "Du är utloggad",

    // Deposit / Withdrawal view
    // New
    deposit_details: "Deposit details",
    "deposit - sale": "Deposit - Sale",
    deposit_details_text:
      "Specify account and what amount you want to deposit.",
    // 'money_origin_title': 'Where do your securities or money come from?',
    // 'money_origin_text': 'Are your answers given at the time of registration still correct?',
    // 'demand_assesment_text': `You completed your Demand assessment ${v.date}. If your demand has changed since this date, please update your current demand.`,

    // Old: check if need's to be removed
    autogiro_agree: "Jag godkänner villkoren för betalning via autogiro",
    autogiro_sub_description:
  <Fragment>
    Om du önskar ändra ditt föranmälda konto, ber vi dig kontakta din
    mäklare eller vår kundservice på{" "}
    <a className="link" href="tel:0317971936">
      031-797 19 36
    </a>
  </Fragment>
    ,
    autogiro_subtitle: "Ditt föranmälda konto",
    autogiro_terms: "Villkor för betalning via Autogiro",
    autogiro_title: "Du har inte föranmält något konto för autogiro",
    available_for_withdrawal_info:
      "I din kapitalförsäkring behöver du ha likvida medel tillgängliga för att täcka framtida kostnader, motsvarande ca 6 månader.",
    available_for_withdrawal: "Tillgängligt för uttag",
    bank_transfer_bankgiro: "Bankgiro",
    bank_transfer: "Banköverföring",
    bank: "Bank",
    bankgiro_number: "Bankgironummer",
    bankgiro_payment: "Bankgirobetalning",
    bankgiro: "Bankgiro",
    conducts_deposit: "Genomför insättning",
    deposit_payment_option_desc:
      "Om du önskar att byta betalningsalternativ går det att göra under dina profilinställningar.",
    deposit_payment_settings_link: "Ändra betalningsalternativ",
    direct_debit: "Autogiro",
    documents_terms: "Användaravtal & Villkor",
    sign_bankid: "Signera med BankID",
    sign_open_new_window: "Signering öppnas i nytt fönster",

    withdrawal_reason: "Andledning till uttag",
    withdrawal_reason_investment: "Investering",
    withdrawal_reason_saving_in_institute: "Sparande i annan institution",
    withdrawal_reason_consumption: "Konsumtion",
    withdrawal_reason_other: "Övrigt",
    confirm_withdrawal: "Bekräfta uppgifter",

    choose: "Välj", // choose a reason for withdrawel
    conduct_withdrawal: "Genomför uttag",
    debpay: "Skuldbetalning",
    direct_debit_success_ingress:
      "Vi kommer inom 1–3 bankdagar att dra pengar från det konto du har angett vid registreringen för autogiro. Därför är det viktigt att det finns tillgängligt med likvida medel på ditt konto.",
    direct_debit_success_title: "Överföring via autogiro registrerad",
    faq_a1:
      "Beroende på vilket betalningsalternativ du väljer samt vilken bank du har, varierar tiden det tar för insättningen att synas på ditt konto. Från och med att du initierat en insättning via din bank, tar det 1–3 bankdagar för pengarna att vara synliga i ditt konto.",
    faq_q1: "När syns pengarna på mitt konto?",
    FAQ: "FAQ",
    market_buy_success_ingress:
  <Fragment>
    <p>
      Din order kommer vara aktiv i 1 månad. Vi meddelar dig om matchande
      säljare hittas.
    </p>
    <p>Följ orderns status på mina konton.</p>
  </Fragment>
    ,
    market_buy_success_title: "Köporder registrerad",
    market_sell_success_ingress:
  <Fragment>
    <p>
      Din order kommer vara aktiv i 1 månad. Vi meddelar dig om matchande
      köpare hittas.
    </p>
    <p>Följ orderns status på mina konton.</p>
  </Fragment>
    ,
    market_sell_success_title: "Säljorder registrerad",
    new_withdrawal: "Nytt uttag",
    wd_info_header: "Uttag sker till ditt föranmälda konto",
    wd_info_text:
  <Fragment>
    Om du önskar att ändra ditt föranmälda konto, vänligen kontakta
    kundtjänst på <a href="tel:031-797 19 36">031-797 19 36</a>
  </Fragment>
    ,
    withdrawal_accept_terms_text:
      "Jag bekräftar och godkänner att pengarna går till mitt föranmälda konto.",
    withdrawal_reason_text: "Fyll i anledning till uttag",
    withdrawal_success_ingress: `Ditt uttag har registerats på ditt konto hos ${v.app_name}.Du kommer se pengarna på ditt föranmälda konto inom 1–3 bankdagar. Tiden kan variera beroende på vilken bank du har.`,
    withdrawal_success_title: "Uttag genomfört",

    dropdowneffect_desc:
      "Vänligen notera att vi endast tar hänsyn till courtaget i denna illustration",

    // Transfer Instructions
    bank_details: "Bankuppgifter",
    "bank fee": "Bank Fee",
    list_of_banks_text:
      "Här finns länkar till de vanligaste bankerna i Sverige",
    payment_amount: "Inbetalningsbelopp",
    pi_from_bank: "Notera att betalningen görs till SIP NORDIC AB.", // payment instruction from what bank the money will be tranfered to
    ti_info: `När allt är klart kommer insättningen synas på ditt konto ${v.name} `, // name - the account name
    // 'ti_step1': 'Logga in på din bank',
    // 'ti_step2': 'Välj ny betalning och betalningssätt',
    // 'ti_step3': 'Fyll i bankuppgifterna och referensnumret',
    // 'ti_step4': 'Genomför betalningen',
    payment_instructions:
      "Gå till din internetbank och genomför insättning med uppgifterna nedan.",

    // Money origin (also used in income source in registration)
    previous_investments: "Tidigare investeringar",
    inheritance_gift: "Arv/Gåva",
    property_sale: "Fastighetsförsäljning",
    insurance_payout: "Försäkringsutbetalning",
    dividend: "Utdelning",
    salary_pension: "Lön/Pension",
    lottery_gaming: "Lotteri/Spel",
    corporate_sales: "Företagsförsäljning",
    share_dividend_from_own_company: "Aktieutdelning från eget bolag",
    profits_daily_operations: "Vinster från den dagliga verksamheten",
    "profit correction": "Korrigering av utbetalning",
    "deposit correction": "Deposit correction",
    dividend_associated_comp: "Aktieutdelning från intressebolag",
    money_origin_other: "Annat",

    income_source: "Varifrån kommer dina pengar eller värdepapper?",
    company_income_source:
      "Varifrån kommer företagets pengar eller värdepapper?",
    income_source_text: "Stämmer fortfarande dina svar från registreringen?",

    // Buy /sell market bonds
    available_for_purchase: "Disponibelt",
    available_for_purchase_amount: "Disponibelt belopp",
    blocksize: "Handlas i poster om",
    bonds_amount: "Antal handelsposter",
    stocks_amount: "Antal aktier",
    company_bonds_amount: "Antal obligationer",
    buy_bonds: "Köp obligationer",
    choose_account: "Välj konto",
    choose_amount: "Anskaffningsvärde",
    choose_investment: "Välj investering",
    info_about_fund: "Information om obligationen",
    info_about_stock: `Information om den onoterade aktien`,
    order_validity_terms: "Notera att uppluppen ränta kan tillkomma.",
    order_validity: "Orderns giltighet",
    ordering: "Orderläggning",
    remaining_rate: "Ränta återstående ",
    sell_bonds: "Sälj innehav",
    "value adjustment": "Value adjustment",
    "Transaction fee": "Transaction fee",
    "transaction charge ql1": "Transaktionskostnad, Quantum",
    "transaction charge ql2": "Transaktionskostnad, Utkontraktering",
    transaction_fee_terms_tooltip:
  <Fragment>
    <p>
      När man köper värdepapper på andrahandsmarknaden så kan det tillkomma
      en upplupen ränta för köparen ifall värdepapperet är en obligation
      eller liknande räntebärande värdepapper. Beloppet motsvarar den ränta
      som ännu inte betalats ut sedan den senaste ränteutbetalningen och
      räknas fram till datumet då affären genomförs. Räntan utgår från det
      nominella beloppet, alltså till pris 100%.
    </p>
    <p>
      <strong>
        I praktiken betyder det att köparen betalar för den upplupna räntan
        men får hela ränteutbetalningen vid nästa tillfälle, inklusive den
        upplupna räntan.
      </strong>
    </p>
    <p>
      Övriga avgifter hos produktbolaget såsom säljavgift etc. kan
      tillkomma. Vänligen se villkoren för den specifika investeringen för
      mer information.
    </p>
  </Fragment>
    ,
    transaction_fee_terms:
      "Notera att uppluppen ränta samt eventuella övriga avgifter hos produktbolaget kan tillkomma.",

    //Alerts
    autogiro_clarification:
      "Vid autogirobetalning kommer pengar per automatik dras från ditt förvalda konto.",
    autogiro_error_alert: `Något har hänt med din autogirokoppling till oss för kontot "${v.name}".Vänligen kontakta oss på 031 - 797 19 36 eller ${v.email} `,
    autogiro_waiting_alert: `Vi bearbetar just nu din autogiroansökan för "${v.name}"`,
    cancel_autogiro_modal:
      "För att avsluta ditt autogiro ber vi dig vänligen kontakta din bank eller logga in på din internetbank",
    cancel_autogiro: "Avsluta autogiro permanent",
    not_all_signatories_signed: "Alla firmatecknare/huvudmän har ännu inte skrivit på fullmakten. Under profilinställningar kan du se vilka som har slutfört registreringen.",
    yearly_reminder_alert: "Lorem ipsum Du behöver fylla i de årliga testerna",

    "asset management fee": "Rörlig avgift",

    // Error messages
    correct_data_not_loaded:
      "Vi kan för tillfället inte hämta korrekt data. Testa att logga ut och logga in igen.",
    bad_network:
      "Det verkar vara något fel på din internetanslutning. Testa att ladda om sidan.",
    checkbox_error: "Obligatoriskt för att kunna gå vidare",
    dropdown_error: "Välj ett av alternativen",
    empty_rows: "Ingen data tillgänglig", // Ex when rows missing in expandable table
    error_amount: "Fyll i ett belopp",
    error_email: "Fyll i en korrekt epost-adress",
    error_empty: "Fyll i det tomma fältet",
    error_form: "Vänligen titta igenom formuläret efter felmeddelanden",
    error_max_value: `Värdet måste vara max ${v.number} `, // Number is the provided max value
    error_min_value: `Vänligen ange ett högre belopp`, // Number is the provided min value
    error_not_enough_data: "Otillräcklig data", // Example diagram that has not data
    error_options: "Välj ett av alternativen",
    error_number: "Endast siffror är tillåtna",
    error_pattern: `Fyll i ett giltigt värde.Giltiga tecken är ${v.pattern} `, // pattern is the provided pattern
    error_step: `Fyll i ett giltigt tal.Närmsta giltiga tal är ${v.value} `, // pattern is the provided pattern, eg 10 or 15, instead of 10.2 or 15.8
    error_underaged: "Du måste tyvärr vara äldre än 18",
    input_error: "Fyll i det tomma fältet",
    no_documents: "Inga dokument hittades", // is shown in the <Documents> component ex on /settings#documents
    ssn_validation_error: "Felaktigt personnummer (ÅÅÅÅMMDDXXXX)",
    org_validation_error: "Felaktigt organisationsnummer (XXXXXX-XXXX)",
    standard_error_message:
  <>
    Något har tyvärr gått fel men försök gärna igen om en liten stund. Om
    felet kvarstår får ni gärna maila{" "}
    <a href={`mailto:${v.email}`}>{v.email}</a> eller ringa oss på{" "}
    <a href={`tel:${v.tel}`}>{v.tel}</a>.
  </>
    ,

    // Statuses
    status_reg_comp: "Registrerar företag",
    status_fetching_holdings: "Hämtar innehav",

    // Dashboard
    type_buy: "Köp", // type of trade order
    type_wdsec: "Uttag",
    wdsec_title: "Uttagsbegäran mottagen",
    wdsec_text: "Er uttagsbegäran är mottagen av försäkringsbolaget",
    wdsec_title_2: 'Uttag genomfört',
    wdsec_text_2: 'Uttag genomfört.',
    type_surr: 'Avslutsbegäran',
    surr_text: 'Er avslutsbegäran är mottagen',
    surr_text_2: 'Avslut genomförd',
    surr_title: 'Avslut pågår',
    surr_title_2: 'Avslut genomförd',

    type_deposit: "Insättning", // type of trade order
    type_sell: "Sälj", // type of trade order
    type_withdrawal: "Uttag", // type of trade order
    type_add: "Inlägg",
    type_s: 'Sälj',
    type_b: 'Köp',
    type_exch: 'Konvertering',
    type_pre: 'Insättning',
    type_presec: 'Inflytt',
    type_wd: 'Uttag',
    "type_premium security": "Inflytt av värdepapper",

    await_delivery_deposit: "Inväntar insättning",
    buy_course: "Köpkurs",
    depositcard_directdebit_info:
      "Beloppet kommer dras från ditt konto inom några dagar. Se till att det finns täckning på ditt konto.",
    directdebit_payment: "Autogirobetalning",
    executed_deposit: "Insättning genomförd",
    payment_info: "Betalningsinfo",
    sell_course: "Säljkurs",
    total_amount_brokerage: "Totalt belopp (inklusive avgifter)",
    total_amount: "Totalt belopp",
    view_advice: "Se förslag",
    view_productpage: "Se produktsida",

    // Event Cards
    premium: "Premieinsättning - Kontanter",
    buy_open_title: "Order mottagen",
    buy_open_text: "Er order är mottagen av försäkringsbolaget.",
    buy_accepted_title: "Order mottagen",
    buy_accepted_text:
      "Er order är mottagen av försäkringsbolaget.",
    buy_executable_title: "Order skickad",
    buy_executable_text: "Er order är skickad.",
    buy_executed_title: "Genomförd",
    buy_executed_text: "Er order är genomförd",
    buy_executed_withoutdate_title: "Köp genomfört",
    buy_executed_withoutdate_text:
      "Ditt köp är genomfört och har bokats in i din försäkring",
    sell_accepted_title: "Order mottagen",
    sell_accepted_text:
      "Er order är mottagen av försäkringsbolaget.",
    buy_sent: 'Er order är skickad.',
    buy_sent_title: 'Order skickad',
    sell_executable_title: "Order skickad",
    sell_executable_text: "Er order är skickad.",
    sell_executed_title: "Order genomförd",
    sell_executed_text:
      "Er order är genomförd.",
    sell_executed_withoutdate_title: "Pengar mottagna",
    sell_executed_withoutdate_text:
      "Er order är genomförd.",
    add_open_title: "Inflytt registrerad",
    add_open_text: "Din begäran om infyltt är registrerad",
    add_accepted_title: "Inflytt registrerad",
    add_accepted_text: "Din begäran om infyltt är registrerad",
    add_executed_title: "Inflytt inbokad",
    add_executed_text: "Din inflytt är registrerad",
    add_executed_withoutdate_title: "Din inflytt är registrerad",
    add_executed_withoutdate_text: "Din inflytt är registrerad",
    premium_open_title: "Premieinbetalning",
    premium_open_text:
      "Du har uppgett att du kommer att genomföra en inbetalning till din försäkringspolicy",
    premium_accepted_title: "Premieinbetalning",
    premium_accepted_text:
      "Du har uppgett att du kommer att genomföra en inbetalning till din försäkringspolicy",
    premium_executed_title: "Betalning genomförd",
    premium_executed_text:
      "Din betalning är utfärdad och inbokad på din försäkring",
    premium_executed_withoutdate_title: "Betalning genomförd",
    premium_executed_withoutdate_text:
      "Din betalning är utfärdad och inbokad på din försäkring",
    premium__security_pending_title: "Inflytt pågår",
    premium__security_executed_withoutdate: "Inflytt genomförd",
    presec_sent_to_counterpart: 'Er inflyttsbegäran är skickad till motpart',
    premium__security_cancelled_title: "Inflytt avbruten",
    premium__cancelled_title: "Insättning avbruten",
    premium_security_accepted_withoutdate_text: "-",
    premium_security_executable_withoutdate_text:
      "Din inflytt behandlas just nu.",
    premium_security_executed_withoutdate_text:
      "Din inflytt är genomförd och inbokad i din försäkring.",
    premium_security_open_withoutdate_text: "Din inflytt behandlas just nu.",
    premium_security_executable_text: "Din inflytt behandlas just nu.",
    // Interest indicator
    ii_up: "Positiv", // Utveckling: Positiv
    ii_down: "Negativ", // Utveckling: Negativ
    ii_middle: "Ingen utveckling", // Utveckling: Ingen utveckling

    // Yearly Checkup
    yc_title: "Vi behöver uppdatera dina svar",
    yc_desc_warning:
  <>
    <p>
      <strong>
        Lorem impsum Enligt lagen xxxx så måste vi varje år be dig svara på
        lorem ipsum för att lorem ipsum.
      </strong>
    </p>
    <p>
      <strong>Vänligen ta dig tid att fylla i alla svar nedan.</strong>
    </p>
  </>
    ,
    yc_desc_locked:
  <>
    <p>
      <strong>
        Lorem ipsum Du måste göra detta innan du kan fortsätta använda
        tjänsten. Enligt lagen xxxx så måste vi varje år be dig svara på
        lorem ipsum för att lorem ipsum.
      </strong>
    </p>
  </>
    ,

    yc_kt_action: "Gör kunskapstestet",
    yc_kt_desc:
      "Lorem ipsum. Du behöver genomföra kunskapstestet på nytt. Om du inte klarar testet på första försöket får du självklart en chans till!",
    yc_kt_error:
      "Du behöver gå igenom och klara hela kunskapstestet för att kunna gå vidare.",
    "edit-org-form": "Redigera hela formuläret",
    yc_locked_risk_text:
  <>
    Om du önskar att ändra ditt svar. Vänligen kontakta {v.platform} på{" "}
    {v.email}
  </>
    ,
    yc_success_title: "Tack för dina svar!",
    yc_success_desc:
  <p>
    Vi har uppdaterat din profil med dina svar. Du kan nu fortsätta att
    använda platformen precis som innan. Nästa datum då svaren kommer behöva
    uppdateras igen är {v.date}.
  </p>
    ,
    do_later: "Stäng och gör senare",
    market_value_start: "Försäkringsvärde startbalans",
    dev_during_period: "Utveckling under perioden",
    access_law: "Tillgångsslag",
    whole_period: "Hela perioden",
    endowment_title: "Utveckling per år (SEK)",

    total_insurance_fees: "Totala försäkringsavgifter",
    total_insurance_fees_ql: "Total ersättning till Quantum Leben AG",
    total_insurance_fees_hubins:
      "Total ersättning till Hubins för utkontrakterade tjänster åt Quantum",
    total_insurance_fees_hubins_1:
      "Total ersättning till Hubins för försäkringsdistribution",
    total_insurance_fees_other:
      "Total ersättning till tidigare försäkringsdistributör, ej Hubins",
    total_insurance_from_third_party:
      "Total ersättning som Hubins har mottagit från annan tredjepart än Quantum Leben AG (Ex. från fondbolag eller annat produktbolag)",
    total_insurance_to_third_party:
      "Total ersättning som Hubins har tillhandahållit till tredjepart",
    one_time_fee_total: "Engångsavgifter: Transaktionsavgift",
    one_time_fee_ql: "Varav ersättning till Quantum Leben AG",
    one_time_fee_hubins:
      "Varav ersättning till Hubins för utkontrakterade tjänster åt Quantum",
    running_fixed_cost_total: "Löpande avgifter: Fast avgift",
    running_fixed_cost_ql: "Varav ersättning till Quantum Leben AG",
    running_fixed_cost_hubins:
      "Varav ersättning till Hubins för utkontrakterade tjänster åt Quantum",
    running_variable_cost_total: "Löpande avgifter: Rörlig avgift",
    running_variable_cost_ql: "Varav ersättning till Quantum Leben AG",
    running_variable_cost_hubins:
      "Varav ersättning till Hubins för utkontrakterade tjänster åt Quantum",
    running_variable_cost_hubins1:
      "Varav ersättning till Hubins för försäkringsdistribution",
    running_cost_riskpre_total:
      "Löpande avgifter: Riskpremie (från 65 års ålder)",
    running_cost_riskpre_ql: "Varav ersättning till Quantum Leben AG",

    total_yield_without_fees:
      "Total avkastning utan försäkringsavgifter eller försäkringskostnader",
    total_fees_and_costs: "Totala försäkringsavgifter och försäkringskostnader",
    total_yield_after_fees_and_costs:
      "Total avkastning efter totala försäkringsavgifter och försäkringskostnader",
    insurance_fees: "Försäkringsavgifter och försäkringskostnader",
    yearly_report: "Årsrapport",
    fetching_yearly_report: "Hämtar årsrapport",
    yearly_report_header_title: "Logga in för att se din årsrapport",

    insurance_value_start: "Försäkringsvärde vid beskattningsårets ingång",
    premiums_start: "Premier januari-juni",
    premiums_half_end: "50% av premier juli-december",
    capital_base: "Kapitalunderlag",
    interest_rate: "Räntesats",
    tax_base: "Skatteunderlaget",
    tax_rate: "Skattesats",
    actual_tax_rate_before: "Faktisk beskattning innan nedsättning",
    total_paid_tax_during_year:
      "Total betald stämpelskatt under beskattningsåret samt överskjutande belopp från föregående år",
    total_paid_tax_down:
      'Total betald stämpelskatt för nedsättning (Anges under "Övriga upplysningar" i deklarationen)',
    total_tax_for_coming_year: "Överskjutande belopp till senare år",
    premiums_start_tooltip:
      "Premier som betalas under januari till juni räknas med i sin helhet.",
    tax_table_title: `Skatt och Deklaration - Utländsk kapitalförsäkring`,
    tax_table_text:
  <div>
    <p className="clarification">
      Hubins vill även göra dig uppmarksam på följande:
      <br />
      Vänligen notera att dessa beräkningar är indikativa och att Hubins inte ger någon form av skatterådgivning. Denna kalkyl tar endast hänsyn till den aktuella kapitalförsäkring som du har hos Quantum Leben AG. Vare sig Quantum Leben AG eller Hubins AB kan garantera riktigheten i beskattningen, det är alltid ert eget ansvar att deklarera korrekt i din deklaration till Skatteverket. Nedan beräkning tar heller inte hänsyn till hela din skattesituation. 
    </p>
  </div>
    ,
    tax_table_text_1:
  <>
    <p className="clarification">
      En försäkringstagare med en utländsk kapitalförsäkring ansvarar själv för att redovisa skatteunderlaget i sin inkomstdeklaration. För privatpersoner är kapitalunderlaget i de flesta fall förtryckt i deklarationen, men det är alltid den enskildes ansvar att säkerställa att uppgifterna stämmer. För företag kan det finnas särskilda omständigheter som påverkar hur kapitalförsäkringen ska redovisas, vilket innebär att den uträkning som presenteras inte alltid är korrekt.
    </p>
    <p className="clarification">
      Skatteunderlaget för en kapitalförsäkring beräknas enligt skatteverket i två steg. Först beräknas ett kapitalunderlag och därefter ett skatteunderlag. Beskattningsåret för avkastningsskatt är alltid kalenderår och kapitalunderlaget motsvarar försäkringens värde vid beskattningsårets ingång ökat med årets premiebetalningar. Kapitalunderlaget ska ökas med alla överföringar från försäkringstagare till försäkringen, dvs. både inbetalningar i form av likvida medel och tillgångar. Premier som betalas under januari till juni räknas med i sin helhet och premier som betalas under juli till december räknas bara med till hälften. Skatteunderlaget beräknas genom att kapitalunderlaget multipliceras med statslåneräntan den sista november, året innan beskattningsåret, plus 1 procentenhet. Skatteunderlaget ska dock beräknas till lägst 1,25 procent av kapitalunderlaget (det finns således ett ”golv” för statslånelånräntan avseede den här typen av beräkning). Det innebär att om statslåneräntan vid utgången av november är lägre än 0,25 procent ska kapitalunderlaget multipliceras med 1,25 procent. Skatteunderlaget avrundas nedåt till ett helt hundratal kronor. Läs mer om detta på Skatteverkets hemsida: 
      <a
        href="https://skatteverket.se/privat/skatter/forsakringar/avkastningsskattpautlandskalivforsakringar/kapitalforsakringfran2012.4.3684199413c956649b57c4b.html"
        target="_blank"
        rel="noreferrer"
      >
        {" "}
            skatteverket.se{" "}
      </a>
    </p>
    <p className='clarification'>
      Stämpelskatten Stamp duty i Liechtenstein anses som utländsk punktskatt och man har rätt att sätta ned den svenska avkastningsskatten med utländsk skatt som försäkringstagaren själv eller försäkringsföretaget har betalat för försäkringen. Den debiterade stämpelskatten i försäkringen framgår oftast i form av en initial stämpelskatt på 0,15 % av den inbetalade premien i likvida medel, eller 0,3 % vid inflytt av värdepapper, samt en stämpelskatt för återköp på 0,15 % av det återköpta likvidbeloppet och en stämpelskatt för återköp av värdepapper på 0,30 %. För att göra en nedsättning av avkastningsskatt anges det beloppet för nedsättning under övriga upplysningar i din inkomstdeklaration. Läs mer om detta på Skatteverkets hemsida:
      <a
        href="https://skatteverket.se/privat/skatter/forsakringar/avkastningsskattpautlandskalivforsakringar/utlandskkapitalforsakring.4.3684199413c956649b57c4b.html"
        target="_blank"
        rel="noreferrer"
      >
        {" "}
            skatteverket.se{" "}
      </a>
    </p>
  </>
    ,
    table2_header_1: `I deklarationen anges skatteunderlaget, vilket för ${v.year} är`,
    table2_header_2:
      'Under "Övriga upplysningar" i deklarationen begärs nedsättning med betald stämpelskatt om',
    tax_table_title2: "Summering av uppgifter som ska deklareras*",
    tax_table_title3: "Indikativa uträkningar*",
    premiums_half_end_tooltip:
      "Premier som betalas under juli till december räknas bara med till hälften.",
    capital_base_tooltip:
      "Kapitalunderlaget motsvarar försäkringens värde vid beskattningsårets ingång ökat med årets premiebetalningar, enligt beskrivningen ovan. Både inbetalningar i form av likvida medel och tillgångar räknas med.",
    interest_rate_tooltip:
      "Räntesats = Statslåneräntan vid utgången av november kalenderåret närmast före beskattningsåret + 1 procentenhet. Om statslåneräntan vid utgången av november är lägre än 0.25 procent ska kapitalunderlaget multipliceras med 1.25 procent, vilket innebär att räntesatsen i det här fallet blir 1.25%",
    tax_base_tooltip:
  <>
    Skatteunderlaget = Kapitalunderlaget * Räntesats. Skatteunderlaget
    avrundas nedåt till ett helt hundratal kronor. Läs mer om detta på
    Skatteverket hemsida:{" "}
    <a
      href="https://www4.skatteverket.se/rattsligvagledning/edition/2020.15/339070.html"
      target="_blank"
      rel="noreferrer"
    >
      https://www4.skatteverket.se/rattsligvagledning/edition/2020.15/339070.html
    </a>
  </>
    ,
    actual_tax_rate_before_tooltip:
      "Avkastningsskatt innan nedsättning motsvarar 30 % av skatteunderlaget och speglar den faktiska skatten som betalas för kapitalförsäkringen, om nedsättning kan fås för all stämpelskatt.",
    total_paid_tax_down_tooltip:
  <>
    Du har rätt att sätta ned den svenska avkastningskatten med utländsk
    skatt. Skulle den utländska skatten överstiga den svenska
    avkastningsskatten så finns möjlighet att spara det överskjutande
    beloppet till ett senare år. Vänligen observera att vare sig Quantum
    Leben AG eller Hubins kan garantera att någon nedsättning kommer att
    beviljas. Läs mer om detta på Skatteverkets hemsida:{" "}
    <a
      href="https://www4.skatteverket.se/rattsligvagledning/edition/2021.18/339082.html"
      target="_blank"
      rel="noreferrer"
    >
        https://www4.skatteverket.se/rattsligvagledning/edition/2021.18/339082.html
    </a>
  </>
    ,
    total_tax_for_coming_year_tooltip:
  <>
    Spara det överskjutande beloppet till ett senare år för att räkna av det
    mot avkastningsskatt på samma försäkring. Läs mer om detta på
    Skatteverkets hemsida:{" "}
    <a
      href="https://skatteverket.se/privat/skatter/forsakringar/avkastningsskattpautlandskalivforsakringar/nedsattavkastningsskatt.4.1a098b721295c544e1f800027816.html"
      target="_blank"
      rel="noreferrer"
    >
      https://skatteverket.se/privat/skatter/forsakringar/avkastningsskattpautlandskalivforsakringar/nedsattavkastningsskatt.4.1a098b721295c544e1f800027816.html
    </a>
  </>
    ,
    important: "Viktigt",
    important_information: "Viktig information",
    report_intro_text:
  <>
    I rapporten får ni som kund information om kostnader och avgifter som
    har samband med kapitalförsäkringen och ni kan när som helst begära en
    uppdelning per del genom att kontakta oss på{" "}
    <a
      href="mailto:kundservice@hubins.com"
      target="_blank"
      rel="noreferrer"
    >
      kundservice@hubins.com
    </a>{" "}
    eller{" "}
    <a target="_blank" href="tel:0317971939" rel="noreferrer">
      031-797 19 39
    </a>{" "}
    eller vid övriga frågor om uträkningar osv.
  </>
    ,
    report_intro_text_ql:
  <p className="clarification">
    <strong>Viktig information</strong>
    <br />I rapporten får ni som kund information om kostnader och avgifter
    som har samband med kapitalförsäkringen. Ni kan när som helst begära en
    uppdelning per del, eller om ni har frågor kring rapporten, genom att
    kontakta oss på{" "}
    <a
      href="mailto:kundservice@quantumleben.se"
      target="_blank"
      rel="noreferrer"
    >
      kundservice@quantumleben.se
    </a>{" "}
    eller{" "}
    <a href="tel:0317971939" target="_blank" rel="noreferrer">
      031-797 19 39
    </a>
    .
  </p>
    ,
    report_intro_text_ql_report:
  <p className="clarification">
    <strong>Viktig information</strong>
    <br />
    Ni kan när som helst begära en uppdelning per del, eller om ni har
    frågor kring rapporten, genom att kontakta oss på{" "}
    <a
      href="mailto:kundservice@quantumleben.se"
      target="_blank"
      rel="noreferrer"
    >
      kundservice@quantumleben.se
    </a>{" "}
    eller{" "}
    <a href="tel:0317971939" target="_blank" rel="noreferrer">
      031-797 19 39
    </a>
    .
  </p>
    ,
    report_intro_text_hubins:
  <p className="clarification">
    <strong>Viktig information</strong>
    <br />I rapporten får ni som kund information om kostnader och avgifter
    som har samband med kapitalförsäkringen och ni kan när som helst begära
    en uppdelning per del genom att kontakta oss på{" "}
    <a
      href="mailto:kundservice@quantumleben.se"
      target="_blank"
      rel="noreferrer"
    >
      kundservice@hubins.com
    </a>{" "}
    eller{" "}
    <a target="_blank" href="tel:0317971939" rel="noreferrer">
      031-797 19 39
    </a>
    . Vänligen läs disclaimern till respektive del av årsrapporten för mer
    information om beräkningarna bakom årsrapporten och dess innehåll. Ni
    kan även kontakta oss vid övriga frågor om uträkningar.
  </p>
    ,
    report_intro_disclaimer:
  <>
    <p className="clarification">
      Hubins vill även göra dig uppmarksam på följande: <br />
      När pengar investeras i olika investeringar förväntas investeringarna
      att utvecklas i värde under investeringsperioden. Dessa investeringar
      förväntas också medföra vissa avgifter och kostnader. Vissa av dessa
      avgifter och kostnader är relaterade till investeringsprodukten och
      vissa andra är relaterade till försäkringstjänsten. Avgifter och
      kostnader relaterade till försäkringstjänsten kan vara i form av både
      engångsavgifter, fasta eller rörliga avgifter.
    </p>
  </>
    ,
    report_intro_disclaimer_1:
  <>
    <p className="clarification">
      Vänligen notera att förväntad avkastning ger inte garanti till
      framtida avkastning. Det finns också olika sätt att mäta avkastningen
      på en investering. Investerade pengar kan både öka och minska i värde
      och alla investeringar kunder gör sker på egen risk. Värderingen av
      alla tillgångar och investeringar görs av försäkringsbolaget Quantum
      Leben AG. Hubins AB påverkar inte tillgångarnas eller investeringarnas
      värde. Hubins AB ger inte heller någon form av skatterådgivning och en
      försäkringstagare som har en utländsk kapitalförsäkring får själv
      redovisa skatteunderlaget i sin inkomstdeklaration.
    </p>
    <p className="clarification">
      Årsrapporten har för avsikt att informera om utvecklingen av diverse
      investeringar i försäkringen samt ge en översiktlig bild av alla
      försäkringsrelaterade avgifter och kostnader som har tillkommit under
      året.
    </p>
    <p className="clarification">
      Årsrapporten inleds med en graf som visualiserar den övergripande
      avkastningen under den valda tidsperioden. Här anges avkastningen i
      procent på den vertikala axeln och den horisontella axeln visar
      tidsperioden.
    </p>
  </>
    ,
    insurance_fees_table_2_intro: `Hur har kapitalförsäkringens avkastning påverkats av försäkringsavgifterna och försäkringskostnaderna under vald period?`,
    unit_price: "Pris per enhet",
    repurchase_value: "Totalt återköpsvärde",
    "promissory note": "Direktlån",
    janoojun: "Insättningar Jan-Jun",
    jultodec: "Insättningar Jul-Dec",
    "premium security": "Premieinsättning - Värdepapper",
    charge: "Transaktionsavgift",
    tax: "Stämpelskatt",
    "management fee ifa": "Rörlig avgift - Försäkringsdistribution",
    "management fee ql": "Rörlig avgift - Försäkringsbolag",
    "management fee ql2": "Rörlig avgift - Utkontraktering",
    "fixed policy cost ql": "Fast avgift - Försäkringsbolag",
    "fixed policy cost ql2": "Fast avgift - Utkontraktering",
    "risk premium": "Riskpremie- Försäkringsbolag",
    "death payment": "Dödsutbetalning",
    "deposit - repayment": "Återbetalning - Likvider",
    "deposit - correction": "Korrigering",
    "dividend as shares": "Utdelning - Andelar",
    exchange: "Konvertering",
    "exchange (issue)": "Konvertering",
    expire: "Förfall",
    "fee correction": "Avgiftskorrigering",
    issue: "Emission",
    mature: "Återbetalning",
    "repayment correction": "Korrigering - Återbetalning",
    split: "Split",
    subscription: "Teckningsrätt",
    "subscription ©": "Teckningsrätt",
    "subscription rights issue ©": "Utfärdande - Teckningsrätt",
    surrender: "Avslut",
    "surrender sec": "Uttag - Värdepapper",
    "withdrawal - buy": "Köp - Kontanter",
    "withdrawal correction": "Korrigering - Uttag",
    "withdrawal sec": "Uttag - Värdepapper",
    "capital return": "Återbetalning",
    "issue - new": "Kommande investeringar",
    "issue - marketing": "Kommande investeringar",
    "issue - on sale": "Primärmarknad",
    "issue - closed": "Utkast",
    "issue - secondary market": "Andrahandsmarknad",
    "issue - matured": "Handla",
    password_length_validation: "Ditt lösenord måste minst inehålla 6 tecken",
    repeat_password_validation:
      "Det repeterade lösenorder matchar inte orginalet",
    common_error_validation: "Något gick fel. Vänligen försök igen.",
    password: "Lösenord",
    email_password_validation_error:
      "Användarnamnet eller lösenordet är felaktigt",
    tfa_validation_error: "Koden är fel, eller så har sessionen gått ut. Testa igen eller starta om login",
    activation_success: "Du kan nu logga in med ditt nya lösenord",
    activation_success2: "Nu kan du logga in för att se din årsrapport",
    to_login: "Till login",
    insurance_fees_section_1:
  <>
    <p>
      Hubins vill även göra dig uppmarksam på följande: <br />
      Den totala försäkringsavgiften och försäkringskostnaden under perioden
      beräknas som summan av alla engångsavgifter i form av
      transaktionsavgifter, den fasta löpande avgiften, den rörliga löpande
      avgiften samt riskpremien som har belastat kapitalförsäkringen
      under vald period enligt ovan. För information om tidigare år vänligen kontakta
      kundservice.
    </p>
  </>
    ,
    insurance_fees_section_2:
  <>
    <p>
      Dessa avgifter fördelas sedan i form av ersättning till samtliga
      parter, exempelvis försäkringsbolaget Quantum Leben AG eller
      försäkringsdistributören Hubins AB. Dessa avgifter och kostnader samt
      ersättningar presenteras i tabellen ”Försäkringsavgifter och
      försäkringskostnader”. Här informeras det även att Hubins AB inte
      mottar någon ersättning från annan tredjepart än Quantum Leben AG (ex.
      från fondbolag eller annat produktbolag) samt att Hubins AB inte
      tillhandahåller någon ersättning till annan tredjepart. Eventuella
      ersättningar till tidigare försäkringsdistributörer (ej Hubins) anges
      också i denna tabell.
    </p>
    <p>
      Vänligen notera att ovan beskrivning av försäkringsavgifter och
      försäkringskostnader är generellt och de faktiska
      försäkringsavgifterna och försäkringskostnaderna kan komma att skilja
      sig i procent från ovan.
    </p>
    <p>
      Tabellen nedanför har för avsikt att illustrera hur
      försäkringsavgifter och försäkringskostnader reducerar avkastningen
      för vald period enligt ovan. Här visas även den totala avkastning före och efter årets
      försäkringsavgifter och försäkringskostnader. Denna illustration ska
      sätta de totala försäkringsavgifterna och försäkringskostnaderna i
      relation till investeringens avkastning samt förtydliga hela avgift-
      och kostnadsstrukturen.
    </p>
  </>
    ,
    the_currency: "Valuta",
    username: "Användarnamn",
    liquid_account: "Likvidkonto",
    debt_account: "Skuldkonto",
    current_holdings: "Aktuellt innehav",
    // For debugging - don't translate
    test: "Test se",
    warning: "Varning",
    wish_to_change_form:
      "Om du går tillbaka för att justera tidigare svar, kommer svaren du angett i på den här sidan inte att sparas.",
    reason_for_withdrawal: "Anledning till uttag",
    "policy_application_imported_&_kyc":
      "Försäkringsuppdatering och Kundkännedom",
    forgot_password: "Glömt lösenord?",
    reset_password_error:
  <p>
    Något gick fel, vänligen försök igen eller kontakta oss på{" "}
    <a
      href="mailto:kundservice@quantumleben.se"
      target="_blank"
      rel="noreferrer"
    >
      kundservice@quantumleben.se
    </a>{" "}
    eller{" "}
    <a target="_blank" rel="noreferrer" href="tel:0317971939">
      031-797 19 39
    </a>
  </p>
    ,
    request_reset: "Återställ lösenord",
    reset_success: "Kolla din epost",
    'reset_password_success': 'Klart!',
    reset_success_text:
      "Om du har en epost registrerad hos oss ska du ha fått en länk för att återställa ditt lösenord i din inbox. Om mailet inte kommer fram kan du behöva kolla din skräppost.",
    reset_password_success_text:
      "Nu kan du logga in med ditt nya lösenord",
    reset_password_header: "Glömt lösenord",
    yearly_checkup: 'Årsuppdatering',
    repeater_error: 'Vänligen fyll i de tomma fälten',
    percent_error_message: 'Värdet får inte vara större än 100 eller mindre än 0',
    'hubins_backoffice': 'Hubins Backoffice',
    'hubins_compliance': 'Hubins Compliance',
    'quantum_backoffice': 'Quantum Backoffice',
    'quantum_compliance': 'Quantum Compliance',
    'a_policy_1': 'Policy information',
    'a_policy_2': 'Demand and needs Assesment',
    'a_policy_3': 'Know your customer',
    'a_policy_4': 'Connections',
    'a_policy_5': 'Transactions',
    'a_policy_6': 'Source of Funds',
    'a_policy_7': 'Enhanced due diligence',
    'a_policy_verdict': 'Policy application approval ',

    'handle_sources': 'Hantera källor',
    'sources': 'Källor',
    'new_source': 'Ny källa',

    'sof_company_profits': 'Vinst från bolag',
    'sof_company_profits_investment': 'Vinst från tidigare investeringar',
    'sof_company_property': 'Försäljning av fastighet',
    'sof_company_sale_company': 'Försäljning av bolag',
    'sof_company_dividend': 'Utdelning',
    'sof_company_other': 'Annat',
    'sof_company_direct_investments': 'Direkt investeringar',
    'sof_issue_stock': 'Aktieemission',
    'sof_issue_bonds_loan': 'Obligationsemission/Lån',

    'sof_salary': 'Överskott lön',
    'sof_profits_investment': 'Vinst från investering',
    'sof_property': 'Försäljning av fastighet',
    'sof_company_financing': 'Finansiering från moderbolag',
    'sof_dividend': 'Utdelning från eget bolag',
    'sof_sale_company': 'Försäljning av företag',
    'sof_inheritance_gift': 'Arv, gåvor eller annat',
    'sof_direct_investments': 'Direktinvesteringar',

    'proof_verified': 'Källa bevisad',
    'proof_provided': 'Bevis skickat för granskning',
    'proof_not_provided': 'Källa ej bevisad',
    'insufficient_transaction_amount': 'Summan av de valda källorna uppnår ej det valda beloppet av transaktionen. Du kan gå tillbaks och ändra värdet på transaktionen om du önskar sätta in ett lägre belopp.',
    'deposit_instructions_title': 'Insättningsuppgifter',
    'deposit_instructions_text': 'Logga in på din internetbank och genomför insättning med uppgifterna nedan.',
    'investment_stock': 'onoterade aktier',

    'sof_status_denied': 'Avfärdad',
    'sof_status_proof_needed': 'Bevis krävs',
    'sof_status_complete': 'Godkänd',
    'sof_status_locked': 'Låst',
    'sof_status_': '',

    'transaction_type_pre': 'Premie insättning',

    'stock_2_status': 'Genomförd',
    'stock_6_status': 'Under behandling',
    'stock_10_status': 'Tilldelning',
    'stock_8_status': 'Väntelista',
    'stock_4_status': 'Order mottagen',
    'stock_5_status': 'Order mottagen',
    'stock_2_text': 'Investering genomförd - Aktier inbokade',
    'stock_6_text': ' ',
    'stock_10_text': 'Ni har fått tilldelning - Se till att ha likvid tillgängligt',
    'stock_8_text': 'Väntelista - Ingen tilldelning',
    'stock_4_text': 'Order mottagen - Inväntar tilldelning',
    'stock_5_text': 'Order mottagen - Inväntar tilldelning',
    'stock_1_text': 'Ni har fått tilldelning - Se till att ha likvid tillgängligt',
    'stock_1_status': 'Tilldelning',

    insurance_number: 'Försäkringsnummer',
    company: 'Bolag',
    product: 'Produkt',
    unlist: 'Onoterad',
    stock_list: 'Noterad aktie',
    stock_unlist: 'Onoterad aktie',
    investment_knowledge_test_title: 'Passandeprövning - Onoterade aktier',
    tfa_code: 'Kod',
    tfa_verify: 'Verifiera kod',
    tfa_required_title: 'Aktivera tvåstegsverifiering',
    tfa_required_text: 'Vi kommer inom kort kräva att alla aktiverar tvåstegsverifiering för att kunna fortsätta använda tjänsterna.',
    tfa_required_text_2: 'Gå till inställningar för att aktivera tvåstegsverifiering.',
    settings: "Inställningar",
    user_documents: 'Användarens dokument',
    portfolio_documents: 'Portföljens dokument',
    client: 'Klient',
    ssn_org_num: 'Pers/Org-nummer',
    distributor: 'Distributör',
    updated_at: 'Senast uppdaterad',
    view: 'Se mer',
    a_summary: 'Summering',
    register_insurance: 'Kapitalförsäkring',
    ongoing_insurance: 'Påbörjad registrering',
    register_insurance_title: 'Teckna en ny kapitalförsäkring',
    register_insurance_title_new: 'Kapitalförsäkringen för alla investeringar',
    register_insurance_text: 'Hos oss kan du samla och hantera hela ditt innehav under ett och samma tak. Hubins säkerställer att du har behov av en kapitalförsäkring och om du tjänar på att hålla dina investeringar hos oss.',
    register_insurance_summary_text: 'Här får du en översikt av den pågående försäkringsansökan och de olika stegen som behöver genomföras för att kapitalförsäkringen ska öppnas. Att teckna en försäkring är kostnadsfritt. Avgifter tillkommer först i samband med att du gör din första insättning.',
    almost_done_registration: 'Utforska nya investeringar, öppna kapitalförsäkring och flytta in dina befintliga investeringar för att samla allt på en plattform.',
    started_registration: 'Påbörjad registrering',
    start_registration_desc: 'Få tillgång till vår flexibla kapitalförsäkringslösning - optimerad för onoterade och alternativa investeringar.',
    p_kt_start: "Kunskapstest",
    personnel_registration: 'Kundkännedom och signering av fullmakt',
    holdings: 'Innehav',
    code: 'Kod',
    solve_price: 'Lösenpris',
    option: 'Optioner',
    p_presale: 'Förköpsinformation',
    cryptocurrency: 'Kryptotillgångar',
    faq_stock_order_title: "Viktiga Upplysningar",
    faq_crypto_order_title: "Viktiga Upplysningar",
    faq_crypto_order:
    <>
      <p>Nedan pajdiagram har för avsikt att illustrera hur avgifter reducerar den förväntade avkastningen under investeringsperioden. Tabellen nedanför visar vilka typer av avgifter denna investering medför samt vad den totala förväntade avkastningen för hela investeringsperioden beräknas vara efter avgifter.</p>
      <p>Vänligen notera att beräkningarna som resulterade i tabellen och pajdiagrammet är baserade på ett antal antaganden samt begränsningar. Exempel på där antaganden är gjorda är förväntad avkastning och investeringsperiod. Hubins reserverar sig även för eventuella felräkningar.</p>
      <p>När du investerar dina pengar i kryptotillgångar förväntas ditt kapital att öka eller minska i värde under investeringssperioden. Du förväntas också betala vissa avgifter för att kunna ta del av den försäkringsbaserade investeringsprodukten. Vissa av dessa avgifter är relaterade till investeringsprodukten och vissa andra är relaterade till försäkringstjänsten. Dessa avgifter kan vara i form av både engångsavgifter, löpande avgifter, fasta eller rörliga avgifter. Här ser du ett estimat på hur mycket den totala förväntade avkastningen kommer att påverkas av totala avgifter som kommer att tas ut i investeringsprodukten och/eller i försäkringen. Detta ska hjälpa dig att sätta de totala avgifterna i relation till investeringens förväntade avkastning samt hjälpa dig att förstå hela avgiftstrukturen.</p>
      <p>Vänligen notera att beräkningarna baseras på den enskilda investeringens förväntade avkastning och att investeringen behålls i 1 till 10 år. Den beräknade kapitaltillväxten uppnås därmed med hjälp av ränta-på-ränta-effekten där den årliga förväntade avkastningen först omvandlas till en månatlig räntesats. Ränta-på-ränta-effekten resulterar sedan i en exponentiell utveckling av dina investerade pengar där du även får avkastning på den avkastning som genererats föregående månad. Detta skiljer sig avsevärt från en linjär utveckling där avkastningen i kronor är lika stor varje månad.</p>
      <p>I detta exempel växer investeringen med en viss förväntad avkastning per år, vilket innebär att även den rörliga försäkringsavgiften och försäkringens riskpremie samt produktens förvaringsavgift växer i samma takt under investeringsperioden. Dessa avgifter baseras här på investeringens värde vid slutet av varje månad men dras ej från försäkringens ingångsvärde för nästa månad. Vänligen observera att produktavgifter i form av engångsavgifter (köpavgift) baseras i exemplet på investeringens pris.</p>
      <h5>Produktavgifter</h5>
      <p>Engångsavgifter: Ersättning till Tjänsteleverantör, i detta fall Safello AB, vid köp av kryptotillgångar. Engångsavgifter tas vanligtvis ut i början av din investering.</p>
      <p>Löpande avgifter: Produktens förvaringsavgift om 0.5% per år som dras månadsvis från saldot av kryptotillgångarna. Tjänsteleverantören, i detta fall Safello AB, erhåller 100% av denna avgift.</p>
      <h5>Försäkringsavgifter</h5>
      <p>Engångsavgifter: Försäkringens transaktionsavgifter för köp och sälj av värdepapper. Hubins AB erhåller 30% av den transaktionsavgift som betalas till Quantum Leben AG för outsourcing av Quantums tjänster. Denna avgift tas ut i början av din investering vid köp.</p>
      <p>Löpande avgifter: Den rörliga avgiften syftar till den rörliga ersättningen som baseras på försäkringsvärdet vid slutet av varje månad och dras kvartalsvis. Hubins AB erhåller i sin roll som försäkringsdistributör 30% av de rörliga ersättningen som betalas till Quantum Leben AG. Hubins AB erhåller även ytterligare 35% av den rörliga ersättningen som betalas till Quantum Leben AG för utkontraktering av Quantums tjänster som inkluderar men är inte begränsad till kundtjänstärenden, support avseende försäkringsadministration, förberedelse för hantering av försäkringsfall, IT-support etc.</p>
      <p>Den fasta avgiften syftar till en fast årlig ersättning som dras kvartalsvis från försäkringsvärdet. Denna avgift motsvarar 1800 svenska kronor och tillkommer endast om du väljer att investera i eller hålla onoterade obligationer och direktlån samt noterade obligationer. Den fasta avgiften baseras här på den andel som investeringen utgör i hela försäkringen. Vid investeringar i utländska valutor omvandlas denna avgift enligt dagens kurs. Uträkningarna tar därför inte hänsyn till eventuella valutakursändringar under investeringsperioden. Hubins AB erhåller 61,11% av den fasta avgiften som betalas till Quantum Leben AG för utkontraktering av vissa administrativa tjänster till Hubins.</p>
      <p>För kunder över 60 år tillkommer även en riskpremie enligt dokumentet “Kostnader och Avgifter - Hubins och Quantum Leben” för att täcka kapitalförsäkringens återbetalningsskydd. Vid inträffande av försäkringsfall kommer denna avgift att finansiera den extra procenten av försäkringsvärdet som betalas ut vid dödsfall. I uträkningarna baseras riskpremien på den livförsäkrades ålder per idag.</p>
      <p>Vänligen notera att förväntad/prognostiserad avkastning inte ger någon garanti för framtida avkastning och att dessa beräkningar enbart kan stämma med verkligheten/faktiska utfall om alla förutsättningar och antaganden uppfylls. Pajdiagramet och tabellen är till för att hjälpa dig sätta de totala produkt- och försäkringsavgifterna i relation till den förväntade avkastningen för hela investeringsperioden och visa hur dessa påverkar avkastningen. Investerade pengar kan både öka och minska i värde och det finns aldrig några garantier för att du/ni återfår hela eller delar av det investerade kapitalet. Alla investeringar du/ni som kund gör sker på din/er egen risk.</p>
    </>,
    abort_purchase: 'Avbryt order',
    investment_cryptocurrency: 'Kryptotillgångar',
    other: 'Övrigt',
    crypto: 'Kryptotillgångar',
    end_date: 'Slutdatum',
    cufe: 'Förvarningsavgift',
    va: "Värdejustering",
    subs: 'Teckningsrätter',
    subsc: 'Teckningsrätter',
    feecorr: 'Avgiftskorrigering',
    bankfee: "Bankkostnad",
    transfer: 'Överför',
    easier_declaration: 'Enklare deklaration',

    //approval types
    approval_section_type_sof: 'Källa till kapital',
    approval_section_type_portfolio: 'Policy application approval ',
    sof_statuses_unproven: 'Du har inte skickat in några bevis för denna källa',
    sof_statuses_unverified: 'Vi granskar just nu dina inskickade bevis för denna källa',
    sof_statuses_verified: 'Dina inskickade bevis har granskats och godkänts för denna källa',
    hubins_contact_information:
    <p>
      <strong>Telefon: </strong><a href="tel:0317971936">031 - 797 19 36</a><br/>
      <strong>E-post: </strong><a href="mailto:kundservice@hubins.com">kundservice@hubins.com</a>
    </p>,
    payment_receiver: 'Betalningsmottagare',
    verdict_status_waiting_on_third_party: 'Waiting on third party',
    verdict_status_approved: 'Approved',
    verdict_status_denied: 'Rejected',
    verdict_status_comment: 'Comment',
    verdict_status_waiting_on_management: 'Waiting on management',
    verdict_status_processing: 'Processing',
    verdict_status_suspected: 'Suspected',
    archived_approvals: 'Arkiv',
    completed_approvals: 'Färdigsställda',
    menu_settings: 'Inställningar',
    menu_home: 'Hem',
    menu_users: 'Användare',
    menu_approvals: 'Godkännanden',
    menu_forms: 'Formulär',
    menu_securities_cms: 'Securities CMS',
    menu_files: 'Filer',
    menu_my_clients: 'Mina kunder',
    creation_date: 'Skapad',
    priority: 'Prioritet',
    assigned: 'Tilldelad',
    due_date: 'Förfallodatum',
    available_amount: 'Tillgängligt belopp',
    used_amount: 'Använt belopp',
    created_at: 'Skapad',
    transaction_info: 'Orderinformation',
    choose_subaccount: 'Välj underkonto',
    portfolios: 'Portföljer',
    portfolio_information: 'Portföljinformation',
    pending_transactions: 'Pågående transaktioner',
    nin: 'Personnummer',
    profile_attributes: 'Profilattribut',
    portfolio_attributes: 'Portföljattribut',
    life_assured: 'Livförsäkrad',
    beneficiary: 'Förmånstagare',

    standard: 'Standard',
    urgent: 'Brådskande',
    waiting_on_third_party: 'Väntar på tredje part',
    waiting_on_management: 'Väntar på ledning',
    processing: 'Behandlas',
    suspected: 'Misstänkt',
    normal: 'Normal',
    approved: 'Godkänd',
    denied: 'Nekad',
    comment: 'Kommentar',
    completed: 'Färdigställd',
    in_progress: 'Pågående',
    pending: 'Väntande',
    uploaded_files: 'Uppladdade filer',
    leave_your_verdict: 'Lämna ditt beslut',
    due_date_label: 'Förfallodatum (valfritt)',
    priority_label: 'Prioritet (valfritt)',
    select_priority: 'Välj prioritet',
    verdict: 'Beslut',
    select_verdict: 'Välj beslut',
    new_comment: 'Ny kommentar',
    exit: 'Avsluta',
    archive: 'Arkivera',
    remove_from_archive: 'Ta bort från arkiv',
    verdicts: 'Beslut',
    be_the_first_to_leave_a_verdict: 'Var den första att lämna ett beslut',
    back: 'Tillbaka',
    aptitude_tests: 'Passandeprövning',
    approval_test_completed: 'Godkänt',
    approval_test_not_completed: 'Ej godkänt - Gå till testet',
    knowledge_test_cryptocurrency: 'Passandebedömning - Kryptotillgångar',
    knowledge_test_stock: 'Passandebedömning - Onoterade aktier',
    create_approval: 'Skapa godkännande',
    create_sof_approval: 'Skapa Källa till kapital godkännande',
    create_policy_approval: 'Skapa Portfölj godkännande',
    search: "Sök",
    choose_type: 'Välj typ',
    person: 'Person',
    organization_number: 'Organisationsnummer',
    national_identity_number: 'Personnummer',
    change_notes: 'Ändringsnoteringar',
    edit_form: 'Redigera formulär',
    save_changes: 'Spara ändringar',
    please_add_note_of_changes: 'Vänligen lägg till en notering om de ändringar du har gjort',
    pof: 'Bevis av källa',
    form_response: 'Formulärsvar',
    upload_pof: 'Ladda upp bevis',
    upload: 'Ladda upp',
    sow: 'Källa till förmögenhet',
    sof: 'Källa till kapital',
    event: 'Event',
    delete: 'Ta bort',
    download: 'Ladda ner',
    description: 'Beskrivning',
    startyear: 'Startår',
    endyear: 'Slutår',
    deposit_invalid_message: 'Du mäste välja ett Underkonto samt välja ett Emissionsdatum för att kunna fortsätta. Säkerställ att du har sparat dina ändringar innan du fortsätter.',
    confirm_delete_transaction: 'Är du säker på att du vill ta bort denna transaktionen? Detta går inte att ångra.',
    latest_approvals: 'Senaste godkännanden',
    menu_portfolios: 'Portföljer',
    open: 'Öppna',
    score: 'Poäng',
    view_history: 'Historik',
    pep_risk: 'PEP',
    tax_country_risk: 'Skattemässigt hemvist',
    nationality_risk: 'Medborgarskap',
    occupation_risk: 'Bransch',
    dd_policy_approval: 'Due diligence portfölj',
    dd_due_dilligence_crossover: 'Risk Tool',
    dd_sof: 'Due diligence källa till kapital',
    create_entry: 'Skapa inflytt',
    risk_tool: 'Riskverktyg',
    roles: 'Roller',
    tags: 'Taggar',
    empty_input_message: 'Vänligen fyll i detta fält för att kunna gå vidare',
    choose_source_of_funds_for_entry: 'Välj källa till kapital för inflytt',
    error_creating_entry: 'Något gick fel när inflytt skapades. Vänligen försök igen.',
    entry_completed: 'Inflytt genomförd',
    create_new_entry: 'Skapa ny inflytt',
    tof_approval: 'Inflyttspremie',
    dd_deposit: 'Due diligence insättning',
    user_sources_for_tof: 'Valda källor för inflytt',
    menu_pending_fa_transactions: 'Inflytt',
    pending_tof: 'Pågående inflytt',
    pending_tof_desc: 'Nedan kan ni se pågående inflyttningar som behöver slutföras i FA-back. När allt är inbokat i FA-back kan ni sedan slutföra inflyttningen här så att den försvinner från listan.',
    pending_fa: 'Inväntar booking i FA-back',
    choose_status: 'Välj status',
    portfolio: 'Portfölj',
    complete: 'Färdisställ',
    set_to_pending: 'Sätt som Väntande',
    are_you_sure_tof_approve: 'Är du säker på att du vill färdigställa inflyttningen? Om du ångrar dig kan du alltid sätta den tillbaka till väntande.',
    deposit_amount_risk: 'Insättningsbelopp',
    archived: 'Arkiverad',
    show_form_files: 'Visa dokument',
    inv_rep: 'Investeringsrepresentant',
    form: 'Formulär',
    choose_form: 'Välj formulär',
    sync_user: 'Synka användare',
    latest_update: 'Senaste uppdatering',
    your_sources: 'Dina källor',
    proven_sof: 'Bevisad källa till kapital',
    company_country_founded_risk: 'Land bolaget är grundat i',
    company_country_registered_risk: 'Land bolaget är registrerat i',
    manual_update_risk: 'Manuell uppdatering',
    risk_score: 'Riskpoäng',
    fma_risk: 'Riskverktyg',
    security_id: 'Security ID',
    security_name: 'Security namn',
    'sof_old_source': 'Källa till kapital (v1)',
    old_sof_desc: 'Tidigare angivna källor',
    business_outside_sweden: 'Verksamhet utanför Sverige',
    waiting_on_payment: 'Väntar på betalning',
    choose_units: 'Antal',
    choose_origin: 'Nuvarande bank/förvaltare',
    choose_origin_account: 'Nuvarande kontonummer',
    amount_required_err: 'Belopp krävs för att godkänna transaktionen',
    sub_account_required_err: 'Välj underkonto för att kunna godkänna transactionen',
    choose_sub_account: 'Underkonto',
    sub_account: 'Underkonto',
    deny_transaction: 'Neka transaktion',
    approve_transaction: 'Godkänn transaktion',
    origin: 'Ursprung',
    origin_account: 'Ursprungskonto',
    units: 'Enheter',
    edit_amount: 'Redigera belopp',
    used_sources_for_transaction: 'Valda källor för inflytt',
    tof: 'Inflyttar',
    batch_id: 'Batch ID',
    proxy_sent: 'Fullmakt skickad',
    waiting_on_counterpart: 'Väntar på motpart',
    investment_received: 'Investering mottagen',
    complete_batch: 'Färdigställ batch',
    save_contact_details: 'Spara kontaktuppgifter',
    contact_details: 'Kontaktuppgifter',
    choose_paid_premium_transaction: 'Välj paid premium transaktion',
    choose_transaction_date: 'Transaktionsdatum',
    'ready-for-execution': 'Färdig för exekvering',
    choose_unit_price: 'Pris per enhet',
    choose_paid_premium: 'Välj betald premium transaktion',
    transaction_date: 'Transaktionsdatum',
    choose_currency: 'Välj valuta',
    choose_exchange_rate: 'Växelkurs',
    premium_withdrawal_completed: 'Ej färdigställt i FA',
    hubins_status: 'Hubins status',
    quantum_status: 'Quantum status',
    batch_status: 'Batch status',
    transaction_singular: 'Transaktion',
    are_you_sure_you_want_to_complete_the_batch: 'Samtliga transaktioner kommer att färdigställas och bokas in i FA-back. Är du säker på att du vill färdigställa batchen?',
    accrued_interest_label: 'Upplupen ränta (Skriv "?" för att räkna ut automatiskt)',
    exchange_rate: 'Växelkurs',
    portfolio_exact_name: 'Portfölj (försäkringsnummer)',
    start_date: 'Startdatum',
    only_displays_latest_100_transactions: 'Visar endast de senaste 100 transaktionerna, om du behöver hitta specifika transaktioner filtrera på startdatum, slutdatum, typ eller portföljnummer.',
    choose_portfolio: 'Välj portfölj',
    choose_security: 'Välj värdepapper',
    create_paid_premium: 'Skapa betald premie',
    paid_premium_type: 'Premietyp',
    choose_paid_premium_type: 'Välj premietyp',
    paid_premium_type_required: 'Du måste välja en premietyp',
    portfolio_required: 'Du måste välja en portfölj',
    security_required: 'Du måste välja ett värdepapper',
    paid_premium_created: 'Betald premie skapad',
    trade_order: 'Handelsorder',
    reset_filters: 'Återställ filter',
    save_transfer_instructions: 'Spara överföringsinstruktioner',
    transfer_instructions: 'Överföringsinstruktioner',
    booking_status: 'Bokningsstatus',
    reserved_by_transaction: 'Reserverad',
    security_booked: 'Värdepapper inbokat',
    files: 'Filer',
    draft_transactions: 'Utkast',
    create_new_tof: 'Skapa ny inflytt',
    choose_sources: 'Välj källor',
    amount_is_required: 'Belopp krävs för att skapa transaktionen',
    security_is_required: 'Värdepapper krävs för att skapa transaktionen',
    clients: 'Kunder',
    orders: 'Ordrar',
    pending_fa_booking: 'Inväntar bokning i FA-back',
    pending_fa_tax_booking: 'Inväntar skatt bokning i FA-back',
    approval_status: 'Godkännande status',
    fetch_exchange_rate: 'Hämta växelkurs',
    failed_to_fetch_exchange_rate: 'Misslyckades att hämta växelkurs',
    fetch_unit_price: 'Hämta enhetspris',
    failed_to_fetch_unit_price: 'Misslyckades att hämta enhetspris',
    create_single_transaction_execution: 'Skapa orderprocess',
    create_batch: 'Skapa orderprocess',
    add_to_batch: 'Lägg till i batch',
    trade_amount_in_portfolio_currency: 'Handelsbelopp i portföljvaluta',
    cash_flow: 'Kassaflöde',
    under_review: 'Under granskning',
    no_policy_holder_found: 'Hitta ingen försäkringstagare på den valda portföljen',
    are_you_sure_you_want_to_delete_this_batch: 'Är du säker på att du vill ta bort denna batch?',
    contact_name: 'Kontaktperson',
    contact_email: 'Kontaktpersonens e-post',
    contact_phone: 'Kontaktpersonens telefonnummer',
    deny_batch: 'Neka batch',
    batch_is_not_ready_for_execution: 'Samtliga transaktioner måste vara färdiga för exekvering innan batchen kan färdigställas',
    initial_trade_amount: 'Anskaffningsvärde',
    trade_origin: 'Nuvarande bank/förvaltare',
    trade_origin_account: 'Nuvarande kontonummer',
    policy_approval: 'Försäkring',
    order_processes: 'Orderprocesser',
    order_process: 'Orderprocess',
    all_orders: 'Alla ordrar',
    fa_transactions: 'FA transaktioner',
    cash_balance: 'Obokade insättningar',
    received_premium_in_kind: 'Obokade inflyttar',
    create_premium_in_kind: 'Skapa Inflytt',
    unprocessed_orders: 'Ohanterade ordrar',
    order_type: 'Ordertyp',
    feed: 'Avgiftsinsättning',
    'fee receivable': 'Avgiftsinsättning',
    manage_sof: 'Hantera källor',
    manage_sof_description: 'Vänligen observera att denna vy inte tar hänsyn till insättningar som har statusen “Utkast”.',
    on_hold: 'Avvakta',
    waiting_on_client: 'Väntar på klient',
    denied_by_ql: 'Nekad av QL',
    waiting_on_tof_approval: ' Väntar på inflyttsgodkännande',
    waiting_on_ql_signature: 'Väntar på QLs signatur',
    signed_by_ql: 'Signerad av QL',
    sent_to_counterparty: 'Skickad till motpart',
    add_settlement_instructions: 'Lägg in settlement instruktioner i bank',
    waiting_on_settlement: 'Väntar på settlement',
    investment_received_ownership_confirmed: 'Investering mottagen / Ägande verifierat',
    card_on_hold: 'Under behandling',
    card_waiting_on_client: 'Väntar på kund',
    card_denied_by_ql: 'Under behandling',
    card_waiting_on_tof_approval: 'Under behandling',
    card_waiting_on_ql_signature: 'Under behandling',
    card_signed_by_ql: 'Under behandling',
    card_sent_to_counterparty: 'Skickad till motpart',
    card_add_settlement_instructions: 'Väntar på motpart',
    card_waiting_on_settlement: 'Väntar på motpart',
    card_investment_received_ownership_confirmed: 'Väntar på inbokning',
    card_approved: 'Investering mottagen',
    are_you_sure_you_want_to_delete_this_transaction: 'Är du säker på att du vill ta bort denna transaktionen?',
    create_order: 'Skapa order',
    max_value_exceeded: 'Vänligen ange ett lägre belopp',
    updated_by: 'Uppdaterat av',
    client_view: 'Kundvy',
    business_profile: 'Affärsprofil',
    risk_profile: 'Riskprofil',
    due_dilligence_checks: 'Due diligence-kontroller',
    fa_notes: 'FA anteckningar',
    internal_documents: 'Internt dokument',
    imported_risk_categorization_from_fa: 'Importerad riskkategorisering från FA',
    comments: 'Kommentarer',
    add_comment: 'Kommentera',
    reply: 'Svara',
    replying_to: 'Svarar på',
    broker: 'Mäklare',
    view_rights: 'Visningsrättigheter',
    are_you_sure_you_want_to_delete_this_comment: 'Är du säker på att du vill ta bort denna kommentar?',
    unhandled_orders: 'Ohanterade ordrar',
    pending_batches: 'Pågående orderprocesser',
    all_batches: 'Alla orderprocesser',
    registration_completed: 'Registrering genomförd',
    registration_pending: 'Registrering väntar',
    inactive: 'Inaktiv',
    portfolio_cancelled: 'Avslutad',
    tof_not_ready_for_execution: 'Inflytten är inte satt som färdig för exekvering',
    policy_holder_comments: 'Försäkringstagarens kommentarer',
    portfolio_comments: 'Portföljens kommentarer',
    waiting_on_quantum: 'Väntar på Quantum',
    stage: 'Steg',
    stage_label: 'Steg (valfritt)',
    await_third_party: 'Inväntar tredjepart',
    follow_up: 'Följ upp',
    to_be_approved: 'Att godkänna',
    started: "Påbörjad",
    awaits_approval: "Väntar på godkännande",
    paused: "Pausad",
    resume: "Återuppta",
    menu_risk_assessment_params: 'Riskbedömningsparametrar',
    confirm_delete: 'Är du säker på att du vill ta bort denna?',
    first_deposit_amount: 'Första insättningsbelopp',
    units_is_required: 'Antal krävs för att skapa transaktionen',
    unit_price_is_required: 'Enhetspris krävs för att skapa transaktionen',
    exchange_rate_is_required: 'Växelkurs krävs för att skapa transaktionen',
    choose_source: 'Välj källa',
  },

});

export default keys;
