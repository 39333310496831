import React from 'react';
import FormHistory from 'modules/FormHistory';

const BasicInfo = ({ contact }) => {
  const key = 'p_roaring';
  // aa
  return <FormHistory
    formKey={key}
    contactName={contact.name}
    userId={contact.national_identity_number}
    canEdit={true}
  />;


};

export default BasicInfo;
