import React, { useState } from 'react';
import { Columns, Column } from 'components/Columns';
import Badge from '@hubins/components/Badge';
import { getStatusColor } from 'helpers/approvals';
import NameInitialsBadge from '@hubins/components/NameInitialsBadge';
import Button from '@hubins/components/Button';
import middleman from 'helpers/middleman';
import AssignRole from './AssignRole';
import __ from 'localisation';
import './index.scss';

const ApprovalProgressItem = ({ role: propsRole, myRoleIsActive, userUuid }) => {
  const [role, setRole] = useState(propsRole);
  const [loading, setLoading] = useState(false);
  const [assingUser, setAssignUser] = useState(false);

  const updateRoleWithActiveUser = async (user) => {
    setLoading(true);
    await middleman.promisePost(`/ql/crm/approvals/role/${role.uuid}`, { user_uuid: user.uuid, user_name: user.name });
    setRole({
      ...role,
      user_uuid: user.uuid,
      user_name: user.name,
    });
    setLoading(false);
  };

  const unassingSelf = async () => {
    setLoading(true);
    await middleman.promisePost(`/ql/crm/approvals/role/${role.uuid}`, { user_uuid: 'null', user_name: 'null' });
    setRole({
      ...role,
      user_uuid: null,
      user_name: null,
    });
    setLoading(false);
  };

  return  (
    <Column s="3">
      <div className="neutral-2 basic-padding-small">
        <AssignRole open={assingUser} close={() => {setAssignUser(false);}} updateRoleWithActiveUser={updateRoleWithActiveUser} roleName={role.name} />
        <div className="ap__status">
          <p className="label">{__(role.name)}</p>
          <Badge static color={getStatusColor(role.status)} />
        </div>
        {role.user_name && (
          <div className="ap__user">
            <p className="s-top-sm s-bottom-sm flex flex--row align-center">
              <NameInitialsBadge className="s-right-s" name={role.user_name} />
              {role.user_name}
              {(myRoleIsActive && role.user_uuid === userUuid) && (
                <Button size="small" onlyIcon className='s-left-s  transparent' icon="remove" iconSize={16} onClick={unassingSelf} />
              )}
            </p>
          </div>
        )}
        {(myRoleIsActive && !role.user_uuid) && (
          <Button iconLeft size="small" className='transparent s-bottom-s' icon="add" loading={loading} onClick={() => {setAssignUser(true);}}>Assign this approval to a user</Button>
        )}
      </div>

    </Column>
  );
};

const ApprovalProgress = ({ roles, myRole, userUuid }) => {
  return (
    <Columns className="ap">
      {roles.sort((roleA, roleB) => (
        roleA.priority - roleB.priority
      )).map((role) => {
        const myRoleIsActive = role.name === myRole.name && role.status === 'active';
        return <ApprovalProgressItem key={role.uuid} role={role} myRoleIsActive={myRoleIsActive} userUuid={userUuid} />;
      })}
    </Columns>
  );

};

export default ApprovalProgress;
