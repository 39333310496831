import React, { useEffect, useState } from 'react';
import middleman from 'helpers/middleman';
import ListButton from '@hubins/components/ListButton';
import __ from 'localisation';

type Props = {
  profileUuid: string
}

const Portfolios = ({ profileUuid }: Props) => {
  const [portfolios, setPortfolios] = useState<any[]>([]);

  const fetchPortfolios = async () => {
    try {
      const res = await middleman.get(`/ql/crm/profile/portfolios/${profileUuid}`);
      setPortfolios(res.data);
    } catch (e) {
      console.error('error fetching portfolios', e);
    }
  }

  useEffect(() => {
    fetchPortfolios();
  }, [])

  return (
    <div className='maxwidth'>
      {portfolios.map((portfolio) => {
        return (
          <ListButton
            key={portfolio.uuid}
            href={`/dashboard/clients/${profileUuid}/${portfolio.uuid}/tabs`}
            icon="Products Briefcase by Streamlinehq"
            actionText={__('open')}
            title={portfolio.name}
            text={portfolio.short_name}
          />
        )
      })}
    </div>
  )

};

export default Portfolios;