import React, { useState } from 'react';
import AccordeonButton from '@hubins/components/AccordeonButton';
import { useCollapse } from 'react-collapsed';
import Heading from '@hubins/components/Heading';
import PrivateDocuments from '@hubins/components/Documents/PrivateDocuments';
import { Column, Columns } from 'components/Columns';
import Box from 'components/Box';

import __ from 'localisation';

const ClientDocumentsAccordeon = ({ portfolioUuid, profileUuid }) => {

  const [documentsIsExpanded, setDocumentsIsExpanded] = useState(false);
  const handleDocumentsToggle = () => setDocumentsIsExpanded(!documentsIsExpanded);

  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded: documentsIsExpanded });

  return (
    <Box className="border">
      <AccordeonButton {...getToggleProps({ onClick: handleDocumentsToggle })} className="hover-pointer">
        <Heading element="span" size="5">
          {__('documents')}
        </Heading>
      </AccordeonButton>
      <div {...getCollapseProps()}>
        <Columns>
          {(portfolioUuid && profileUuid) && (
            <>
              <Column m="6">
                <PrivateDocuments excludeTag={false} portfolioUuid={portfolioUuid} portfolio />
              </Column>
              <Column m="6">
                <PrivateDocuments excludeTag={false} portfolioUuid={portfolioUuid} profileUuid={profileUuid} />
              </Column>
            </>
          )}
        </Columns>

      </div>
    </Box>
  )

};

export default ClientDocumentsAccordeon;