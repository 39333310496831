import React from 'react';
import Modal from '@hubins/components/Modal';
import LoadingAnimation from "@hubins/components/HubinsLoadingAnimation";
import __ from 'localisation';

type Props = {
  open?: boolean;
}

const LoadingModal = ({ open = true }: Props) => (
  <Modal
    open={open}
    callback={() => ''}
    className="animation-reveal"
    overlayClassName="modal__overlay--centermobile"
    contentLabel={__('loading')}
    noWrapper
  >
    <LoadingAnimation />
  </Modal>
);

export default LoadingModal;
