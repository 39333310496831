import React from 'react';
import './index.scss';

type Props = {
    name: string;
    color?: string;
    className?: string;
    size?: 'small' | 'medium' | 'large';
};

const NameInitialsBadge = ({ name, color, className, size = 'medium'}: Props ) => {
    const splittedName = name.split(' ');
    const initials = splittedName?.map((word) => word[0])?.filter((letter, index) => (index === 0 || index === (splittedName?.length - 1)))?.join('') || '';
    const backgroundColor = color ? color : '#ccc';

    return (
        <span className={`name-initials-badge name-initials-badge--${size} ${color || 'secondary'} ${className || ''}`}>
            {initials}
        </span>
    );
};

export default NameInitialsBadge;