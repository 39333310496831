import React, { useState } from 'react';
import DecimalInput from '@hubins/components/DecimalInput';
import Button, { ButtonWrap } from '@hubins/components/Button';
import __ from 'localisation';
import middleman from 'helpers/middleman';
import { useNotificationContext } from '@hubins/components/NotificationContext/NotificationContext';

type Props = {
  value: string | number;
  callback: (value: string | number) => void;
  securityId: number;
  disabled?: boolean;
}

const UnitPriceInput = ({ value, callback, securityId, disabled = false }: Props) => {

  const [loading, setLoading] = useState<boolean>(false);
  const { setNotification } = useNotificationContext();

  const fetchUnitPrice = async () => {
    setLoading(true);
    try {
      const response = await middleman.get(`/ql/crm/fa/security/latest-market-data/${securityId}`);
      if (!response.data) {
        throw new Error(__('failed_to_fetch_unit_price'));
      }
      callback(response.data);
    } catch (err) {
      setNotification({ type: 'error', message: err.message });
    }
    setLoading(false);

  }

  return (
    <div className='flex-horizontal-center'>
      <div className="wide">
        <DecimalInput
          label={__('choose_unit_price')}
          value={value}
          callback={callback}
          disabled={disabled}
        />
      </div>
      {!disabled && (
        <div className="s-left-m s-right-m wide">
          <ButtonWrap>
            <Button
              onClick={fetchUnitPrice}
              loading={loading}
              disabled={!securityId || loading}
              icon="Graph Stats Ascend by Streamlinehq"
            >
              {__('fetch_unit_price')}
            </Button>
          </ButtonWrap>
        </div>
      )}
    </div>
  )

};

export default UnitPriceInput;