import React, { useState, useCallback, useEffect } from 'react';
import Modal from '@hubins/components/Modal';
import TextArea from 'components/Textarea';
import Heading from '@hubins/components/Heading';
import Button, { ButtonWrap } from '@hubins/components/Button';
import ErrorMessage from 'components/ErrorMessage';
import __ from 'localisation';

const SofChangeNotes = ({ callback, open, close, loading, error }) => {

  const [notes, setNotes] = useState('');

  useEffect(() => {
    setNotes('');
  }, [open])

  const submit = useCallback(() => {
    callback(notes);
  }, [callback , notes]);

  return (
    <Modal open={open} callback={close} closeButton={true}>
      <Heading size="4" className="">{__('change_notes')}</Heading>
      <p>{__('please_add_note_of_changes')}</p>
      <TextArea
        optionsState={{
          value: notes,
          error: false,
          }}
        label="Note"
        onChange={setNotes}
      />
      <ButtonWrap className='align-start'>
        <Button className='s-bottom-xl cta' loading={loading} disabled={loading} onClick={submit}>{__('save_changes')}</Button>
        <Button className='s-bottom-xl' onClick={() => close(false)} icon="remove" disabled={loading}>{__('abort')}</Button>
      </ButtonWrap>
      <ErrorMessage text={error} showError={!!error} />
    </Modal>
  )

};

export default SofChangeNotes;