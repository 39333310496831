import React, { useEffect, useState } from 'react';
import middleman from 'helpers/middleman';
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import Heading from '@hubins/components/Heading';
import Button from '@hubins/components/Button';
import { Columns, Column } from 'components/Columns';

const CreateTestApproval = () => {

  const [loading, setLoading] = useState(false);
  const [sources, setSources] = useState([]);

  const fetchSources = async () => {
    setLoading(true);
    try {
      const res = await middleman.get('/ql/crm/source-of-funds');
      setSources(res.data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSources();
  }, []);

  const createApproval = async (profileUuid, sourceUuid) => {
    setLoading(true);
    try {
      await middleman.post(`/ql/crm/approvals/testcreate/${profileUuid}/${sourceUuid}`);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  if (loading) return <LoadingBox />;

  console.log('sources', sources);

  return (
    <Columns>
      {sources.map((source) => (
        <Column m="6" key={source.id}>
          <Heading size="4">{source.type}</Heading>
          <p>profile: {source.profile_uuid}</p>
          <Button onClick={() => {
            createApproval(source.profile_uuid, source.uuid);
          }} className='cta'>Create sof approval</Button>
        </Column>
      ))
      }
    </Columns>
  );

};

export default CreateTestApproval;
