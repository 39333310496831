import React, { useMemo } from 'react';

// Import React Table
import { useTable, useResizeColumns, useFlexLayout, usePagination, useSortBy } from 'react-table';
import Button from '@hubins/components/Button';
import './index.scss';
import { useEffect } from 'react';

const ApiPaginationTable = ({
  headers: propsHeaders,
  data: propsData,
  footer = false,
  // loading,
  fetchData,
  pageCount: controlledPageCount,
  index: controlledPageIndex,
}) => {
  const columns = useMemo(() => propsHeaders, [propsHeaders]);
  const data = useMemo(() => propsData, [propsData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    footerGroups,
    page,
    state: { pageIndex },
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 },
    manualPagination: true,
    pageCount: controlledPageCount,
    useControlledState: (state) => {
      return useMemo(
        () => ({
          ...state,
          pageIndex: controlledPageIndex,
        }),
        [state, controlledPageIndex]
      );
    }
  },
  useResizeColumns,
  useFlexLayout,
  useSortBy,
  usePagination,
  );

  useEffect(() => {
    fetchData(0);
  }, []);

  const nextPage = () => {
    fetchData(controlledPageIndex + 1);
  };

  const previousPage = () => {
    fetchData(controlledPageIndex - 1);
  };

  return (
    <>
      <div className='table__wrapper'>
        <table {...getTableProps} className="table">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={`${index}-thead-tr`}>
                {headerGroup.headers.map((column, subIndex) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} key={`${subIndex}-thead-tr-th`}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="align-items-center" key={`${index}-tbody-tr`}>
                  {row.cells.map((cell, subIndex) => {
                    return (
                      <td {...cell.getCellProps()} key={`${subIndex}-tbody-tr-td`}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {(footer) && (
            <tfoot>
              {footerGroups.map((footerGroup, index) => (
                <tr {...footerGroup.getFooterGroupProps()} key={`${index}-tfoot-tr`}>
                  {footerGroup.headers.map((column, subIndex) => (
                    <td {...column.getFooterProps()} key={`${subIndex}-tfoot-tr-td`}>{column.render('Footer')}</td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </div>
      <div className="pagination s-top-xl">
        <Button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          iconLeft
          icon="arrow left 1"
          className="secondary">
          Föregående
        </Button>
        <span>
          Sida{' '}
          <strong>
            {pageIndex + 1} av {pageOptions.length}
          </strong>{' '}
        </span>
        <Button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="secondary"
          icon="arrow right 1"
        >
          Nästa
        </Button>
      </div>
    </>
  );

};

export default ApiPaginationTable;
