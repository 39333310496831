import React from 'react';
import { __ } from "localisation";
import { motion } from "framer-motion";
import RenderCompactForm from "./RenderCompactForm";
import ErrorBoundary from "components/ErrorBoundary";
import ErrorMessage from "components/ErrorMessage";
import { Columns, Column } from "components/Columns";
import Button, { ButtonWrap } from "@hubins/components/Button";
import Badge from "@hubins/components/Badge";
import Heading from '@hubins/components/Heading';

const CompactForm = ({
  validKey,
  showButton,
  className,
  preview,
  submitRef,
  disabled,
  buttonStyle,
  buttonProps,
  submitText,
  secondaryButton,
  secondaryButtonStyle,
  secondaryText,
  error,
  submitClarification,
  loading,
  layoutId,
  animate,
  formErrors,
  submitted,
  submitForm,
  formStructure,
  formState,
  handleInputChangeMethod,
  rerenderKey,
  checkValidKey,
  submittedAt,
  title,
}) => {
  return (
    <motion.form
      layoutId={layoutId}
      className={`${className} standard-border ${submittedAt ? "c-success-border" : 'c-neutral-2-border'} `}
      onSubmit={submitForm}
    >
      {title && (
        <Heading size="4" className="s-bottom-l">
          {title}
        </Heading>
      )}
      <ErrorBoundary>
        <RenderCompactForm
          data={formStructure}
          optionsState={formState}
          callback={handleInputChangeMethod}
          rerenderKey={rerenderKey}
          key={rerenderKey}
          animate={animate}
        />
      </ErrorBoundary>

      <Columns className="section section--no-border section section--compact">
        <Column s="12" className="">
          <div className="flex-start wide">
            <ButtonWrap className="flex flex--row align-items-center">
              {(!preview && !validKey) ||
              (validKey && checkValidKey(formState, validKey, showButton))
                ? (
                  <>
                    <Button
                      ref={submitRef}
                      loading={submitted && loading && !error}
                      disabled={submitted && disabled} // TODO: if loading === true, this should trigger the disabled too. But currenlty a lot of forms using loading true as a default value
                      className={`large center s ${buttonStyle} ${validKey ? "a-fadein" : ""
                      }`}
                      type="submit"
                      {...buttonProps}
                    >
                      {submitText}
                    </Button>
                    {submittedAt && (
                      <Badge color="success s-left-m">
                        Submitted at: {submittedAt}
                      </Badge>
                    )}
                    {secondaryButton && (
                      <Button
                        loading={submitted && loading && !error}
                        disabled={submitted && disabled && !error}
                        className={`center s ${secondaryButtonStyle} ${validKey ? "a-fadein" : ""
                        }`}
                        // Second param in submitForm is true to notice that this is the secondarySubmit
                        onClick={(e) => {
                          submitForm(e, true);
                        }}
                      >
                        {secondaryText}
                      </Button>
                    )}
                  </>
                )
                : (
                  <span className="buttonheightfiller"></span>
                )}
            </ButtonWrap>
          </div>
          {buttonStyle !== 'hidden' && (
            <ErrorMessage
              text={error ? error : __("error_form")}
              showError={formErrors || error}
            />
          )}
          {submitClarification && (
            <div className="clarification center">{submitClarification}</div>
          )}
        </Column>
      </Columns>
    </motion.form>
  );
};

export default CompactForm;
