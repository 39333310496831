import React, { Fragment } from 'react';
import { motion } from 'framer-motion';
import Heading from '@hubins/components/Heading';
import {
  getSectionChilds,
} from "modules/CreateForm/createFormMethods";

const RenderCompactForm = (props) => {

  return (

    props.data.map((section, index) => {
      const children = getSectionChilds(section, props, true);

      return (
        <Fragment key={`key_${section.id}-${index}-${props.rerenderKey}`}>
          <motion.section animate={props.animate} className={`section section--compact ${section.class ? section.class : ''} ${props.repeater ? 'no-side-padding' : ''} relative`} data-section-content={section.id}>
            {section.title &&
              <Heading element="h2" size={3} className="s-top-md s centeronmobile">
                {section.title}
              </Heading>
            }
            {children.length >= 1 && children}
          </motion.section>
        </Fragment>
      );
    })
  );
};

export default RenderCompactForm;
