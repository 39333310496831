import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { matchPath } from "react-router-dom";
import { connect } from 'react-redux';
import LoggedinMenuQLCrm from 'modules/Menu/LoggedinMenuQLCrm';
import ErrorBoundary from 'components/ErrorBoundary';
import __ from 'localisation';
import SkipToContentLink from 'components/SkipToContentLink';
import { Logo } from 'settings';
import { LoadingBox, LoadingBar } from '@hubins/components/LoadingIndicators';
// import Heading from '@hubins/components/Heading';
import Icon from '@hubins/components/IconNew';
import Main from 'components/Main';
import Wrapper from '@hubins/components/Wrapper';
import Heading from '@hubins/components/Heading';
import PageFooter from 'components/PageFooter';
import SyncUserButton from '@hubins/components/SyncUserButton';
import { ButtonWrap } from '@hubins/components/Button';
import middleman from 'helpers/middleman';

import './ClientLayout.scss';
import './LayoutDefault.scss';

// IMPORTANT: Route must have a param named "uid"
const ClientLayout = (props) => {

  const match = matchPath(props.location.pathname, props);

  const { profileUuid, portfolioUuid } = match.params;

  const TheComponent = props.component || props.render;

  const [client, setClient] = useState(false);
  const [portfolio, setPortfolio] = useState(false);

  const init = async () => {
    const res = await middleman.get(`/portfolio/${portfolioUuid}`);
    const portfolioRes = res.data;
    const policyHolder = portfolioRes.policy_holder.find((ph) => ph.uuid === profileUuid);
    setClient(policyHolder);
    setPortfolio(portfolioRes);
  };

  useEffect(() => {
    init();
  }, []);

  const ssn = client ? client.national_identity_number : '';

  return (
    <>
      <SkipToContentLink
        target="MainContent"
        text={__('skip_to_main_content')}
      />
      <ErrorBoundary>
        <LoggedinMenuQLCrm path={props.path} logo={Logo} name={props.name} />
      </ErrorBoundary>
      {/* <div className="side-menu-layout" data-shiftonsidebaropen> */}
      <ErrorBoundary>
        <div className="layout_default">
          <div className="layout_default__content">

            <Main noSpace className="relative">
              <Wrapper rounded stretch tight className="backoffice-wrapper">
                <div className="flex flex--row align-items-center space-between">
                  <Heading size="2" className="c-primary s-left-m width-auto">
                    {portfolio ? portfolio.short_name : <LoadingBar large h1 />}
                  </Heading>
                  <div className="clientlayout-sync">
                    <span><strong>{__('latest_update')}</strong>: {portfolio.updated_at}</span>
                    <ButtonWrap>
                      <SyncUserButton profileUuid={profileUuid} />
                    </ButtonWrap>
                  </div>
                </div>
                <div className="flex flex--row align-items-center s-left-m ">

                  <Icon title="name" icon="user" size={16} className="s-right-s"  />
                  {client ? client.name : ''}
                  {/* <Button external small className="link withicon withicon--left" href={`tel:${phone}`}>
                    <Icon title="phone" icon="phone" size="small" />
                    {phone}
                  </Button> */}

                  <Icon title="ssn" icon="Touch Id 1" size={16} className="s-right-s s-left-m"/>
                  {ssn}

                  {/* <Button external small className="link withicon withicon--left" href={`mailto:${email}`}>
                    <Icon title="email" icon="mail" size="small" />
                    {email}
                  </Button> */}
                </div>
                <hr />
                {/* <WrapperContent wide> */}
                {(client && portfolio) ? (
                  <>
                    <Route
                      {...props}
                      component={undefined}
                      render={(renderProps) => {
                        return <TheComponent {...renderProps} client={client} portfolio={portfolio} />;
                      }}
                    />
                  </>
                ) : (
                  <LoadingBox />
                )}

                {/* </WrapperContent> */}
              </Wrapper>
            </Main>
          </div>
        </div>
      </ErrorBoundary>
      <PageFooter />
    </>
  );

};

function mapStateToProps(state) {
  return {
    name: state.auth.User.name,
    userRoles: state.auth.User.userRoles,
    contact_type: state.auth.User.roles && state.auth.User.roles[0].name,
  };
}

export default connect(
  mapStateToProps,
)(ClientLayout);
