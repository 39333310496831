import React, { useState } from 'react';
import Heading from '@hubins/components/Heading';
import Icon from '@hubins/components/IconNew'
import Badge from '@hubins/components/Badge';
import Button, { ButtonWrap } from '@hubins/components/Button';
import middleman from 'helpers/middleman';
import { __route } from 'localisation';
import { getMoney } from 'helpers';
import './index.scss';

const titleByType = {
  'PRE': 'Insättning',
  'WDR': 'Uttag',
}

const statusTranslations = {
  'draft': 'Utkast',
  'pending': 'Väntar på betalning',
  'completed': 'Genomförd',
  'OK': 'Genomförd'
}

const badgeColorByStatus = {
  'draft': 'warning',
  'pending': 'info',
  'completed': 'success',
  'OK': 'success'
}

type Props = {
  type: string,
  amount: number,
  created_at: string,
  portfolio: any,
  status: string,
  href: string,
  uuid: string,
  currency: string,
  refetch: () => void,
}

const WithdrawalAndPremiumCard = ({type, amount, created_at, portfolio, status, href, uuid, currency, refetch}: Props) => {

  const [loading, setLoading] = useState(false);

  const deleteTransaction = async () => {
    setLoading(true);
    try {
      await middleman.promiseDelete(`/transactions/delete/${uuid}`);
      refetch();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  return (
      <div className="transaction-card">
        <div className='transaction-card__header'>
          <div className='transaction-card__left'>
            <Icon icon="Deposit" color="primary" size={24} />
            <Heading size="5">{titleByType[type]}</Heading>
          </div>
        </div>
        <Badge color={badgeColorByStatus[status]}>
          {statusTranslations[status]}
        </Badge>
        <p>
          <strong>Datum:</strong> {new Date(created_at).toISOString().substring(0, 10)} <br />
          <strong>Portfölj:</strong> {portfolio.short_name} <br />
          <strong>Belopp:</strong> {getMoney(amount / 100, currency)} {/* TODO: money pattern or helper */}
        </p>
        {(type === 'PRE' && status === 'pending') && (
          <Button className="cta" icon="arrow right 1" iconColor="white" href={`${__route("depositInstructions")}/s/${uuid}`}>
            Betalningsuppgifter
          </Button>
        )}
        {(type === 'PRE' && status === 'draft') && (
        <ButtonWrap>
          <Button
            className='danger'
            icon="Bin by Streamlinehq"
            iconColor='white'
            onClick={deleteTransaction}
            loading={loading}
            disabled={loading}
            >
            Ta bort
          </Button>
          <Button
            color="secondary"
            icon="arrow right 1"
            href={`${__route("deposit")}/${portfolio.uuid}#sow`}
            disabled={loading}
          >
            Fortsätt
          </Button>
        </ButtonWrap>
        )}
      </div>
  )

};

export default WithdrawalAndPremiumCard;
