import {
  useQuery,
} from 'react-query';

import middleman from 'helpers/middleman';

export const useRiskAssessment = (portfolioUuid) => useQuery(['riskAssessment', portfolioUuid], () => {
  return middleman.promiseGet(`/risk-assessment/${portfolioUuid}`).then(response => response.data);
}, {
  enabled: !!portfolioUuid,
});

export const useRisksHistory = (portfolioUuid, shouldRun) => useQuery(['risksHistory', portfolioUuid], () => {
  return middleman.promiseGet(`/ql/crm/risk-assessment/risk/history/${portfolioUuid}`).then(response => response.data);
} , {
  enabled: shouldRun,
});