import React, { useEffect, useState } from 'react';
import SimpleTable from 'modules/Tables/SimpleTable';
import LoadingBar from '@hubins/components/LoadingIndicators';
import middleman  from 'helpers/middleman';
import __ from 'localisation';

const userHeaders = [
  {
    Header: '',
    accessor: 'type',
  },
  {
    Header: '',
    accessor: 'value',
  }
];

type ApprovalProfilePortfolioSummaryProps = {
  portfolioUuid: string,
  portfolio?: any,
};

export const ApprovalProfilePortfolioSummary = ({ portfolioUuid, portfolio: propsPortfolio }: ApprovalProfilePortfolioSummaryProps) => {

  const [portfolio, setPortfolio] = useState(false);
  const [risk, setRisk] = useState(0);
  const [fmaCategory, setFmaCategory] = useState(0);
  const [importedFmaCategory, setImportedFmaCategory] = useState(0);

  const fetchInitialData = async () => {
    const portfolioRes = propsPortfolio ? {data: propsPortfolio} : await middleman.get(`/portfolio/${portfolioUuid}`);
    const riskResPromise = middleman.get(`/risk-assessment/${portfolioUuid}`).catch(() => ({data: 0}));
    const fmaCategoryResPromise = middleman.get(`/ql/crm/risk-assessment/fma/category/${portfolioUuid}`).catch(() => ({data: 0}));
    const importedFmaCategoryResPromise = middleman.get(`/ql/crm/risk-assessment/fma/manual-risk/${portfolioUuid}`).catch(() => ({data: 0}));
    const [
      riskRes,
      fmaCategoryRes,
      importedFmaCategoryRes] = await Promise.all([
        riskResPromise,
        fmaCategoryResPromise,
        importedFmaCategoryResPromise
      ]);
    setPortfolio(portfolioRes.data);
    setRisk(riskRes.data || 0);
    setFmaCategory(fmaCategoryRes.data || 0);
    setImportedFmaCategory(importedFmaCategoryRes.data || 0);
  };

  useEffect(() => {
    if (portfolioUuid) {
      fetchInitialData();
    }
  }, [portfolioUuid]);

  return (
    <ApprovalProfilePortfolioSummaryTable portfolio={portfolio} risk={risk} fmaCategory={fmaCategory} importedFmaCategory={importedFmaCategory} />
  );
};

const loadingState = [
  {
    type: 'Policy',
    value: <LoadingBar />,
  },
  {
    type: `${__('policy_holder')} 1`,
    value: <LoadingBar />,
  },
  {
    type: __('risk_category'),
    value: <LoadingBar />,
  },
];

type ApprovalProfilePortfolioSummaryTableProps = {
  portfolio: any,
  risk: number,
  fmaCategory: number,
  importedFmaCategory: number,
};

export const ApprovalProfilePortfolioSummaryTable = ({ portfolio, risk, fmaCategory, importedFmaCategory }: ApprovalProfilePortfolioSummaryTableProps) => {

  const userData = (portfolio && risk) ? [
    ...portfolio ? [{
      type: 'Policy',
      value: portfolio.short_name,
    }] : [],
    ...portfolio.policy_holder.map((policyHolder, index) => (
      {
        type: `${__('policy_holder')} ${index + 1}`,
        value: policyHolder.name,
      }
    )
    ),
    ...risk ? [{
      type: __('risk_profile'),
      value: risk,
    },
    {
      type: __('risk_tool'),
      value: fmaCategory,
    },
    {
      type: __('imported_risk_categorization_from_fa'),
      value: importedFmaCategory,
    }] :  [],
  ] : loadingState;

  return (
    <SimpleTable headers={userHeaders} data={userData} />
  );

}

export default ApprovalProfilePortfolioSummary;