import React, { useState } from 'react';
import Input from '@hubins/components/Input';
import Button, { ButtonWrap } from '@hubins/components/Button';
import __ from 'localisation';
import middleman from 'helpers/middleman';
import { useNotificationContext } from '@hubins/components/NotificationContext/NotificationContext';
import DecimalInput from '@hubins/components/DecimalInput';

type Props = {
  value: string | number;
  callback: (value: string | number) => void;
  currency: string;
  disabled?: boolean;
}

const ExchangeRateInput = ({ value, callback, currency, disabled = false }: Props) => {

  const [loading, setLoading] = useState<boolean>(false);
  const { setNotification } = useNotificationContext();

  const fetchExchangeRate = async () => {
    setLoading(true);
    try {
      const response = await middleman.get(`/ql/crm/fa/currency/exchange-rate/${currency}`);
      if (!response.data) {
        throw new Error(__('failed_to_fetch_exchange_rate'));
      }
      callback(response.data);
    } catch (err) {
      setNotification({ type: 'error', message: err.message });
    }
    setLoading(false);
  }

  return (
    <div className='flex-horizontal-center'>
      <div className="wide">
        <DecimalInput
          label={__('choose_exchange_rate')}
          value={value}
          callback={callback}
          disabled={disabled}
        />
      </div>
      {!disabled && (
      <div className="s-left-m s-right-m wide">
        <ButtonWrap>
          <Button
            onClick={fetchExchangeRate}
            loading={loading}
            disabled={!currency || loading}
            icon="Accounting Coins Stack by Streamlinehq"
          >
            {__('fetch_exchange_rate')}
            </Button>
          </ButtonWrap>
        </div>
      )}
    </div>
  )

};

export default ExchangeRateInput;