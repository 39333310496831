import React from 'react';
import Heading from '@hubins/components/Heading';
import FormDocument from '../FormDocument/FormDocument';
import './index.scss'

type Props = {
  title: string;
  files: any[];
}

const FormDocuments = ({title, files}: Props) => {
  return (
    <>
      <Heading size="4" className="s-bottom-l">
        {title}
      </Heading>
      <div className='files-row'>
        {files.map((file: any) => (
          <FormDocument
            key={file.id}
            id={file.id}
            title={file.name}
          />
        ))}
      </div>
    </>
  );
};

export default FormDocuments;