import React, { useState, useEffect, ChangeEvent, KeyboardEvent } from 'react';
import { Column, Columns } from 'components/Columns';
import Heading from '@hubins/components/Heading';
import middleman from '@hubins/middleman';
import ApiPaginationTable from 'modules/Tables/ApiPaginationTable';
import Input from '@hubins/components/Input';
import AddRoleModal from './AddRoleModal';
import Button from '@hubins/components/Button';
import { LoadingBar } from '@hubins/components/LoadingIndicators';
import { UserData as User} from './User.types';
import { isAdmin } from 'helpers/userRoles';

const loadingObj = {
  name: <LoadingBar small />,
  email: <LoadingBar small />,
  national_identity_number: <LoadingBar small />,
  created_at: <LoadingBar small />,
  updated_at: <LoadingBar small />,
};

const loadingState = [loadingObj, loadingObj, loadingObj];


const Users = ({ userRoles }) => {
    
    const [users, setUsers] = useState<User[] | null>(null);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedPage, setSelectedPage] = useState(0);
    const [amountOfPages, setAmountOfPages] = useState(-1);
    const [searchValue, setSearchValue] = useState('');

    const columns = [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'National Identity Number',
            accessor: 'national_identity_number',
        },
        {
            Header: 'Created At',
            accessor: 'created_at',
        },
        {
            Header: 'Updated At',
            accessor: 'updated_at',
        },
        {
            Header: 'Manage Roles',
            Cell: ({ row }) => (
                <Button
                    onClick={() => {
                        setOpen(true);
                        setSelectedUser(row.original);
                    }}
                    className="btn btn-primary"
                    size='small'
                    disabled={loading}
                >
                    Manage Roles
                </Button>
            ),
        },
    ];

    const fetchPage = async (page: number, searchParam: string = '') => {
        setLoading(true);
        setSelectedPage(page - 1);
        try {
            const res = await middleman.get(`/ql/crm/user/search?page=${page}${searchParam ? `&search=${searchParam}` : ''}`);
            setAmountOfPages(res.data.last_page);
            setUsers(res.data.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageFetch = (index: number) => {
        fetchPage(index + 1, searchValue);
    };

    useEffect(() => {
        fetchPage(1);
    }, []);

    console.log(userRoles, 'userRoles');
    if (!isAdmin(userRoles)) {
        return <div>You are not authorized to view this page</div>;
    }
    return (
        <>
            {selectedUser && <AddRoleModal user={selectedUser} open={open} setOpen={setOpen} />}
            <Columns>
                <Column m="12">
                    <Heading center={false} size={4} className="s-bottom-md">
                        Users
                    </Heading>
                    <Input
                        label="Search"
                        value={searchValue}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
                        onKeyDown={(e: KeyboardEvent) => {
                            if (e.key === 'Enter') {
                                fetchPage(1, searchValue);
                            }
                        }}
                    />
                    <ApiPaginationTable
                        data={users || loadingState}
                        headers={columns}
                        fetchData={handlePageFetch}
                        pageCount={amountOfPages}
                        index={selectedPage}
                    />
                </Column>
            </Columns>
        </>
    );
};

export default Users;