import React from 'react';
import __ from 'localisation';
import Button, { ButtonWrap } from '@hubins/components/Button';
import Modal from '@hubins/components/Modal';
import SimpleTable from 'modules/Tables/SimpleTable';

const historyHeaders = [
  {
    Header: __('date'),
    accessor: 'created_at',
  },
  {
    Header: __('score'),
    accessor: 'score',
  },
  {
    Header: __('updated_by'),
    accessor: 'user_name',
  },
]

type Props = {
  callback: () => void;
  data: any[];
}

const RisksHistoryModal = ({ callback, data }: Props) => (
  <Modal open={data.length > 0} callback={callback} closeButton>
    <div className='s-top-xl' />
    <SimpleTable headers={historyHeaders} data={data} />
    <ButtonWrap>
      <Button onClick={callback}>{__('close')}</Button>
    </ButtonWrap>
  </Modal>
);

export default RisksHistoryModal;