import apiConnector from "api-connector";
import axios from "axios";

const middlemanURL: string = import.meta.env.VITE_MIDDLEMAN_URL;
let middleman = apiConnector(middlemanURL + "/api");

interface UserProfileRolePayload {
    userUuid: string;
    roleName: string;
}

interface PolicyProfileRolePayload {
    profileUuid: string;
    roleName: string;
}

type RoleData = {
    name: string;
    national_identity_number: string;
    uuid: string;
    email: string;
}

type PolicyRolesResponse = {
    life_assured: RoleData[];
    policy_holder: RoleData[];
    beneficial: RoleData[];
    asset_manager: RoleData[];
    view_only_users: RoleData[];
}

type ProfileRolesResponse = {
    roles: RoleData[];
}

type UserRoleHistoryResponse = {
    log: string[];
}

middleman = {
    ...middleman,
    getProfileRoles: async (profileUuid: string): Promise<ProfileRolesResponse> => {
        const res = await middleman.get(`/userprofileroles/${profileUuid}`);
        return res.data as ProfileRolesResponse;
    },
    addUserProfileRole: async (profileUuid: string, userUuid: string, roleName: string): Promise<any> => {
        const payload: UserProfileRolePayload = { userUuid, roleName };
        return await middleman.post(`/userprofileroles/${profileUuid}`, payload);
    },
    removeUserProfileRole: async (profileUuid: string, userUuid: string, roleName: string): Promise<any> => {
        const payload: UserProfileRolePayload = { userUuid, roleName };
        return await middleman.promisePost(`/userprofileroles/delete/${profileUuid}`, payload);
    },
    getPolicyRoles: async (portfolioUuid: string): Promise<PolicyRolesResponse> => {
        const response = await middleman.get(`/policyroles/${portfolioUuid}`);
        return response.data as PolicyRolesResponse;
    },
    addPolicyProfileRole: async (portfolioUuid: string, profileUuid: string, roleName: string): Promise<any> => {
        const payload: PolicyProfileRolePayload = { profileUuid, roleName };
        return await middleman.post(`/policyroles/${portfolioUuid}`, payload);
    },
    removePolicyProfileRole: async (portfolioUuid: string, profileUuid: string, roleName: string): Promise<any> => {
        const payload: PolicyProfileRolePayload = { profileUuid, roleName };
        return await middleman.promisePost(`/policyroles/delete/${portfolioUuid}`, payload);
    },
    getUserRoleHistory: async (userUuid: string): Promise<UserRoleHistoryResponse> => {
        const response = await middleman.get(`/users/${userUuid}/role-history`);
        return response.data as UserRoleHistoryResponse;
    },
};


export const csrf = (): Promise<any> => {
    const csrf_url: string = middlemanURL + "/sanctum/csrf-cookie";
    return axios.get(csrf_url, { withCredentials: true });
};

export default middleman;