import React, { useState } from 'react';
import Modal from '@hubins/components/Modal';
import Icon from '@hubins/components/IconNew';
import Button, { ButtonWrap } from "@hubins/components/Button";
import __ from "localisation/hubins/registration";
import __ui from "localisation";

type FormErrorHandlerConextValues = {
  error: string;
  setError: (error: string) => void;
}

const FormErrorContext = React.createContext({error: "", setError: (error: string) => {}} as FormErrorHandlerConextValues);

const FormErrorHandler = ({ children }) => {

  const [error, setError] = useState<string>("")

  const closeModal = () => {
    setError("");
  }

  return (
    <FormErrorContext.Provider value={{ error, setError }}>
      <Modal
        className="animation-reveal"
        open={!!error}
        contentClass="forminfo__color forminfo__info maxwidth maxwidth--center neutral-2 basic-padding-large"
        noWrapper
      >
        <Icon icon="Information Circle by Streamlinehq" size={24} />
        <p dangerouslySetInnerHTML={{ __html: error }} />
        {__("da_modal_content", { email: __ui("platform_email"), tel: __ui('platform_tel') })}
        <ButtonWrap>
          <Button
            size="medium"
            className="cta"
            onClick={closeModal}
          >
            Ändra
          </Button>
          <Button
            size="medium"
            className="secondary"
            href="/"
            external
            externalWindow={false}
          >
            Avsluta
          </Button>
        </ButtonWrap>
      </Modal>
      {children}
    </FormErrorContext.Provider>
  )

};
export { FormErrorContext }
export default FormErrorHandler;