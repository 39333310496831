import React, { useState } from 'react';
import { __, __route } from 'localisation';
import { formatMoney, formatPercent } from 'helpers';
import { LoadingBar } from '@hubins/components/LoadingIndicators';
import TableFooter from 'components/TableFooter';
import Thumbnail from 'components/Thumbnail';
import { Link } from 'react-router-dom';
import Badge from '@hubins/components/Badge';
import EventCard from 'modules/EventCard';


const TradeOrderCard = ({
  deleteCallback,
  data = {
    eventCard: {
      text: ''
    }
  },
  ...restProps
}) => {
  const type = data.type ? data.type.typeCode : '';
  const tableData = ((data.marketGroup && data.marketGroup.includes('active_investments')) || type === 'PRESEC') ?
    [
      { label: __('amount'), value: data.amount },
      ...(type === 'PRESEC')
        ? []
        : [
          {
            label: type === 'S'
              ? __('sell_course')
              : __('buy_course'),
            value: formatPercent(data.price * 100, data.currencyCode)
          }
        ],
      { label: __('total_amount'), value: formatMoney(data.tradeAmount, data.currencyCode) }, // trade amount
    ] :
    [
      { label: __('volume'), value: formatMoney(data.amount, data.currencyCode) }, // amount
      { label: __('investment_amount'), value: formatMoney(data.tradeAmount, data.currencyCode) }, // trade amount
    ];

  const name = data.security ? data.security.name : data.typeCode;

  const canBeDeleted = (data.security && data.security.type && data.security.type.code === "CRYPTOCURRENCY") && !data.tags.includes('Acceptance:HubinsAcceptance');

  return (
    <EventCard
      {...restProps}
      text={data.eventCard ? __(data.eventCard.text) : false}
      data={data}
      cardtype="TradeOrderCard"
      alt={name}
      canBeDeleted={canBeDeleted}
      deleteCallback={deleteCallback}
      thumbnail={
        <Link to={`${__route('case')}/${data.security ? data.security.id : ''}`} className="event-card__thumbnail">
          <Thumbnail
            src={data.thumbnail}
            fill
            alt={(data.hero && data.hero.text && data.hero.text !== null)
              ? data.hero.text :
              name || ''} />
        </Link>
      }
      meta={
        type
          ? `${__('type_' + type)} - ${data.transactionDate}`
          : <LoadingBar medium />
      }

      status={<Badge color={data.eventCard && data.eventCard.color}>{__(data.eventCard && data.eventCard.title)}</Badge>}
      footer={
        <TableFooter
          data={data.placeholder ? undefined : tableData}
        />
      }
    />
  );

};

export default TradeOrderCard;
