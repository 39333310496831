import React, { useState, useEffect } from 'react';
import Button from '@hubins/components/Button';
import SearchInput from 'components/SearchInput';
import { LoadingBar } from '@hubins/components/LoadingIndicators';
import __ from 'localisation';
import ApiPaginationTable from 'modules/Tables/ApiPaginationTable';
import { Columns, Column } from 'components/Columns';

import middleman from 'helpers/middleman';

const loadingObj = {
  'toolsBeginning': <LoadingBar small />,
  'name': <LoadingBar small />,
  'national_identity_number': <LoadingBar small />,
  'updated_at': <LoadingBar small />,
};

const loadingState = [
  loadingObj,
  loadingObj,
  loadingObj,
];

const headers = [
  {
    Header: '',
    accessor: 'toolsBeginning',
    className: 'rt-cell-button',
    width: 40
  },
  {
    Header: __('name'),
    accessor: 'name',
  },
  {
    Header: __('ssn_org_num'),
    accessor: "national_identity_number"
  },
  {
    Header: __('updated_at'),
    accessor: 'updated_at'
  },
];

const Profiles = () => {

  const [profiles, setProfiles] = useState<any[]>([]);
  const [amountOfPages, setAmountOfPages] = useState<number>(-1);
  const [search, setSearch] = useState<string>('');
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [init, setInit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchPage = (page: number, searchParam:string = '') => {
    setLoading(true);
    setSelectedPage(page - 1);
    middleman.get(`/ql/crm/search?page=${page}${searchParam ? `&search=${searchParam}` : ''}`)
      .then(res => {

        const formattedRes = res.data.data.map((item) => {
          return {
            ...item,
            toolsBeginning: <Button
              href={`/dashboard/profiles/${item.uuid}`}
              className={`transparent`}
              icon="arrow-right"
              onlyIcon
            />
          }
        });

        setAmountOfPages(res.data.last_page);
        setProfiles(formattedRes);
        setLoading(false);
      });
  };

  const searchCallback = () => {
    fetchPage(1, search);
  };

  useEffect(() => {
    if (init) {
      const timeout = setTimeout(searchCallback, 1500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [search]);

  const handlePageFetch = (index) => {
    fetchPage(index + 1, search);
    if (!init) {
      setInit(true);
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <Columns>
      <Column s="12" className="flex flex--row flex-end align-items-center">
        <SearchInput onChange={handleSearchChange} value={search} placeholder="Search client name, policy or SSN/Org-nr" loading={loading} />
      </Column>
      <Column s="12">
        <ApiPaginationTable
          data={profiles ? profiles : loadingState}
          headers={headers}
          fetchData={handlePageFetch}
          pageCount={amountOfPages}
          index={selectedPage}
        />
      </Column>
    </Columns>
  )

};

export default Profiles;