import React from 'react';
import { LoadingBar } from '@hubins/components/LoadingIndicators';

import './index.scss';

const defaultData = [
  { label: '-', value: <LoadingBar medium/> },
  { label: '-', value: <LoadingBar medium/> },
  { label: '-', value: <LoadingBar medium/> },
];

const TableFooter = ({ data = defaultData, ...props }) => {
  return(
    <div className={`table-footer${props.inline ? ' table-footer--inline' : ''}`}>
      {data.map((column, index) => (
        <div className={`table-footer__column${props.inline ? ' table-footer__column--inline' : ''}`} key={index}>
          <p className={`table-footer__content${props.inline ? ' table-footer__content--inline' : ''}`}>
            <span className="clarification">{column.label}</span>
            <span className="table-footer__value">{column.value}</span>
          </p>
        </div>
      ))}
    </div>
  );
};

export default TableFooter;
