import React from 'react';
import ExpandableSummaryTable from 'components/ExpandableSummaryTable';
import './index.scss';

interface ExpandableButtonProps {
  heading: string;
  children: React.ReactNode;
  className?: string;
}

const ExpandableButton = ({ heading, children, className }: ExpandableButtonProps) => {

  return (
    <div className={`expandable-button ${className}`}>
      <ExpandableSummaryTable heading={heading}>
        {children}
      </ExpandableSummaryTable>
    </div>
  )
};

export default ExpandableButton;