import React, { useState, useEffect } from 'react';
import Routes from './routes';
import 'globalstyles/helpclasses.scss';
import 'globalstyles/normalize.scss';
import 'globalstyles/main.scss';
import 'styles/index.scss';
import UiProvider from 'components/UiContext';
import Sidebar from 'components/Sidebar';
import axios from 'axios';
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import { QueryClient, QueryClientProvider } from 'react-query';
import VersionHandler from 'components/VersionHandler';
import FormErrorHandler from '@hubins/components/FormErrorHandler';

const FEATURES_ENDPOINT = import.meta.env.VITE_FEATURES_ENDPOINT;
import NotificationContext from '@hubins/components/NotificationContext/NotificationContext';

const App = () => {

  const queryClient = new QueryClient();

  const [theBook, setTheBook] = useState(false);
  const [loading, setLoading] = useState(true);

  const init = async () => {
    const growthbook = new GrowthBook();
    await axios.get(FEATURES_ENDPOINT)
      .then((parsed) => {
        const features = parsed.data.features;
        growthbook.setFeatures(features);
        setTheBook(growthbook);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    init();
  }, []);

  if (loading) return <LoadingBox />;

  return (
    <QueryClientProvider client={queryClient}>
      <GrowthBookProvider growthbook={theBook}>
        <UiProvider>
          <NotificationContext>
            <FormErrorHandler>
              <Routes>
                <VersionHandler platform="backoffice" />
              </Routes>
            </FormErrorHandler>
            <Sidebar />
          </NotificationContext>
        </UiProvider>
      </GrowthBookProvider>
    </QueryClientProvider>
  );

};

export default App;
