import React, { Component } from 'react';
import SidebarTrigger from 'components/Sidebar/SidebarTrigger';
import Heading from '@hubins/components/Heading';
import './index.scss';

interface FormInfoProps {
  tooltip?: string;
  title: string;
  children?: any;
  description?: string;
  id?: string;
  texts?: any;
  text: any;
  modifier?: string;
  color?: "warning" | "danger" | "success" | "info";
  className?: string;
}


const FormInfo = ({
  tooltip = '',
  title = '',
  description = '',
  id,
  texts,
  text,
  modifier = '',
  color,
  className = '',
}: FormInfoProps) => {
    return (
      <div className={`
          forminfo
          ${tooltip && 'forminfo--tooltip'} fc
          ${color ? `forminfo__color forminfo__${color}` : ''}
          ${className}
          `}
        >
        {title && (
          <Heading element="h3" size="4">{title}</Heading>
        )}
        {description && (
          <div className={`${title ? 's-top-md' : ''}`}>
            {description}
          </div>
        )}
        {tooltip && (
          <SidebarTrigger
            id={id}
            icon="question"
            text={tooltip}
          />
        )}
        {texts && <div className={`body-big ${title ? 's-top-md' : ''} ${modifier}`}>{texts}</div>}
        {text && typeof text === 'string' ? <p>{text}</p> : text}
      </div>
    );
}

export default FormInfo;
