import React from 'react';
import './index.scss';

type Props = {
  children: React.ReactNode;
  element?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'legend' | 'span';
  size?: 1 | 2 | 3 | 4 | 5 | '1' | '2' | '3' | '4' | '5' | 'hero-1' | 'hero-2';
  className?: string;
  center?: boolean;
};

const Heading = ({
  children,
  element: DynamicElement = 'h2',
  size = 2,
  className = '',
  center,
  ...restProps
}: Props) => {
  return (
    <DynamicElement className={`heading heading--${size} ${className}${center ? ' center' : ''}`} {...restProps}>
      {children}
    </DynamicElement>
  );
};

export default Heading;
