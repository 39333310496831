import React, { useEffect, useState, useCallback } from 'react';
import middleman from 'helpers/middleman';
import SimpleTable from 'modules/Tables/SimpleTable';

type Person = string;

type Roles = {
    investment_representative: Person[];
    signatory: Person[];
    beneficial_owner: Person[];
};

type RoleHistoryEntry = {
    roles: Roles;
    date: string;
    event: string;
};

type RoleHistory = {
    roleHistory: RoleHistoryEntry[];
    startDate: string;
    endDate: string;
};

const ProfileRoleHistory = ({ profileUuid }) => {
    

    const [roleHistory, setRoleHistory] = useState<RoleHistory | null>(null);

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const getHistory = useCallback(async () => {
        try {
            const dateQuery = "?start_date=1990-01-01&end_date=" + new Date().toISOString().split('T')[0];
            const res = await middleman.promiseGet(`/userprofileroles/${profileUuid}/history${dateQuery}`);
            setRoleHistory(res.data.roleHistory);
        } catch (err) {
            setError('Failed to fetch role history');
        } finally {
            setLoading(false);
        }
    }, [profileUuid]);

    useEffect(() => {
        getHistory();
    }, [getHistory]);
  

    if(loading) {
        return <p>Loading</p>;
    }
    if (error) {
        return <p>{error}</p>;
    }

    if (!roleHistory) {
        return <p>No role history found</p>;
    }
 
    return (
        <div>
            <h2>Profile Role History</h2>
            <SimpleTable
                data={roleHistory}
                headers={[
                    {
                        Header: 'Date',
                        accessor: 'date',
                    },
                    {
                        Header: 'Event',
                        accessor: 'event',
                    },
                    {
                        Header: 'Policy Holders',
                        accessor: 'roles.investment_representative',
                        Cell: ({ value }) => value?.join(', '),
                    },
                    {
                        Header: 'Life Assured',
                        accessor: 'roles.beneficial_owner',
                        Cell: ({ value }) => value?.join(', '),
                    },
                    {
                        Header: 'Beneficiary',
                        accessor: 'roles.signatory',
                        Cell: ({ value }) => value?.join(', '),
                    },
                ]} />
          
        </div>
    );



};

export default ProfileRoleHistory;
