import React from 'react';
import { Column, Columns } from 'components/Columns';
import PropTypes from 'prop-types';
import ErrorMessage from 'components/ErrorMessage';
import { __ } from 'localisation';
import SidebarTrigger from 'components/Sidebar/SidebarTrigger';
import Heading from '@hubins/components/Heading';

const Template = (props) => {

  const renderButtons = () => {
    let buttons = props.options.map((item, index) => {

      let buttonClassName = '';

      if (props.locked) {
        buttonClassName = 'disabled';
      }

      const checked = props.optionsState.value[item.id].value;
      return (
        <div
          key={props.id + item.id + index + props.rerenderKey}
          className={ `formselect__wrapper column` }
        >
          <label className="">
            <input
              onChange={(event) => props.handleChange(props.partId, item.id, false, event)}
              className="formselect visuallyhidden"
              type={props.multiple ? 'checkbox' : 'radio'}
              name={props.partId}
              value={props.optionsState.value[item.id].id}
              checked={checked}
              disabled={item.disabled || props.locked}
            />
            <span
              data-id={props.partId + '_' + props.optionsState.value[item.id].id}
              className={`button withicon narrow ${buttonClassName}`}
            >
              <span>{item.title}</span>
            </span>
          </label>
          {item.tooltip && (
            <SidebarTrigger
              id={`${props.id + item.id}_tooltip`}
              text={item.tooltip}
              icon="Question Circle by Streamlinehq"
            />
          )}
        </div>
      );
    });

    return buttons;
  };

  const throwError = props.optionsState.error;
  return (
    <>

      <Columns>
        <Column s="6" className="formselect-compact-content-wrapper">
          <Heading element="h4" size="5" className="form-group__title">
            {props.title}
            {(props.tooltip && props.title) && (
              <SidebarTrigger
                id={`${props.id}_tooltip`}
                text={props.tooltip}
                icon="Question Circle by Streamlinehq"
                absolute={false}
              />
            )}
          </Heading>
          {'description' in props && (
            <p className="form-group__desc clarification">
              {props.description}
              {props.tooltip && props.description && !props.title && (
                <SidebarTrigger
                  id={`${props.id}_tooltip`}
                  text={props.tooltip}
                  icon="Question Circle by Streamlinehq"
                  absolute={false}
                />
              )}
            </p>
          )}
        </Column>
        <Column s="6" align="end">
          <div className="formselect-buttons-row">
            {renderButtons()}
          </div>
          {props.lockedText && (
            <p className="clarification center section-padding-small">{props.lockedText}</p>
          )}

          <ErrorMessage text={props.error_message} showError={throwError} />
        </Column>
      </Columns>
    </>
  );
};

Template.propTypes = {
  options: PropTypes.array,
  id: PropTypes.string.isRequired,
  multiple: PropTypes.bool.isRequired,
  columns: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ])
};

Template.defaultProps = {
  rerenderKey: '',
  columns: true,
  multiple: false,
  title: '',
  error_message: __('select_error'),
  locked: false,
  lockedText: false,
  spacing: true,
  optionsState: {
    validation: 'standard',
    error: false,
    value: {},
    visible: true,
    type: 'select', //is used in validation,
    disabled: false,
  }
};

export default Template;
