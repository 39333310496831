import React, { useState } from 'react';
import __ from 'localisation';
import Dropdown from '@hubins/components/Dropdown';
import FormHistory from 'modules/FormHistory';

const tabs = [
  {
    id: 'cryptocurrency',
    title: __('knowledge_test_cryptocurrency'),
  },
  {
    id: 'stock',
    title: __('knowledge_test_stock'),
  }
];

const AptitudeTests = ({ client }) => {

  const [selectedTab, setSelectedTab] = useState('');

  const theTab = tabs.find(tab => tab.id === selectedTab);

  return (
    <div>
      <Dropdown
        className={"input-max-width"}
        label={__('form')}
        options={tabs}
        value={selectedTab}
        callback={setSelectedTab}
        placeholder={__('choose_form')}
      />
      {theTab && (
        <FormHistory
          key={selectedTab}
          formKey={selectedTab}
          // title={theTab.title}
          contactName={client.name}
          userId={client.national_identity_number}
          type="inv"
          canEdit={false}
        />
      )}
    </div>
  );
};




export default AptitudeTests;
