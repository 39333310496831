import React, { useEffect, useState } from 'react';
import Heading from '@hubins/components/Heading';
import { Column, Columns } from 'components/Columns';
import middleman from 'helpers/middleman';
import Button from '@hubins/components/Button';
import AddTagModal from './AddTagModal';
import SimpleTable from 'modules/Tables/SimpleTable';

const headers = [
  {
    Header: 'Tag',
    accessor: 'name',
  },
  {
    Header: '',
    accessor: 'button',
    width: 30,
  },
];
const Tags = ({ portfolio, profileUuid }) => {

  const [profileTags, setPorfileTags] = useState([]);
  const [portfolioTags, setPortfolioTags] = useState(portfolio.tags || []);
  const [addTag, setAddTag] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchProfile = async () => {
    const profile = await middleman.get(`/profile/${profileUuid}`);
    setPorfileTags(profile.data.tags || []);
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const removeIndex = (index, isPortfolio) => {
    const currentTags = isPortfolio ? portfolioTags : profileTags;
    const newTags = currentTags.filter((tag, i) => i !== index);
    if (isPortfolio) {
      setPortfolioTags(newTags);
    }
    else {
      setPorfileTags(newTags);
    }
  };

  const handleDelete = async (isPortfolio, uuid, id, index) => {
    setLoading(true);
    try {
      const url = isPortfolio ? `/ql/crm/portfolio/tag/${uuid}/${id}` : `/ql/crm/profile/tag/${uuid}/${id}`;
      await middleman.promiseDelete(url);
      removeIndex(index, isPortfolio);
    } catch (e) {
      console.error(e.message);
    }
    setLoading(false);
  };

  return (
    <>
      <AddTagModal open={addTag} setOpen={setAddTag} profileUuid={profileUuid} portfolioUuid={portfolio.uuid} />
      <Columns>
        <Column s="12">
          <Heading size="3">Tags</Heading>
        </Column>
        <Column m="6">
          <Heading size="5" className="s-bottom-m">Portfolio Tags</Heading>
          <SimpleTable
            headers={headers}
            data={[{
              name: 'Add new tag',
              button: <Button icon="add" onlyIcon onClick={() => {setAddTag(true);}} className='transparent' />
            }, ...portfolioTags.map((tag, index) => ({
              name: tag.name,
              button:
                <Button
                  onClick={() => handleDelete(true, portfolio.uuid, tag.id, index)}
                  icon={"Bin by Streamlinehq"}
                  loading={loading}
                  onlyIcon
                  className='transparent'/>
            }))]}
          />
        </Column>
        <Column m="6">
          <Heading size="5" className="s-bottom-m">Profile Tags</Heading>
          <SimpleTable
            headers={headers}
            data={[
              { name: 'Add new tag', button: <Button icon="add" onlyIcon onClick={() => {setAddTag(true);}} className='transparent' /> },
              ...profileTags.map((tag, index) => ({
                name: tag.name,
                button:
                <Button
                  onClick={() => handleDelete(false, profileUuid, tag.id, index)}
                  icon={"Bin by Streamlinehq"}
                  loading={loading}
                  onlyIcon
                  className='transparent' />
              }))]}
          />
        </Column>
      </Columns>
    </>
  );
};

export default Tags;
