import React, { useEffect, useState } from 'react';
import { Content } from 'views/NewDASignOff/content_v2';
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import { Columns, Column } from 'components/Columns';
import Heading from '@hubins/components/Heading';
// import SummaryInfo from 'components/SummaryInfo';
import middleman from 'helpers/middleman';
import ErrorBoundary from 'components/ErrorBoundary';
import { NEW_getAnswerPromise, getSummary } from 'helpers/form';


const DemandAssessment = ({ contact, profileUuid, portfolioUuid }) => {

  const [summary, setSummary] = useState(false);
  const [init, setInit] = useState(false);
  const [accounts, setAccounts] = useState(false);
  const [answer, setAnswer] = useState(false);
  const [deposits, setDeposits] = useState(false);
  const [withdrawals, setWithdrawals] = useState(false);

  const isCompany = contact.type === 'COMPANY';
  const summaryKey = isCompany ? 'c_da_so' : 'p_da_so';

  const fetchQlSpecifikData = async () => {
    const accountsPromise = middleman
      .get(`/portfolio/${portfolioUuid}`)
      .then(res => {
        setAccounts(res.data);
      });
    const transactionsPromise = middleman
      .get(`/v1/fa/transactions/amount/${portfolioUuid}`)
      .then(res => {
        setDeposits(Number(res.data.deposits));
        setWithdrawals(Number(res.data.withdrawals));
      });

    await Promise.all([accountsPromise, transactionsPromise]);

    setInit(true);
  };

  const fetchAnswers = async () => {
    const key = isCompany ? 'c_da' : 'p_da';
    const promises = await Promise.all([
      NEW_getAnswerPromise(`${key}_purpose`, false, profileUuid, portfolioUuid),
      NEW_getAnswerPromise(`${key}_transactions`, false, profileUuid, portfolioUuid),
      NEW_getAnswerPromise(`${key}_products`, false, profileUuid, portfolioUuid),
      NEW_getAnswerPromise(`${key}_target_return`, false, profileUuid, portfolioUuid),
      NEW_getAnswerPromise(`${key}_tax`, false, profileUuid, portfolioUuid),
      NEW_getAnswerPromise(`${key}_beneficiaries`, false, profileUuid, portfolioUuid),
    ]);
    const mergedAnswers = promises.reduce((merged, item) => {
      return {
        ...merged,
        ...item.data.response,
      };
    }, {});
    setAnswer(mergedAnswers);
  };

  useEffect(() => {
    getSummary(summaryKey, profileUuid, portfolioUuid, setSummary);
    fetchAnswers();
  }, []);

  useEffect(() => {
    if (summary !== false) {
      if (summary.type === 'ql') {
        fetchQlSpecifikData();
        return;
      }
      setInit(true);
    }
  }, [summary]);

  if (summary === false || init === false || answer === false) {
    return <LoadingBox />;
  }

  return (
    <Columns className="no-side-padding">
      <Column s="12" className="s-top-s-womq">
        <Heading size="3">
          Demand Assessment
        </Heading>
        {/* <SummaryInfo
          summary={summary}
          name={name}
          // href={`/dashboard/clients/${profileUuid}/${portfolioUuid}/history#${summaryKey.substr(2)}`}
        /> */}
      </Column>
      <Column s="12">
        {(typeof summary.response === 'object') ? (
          <ErrorBoundary>
            <Content
              isCompany={isCompany}
              national_identity_number={contact.national_identity_number}
              info={answer}
              name={contact.name}
              account={accounts}
              deposits={deposits}
              withdrawals={withdrawals}
            />
          </ErrorBoundary>
        ) : (
          <p>No content</p>
        )}

      </Column>
    </Columns>

  );

};

export default DemandAssessment;
