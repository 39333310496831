import Bugsnag from "@bugsnag/browser";
import { formatNumberByThousands } from 'helpers';

// Flatten formData into array of all fields
export const deconstructFormData = (data) =>
  data
    .map((section) => section.fields.map((field) => field))
    .reduce((a, b) => a.concat(b), []);
// .filter(item => item.type !== 'hidden' && item.type !== 'info' && item.type !== 'tooltip' && item.type !== 'subtitle')

// Flatten tests formData into array of all fields
export const deconstructTestsFormData = (data) =>
  deconstructFormData(data.reduce((a, b) => a.concat(b), []));

export const deconstructForm = (data) => {
  if (Array.isArray(data[0])) {
    return deconstructTestsFormData(data);
  }
  return deconstructFormData(data);
};

const summaryCheckRuleSet = (rules, response) => {
  const correctRules = rules.filter((rule) => {
    if (typeof response[rule.key] === "undefined") return false;
    if (rule.value && response[rule.key].includes(rule.answer)) return true;
    if (!rule.value && !response[rule.key].includes(rule.answer)) return true;
    return false;
  });
  return correctRules === rules.length;
};

export const checkSummaryRules = (rules, response) => {
  if (!rules || rules.length === 0) return true;
  const correctRules = rules.filter((ruleSet) => {
    return summaryCheckRuleSet(ruleSet, response);
  });
  return correctRules === rules.length;
};

export const acceptedFieldType = (type) => {
  switch (type) {
    case "select":
    case "input":
    case "textarea":
    case "dropdown":
    case "checkbox":
    case "slider":
    case "file":
    case "repeater":
      return true;
    default:
      return false;
  }
};

const indexOptions = (options) => {
  return options.reduce((collection, option) => {
    collection[option.id] = option;
    return collection;
  }, {});
};

const getSelectDropdownValue = (field, value) => {
  const indexedOptions = indexOptions(field.options);
  return value.reduce((string, key, index) => {
    const option = indexedOptions[key];
    if (index === 0) {
      // if (typeof option === "undefined") return key;
      return option.title;
    }
    string += `, ${option.title}`;
    return string;
  }, "");
};

const getSliderValue = (field, value) => {
  const valueType = field.valueType || "";
  if (typeof value === "object") {
    return `${value.min}${valueType} - ${value.max}${valueType}`;
  }
  return `${value}${valueType}`;
};

const getFileValue = (value) => {
  if (value) {
    return value.map((item) => item.path).toString();
  }
  return "";
};

export const getFieldValue = (field, response) => {
  const { type, id } = field;
  const value = response[id];
  try {
    switch (type) {
      case "input":
      case "textarea":
        if (field.inputType === "currency") {
          return formatNumberByThousands(value);
        }
        return value;
      case "checkbox":
        return "Godkänt";
      case "dropdown":
      case "select":
        return getSelectDropdownValue(field, value);
      case "slider":
        return getSliderValue(field, value);
      case "file":
        return getFileValue(value);
    }
  } catch (e) {
    Bugsnag.notify(e);
    return "";
  }
};
