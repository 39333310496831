import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'components/Tabs';
import ApiPaginationTable from 'modules/Tables/ApiPaginationTable';
import Input from '@hubins/components/Input';
import Button from '@hubins/components/Button';
import Modal from '@hubins/components/Modal';
import { LoadingBar } from '@hubins/components/LoadingIndicators';
import { swedishSsn, orgNumber } from "components/Validation";
import ErrorMessage from 'components/ErrorMessage';
import Dropdown from '@hubins/components/Dropdown';
import middleman from '@hubins/middleman';
import __ from 'localisation';

const loadingObj = {
  name: <LoadingBar small />,
  national_identity_number: <LoadingBar small />,
};

const loadingState = [
  loadingObj,
  loadingObj,
  loadingObj,
];

const typeOptions = [
  { id: 'person', title: __('person') },
  { id: 'company', title: __('company') },
];

const AddProfileToPortfolioRoleModal = ({ roleToAdd, setRoleToAdd, portfolioUuid }) => {

  const [loading, setLoading] = useState(false);
  const [selectedPage, setSelectedPage] = useState(0);
  const [amountOfPages, setAmountOfPages] = useState(-1);
  const [users, setUsers] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [type, setType] = useState('');
  const [email, setEmail] = useState('');
  const [ssn, setSsn] = useState('');
  const [ssnError, setSsnError] = useState(false);
  const [orgNr, setOrgNr] = useState('');
  const [orgNrError, setOrgNrError] = useState(false);

  const addUser = async (user) => {
    if (confirm(`Are u sure you want to add ${user.name} as ${__(roleToAdd)}?`)) {
      setLoading(true);
      try {
        await middleman.post(`/ql/crm/portfolio/add-role/${portfolioUuid}`, {
          portfolioUuid,
          nationalIdentityNumber: user.national_identity_number,
          role: roleToAdd,
        });
        setLoading(false);
        window.location.reload();
      } catch (e) {
        setLoading(false);
        console.error(e.message);
      }
    }
  };

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'National identity number',
      accessor: 'national_identity_number',
    },
    {
      Header: 'Add user',
      Cell: ({ row }) => {
        return (
          <Button
            onClick={() => {
              addUser(row.original);
            }}
            loading={loading}
            disabled={loading}
          >
            Add
          </Button>
        );
      }
    }
  ];

  const fetchPage = async (page, searchParam = false) => {
    setLoading(true);
    setSelectedPage(page - 1);
    const res = await middleman.get(`/ql/crm/search?page=${page}${searchParam ? `&search=${searchParam}` : ''}`);
    setAmountOfPages(res.data.last_page);
    setUsers(res.data.data);
    setLoading(false);
  };

  const handlePageFetch = (index) => {
    fetchPage(index + 1, searchValue);
  };

  const submit = async (e) => {
    e.preventDefault();
    try {
      if (type === 'company') {
        if (orgNumber(orgNr)) {
          setOrgNrError(__('org_validation_error'));
          return;
        }
      } else {
        if (swedishSsn(ssn, 'input', true, false)) {
          setSsnError(__('ssn_validation_error'));
          return;
        }
      }
      setLoading(true);
      await middleman.post(`/ql/crm/portfolio/add-role/${portfolioUuid}`, {
        nationalIdentityNumber: type === 'company' ? orgNr : ssn,
        isCompany: type === 'company',
        email,
        role: roleToAdd,
      });
      window.location.reload();
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <Modal open={!!roleToAdd} callback={setRoleToAdd} closeButton>
      <Tabs
        className="line-tabs"
        selectedTabClassName="line-tabs__tab--selected"
        selectedTabPanelClassName="line-tabs__tab-panel--selected"
      >
        <TabList className="line-tabs__tab-list line-tabs__submenu">
          <Tab className="heading heading--5 line-tabs__tab">Add existing user</Tab>
          <Tab className="heading heading--5 line-tabs__tab">Add none existing user</Tab>
        </TabList>
        <TabPanel>
          <Input
            label="Search"
            value={searchValue}
            callback={setSearchValue}
          />
          <Button
            onClick={() => {fetchPage(1, searchValue);}}
            loading={loading}
            className='s-bottom-xl s-top-md'
          >
            Search
          </Button>
          <ApiPaginationTable
            data={users ? users : loadingState}
            headers={columns}
            fetchData={handlePageFetch}
            pageCount={amountOfPages}
            index={selectedPage}
          />
        </TabPanel>
        <TabPanel>
          <form onSubmit={submit}>
            <Dropdown
              options={typeOptions}
              value={type}
              callback={setType}
              label={__('type')}
              placeholder={__('choose_type')}
            />
            <Input
              label="Email"
              type="email"
              value={email}
              callback={setEmail}
            />
            {type === 'company' ? (
              <>
                <Input
                  label={__('organization_number')}
                  value={orgNr}
                  callback={setOrgNr}
                />
                <ErrorMessage text={orgNrError} showError={orgNrError} />
              </>
            ) : (
              <>
                <Input
                  label={__('national_identity_number')}
                  value={ssn}
                  callback={setSsn}
                />
                <ErrorMessage text={ssnError} showError={ssnError} />
              </>
            )}

            <Button
              disabled={loading}
              loading={loading}
              type="submit"
              className="s-top-xl"
            >
            Spara
            </Button>
          </form>
        </TabPanel>
      </Tabs>
    </Modal>
  );

};

export default AddProfileToPortfolioRoleModal;
