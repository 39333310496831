import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'components/Tabs';
import { __ } from 'localisation';
import useTabs from 'hooks/useTabs';
import Transactions from './Transactions';
import FaTransactions from './FATransactions';
import CreateEntry from '../CreateEntry';

type Props = {
  portfolioUuid: string;
  profileUuid: string;
}

const tabs = [
  'transactions',
  'fa-transactions',
  'create',
]

const Orders = ({ portfolioUuid, profileUuid }: Props) => {
  const [index, setIndex] = useState(0);

  useTabs(setIndex, index, tabs);

  return (
    <Tabs
      className="line-tabs"
      selectedTabClassName="line-tabs__tab--selected"
      selectedTabPanelClassName="line-tabs__tab-panel--selected"
      onSelect={setIndex}
      selectedIndex={index}
    >
      <TabList className="line-tabs__tab-list line-tabs__submenu">
        <Tab className="heading heading--5 line-tabs__tab">{__('orders')}</Tab>
        <Tab className="heading heading--5 line-tabs__tab">{__('fa_transactions')}</Tab>
        <Tab className="heading heading--5 line-tabs__tab">{__('create_order')}</Tab>
      </TabList>
      <TabPanel>
        <Transactions portfolioUuid={portfolioUuid} profileUuid={profileUuid} />
      </TabPanel>
      <TabPanel>
        <FaTransactions portfolioUuid={portfolioUuid} profileUuid={profileUuid} />
      </TabPanel>
      <TabPanel>
        <CreateEntry
          portfolioUuid={portfolioUuid}
          profileUuid={profileUuid}
        />
      </TabPanel>
    </Tabs>
  );
};

export default Orders;