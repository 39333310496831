import React, { useState} from 'react';
// import Batches from '../Batches';
import { Tab, Tabs, TabList, TabPanel } from 'components/Tabs';
import FaTransactions from '../FaTransactions';
// import CreatePaidPremium from '../CreatePaidPremium';
import Transactions from '../Transactions';
// import PositiveAccountBalancePaidPremiumPortfolios from '../PositiveAccountBalancePaidPremiumPortfolios';
// import UnbookedTransactions from '../UnbookedTransactions';
import CreateEntry from 'views/Client/CreateEntry';
import __ from 'localisation';

const tabs = [
  // 'batches',
  'transactions',
  'fa-transactions',
  'create-tof',
  // 'create-paid-premium',
  // 'pending-bookings',
  // 'unbooked-transactions'
]

const Orders = ({ match, history }) => {

  const { tab } = match.params;
  const [index, setIndex] = useState(
    tabs.includes(tab) ? tabs.indexOf(tab) : 0
  );

  const changeTab = (newIndex) => {
    const newTab = tabs[newIndex];
    history.replace(`/dashboard/orders/${newTab}`);
    setIndex(newIndex);
  };

  return (
    <div>
      <Tabs
        className="line-tabs"
        selectedTabClassName="line-tabs__tab--selected"
        selectedTabPanelClassName="line-tabs__tab-panel--selected"
        onSelect={changeTab}
        selectedIndex={index}
      >
        <TabList className="line-tabs__tab-list">
          {/* <Tab className="heading heading--5 line-tabs__tab">{__('order_processes')}</Tab> */}
          <Tab className="heading heading--5 line-tabs__tab">{__('all_orders')}</Tab>
          <Tab className="heading heading--5 line-tabs__tab">{__('fa_transactions')}</Tab>
          <Tab className="heading heading--5 line-tabs__tab">{__('create_premium_in_kind')}</Tab>
          {/* <Tab className="heading heading--5 line-tabs__tab">{__('create_paid_premium')}</Tab>
          <Tab className="heading heading--5 line-tabs__tab">{__('cash_balance')}</Tab>
          <Tab className="heading heading--5 line-tabs__tab">{__('received_premium_in_kind')}</Tab> */}
        </TabList>
        {/* <TabPanel className="line-tabs__tab-panel">
          <Batches />
        </TabPanel> */}
        <TabPanel className="line-tabs__tab-panel">
          <Transactions />
        </TabPanel>
        <TabPanel className="line-tabs__tab-panel">
          <FaTransactions />
        </TabPanel>
        <TabPanel className="line-tabs__tab-panel">
          <CreateEntry />
        </TabPanel>
        {/* <TabPanel className="line-tabs__tab-panel">
          <CreatePaidPremium />
        </TabPanel>
        <TabPanel className="line-tabs__tab-panel">
          <PositiveAccountBalancePaidPremiumPortfolios />
        </TabPanel>
        <TabPanel className="line-tabs__tab-panel">
          <UnbookedTransactions />
        </TabPanel> */}
      </Tabs>
    </div>
  )
};

export default Orders;