import React from 'react';
import LoadingAnimation from '../HubinsLoadingAnimation';
import __ from 'localisation';

type Props = {
  page?: boolean;
  height?: string;
  children?: React.ReactNode;
  isLoading?: boolean;
  timedOut?: boolean;
  pastDelay?: number;
  error?: any;
}

export const LoadingBox = ({
  page = true,
  height = '10em',
  children,
  isLoading = true,
  timedOut,
  pastDelay = 1,
  error = false,
  ...restProps
}: Props) => {
  if (isLoading) {
    if (timedOut) {
      return <div><p>{__('bad_network')}</p></div>;
    } else if (pastDelay) {
      return (
        <div className={`main flex-center ${page ? 'flex-grow' : ''}`} style={{ height: page ? 'auto' : height }}>
          {children
            ? children
            : <LoadingAnimation {...restProps} />
          }
        </div>
      );
    } else {
      return null;
    }
  } else if (error) {
    return (
      <div className="main">
        <p>Error loading module. {error.message}. Try reloading or contact support at <a href={`mailto: ${__('platform_email')}`} rel="noopener noreferrer" target="_blank">{__('platform_email')}</a></p>
      </div>
    );
  } else {
    return null;
  }
};

export default LoadingBox;
