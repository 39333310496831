import React, { useState } from 'react';
import { useFATransactions } from 'queries/transactions';
import SimpleTable from 'modules/Tables/SimpleTable';
import Input from '@hubins/components/Input';
import DateInput from '@hubins/components/DateInput';
import Button from '@hubins/components/Button';
import { Column, Columns } from 'components/Columns';
import { tableHeaders, typeCodeOptions, loadingState } from 'helpers/faTransactions';
import MultipleDropdown from '@hubins/components/MultipleDropdown';
import __ from 'localisation';

type Props = {
  portfolioUuids?: string[];
}

const FaTransactions = ({ portfolioUuids }: Props) => {
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [typeCodes, setTypeCodes] = useState<string[]>([]);
  const [portfolioId, setPortfolioId] = useState<any>(undefined);
  const [startDateInput, setStartDateInput] = useState<string>('');
  const [endDateInput, setEndDateInput] = useState<string>('');
  const [typeCodeInput, setTypeCodeInput] = useState<string[]>([]);
  const [portfolioIdInput, setPortfolioIdInput] = useState<string | number>('');

  const { data, isLoading } = useFATransactions(startDate, endDate, typeCodes, portfolioId, portfolioUuids);

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setStartDate(startDateInput || undefined);
    setEndDate(endDateInput || undefined);
    setTypeCodes(typeCodeInput || undefined);
    setPortfolioId(portfolioIdInput || undefined);
  }

  return (
    <form onSubmit={submit}>
      <Columns>
        <Column s="12">
          <p>{__('only_displays_latest_100_transactions')}</p>
        </Column>
        <Column m="6">
          <DateInput
            label={__("start_date")}
            value={startDateInput}
            callback={setStartDateInput}
          />
        </Column>
        <Column m="6">
          <DateInput
            label={__("end_date")}
            value={endDateInput}
            callback={setEndDateInput}
          />
        </Column>
        <Column m="6">
          <MultipleDropdown
            label={__('type')}
            options={typeCodeOptions}
            values={typeCodeInput}
            callback={setTypeCodeInput}
            placeholder={__('choose_type')}
          />
        </Column>
        {!portfolioUuids && (
        <Column m="6">
          <Input
            label={__('portfolio_exact_name')}
            value={portfolioIdInput}
            callback={setPortfolioIdInput}
          />
          </Column>
        )}
        <Column s="12">
          <Button
            type="submit"
            className='cta'
          >
            {__('search')}
          </Button>
        </Column>
        <Column s="12">
          <SimpleTable
            headers={tableHeaders}
            data={isLoading ? loadingState : data}
          />
        </Column>
      </Columns>
    </form>
  );
};

export default FaTransactions;