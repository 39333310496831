import React from 'react';
import { Column, Columns } from 'components/Columns';
import Heading from '@hubins/components/Heading';
import __ from 'localisation';
import SimpleTable from 'modules/Tables/SimpleTable';
import LoadingBar from '@hubins/components/LoadingIndicators';
import { getMoney } from 'helpers';
import Comments from '@hubins/components/Comments';
const typeValueHeaders = [
  {
    Header: '',
    accessor: 'type',
  },
  {
    Header: '',
    accessor: 'value',
  }
]

const Dashboard = ({client, portfolio}) => {

  const clientData = [
    {
      type: __('name'),
      value: client?.name || <LoadingBar />,
    },
    {
      type: __('national_identity_number'),
      value: client?.national_identity_number || <LoadingBar />,
    },
    {
      type: __('updated_at'),
      value: client?.updated_at || <LoadingBar />,
    }
  ];

  const portfolioData = [
    {
      type: __('insurance_number'),
      value: portfolio?.short_name || <LoadingBar />,
    },
    {
      type: __('distributor'),
      value: portfolio?.distributor || <LoadingBar />,
    },
    {
      type: __('status'),
      value: portfolio?.status || <LoadingBar />,
    },
    {
      type: __('account_balance'),
      value: getMoney(portfolio?.account_balance || 0),
    },
    {
      type: __('market_value'),
      value: getMoney(portfolio?.market_value || 0),
    },
    {
      type: __('updated_at'),
      value: portfolio?.updated_at || <LoadingBar />,
    }
  ];

  return (
    <Columns>
      <Column l="6">
        <Heading size="4" className='s-bottom-xl'>{__('policy_holder')}</Heading>
        <SimpleTable headers={typeValueHeaders} data={clientData} />
      </Column>
      <Column l="6">
        <Heading size="4" className='s-bottom-xl'>{__('portfolio')}</Heading>
        <SimpleTable headers={typeValueHeaders} data={portfolioData} />
      </Column>
      <Column l="6">
        <Comments
          title={__('policy_holder_comments')}
          model='Profile'
          modelUuid={client?.uuid}
          presetChannels={['hubins_backoffice', 'hubins_compliance', 'quantum_backoffice', 'quantum_compliance']}
        />
      </Column>
      <Column l="6">
        <Comments
          title={__('portfolio_comments')}
          model='Portfolio'
          modelUuid={portfolio?.uuid}
          presetChannels={['hubins_backoffice', 'hubins_compliance', 'quantum_backoffice', 'quantum_compliance']}
        />
      </Column>
    </Columns>

  )
};

export default Dashboard;