import React from 'react';
import { LoadingInput } from '@hubins/components/LoadingIndicators';
import SidebarTrigger from 'components/Sidebar/SidebarTrigger';

import './index.scss';
import Heading from '@hubins/components/Heading';

const InputAutofill = ({
  title,
  label,
  labelDescription,
  tooltip,
  id,
  value = false,
  paragraph = false,
  suffix,
  className,
}) => {
  return (
    <>
      {(title && label) && <Heading element="h3" size={4} className="s-bottom-sm">{title}</Heading>}
      <label className={className} id={id}>
        <div className="label-wrapper">
          {!label && <Heading element="h3" size={4} className="s-bottom-sm">{title}</Heading>}
          {label && (<div className="label">{label}:</div>)}
          {labelDescription && (<div className="label c-neutral-4">{labelDescription}</div>)}
        </div>
        {tooltip && (<SidebarTrigger
          text={tooltip}
          id={`${id}_tooltip`}
          icon="question"
        />)}
        {paragraph
          ? (
            <p><strong>{value}</strong></p>
          )
          : value === false
            ? (
              <LoadingInput />
            )
            : (
              <div className={`input__wrap`}>
                <input disabled className="input input-autofill" value={`${value}`} />
                {suffix && (
                  <div className="input__suffix input__suffix--autofill">{suffix}</div>
                )}
              </div>
            )
        }
      </label>
    </>
  );
};

export default InputAutofill;
