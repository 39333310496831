import {
  useQuery,
} from 'react-query';

import middleman from 'helpers/middleman';

export const useSourceOfFunds = (profileUuid) => useQuery(['sourceOfFunds', profileUuid], () => {
  return middleman.promiseGet(`/ql/crm/source-of-funds/${profileUuid}`).then(response => response.data);
});

export const clientUseSourceOfFunds = (portfolioUuid) => useQuery(['clientSourceOfFunds', portfolioUuid], () => {
  return middleman.promiseGet(`/source-of-funds/show/${portfolioUuid}`).then(response => response.data);
}, { enabled: !!portfolioUuid });
