import React, { useRef, useCallback, useState } from 'react';
import middleman from 'helpers/middleman';
import Icon from '../IconNew';
import '../Button/button.scss';

type Props = {
  url: string,
  filename: string,
  children?: any,
  icon?: string | null,
  className?: string,
  onlyIcon?: boolean,
  size?: 'small' | 'medium',
}

const AuthDownloadLink = ({ url, filename, children, icon = 'Download Dash Arrow 1', className, onlyIcon, size = 'medium' }: Props) => {

  const [loading, setLoading] = useState(false);

  const ref = useRef<any>(null);

  const handleClick = useCallback(async () => {
    try {
      if (ref.current && ref.current.href) { return }
      setLoading(true);
      const result = await middleman.getBlob(url);
      const href = window.URL.createObjectURL(result.data);
      ref.current.download = filename;
      ref.current.href = href;
      ref.current.click();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }

  }, [ref]);

  const iconProps = {
    icon: loading ? 'loading' : icon,
    anim: loading ? 'spin' : undefined,
  }

  return (
    <a className={`${onlyIcon ? 'button-icon' : `button ${icon ? `withicon withicon--${size}` : ''} ${size}`}  ${className}`} role="button" ref={ref} onClick={handleClick}>
      {children}
      {icon && <Icon {...iconProps} size={16} color="primary" />}
      <span className="active-effect" />
    </a>
  )
};

export default AuthDownloadLink;